import React, { useState} from "react";
import { useRegister, useLogin, useNotification } from "@refinedev/core";
import { Form, Input, Checkbox, Tag } from "antd"
import { Typography, Box, Stack, Grid, Button } from "@mui/material";
import { oktelo } from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import api from "utils/interceptor"
import apiconfig from "utils/apiconfig";

type FormVariables = {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    regpromo?: string;
};

export const RegisterPage = () => {
    document.title = "Oktelo App | Regisztráció";
    const { mutate: register } = useRegister<FormVariables >();
    const { mutate: login } = useLogin<FormVariables>();
    const { open } = useNotification();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const promo = queryParams.get('promo');

    const handlePassword = (e: any) => {
        setPassword(e.target.value);
    };

    const checkPromo = async (promo: string) => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/users/promo/${promo}`);
            return response.data.available;
        } catch (error) {
            console.error('Error checking promo code:', error);
            return false;
        }
    };

    const onSubmit = async (values: FormVariables) => {

        const hasUppercase = /[A-Z]/.test(values.password);
        const hasNumber = /\d/.test(values.password);
        const hungarianAlphabet = /^[a-zA-ZáÁéÉíÍóÓöÖőŐúÚüÜűŰ]+$/;

        const isValidFirstName = hungarianAlphabet.test(values.firstName);
        const isValidLastName = hungarianAlphabet.test(values.lastName);

        if (values.firstName.length < 2){
            open?.({
                type: "error", 
                message: "A keresztneved legyen minimum 2 karakteres!"
            })
        }

        if (values.lastName.length < 2){
          open?.({
              type: "error",
              message: "A vezetékneved legyen minimum 2 karakteres!"
          })
      }

        if (values.password.length < 7){
            open?.({
                type: "error",
                message: "A jelszavad legyen minimum 7 karakter!",
              }); 
            return;         
        }

        if (!hasUppercase || !hasNumber) {
            open?.({
                type: "error",
                message: "A jelszónak legalább egy nagybetűt és egy számot kell tartalmaznia.",
            }); 
            return;   
        }

        if (!isValidFirstName || !isValidLastName){
            return open?.({
                type: "error",
                message: "A neved csak betűket tartalmazhat.",
            });
        }

        else{
            const gotValues = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                regpromo: values.regpromo,
              };

              if (values.regpromo){
                const isPromoValid = await checkPromo(values.regpromo);
                if (!isPromoValid) {
                    return open?.({
                        type: "error",
                        message: "Érvénytelen promóciós kód.",
                    });
                }
            }
          
              register(gotValues, {
                onSuccess: (data) => {
                    if(!data.success){
                        open?.({
                            type: "error",
                            message: data.message as string,
                            description: "Hiba történt."
                        })
                    }
                    else{
                        open?.({
                            type: "success",
                            message: "Mostmár be tudsz jelentkezni.",
                            description: "Siker!"
                            })
                    }

                },
                onError: (error) => {
                    return console.log(error);
            }});
        }
    };
  
    return (
      <main style={{ minHeight: '100vh', flex: 1, overflow: 'clip', display: 'flex', padding: '20px' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="auto"
        alignSelf="center"
      >
          <Grid container className="auth-container-background">
            <Grid item xs={12} md={6} p={4} className="login-box-left">
              <Box display="flex" flexDirection="column" gap={3} width="100%">
              <img src={oktelo} width="80px" alt="Logo" />

                  <Typography className="darkblue size_24 t_oktelo_medium">Regisztráció</Typography>
            <Typography className="darkblue size_16 t_oktelo_light" mb={2}>
              Hozz létre egy új Oktelo felhasználót, ingyenesen.
            </Typography>

                <Form
                  layout="vertical"
                  onFinish={onSubmit}
                  requiredMark={false}
                  initialValues={{
                    remember: false,
                    regpromo: promo ? promo : '',
                  }}
                >
                    <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
                    <Form.Item
                      name="lastName"
                      label="Vezetéknév"
                      rules={[{ required: true, message: 'Vezetéknevet kötelező megadnod!' }]}
                      className="t_oktelo_normal darkblue size_14"
                      style={{ width: '100%'}}
                      
                    >
                      <Input
                        size="middle"
                        placeholder="Vezetékneved"
                        className="t_oktelo_normal size_14 darkblue"
                        width="100%"
                      />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      label="Keresztnév"
                      rules={[{ required: true, message: 'Keresztnevet kötelező megadnod!' }]}
                      className="t_oktelo_normal darkblue size_14"
                      style={{ width: '100%'}}
                    >
                      <Input
                        size="middle"
                        placeholder="Keresztneved"
                        className="t_oktelo_normal size_14 darkblue"
                        width="100%"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="email"
                    label="Email címed"
                    rules={[{ required: true, message: 'E-mail címet kötelező megadnod!' }]}
                    className="t_oktelo_normal darkblue size_14"
                  >
                    <Input                   
                        size="middle" 
                        placeholder="Valós e-mail címed"
                        className="t_oktelo_normal size_14 darkblue" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Jelszó"
                    rules={[{ required: true, message: 'A jelszavadat kötelező megadnod!' }]}
                    className="t_oktelo_normal darkblue size_14"
                    style={{ marginBottom: '16px' }}
                  >
                    <Input
                      type="password"
                      size="middle" 
                      className="t_oktelo_normal size_14 darkblue"
                      onChange={handlePassword} 
                    />
                  </Form.Item>
                  <Stack direction="row" gap={1} justifyContent="flex-end" alignItems="center">
                    <Tag bordered={false} color={password.length < 7 ? 'red' : 'green'} className="tag_oktelo size_12">Hossz</Tag>
                    <Tag bordered={false} color={!/\d/.test(password) ? 'red' : 'green'} className="tag_oktelo size_12">Szám</Tag>
                    <Tag bordered={false} color={!/[A-Z]/.test(password) ? 'red' : 'green'} className="tag_oktelo size_12">Nagybetű</Tag>
                  </Stack>
                  <Form.Item
                    name="password_check"
                    label="Jelszó mégegyszer"
                    rules={[{ required: true, message: 'A jelszavadat kötelező megadnod!' }]}
                    className="t_oktelo_normal darkblue size_14"
                    style={{ marginBottom: '16px' }}
                  >
                    <Input
                      type="password"
                      size="middle" 
                      className="t_oktelo_normal size_14 darkblue"
                      onChange={handlePassword} 
                    />
                  </Form.Item>
                  <Form.Item
                    name="regpromo"
                    label="Baráti meghívókód"
                    className="t_oktelo_normal darkblue size_14"
                    style={{ marginBottom: '16px' }}
                  >
                    <Input size="middle" className="t_oktelo_normal size_14 darkblue" />
                  </Form.Item>
                  <Form.Item name="policy" valuePropName="checked" required>
                    <Checkbox className="t_oktelo_light size_12 darkblue">
                      Elolvastam és elfogadom az <a href="https://oktelo.com/altalanos-szerzodesi-feltetelek/" target="_blank">Általános Szerződési Feltételeket</a>, az <a href="https://oktelo.com/adatkezelesi-tajekoztato/" target="_blank">Adatkezelési tájékoztatót</a> és az <a href="https://oktelo.com/adatmegorzesi-szabalyzat/" target="_blank">Adatfeldolgozási irányelveket</a>.
                    </Checkbox>
                  </Form.Item>
                  <button
                    className="login_primary_button"
                    type="submit"
                  >
                    <Typography className="t_white size_16 t_oktelo_normal">
                        Regisztrálok
                    </Typography>
                  </button>
                </Form>
                <button
                  style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                  onClick={() => navigate('/login')}
                >
                    <Typography className="darkblue size_14 t_oktelo_normal" sx={{ ':hover': { opacity: '0.8'}}}>
                        Van már fiókod? <span className="t_purple">Bejelentkezés</span>
                    </Typography>
                </button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="login-box-right">
          <img src="https://res.cloudinary.com/duhpymkbk/image/upload/v1724102315/mkzs3c9xfnyshj2upe2b.png" width="100%" height="auto"/>
        </Grid>
          </Grid>
          <Box mt={5}>
      <Stack direction="row" gap={3} alignItems="center" flexWrap="wrap" justifyContent="center">
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/adatkezelesi-tajekoztato/" target="_blank">
            Adatkezelési Tájékoztató
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/altalanos-szerzodesi-feltetelek/" target="_blank">
            Általános Szerződési Feltételek
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/adatmegorzesi-szabalyzat/" target="_blank">
            Adatfeldolgozási irányelvek
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com" target="_blank">
            Oktelo kezdőlap
          </a>
        </Typography>
        <Typography className="t_oktelo_normal size_12 t_purple">
          <a href="https://oktelo.com/kapcsolat/" target="_blank">
            Kapcsolat
          </a>
        </Typography>
      </Stack>
    </Box>
        </Box>
        </main>
      );
      
  };
import React, { useState, useEffect } from 'react';
import api from "utils/interceptor"
import { Box, Button, Stack, Typography, Badge } from '@mui/material';
import dayjs from 'dayjs';
import ChatIcon from '@mui/icons-material/Chat';
import { CircularProgress } from '@mui/material';
import { Input } from 'antd';
import SendIcon from '@mui/icons-material/Send';
import apiconfig from "utils/apiconfig";

// Define interfaces
interface ChatWindowProps {
    isOpen: boolean;
    handleClose: () => void;
}

interface Message {
    from: { 
        name: string;
        id: string;
    };
    created_time: string;
    message: string;
    to: {
        data: Array<{
            name: string;
            id: string;
        }>;
    };
}

// ChatMessages Component
interface ChatMessagesProps {
    id: string;
    messages: Message[];
    isLoading: boolean;
    fetchMessages: (convoId: string) => Promise<void>;
    reply: string;
    onReplyChange: (value: string) => void;
    onSendMessage: () => void;
}
 
interface Conversation {
    id: string;
    title: string;
    updated: Date;
}

interface ChatWindowConversations {
    convo: Conversation;
}

const ChatWindow: React.FC = () => {
    const [convos, setConvos] = useState<Conversation[]>([]);
    const [selectedConvo, setSelectedConvo] = useState<Conversation | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [loadingConvos, setLoadingConvos] = useState<boolean>(false);
    const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
    const [reply, setReply] = useState<string>('');

    const selectedPage = localStorage.getItem('sp');

    useEffect(() => {
        if (isOpen && selectedPage) {
            fetchConvos();
        }
    }, [isOpen, selectedPage]);

    const fetchConvos = async () => {
        setLoadingConvos(true);
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/messages`, {
                headers: { pageid: selectedPage }
            });
            setConvos(response.data);
        } catch (error: any) {
            console.error('Error fetching conversations:', error.message);
        } finally {
            setLoadingConvos(false);
        }
    };


    const updateConversationTitles = async () => {
        try {
            const updatedConvos = await Promise.all(
                convos.map(async (convo) => {
                    try {
                        // Fetch messages for the conversation
                        const messagesData = await fetchMessages(convo.id);
                        
                        // Ensure messagesData is not empty
                        if (messagesData.length === 0) {
                            throw new Error('No messages found');
                        }

                        // Get the name of the first participant
                        const firstParticipantName = messagesData[0].from.name;

                        // Constructing the updated conversation object
                        const updatedConvo = {
                            ...convo,
                            title: firstParticipantName,
                        };

                        return updatedConvo;
                    } catch (error: any) {
                        console.error(`Error updating conversation title for convo ID ${convo.id}:`, error.message);
                        return convo; // Return the original convo if there's an error
                    }
                })
            );

            setConvos(updatedConvos);
        } catch (error: any) {
            console.error('Error updating conversation titles:', error.message);
        }
    };
    
        useEffect(() => {
            updateConversationTitles()
        }, [])
    
    const fetchMessages = async (convoId: string) => {
        setLoadingMessages(true);
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/messages/${convoId}`, {
                headers: { pageid: selectedPage }
            });
            setMessages(response.data.data);
    
            setLoadingMessages(false); // Set loading to false after messages are fetched
    
            return response.data.data; // Return the messages data array
    
        } catch (error: any) {
            console.error('Error fetching messages:', error.message);
            setLoadingMessages(false); // Ensure loading is set to false in case of error
            return []; // Return empty array or handle error as per your application logic
        }
    };

    const handleConvoClick = (convo: Conversation) => {
        setSelectedConvo(convo);
        fetchMessages(convo.id);
    };

    const handleBackButtonClick = () => {
        setSelectedConvo(null);
        setMessages([]); // Clear messages when going back to conversation list
    };

    const handleSendMessage = async () => {
        try {
            const currentUserId = localStorage.getItem('sp');

            const recipientMessage = messages.find(
                msg => msg.from.id !== currentUserId
            );

            if (!recipientMessage) {
                console.error('Recipient not found');
                return;
            }

            const convid = recipientMessage.from.id;
            const pageid = localStorage.getItem('sp');
            const message = reply;

            const response = await api.post(
                `${apiconfig.BACKEND_URI}/messages/send`,
                {
                    pageid: pageid,
                    convid: convid,
                    message: message,
                }
            );

            if (response.status === 200) {
                console.log('Message sent successfully');
                setReply('');
                fetchMessages(selectedConvo?.id || ''); // Fetch messages again after sending a new message
            }
        } catch (error: any) {
            console.error('Error sending message:', error.message);
        }
    };

    return isOpen ? (
        <Box
            className={'animate__animated animate__fadeInUpBig'}
            sx={{
                position: 'fixed',
                right: '12px',
                bottom: '12px',
                height: '500px',
                width: '360px',
                backgroundColor: '#fff',
                borderRadius: '0px',
                overflow: 'auto',
                zIndex: '9999',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                opacity: isOpen ? 1 : 0,
                transform: `translateY(${isOpen ? 0 : '100%'})`,
                transition: 'opacity 0.3s ease, transform 0.3s ease',
                backdropFilter: 'blur(6px)'
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} width="100%" p={1.2}>
                <Typography className="t_oktelo_medium size_12 darkblue">OkteloChat v0.2</Typography>
                {selectedConvo && (
                    <Button onClick={handleBackButtonClick} className="oktelo_card_button">← Vissza</Button>
                )}
                <Button onClick={() => { setOpen(false); setSelectedConvo(null); }} className="b_oktelo_purple">X</Button>
            </Stack>
            {selectedConvo ? (
                <ChatMessages
                    id={selectedConvo.id}
                    messages={messages}
                    isLoading={loadingMessages}
                    fetchMessages={fetchMessages}
                    reply={reply}
                    onReplyChange={setReply}
                    onSendMessage={handleSendMessage}
                />
            ) : (
                loadingConvos ? (
                    <Box
                        padding={4}
                        display="flex"
                        width="100%"
                        alignItems="center"
                        gap={2}
                        flexDirection="row"
                    >
                        <CircularProgress
                            sx={{ color: '#925FFF', width: '20px', height: '20px' }}
                        />
                        <Typography className="t_oktelo t_desc t_purple">
                            Betöltés..
                        </Typography>
                    </Box>
                ) : (
                    convos.map((convo, index) => (
                        <ChatWindowConvos key={convo.id} index={index} convo={convo} onClick={() => handleConvoClick(convo)} />
                    ))
                )
            )}
        </Box>
    ) : (
        <Box
            sx={{
                position: 'fixed',
                right: '8px',
                bottom: '8px',
                height: '64px',
                width: '64px',
                backgroundColor: 'transparent',
                borderRadius: '50%',
                zIndex: '9999',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: ''
            }}
        >
            <Button className="oktelo_card_button" onClick={() => setOpen(true)}>
                <Badge color="warning" badgeContent={convos.length}>
                    <ChatIcon sx={{ color: '#925FFF', width: '40px', height: '40px'}}/>
                </Badge>
            </Button>
        </Box>
    );
};

// ChatWindowConvos Component
const ChatWindowConvos: React.FC<{ convo: Conversation; onClick: () => void; index: number }> = ({ convo, onClick, index }) => {
    return (
        <Stack
            width="100%"
            direction="row"
            alignItems="center"
            gap={1}
            onClick={onClick}
            sx={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #f6f6f6' }}
        >
            <Typography className="t_oktelo_medium darkblue size_14">{convo.title}</Typography>
            <Typography className="t_oktelo_light size_12 darkblue">(Frissítve: {dayjs(convo.updated).format('YYYY.MM.DD HH:mm')})</Typography>
        </Stack>
    );
};


const ChatMessages: React.FC<ChatMessagesProps> = ({
    id,
    messages,
    isLoading,
    fetchMessages,
}) => {
    const [reply, setReply] = useState<string>('');
    const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

    // UseEffect to periodically fetch messages
    useEffect(() => {
        const interval = setInterval(() => {
            fetchMessages(id);
        }, 10000);

        return () => clearInterval(interval); // Clean up interval on component unmount or re-render
    }, [id, fetchMessages]);

    const handleReply = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReply(e.target.value);
    };

    const sendMessage = async () => {
        try {
            const currentUserId = localStorage.getItem('sp');

            const recipientMessage = messages.find(
                msg => msg.from.id !== currentUserId
            );

            if (!recipientMessage) {
                console.error('Recipient not found');
                return;
            }

            const convid = recipientMessage.from.id;
            const pageid = localStorage.getItem('sp');
            const message = reply;

            const response = await api.post(
                `${apiconfig.BACKEND_URI}/messages/send`,
                {
                    pageid: pageid,
                    convid: convid,
                    message: message,
                }
            );

            if (response.status === 200) {
                console.log('Message sent successfully');
                setReply('');
                fetchMessages(id); // Fetch messages again after sending a new message
            }
        } catch (error: any) {
            console.log('Error sending message:', error.message);
        }
    };

    useEffect(() => {
        // Set initial loaded state when messages are loaded for the first time
        if (!isLoading && messages.length > 0) {
            setInitialLoaded(true);
        }
    }, [isLoading, messages]);

    // Handle loading state when messages are still loading
    if (isLoading && !initialLoaded) {
        return (
            <Box
                padding={4}
                display="flex"
                width="100%"
                alignItems="center"
                gap={2}
                flexDirection="row"
            >
                <CircularProgress
                    sx={{ color: '#925FFF', width: '20px', height: '20px' }}
                />
                <Typography className="t_oktelo t_desc t_purple">
                    Betöltés..
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{ padding: '10px' }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="auto"
        >
            <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1} mb={1.5}>
                <Typography className="t_oktelo_title">Üzenetek</Typography>
                {/* <Typography className="t_oktelo_prop t_purple">(4 új)</Typography> */}
            </Stack>
            {messages.slice().reverse().map((msg, index) => (
                <Box
                    key={index}
                    sx={{ marginBottom: '10px', borderBottom: '1px solid #f6f6f6' }}
                >
                    {msg.from.name === localStorage.getItem('spn') && (
                        <Typography className="t_oktelo t_misc t_purple">
                            Válaszoltál:
                        </Typography>
                    )}
                    <Typography className="t_oktelo_text">
                        <span className="t_oktelo t_highlight">
                            {msg.from.name}
                        </span>
                        :{' '}
                        {msg.message === '' ? (
                            <i>Nem megjeleníthető üzenet</i>
                        ) : (
                            msg.message
                        )}
                    </Typography>
                    <Typography className="t_oktelo_prop">
                        {dayjs(msg.created_time).format('YYYY.MM.DD HH:mm')}
                    </Typography>
                </Box>
            ))}
            <Stack direction="row">
                <Input
                    placeholder="Válasz.."
                    value={reply}
                    onChange={handleReply}
                />
                <Button onClick={sendMessage} className="oktelo_card_button">
                    <SendIcon />
                </Button>
            </Stack>
        </Box>
    );
};

export default ChatWindow;

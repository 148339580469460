// AuthLayout.tsx
import React, { ReactNode } from 'react';
import "./authlayout.css";

interface AuthLayoutProps {
    children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    return (
        <div className="auth-container">
            <main className="auth-content register_box">
                {children}
            </main>
        </div>
    );
};

export default AuthLayout;

import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Typography, Box, ButtonGroup, Button, Stack, Select, MenuItem } from '@mui/material';
import "dayjs/locale/hu";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CalendarMonthOutlined, PublicOutlined, ScheduleSendOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface CalendarItem {
    title: string;
    date: dayjs.Dayjs;
    image: string;
    type: string;
    id: string;
}

interface CustomCalendarProps {
    items: CalendarItem[];
}

dayjs.locale('hu');
dayjs.extend(utc);
dayjs.extend(timezone);

const OkteloCalendar: React.FC<CustomCalendarProps> = ({ items }) => {
    const [viewType, setViewType] = useState<'weekly' | 'monthly'>('weekly'); // Default view is weekly
    const navigate = useNavigate();
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month());

    const handleMonthChange = (event: any) => {
        setSelectedMonth(event.target.value);
    };

    const renderCalendarHeader = () => {
        return (
            <Grid container spacing={1} mb={2}>
                <Grid item xl={6}>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={2}>
                <ButtonGroup aria-label="calendar view" className="okt-calendar-bgroup">
                    <Button
                        onClick={() => setViewType('weekly')}
                        variant={viewType === 'weekly' ? 'contained' : 'outlined'}
                        sx={{ color:  viewType === 'weekly' ? '#fff' : '#925FFF', border: '1px solid #925FFF', backgroundColor: viewType === 'weekly' ? '#925FFF' : 'transparent'}}
                    >
                        Hét
                    </Button>
                    <Button
                        onClick={() => setViewType('monthly')}
                        variant={viewType === 'monthly' ? 'contained' : 'outlined'}
                        sx={{ color:  viewType === 'monthly' ? '#fff' : '#925FFF', border: '1px solid #925FFF', backgroundColor: viewType === 'monthly' ? '#925FFF' : 'transparent'}}
                    >
                        Hónap
                    </Button>
                </ButtonGroup>
                {viewType === 'monthly' &&
                <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                displayEmpty
                className="posts-select"
                sx={{ width: '200px'}}
                inputProps={{ 'aria-label': 'Select month' }}
                renderValue={( selected => {
                    if (selected) {
                        return <Typography className="t_oktelo_normal size_14 darkblue" ml={1}>{dayjs().month(selected).format('MMMM')}</Typography>
                    } else {
                        return <Typography className="t_oktelo_normal size_14 darkblue"  ml={1}>{selectedMonth}</Typography>
                    }
                })}
            >
                {Array.from({ length: 12 }, (_, index) => (
                    <MenuItem key={index} value={index} className="t_oktelo_normal size_14 darkblue">
                        {dayjs().month(index).format('MMMM')}
                    </MenuItem>
                ))}
            </Select>}
            </Stack>
                </Grid>
                <Grid item xl={6}>
                <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end" height="100%">
                    <Stack direction="row" alignItems="center" gap={1}>
                        <CalendarMonthOutlined sx={{ fontSize: '16px' }}/>
                        <Typography className="t_oktelo_medium size_12">
                            Naptárba helyezve
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <ScheduleSendOutlined sx={{ fontSize: '16px' }}/>
                        <Typography className="t_oktelo_medium size_12">
                            Ütemezett közzététel
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <PublicOutlined sx={{ fontSize: '16px' }}/>
                        <Typography className="t_oktelo_medium size_12">
                            Közzétéve
                        </Typography>
                    </Stack>
                </Stack>
                </Grid>

            </Grid>
        );
    };

    const renderWeeklyCalendar = () => {
        const startOfWeek = dayjs().startOf('week'); // Start of current week
        const days = [...Array(7)].map((_, index) => startOfWeek.add(index, 'day'));
        const todayDate = dayjs().format('YYYY-MM-DD')

        return (
            <Grid container spacing={2}>
                {days.map((day) => (
                    <Grid item xs={12} md={3} key={day.format('YYYY-MM-DD')}>
                        <Box
                            sx={{
/*                                 border: '1px solid #d3d3d3', */
                                padding: '0px',
                                borderRadius: '0px',
                                cursor: 'pointer',
                                height: '100%',
                                backgroundColor: '#eee9f8',
                            }}
                            onClick={() => handleCellClick(day)}
                        >
                            <Box sx={{ width: '100%', padding: '4px',
                                backgroundColor: dayjs().isSame(day, 'day') ? '#bc74ff' : '#925FFF', // Check if current day is today
                                color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0px 0px 0px 0px' }}>
                            <Typography className="t_oktelo_medium size_14 t_white">
                                {day.format('YYYY.MM.DD (dddd)')}
                            </Typography>
                            </Box>

                            {/* Render items associated with this day */}
                            {renderItemsForDay(day)}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    };
    

    const renderMonthlyCalendar = () => {
        const daysInMonth = dayjs().month(selectedMonth).daysInMonth();
        const monthDays = [...Array(daysInMonth)].map((_, index) =>
            dayjs().month(selectedMonth).startOf('month').add(index, 'day')
        );
    
        return (
            <Grid container spacing={2}>
                {monthDays.map((day) => (
                    <Grid item xs={12} md={2} key={day.format('YYYY-MM-DD')}>
                        <Box
                            sx={{
                                padding: '0px',
                                borderRadius: '0px',
                                cursor: 'pointer',
                                height: 'auto',
                                backgroundColor: '#eee9f8',
                            }}
                            onClick={() => handleCellClick(day)}
                        >
                           <Box sx={{ width: '100%', padding: '4px',
                                backgroundColor: dayjs().isSame(day, 'day') ? '#bc74ff' : '#925FFF', // Check if current day is today
                                color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px 12px 0px 0px' }}>
                            <Typography className="t_oktelo_medium size_14 t_white">
                                {day.format('YYYY.MM.DD (dddd)')}
                            </Typography>
                            </Box>
                            {/* Render items associated with this day */}
                            {renderItemsForDay(day)}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const handleCellClick = (day: dayjs.Dayjs) => {
        const itemsForDay = items.filter((item) => dayjs(item.date).isSame(day, 'day'));
        console.log('Items for day:', itemsForDay);
        // Implement your logic to display items or navigate to a detailed view
    };
    

    const renderItemsForDay = (day: dayjs.Dayjs) => {
        const itemsForDay = items.filter((item) => dayjs(item.date).isSame(day, 'day'));
    
        return (
            <div style={{ maxHeight: '500px', minHeight: '50px', overflowY: 'scroll', padding: '4px'}} className='cretor-content-layout'>
                {itemsForDay.map((item) => (
                    <Box key={item.title} mt={1} position="relative" sx={{ cursor: 'pointer' }} onClick={() => navigate(`show/${item.id}`)}>
{/*                         <Typography className="t_oktelo_normal size_14 darkblue">{item.title}</Typography> */}
                        <img src={item.image} alt={item.title} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                        <Box className="post-grid-approval-box">
                            {item.type === "scheduledWithPublish" && <ScheduleSendOutlined/>}
                            {item.type === "calendarDraft" && <CalendarMonthOutlined/>}
                            {item.type === "published" && <PublicOutlined/>}
                            <Typography className='t_oktelo_medium size_12 darkblue'>{dayjs.utc(item.date).format('HH:mm')}</Typography>
                        </Box>
                    </Box>
                ))}
            </div>
        );
    };
    

    const renderCalendar = () => {
        if (viewType === 'weekly') {
            return renderWeeklyCalendar();
        } else {
            return renderMonthlyCalendar();
        }
    };

    return (
        <Box p={2}>
            {renderCalendarHeader()}
            {renderCalendar()}
        </Box>
    );
};

export default OkteloCalendar;

import { LinearProgress } from '@mui/material';
import React, { Suspense, LazyExoticComponent } from 'react';

// Type definition for the import function
type ImportFunc = () => Promise<{ default: React.ComponentType<any> }>;

// LazyLoadComponent definition
const LazyLoadComponent = (importFunc: ImportFunc) => {
  const Component: LazyExoticComponent<React.ComponentType<any>> = React.lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={<div><LinearProgress /></div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default LazyLoadComponent;

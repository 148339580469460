import type { AuthBindings } from "@refinedev/core";
import apiconfig from "utils/apiconfig";
import api from "./interceptor";

export const authProvider: AuthBindings = {
    login: async ({ email, password, remember }) => {
      try {
        const response = await api.post('/users/login', { email, password, remember });
        if (response.status === 200) {
          const { user, auth } = response.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('isWelcome', "true");
          localStorage.setItem('auth', auth);
          return { success: true, authenticated: true, redirectTo: '/home', message: 'Successful login!' };
        } else {
          return { success: false };
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          return { success: false, message: error.response.data.message };
        } else {
          return { success: false };
        }
      }
    },
  
    register: async ({ firstName, lastName, email, password, username, regpromo }) => {
      try {
        const response = await api.post('/users/register', { firstName, lastName, email, password, username, regpromo });
        localStorage.setItem('isWelcome', "true");
        if (response.status === 200) {
          return { success: true, message: 'Sikeres regisztráció!', redirectTo: '/login' };
        } else {
          return { success: false, message: 'Hiba, próbáld újra később, vagy lépj kapcsolatba velünk.' };
        }
      } catch (error: any) {
        console.error('Registration error:', error);
        if (error.response && error.response.status === 400) {
          return { success: false, message: error.response.data.message };
        } else {
          return { success: false, message: 'Hiba, próbáld újra később, vagy lépj kapcsolatba velünk.' };
        }
      }
    },
  
    forgotPassword: async ({ email }) => {
      //Handle forgot pass
        return {
          success: true,
        };
      },
  
    updatePassword: async ({ password, confirmPassword }) => {
        // You can handle the update password process according to your needs.
    
        // If the process is successful.
        return {
          success: true,
        };
      },
  
      check: async () => {
  
        const storedUserString = localStorage.getItem('user');
  
        if (!storedUserString) {
          return { authenticated: false}
        }
    
        const auth = localStorage.getItem('auth');
  
        if (!auth) {
          throw new Error("Session token not found");
        }

        if (storedUserString && auth) {
            return { authenticated: true }; // Mark the user as authenticated if both user and token exist
          }

        return {
          authenticated: false,
          logout: true,
          redirectTo: "/login",
          error: {
            message: "Check failed",
            name: "Unauthorized",
          },
        };
      },
  
      logout: async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("sp");
        localStorage.removeItem("spn");
        localStorage.removeItem("auth");
        localStorage.clear();

        return {
          success: true,
          authenticated: false,
          successNotification: {
            message: "Kijelentkeztél. Várunk vissza!",
          },
          redirectTo: "/login",
          redirectPath: "/login"
        };
      },

      getPermissions: async () => {
        try {
            const permission = await api.get(`${apiconfig.BACKEND_URI}/users/permission`);
            return { isPermit: permission.data.isPermit };  // Return an object
        } catch (error) {
            return { isPermit: false };  // Ensure it always returns an object
        }
    },

    onError: async (error) => {
      console.error("onError:", error);
      return { error };
    },

    getIdentity: async () => {
      try {
        const storedUserString = localStorage.getItem('user');
    
        if (!storedUserString) {
          return { authenticated: false };
        }
    
        const token = localStorage.getItem('auth');
    
        const response = await api.get(`${apiconfig.BACKEND_URI}/data/identity`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
          },);
    
        if (response.status === 200 && response.data && response.data.data) {
          return response.data.data;
        } else {
          return { authenticated: false, message: 'Unable to fetch identity' };
        }
      } catch (error: any) {
        console.error("Error fetching identity:", error);
        return { authenticated: false, message: 'Error fetching identity', error: error.message };
      }
    }
    
  
  };
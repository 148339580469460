import { ConfigProvider, Tour } from "antd";
import { TourProps } from "antd/lib";
import React from "react";
import { Typography, Box, Stack } from "@mui/material";

interface WelcomeTourProps {
    isOpen: boolean;
    handleClose: () => void;
}



const steps: TourProps['steps'] = [
    {
      title: <Typography className="t_oktelo_normalsize_20 darkblue">Üdvözlünk az Oktelo-ban! 👋</Typography>,
      description: 
      <Box width="100%" py={3} px={1.5}>
        <Stack direction="column" gap={1.5} width="100%">
            <Typography className="t_oktelo_light miscgray size_16">
                Egy új felület megismerése és használata nem mindig zökkenőmentes feladat.
            </Typography>
            <Typography className="t_oktelo_light miscgray size_16">
                Pár alapvető információt bemutatunk neked, ami segíthet a felfedezésben.
            </Typography>
            <Typography className="t_oktelo_light darkblue size_16">
                Kattints a "Következő" gombra a folytatáshoz!
            </Typography>
        </Stack>
      </Box>,
      nextButtonProps : {
        children : (
          <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
        ),
        },
    },
    {
      title: <Typography className="t_oktelo_medium size_20 darkblue">Navigáció 🔍</Typography>,
      description: 
      <Box width="100%" py={3} px={1.5}>  
        <Stack direction="column" gap={1.5} width="100%">
          <Typography className="t_oktelo_light miscgray size_16">
              Bal oldalon találhatod a <span className="t_purple">menüpontokat</span>, amelyek segítségével könnyen elnavigálhatsz a neked szükséges felületre.
          </Typography>
          <Typography className="t_oktelo_light miscgray size_16">
              A beállításaidat és az előfizetésed állapotát a <span className="t_purple">Beállítások</span> menüpontban fogod megtalálni.
          </Typography>
      </Stack>
    </Box>,
          nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
            },
            prevButtonProps: {
                children: (
                    <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
                )
            }
    },
    {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Sablon adatok 📊</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Az ingyenes fiókoddal felfedezheted az összes oldalt, viszont fontos tudnod, hogy <span className="t_purple">a funkciók nem fognak működni</span>.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Például, nem fogsz tudni tartalmakat létrehozni, ütemezni, az oldalaidat összekapcsolni, elemzéseket készíteni.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    A sablon adatok tájékoztató jellegűek és betekintést nyújtanak az Oktelo valós használatába.
                </Typography>
            </Stack>
      </Box>,
          nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
            },
            prevButtonProps: {
                children: (
                    <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
                )
            }
    },
      {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Előfizetések 🌎</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Az előfizetéses fiókoddal lehetőséged lesz akár 50 közösségi oldalt összekapcsolni az Oktelo-val.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Továbbá, minden funkció elérhetővé válik számodra is.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Az előfizetésről szóló információkat a <span className="t_purple">Beállítások/Előfizetés</span> oldalon találod meg.
                </Typography>
                <Typography className="t_oktelo_light darkblue size_16">
                    Automatizáció, időspórolás, hatékonyságnövelés - Kezdd el még ma.
                </Typography>
            </Stack>
      </Box>,
          nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
            },
            prevButtonProps: {
                children: (
                    <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
                )
            }
    },
      {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Tokenek és AI 🤖</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Az Oktelo AI használata <span className="t_purple">Oktelo Tokenek</span>-hez kötött.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Minden előfizetéshez más-más Token jóváírás tartozik, amit mindig éjfélkor automatikusan frissítünk.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    A Tokeneket felhasználhatod például elemzésekhez és tartalomkészítéshez.
                </Typography>
                <Typography className="t_oktelo_light darkblue size_16">
                    A Tokeneid számát a menüben lila színnel láthatod, vagy a Profil oldaladon is bármikor megnézheted.
                </Typography>
            </Stack>
      </Box>,
          nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
            },
            prevButtonProps: {
                children: (
                    <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
                )
            }
    },
      {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Segítségek 🎓</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Bármikor tudsz tőlünk segítséget kérni a menü alján lévő <span className="t_purple">Segítség</span> menüpontban.
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Ugyan ott találhatod meg a segítség központot, ahol leírások és oktató anyagok vannak.
                </Typography>
                <Typography className="t_oktelo_light darkblue size_16">
                    Ha bármiben elakadtál, vagy kérdésed van, nyugodtan lépj velünk kapcsolatba a Segítség oldalon.
                </Typography>
            </Stack>
      </Box>,
          nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Következő</Typography>
            ),
            },
            prevButtonProps: {
                children: (
                    <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
                )
            }
    },
      {
        title: <Typography className="t_oktelo_medium size_20 darkblue">Kezdő lépések ✌️</Typography>,
        description: 
        <Box width="100%" py={3} px={1.5}>
            <Stack direction="column" gap={1.5} width="100%">
                <Typography className="t_oktelo_light miscgray size_16">
                    Kezdd el a közösségi média fejlődésed az Oktelo-val!
                </Typography>
                <Typography className="t_oktelo_light miscgray size_16">
                    Fedezd fel és ismerkedj meg a felületeinkkel, és ha úgy érzed, hogy elnyerte a tetszésedet, várunk szeretettel az Oktelo dinamikusan növekvő felhasználó-bázisában!
                </Typography>
                <Typography className="t_oktelo_light darkblue size_16">
                    Probléma, észrevétel, vagy kérdés esetén pedig várjuk a megkeresésedet. Sok sikert kívánunk!
                </Typography>
            </Stack>
      </Box>,
          nextButtonProps : {
            children : (
              <Typography className="t_white t_oktelo_medium size_14">Bezárás</Typography>
            ),
            },
            prevButtonProps: {
                children: (
                    <Typography className="darkblue t_oktelo_normal size_14">Előző</Typography>
                )
            }
    },
  ];

export const WelcomeTour: React.FC<WelcomeTourProps> = ({ isOpen, handleClose }) => (
    <ConfigProvider
        theme={{
            components: {
            Tour: {
                primaryNextBtnHoverBg: '#1e1e47'
            },
            },
            token: {
                colorPrimary: '#925FFF'
            }
        }}
        >
        <Tour
        open={isOpen}
        onClose={handleClose}
        onFinish={handleClose}
        steps={steps}
        placement="center"
        zIndex={9999}
        indicatorsRender={(current, total) => (
        <span>
            {current + 1} / {total}
        </span>
        )}
        />
    </ConfigProvider>
)

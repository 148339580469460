import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { AudienceCardProps } from "interfaces/oktelo";
import { Tag } from "antd";
import {
  AccessTimeOutlined,
  GroupsOutlined,
  MoodOutlined,
  TravelExploreOutlined,
} from "@mui/icons-material";

const AudienceCard: React.FC<{ data: AudienceCardProps }> = ({ data }) => {
  return (
    <Stack
      direction="column"
      gap={1}
      width="100%"
      height="100%"
      justifyContent="space-evenly"
    >
       <Stack
        direction="column"
        justifyContent="flex-start"
        width="100%"
        alignItems="flex-start"
        gap={1}
        py={1.6}
      >
        <Typography className="t_oktelo_medium size_14 darkblue">
          Követők város szerint
        </Typography>
        {data.city.primary ? (
          <Stack direction="row" alignItems="center" gap={1} width="100%">
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <AccessTimeOutlined sx={{ fontSize: "18px", color: "#6a3bcf" }} />
              <Typography className="t_oktelo_medium size_14 t_link">
                {data.city.primary}
              </Typography>
            </Box>
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <AccessTimeOutlined sx={{ fontSize: "18px", color: "#925fff" }} />
              <Typography className="t_oktelo_medium size_14 darkblue">
              {data.city.secondary}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Box
            className="audience-smallcard"
            sx={{ backgroundColor: "#fff" }}
          >
            <AccessTimeOutlined sx={{ fontSize: "18px", color: "#1e1e47" }} />
            <Typography className="t_oktelo_medium size_14 darkblue">
              Ismeretlen
            </Typography>
          </Box>
        )}
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        width="100%"
        alignItems="flex-start"
        gap={1}
        py={1.6}
        borderBottom={"1px solid #ede4ff"}
      >
        <Typography className="t_oktelo_medium size_14 darkblue">
          Közönséged neme
        </Typography>

        {data.gender.primary ? (
          <Stack direction="row" alignItems="center" gap={1} width="100%">
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <GroupsOutlined sx={{ fontSize: "18px", color: "#6a3bcf" }} />
              <Typography className="t_oktelo_medium size_14 t_link">
                {data.gender.primary}
              </Typography>
            </Box>
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <GroupsOutlined sx={{ fontSize: "18px", color: "#1e1e47" }} />
              <Typography className="t_oktelo_medium size_14 darkblue">
                {data.gender.secondary}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Box
            className="audience-smallcard"
            sx={{ backgroundColor: "#fff" }}
          >
            <GroupsOutlined sx={{ fontSize: "18px", color: "#1e1e47" }} />
            <Typography className="t_oktelo_medium size_14 darkblue">
              Ismeretlen
            </Typography>
          </Box>
        )}
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        width="100%"
        alignItems="flex-start"
        gap={1}
        py={1.6}
        borderBottom={"1px solid #ede4ff"}
      >
        <Typography className="t_oktelo_medium size_14 darkblue">
          Közönséged kora
        </Typography>
        {data.age.primary ? (
          <Stack direction="row" alignItems="center" gap={1} width="100%">
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <MoodOutlined sx={{ fontSize: "18px", color: "#6a3bcf" }} />
              <Typography className="t_oktelo_medium size_14 t_link">
                {data.age.primary}
              </Typography>
            </Box>
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <MoodOutlined sx={{ fontSize: "18px", color: "#1e1e47" }} />
              <Typography className="t_oktelo_normal size_14 darkblue">
                {data.age.secondary}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Box
            className="audience-smallcard"
            sx={{ backgroundColor: "#fff" }}
          >
            <MoodOutlined sx={{ fontSize: "18px", color: "#1e1e47" }} />
            <Typography className="t_oktelo_medium size_14 darkblue">
              Ismeretlen
            </Typography>
          </Box>
        )}
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        width="100%"
        alignItems="flex-start"
        gap={1}
        py={1.6}
        borderBottom={"1px solid #ede4ff"}
      >
        <Typography className="t_oktelo_medium size_14 darkblue">
          Közönséged helye
        </Typography>
        {data.place.primary ? (
          <Stack direction="row" alignItems="center" gap={1} width="100%">
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <TravelExploreOutlined
                sx={{ fontSize: "18px", color: "#6a3bcf" }}
              />
              <Typography className="t_oktelo_medium size_14 t_link">
                {data.place.primary}
              </Typography>
            </Box>
            <Box
              className="audience-smallcard"
              sx={{ backgroundColor: "#fff" }}
            >
              <TravelExploreOutlined
                sx={{ fontSize: "18px", color: "#1e1e47" }}
              />
              <Typography className="t_oktelo_normal size_14 darkblue">
                {data.place.secondary}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Box
            className="audience-smallcard"
            sx={{ backgroundColor: "#fff" }}
          >
            <TravelExploreOutlined
              sx={{ fontSize: "18px", color: "#1e1e47" }}
            />
            <Typography className="t_oktelo_medium size_14 darkblue">
              Ismeretlen
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default AudienceCard;

import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Avatar,
  Switch,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import type { MenuProps } from "antd";
import { Carousel, DatePicker, Layout, Menu, Tag, TimePicker, Tooltip } from "antd";
import {
  AppstoreOutlined,
  AlignCenterOutlined,
  LinkOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  CoffeeOutlined,
} from "@ant-design/icons";
import { Input, Upload } from "antd";
import {
  AccountCircleOutlined,
  AddBoxOutlined,
  AddReactionOutlined,
  AlternateEmail,
  ArrowDownward,
  ArrowUpward,
  AutoAwesome,
  AutoFixHigh,
  BookmarkBorderOutlined,
  Brush,
  CalendarMonthRounded,
  CheckBoxOutlined,
  CircleOutlined,
  ClearOutlined,
  DesktopMacOutlined,
  Facebook,
  FavoriteBorder,
  Home,
  ImageSearchOutlined,
  InboxOutlined,
  Instagram,
  IosShare,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LabelOutlined,
  Link,
  LinkedIn,
  LocalOfferOutlined,
  MenuOutlined,
  ModeCommentOutlined,
  MoreHoriz,
  MoreVert,
  NearMeOutlined,
  NetworkWifi,
  NotificationsOutlined,
  OndemandVideoOutlined,
  OpenInNew,
  PeopleOutline,
  PhoneIphoneOutlined,
  PhotoSizeSelectActualOutlined,
  Public,
  Refresh,
  SearchOutlined,
  SearchRounded,
  Settings,
  SmartDisplayOutlined,
  StickyNote2Outlined,
  TagOutlined,
  TagRounded,
  VideoCameraBack,
  VideoSettings,
} from "@mui/icons-material";
import dayjs from "dayjs";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { okt_hearticon, okt_likeicon } from "assets";
import api from "../../../utils/interceptor"
import { useNotification } from "@refinedev/core";
import { UploadChangeParam } from "antd/lib/upload/interface";
import type { UploadFile } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { ConfigProvider, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { MediaItem } from "interfaces/canva";
import apiconfig from "utils/apiconfig";
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hu';
dayjs.locale('hu')
dayjs.extend(timezone);

const { TextArea } = Input;
const { Content, Footer } = Layout;

type SocialPage = {
  id: string;
  name: string;
  platform: string;
  photo: string;
};

type UserPagesData = {
  allPages: SocialPage[];
};

type UserTags = {
  id: string;
  title: string;
  color: string;
  posts: number;
};

type Gallery = {
  name: string;
  url: string;
  type: string;
}

type UserHashtags = {
  id: string;
  title: string;
  hashtags: string[];
  posts: number;
};

type IGUserTag = {
  username: string;
  location: string;
}

interface NoteSectionProps {
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
}

interface TagSelectorProps {
  userTags: UserTags[];
  selectedTag: string;
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>;
}

interface DateTimePickerProps {
  postDate: string | null;
  postTime: string | null;
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>;
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>;
  handlePublishNow: () => void;
  isPublishNow: boolean;
}

interface FirstCommentSectionProps {
  isFirstComment: boolean;
  setFirstComment: React.Dispatch<React.SetStateAction<boolean>>;
  firstCommentMessage: string;
  setFirstCommentMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface PostMessageSectionProps {
  postMessage: string;
  setPostMessage: (message: string) => void;
  emojiPickerOpen: boolean;
  setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>; 
  handleEmojiClick: any;
  setHashtagModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hashtagModalVisible: boolean;
  selectedHashtag: string;
  setSelectedHashtag: React.Dispatch<React.SetStateAction<string>>;
  userHashtags: UserHashtags[];
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  isAiTextModalShow: boolean;
}

interface TextAreaWithCountProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  maxLength: number;
}

interface AvatarWithLabelProps {
  icon: React.ReactNode;
  label: string;
  bgColor: string;
  iconColor?: string;
}

interface SelectPageProps {
  socialPages: UserPagesData;
  selectedPlatform: string;
  selectedPageId: string;
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>;
}

interface InstagramExtrasProps {
  igCollaborator: string;
  setIgCollaborator: React.Dispatch<React.SetStateAction<string>>;
  igUsersTagged: IGUserTag[];
  handleUsernameChange: (index: number, text: string) => void;
  handleTagLocationChange: (index: number, text: string) => void;
}

interface PostMedia {
  name: string;
  url: string;
  type: string;
  uid: string;
}

type SetPostMedia = React.Dispatch<React.SetStateAction<PostMedia[]>>;

interface ImageUploadSectionProps {
  handleUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void, 
  setPostMedia: SetPostMedia; 
  postMedia: PostMedia[]; 
  setImageUrlPasterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isImageUrlPasterOpen: boolean; 
  getImageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>; 
  submitImageUrl: () => void;
  isMediaWindowOpen: boolean;
  setMediaWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AiModalProps {
  isAiTextModalShow: boolean;
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  AiInstructionsText: string;
  setAiInstructionsText: React.Dispatch<React.SetStateAction<string>>;
  onStartAiTextGeneration: () => void;
}

interface MediaLibraryProps {
  isMediaWindowOpen: boolean;
  setMediaWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userMediaFiles: MediaFile[];
  currentKey: string;
  setPostMedia: SetPostMedia;
  refetchMedia: () => void;
}

interface MediaFile {
  asset_id: string;
  secure_url: string;
  resource_type: string;
  uploaded_at: Date;
}

const CreatePost = () => {
  const { open } = useNotification();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [ isImageUrlPasterOpen, setImageUrlPasterOpen ] = useState(false);
  const [selectedKey, setSelectedKey] = useState("0");
  const [postMessage, setPostMessage] = useState("");
  const [isFirstComment, setFirstComment] = useState(false);
  const [firstCommentMessage, setFirstCommentMessage] = useState("");
  const [postNoteMessage, setPostNoteMessage] = useState("");
  const [postMedia, setPostMedia] = useState<PostMedia[]>([{ name: "", url: "", type: "", uid: "" }]);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("facebook");
  const [postDate, setPostDate] = useState<string | null>(null);
  const [postTime, setPostTime] = useState<string | null>(null);
  const [isPublishNow, setPublishNow] = useState(false);
  const [previewMode, setPreviewMode] = useState("mobile");
  const [socialPages, setSocialPages] = useState<UserPagesData>({
    allPages: [],
  });
  const [selectedPageId, setSelectedPageId] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [userTags, setUserTags] = useState<UserTags[]>([]);
  const [galleryPhotos, setGalleryPhotos] = useState<Gallery[]>([]);
  const [postLink, setPostLink] = useState("");
  const [userHashtags, setUserHashtags] = useState<UserHashtags[]>([]);
  const [selectedHashtag, setSelectedHashtag] = useState<string>("");
  const [hashtagModalVisible, setHastagModalVisible] = useState(false);
  const [getImageUrl, setImageUrl] = useState(""); /* for link url photos submit */
  const [igCollaborator, setIgCollaborator] = useState<string>('');
  const [igUsersTagged, setIgUsersTagged] = useState<IGUserTag[]>([]);
  const [isStoryTypeImage, setStoryTypeImage] = useState(true);
  const [isReelsVideo, setReelsVideo] = useState(false);
  const [isAiTextModalShow, setAiTextModalShow] = useState(false);
  const [aiInstructionsText, setAiInstructionsText] = useState<string>('');
  const [isImage, setIsImage] = useState(false);
  const [isSeeMore, setSeeMore] = useState(false);
  const [localMedia, setLocalMedia] = useState<MediaItem[]>([]);
  const [isMediaWindowOpen, setMediaWindowOpen] = useState(false);

  /* ===== Get hashtags ===== */

  const getUserHashtags = async () => {
    try {
      const _hashtags = await api.get(
        `${apiconfig.BACKEND_URI}/tags/hashtags`
      );

      if (_hashtags.status === 200) {
        setUserHashtags(_hashtags.data);
      } else {
        setUserHashtags([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* === USER MEDIA === */

  const getUserMedia = async () => {
    try {
        const response = await api.get(`${apiconfig.BACKEND_URI}/integration/usermedia`);

        if(response.status === 200){
            setLocalMedia(response.data.resources);
        }
    } catch (error) {
        console.log("error getting user media", error);
    }
}


  /* ==== AI TEXT ==== */

  const onStartAiTextGeneration = async () => {
    try {
      
      const instruction = aiInstructionsText;
      const platform = selectedPlatform || "Not specific platform";
      const parent = socialPages.allPages.find(p => p.id === selectedPageId) || 'Not specific page';
      
      const response = await api.post(`${apiconfig.BACKEND_URI}/ai/aihelpertext`, {
        instruction: instruction,
        platform: platform,
        parent: parent
      })

      if (response.status === 200){
        setPostMessage(response.data.result);
      } else {
        setPostMessage('Nem sikerült AI szöveget generálni.. :(')
      }

    } catch (error) {
      console.log(error);
    } finally {
      setAiTextModalShow(false);
    }
  }

  /* ===== HANDLE TAGS ===== */

  const getUserTags = async () => {
    try {
      const response = await api.get(`${apiconfig.BACKEND_URI}/tags`);

      if (response.status === 200) {
        setUserTags(response.data);
      } else {
        setUserTags([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserTags();
    getUserHashtags();
    getUserMedia();
  }, []);

  /* ====== HANDLE SUBMIT POST ====== */

  const validateSubmit = async (
    type: string,
    key: string,
    message: string,
    photoUrl: string,
    pageId: string,
    isPublish: boolean,
    isComment: boolean,
    firstComment: string,
    galleryUrls: string[],
    linkUrl: string,
    mediaType: string
  ) => {
    const errorMessages = {
      keyError: 'Hiba történt, töltsd újra az oldalt.',
      invalidMessage: 'Hibás bejegyzés szöveg',
      galleryError: 'Galéria esetén minimum 1 kép feltöltése szükséges.',
      commentError: 'Az első kommentnek minimum 1 karakternek kell lennie.',
      pageIdError: 'Nem publikálhatsz anélkül, hogy egy oldal ki lenne választva.',
      storyError: 'Történethez kötelező egy képet feltölteni.',
      wrongFormatError: 'A kép linkje hibás.',
      mediaError: 'Nem töltöttél fel médiát.',
      invalidUrlError: 'Nem adtál meg valós URL-t.',
    };
  
    // Check if key is a valid string
    if (typeof key !== "string" || !key) {
      return { valid: false, type: 'error', message: errorMessages.keyError };
    }
  
    // Clear photoUrl for key "0"
    if (key === "0" && photoUrl) {
      setPostMedia([{ url: "", name: "", type: "", uid: "" }]);
    }
  
    // Check message length and story type
    if (key === "5") {
      setPostMessage("");
    }
  
    if (key !== "5" && !message) {
      return { valid: false, type: 'error', message: errorMessages.invalidMessage };
    }
  
    if (key === "3" && (!galleryUrls || galleryUrls.length < 1)) {
      return { valid: false, type: 'error', message: errorMessages.galleryError };
    }
  
    // Check if firstComment is required and valid
    if (isComment && firstComment.length < 1) {
      return { valid: false, type: 'error', message: errorMessages.commentError };
    }
  
    // Check if pageId is required and valid for publishing
    if (isPublish && !pageId && type === "public") {
      return { valid: false, type: 'error', message: errorMessages.pageIdError };
    }
  
    if (mediaType === 'story' && !photoUrl) {
      return { valid: false, type: 'error', message: errorMessages.storyError };
    }
  
    if (mediaType === "wrong_format" && photoUrl) {
      return { valid: false, type: 'error', message: errorMessages.wrongFormatError };
    }
  
    if ((key === "1" || key === "2" || key === "5") && !photoUrl) {
      return { valid: false, type: 'error', message: errorMessages.mediaError };
    }
  
    const isValidUrl = (urlString: string) => {
      try {
        new URL(urlString);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    if (key === '4' && (!isValidUrl(linkUrl) || linkUrl.length < 3)) {
      return { valid: false, type: 'error', message: errorMessages.invalidUrlError };
    }
  
    return { valid: true, type: 'pending', message: 'A posztod létrehozása folyamatban van..' };
  };
 
  const handleSubmitPost = async (_type: string) => {
    try {
      setLoading(true);

      const mediaUrl = postMedia[0]?.url || "";
      const mediaType = postMedia[0]?.type || "";
      const galleryUrls = galleryPhotos.map((photo) => photo.url);

      let date = postDate && postTime ? dayjs(`${postDate} ${postTime}`, "YYYY.MM.DD HH:mm") : undefined;
      if (!date || !date.isValid()) date = undefined;
      const dateToSend = date ? date.toISOString() : undefined;

      const isValid = await validateSubmit(
        _type,
        selectedKey,
        postMessage,
        mediaUrl,
        selectedPageId,
        isPublishNow,
        isFirstComment,
        firstCommentMessage,
        galleryUrls,
        postLink,
        mediaType
      );

      if (!isValid.valid) {
        open?.({
          type: 'error',
          message: isValid.message,
        });
        return;
      }

      const response = await api.post(`${apiconfig.BACKEND_URI}/posts/new`, {
        type: _type,
        key: selectedKey,
        message: postMessage,
        note: postNoteMessage,
        platform: selectedPlatform,
        date: dateToSend,
        pageId: selectedPageId,
        isPublish: isPublishNow,
        isComment: isFirstComment,
        firstComment: firstCommentMessage,
        tag: selectedTag,
        mediaUrl,
        mediaType,
        galleryUrls,
        linkUrl: postLink,
        hashtag: selectedHashtag,
        collaborator: igCollaborator,
        igtags: igUsersTagged,
        isReels: isReelsVideo,
        storyType: isStoryTypeImage,
      });

    const savedPostId = response.data.postId;

    if (response.status === 200 || response.status === 201) {
      open?.({ type: "success", message: "Sikeresen létrehoztál egy posztot!" });

      if (_type === "public" && savedPostId) {
        const publish = await api.post(`${apiconfig.BACKEND_URI}/publish/handler`, {
          postId: savedPostId,
          isPublishNow: isPublishNow,
          scheduleDate: dateToSend
        });

        if (publish.data) {
          if (publish.data.success) {
            open?.({ type: 'success', message: 'Sikeres közzététel.' });
          } else {
            open?.({ type: 'error', message: 'Sikertelen közzététel.' });
          }
        } else {
          open?.({ type: 'error', message: 'Váratlan válasz a közzételhez.' });
        }
      }

      // Navigate only after handling publish response
      navigate('/posts');

    } else {
      open?.({ type: "error", message: "Hiba történt a poszt létrehozásakor." });
    }
  } catch (error) {
    console.error(error);
    open?.({ type: "error", message: "Hiba történt a poszt létrehozásakor." });
  } finally {
    setLoading(false);
  }
};



  /* ====== HANDLE SELECTION OF POST TYPES & RENDERING RIGHT SIDE ====== */

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.get(`${apiconfig.BACKEND_URI}/data/socials`);

        const allFacebookPages = response.data.facebookPages.map(
          (page: {
            id: string;
            name: string;
            platform: string;
            photo: string;
          }) => ({
            id: page.id,
            name: page.name,
            platform: "facebook",
            photo: page.photo,
          })
        );

        const allInstagramPages = response.data.instagramPages.map(
          (page: {
            id: string;
            name: string;
            platform: string;
            photo: string;
          }) => ({
            id: page.id,
            name: page.name,
            platform: "instagram",
            photo: page.photo,
          })
        );

        const userPagesData: UserPagesData = {
          allPages: [...allFacebookPages, ...allInstagramPages],
        };

        setSocialPages(userPagesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchUserData();
  }, []);

  /* ====== END ====== */

  /* ====== HANDLE SELECTION OF POST TYPES & RENDERING RIGHT SIDE ====== */

  const handleMenuClick = ({ key }: { key: string }) => {
    setSelectedKey(key);
  };

  const allowedPlatforms: { [key: string]: string[] } = {
    facebook: ["Szöveg", "Kép", "Videó", "Galéria", "Link", "Történet"],
    instagram: ["Kép", "Videó", "Galéria", "Történet"],
  };

  const subnavLabels = [
    "Szöveg",
    "Kép",
    "Videó",
    "Galéria",
    "Link",
    "Történet",
  ];
  const icons = [
    AlignCenterOutlined,
    PictureOutlined,
    VideoCameraOutlined,
    AppstoreOutlined,
    LinkOutlined,
    CoffeeOutlined,
  ];

  const allowedItems = allowedPlatforms[selectedPlatform];

  const items2: MenuProps["items"] = subnavLabels
    .map((label, index) => {
      if (!allowedItems.includes(label)) return null;

      const icon = icons[index];
      return {
        key: `${index}`, // Use index as key to maintain consistency
        icon: React.createElement(icon),
        label: label,
      };
    })
    .filter((item) => item !== null); // Remove null values

  const decideFormRender = () => {
    switch (selectedKey) {
      case "0":
        return RenderCreateTextPost(
          selectedKey,
          postMessage,
          setPostMessage,
          isFirstComment,
          setFirstComment,
          handleEmojiClick,
          setEmojiPickerOpen,
          emojiPickerOpen,
          setFirstCommentMessage,
          firstCommentMessage,
          handlePublishNow,
          isPublishNow,
          postDate,
          postTime,
          setPostDate,
          setPostTime,
          socialPages,
          setSelectedPageId,
          selectedPageId,
          selectedPlatform,
          postNoteMessage,
          setPostNoteMessage,
          userTags,
          selectedTag,
          setSelectedTag,
          userHashtags,
          selectedHashtag,
          setSelectedHashtag,
          setHastagModalVisible,
          hashtagModalVisible,
          setAiTextModalShow,
          isAiTextModalShow,
          onStartAiTextGeneration,
          aiInstructionsText,
          setAiInstructionsText,
        );
      case "1":
        return RenderCreatePhotoPost(
          selectedKey,
          postMessage,
          setPostMessage,
          isFirstComment,
          setFirstComment,
          handleEmojiClick,
          setEmojiPickerOpen,
          emojiPickerOpen,
          setFirstCommentMessage,
          firstCommentMessage,
          handlePublishNow,
          isPublishNow,
          postDate,
          postTime,
          setPostDate,
          setPostTime,
          socialPages,
          setSelectedPageId,
          selectedPageId,
          selectedPlatform,
          postNoteMessage,
          setPostNoteMessage,
          userTags,
          selectedTag,
          setSelectedTag,
          postMedia,
          setPostMedia,
          isImageUrlPasterOpen,
          setImageUrlPasterOpen,
          getImageUrl,
          setImageUrl,
          userHashtags,
          selectedHashtag,
          setSelectedHashtag,
          setHastagModalVisible,
          hashtagModalVisible,
          igCollaborator,
          setIgCollaborator,
          igUsersTagged,
          setIgUsersTagged,
          setAiTextModalShow,
          isAiTextModalShow,
          onStartAiTextGeneration,
          aiInstructionsText,
          setAiInstructionsText,
          isMediaWindowOpen,
          setMediaWindowOpen,
          localMedia,
          getUserMedia
        );
      case "2":
        return RenderCreateVideoPost(
          selectedKey,
          postMessage,
          setPostMessage,
          isFirstComment,
          setFirstComment,
          handleEmojiClick,
          setEmojiPickerOpen,
          emojiPickerOpen,
          setFirstCommentMessage,
          firstCommentMessage,
          handlePublishNow,
          isPublishNow,
          postDate,
          postTime,
          setPostDate,
          setPostTime,
          socialPages,
          setSelectedPageId,
          selectedPageId,
          selectedPlatform,
          postNoteMessage,
          setPostNoteMessage,
          userTags,
          selectedTag,
          setSelectedTag,
          postMedia,
          setPostMedia,
          userHashtags,
          selectedHashtag,
          setSelectedHashtag,
          setHastagModalVisible,
          hashtagModalVisible,
          igCollaborator,
          setIgCollaborator,
          igUsersTagged,
          setIgUsersTagged,
          isReelsVideo,
          setReelsVideo,
          setAiTextModalShow,
          isAiTextModalShow,
          onStartAiTextGeneration,
          aiInstructionsText,
          setAiInstructionsText,
          isMediaWindowOpen,
          setMediaWindowOpen,
          localMedia,
          getUserMedia,
          isImageUrlPasterOpen,
          setImageUrlPasterOpen
        );
      case "3":
        return RenderCreateGalleryPost(
          postMessage,
          setPostMessage,
          isFirstComment,
          setFirstComment,
          handleEmojiClick,
          setEmojiPickerOpen,
          emojiPickerOpen,
          setFirstCommentMessage,
          firstCommentMessage,
          handlePublishNow,
          isPublishNow,
          postDate,
          postTime,
          setPostDate,
          setPostTime,
          socialPages,
          setSelectedPageId,
          selectedPageId,
          selectedPlatform,
          postNoteMessage,
          setPostNoteMessage,
          userTags,
          selectedTag,
          setSelectedTag,
          galleryPhotos,
          setGalleryPhotos,
          userHashtags,
          selectedHashtag,
          setSelectedHashtag,
          setHastagModalVisible,
          hashtagModalVisible,
          setAiTextModalShow,
          isAiTextModalShow,
          onStartAiTextGeneration,
          aiInstructionsText,
          setAiInstructionsText,
        );
      case "4":
        return RenderCreateLinkPost(
          postMessage,
          setPostMessage,
          isFirstComment,
          setFirstComment,
          handleEmojiClick,
          setEmojiPickerOpen,
          emojiPickerOpen,
          setFirstCommentMessage,
          firstCommentMessage,
          handlePublishNow,
          isPublishNow,
          postDate,
          postTime,
          setPostDate,
          setPostTime,
          socialPages,
          setSelectedPageId,
          selectedPageId,
          selectedPlatform,
          postNoteMessage,
          setPostNoteMessage,
          userTags,
          selectedTag,
          setSelectedTag,
          postLink,
          setPostLink,
          userHashtags,
          selectedHashtag,
          setSelectedHashtag,
          setHastagModalVisible,
          hashtagModalVisible,
          setAiTextModalShow,
          isAiTextModalShow,
          onStartAiTextGeneration,
          aiInstructionsText,
          setAiInstructionsText,
        );
      case "5":
        return RenderCreateStory(
          handlePublishNow,
          isPublishNow,
          postDate,
          postTime,
          setPostDate,
          setPostTime,
          socialPages,
          setSelectedPageId,
          selectedPageId,
          selectedPlatform,
          postNoteMessage,
          setPostNoteMessage,
          userTags,
          selectedTag,
          setSelectedTag,
          postMedia,
          setPostMedia,
          isStoryTypeImage,
          setStoryTypeImage,
        );
      default:
        return <div>Default Content</div>;
    }
  };

  /* ===== HANDLE EMOJIS ===== */

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const emojiToAdd = emojiData.emoji;
    setPostMessage((prevValue) => prevValue + emojiToAdd);
  };

  /* ===== HANDLE POST TIME ===== */

  const handlePublishNow = () => {
    const nowDate = dayjs().format("YYYY.MM.DD");
    const nowTime = dayjs().format("HH:mm");
    setPostDate(nowDate);
    setPostTime(nowTime);
    setPublishNow(!isPublishNow);
  };

  /* ===== Debugging postLink ===== */
  useEffect(() => {
    console.log("postLink status: ", postLink, "imgurl:", getImageUrl);
  }, [postLink]);

  /* ===== Handle Select Page ===== */
  useEffect(() => {
    if (selectedPageId === "") {
      setSelectedPageId("");
    } else {
      const selectedPage = socialPages.allPages.find((page) => page.id === selectedPageId);
      setSelectedPageId(selectedPageId);
      setSelectedPlatform(selectedPage?.platform || "facebook");

      if (selectedPage?.platform === "instagram") {
        setSelectedKey("1");
      }
    }
  }, [selectedPageId]);

  /* ===== Handle Platform Change ===== */
  useEffect(() => {
    setSelectedPageId("");
    setIgCollaborator("");
    setIgUsersTagged([]);
    setPostLink('');
    if (selectedPlatform === "instagram") {
      setSelectedKey((items2[0]?.key as string) || "1");
    } else {
      setSelectedKey("0");
    }
  }, [selectedPlatform]);

  /* ===== Handle Key Change ===== */
  useEffect(() => {
    setPostMedia([{ name: "", url: "", type: "", uid: "" }]);
    setGalleryPhotos([]);
    setPostLink('');
  }, [selectedKey]);

  /* ===== Check if postLink is an Image ===== */
  useEffect(() => {
    console.log('postLink:', postLink); // Log the value of postLink
    if (postLink && postLink !== '') {
      setIsImage(checkIfImageUrl(postLink));
    }
  }, [postLink]);

  /* ===== Utility Functions ===== */
  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.svg', '.webp'];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some(ext => lowerCaseUrl.includes(ext));
    return isValidUrl(url) && isImageExtension;
  };

  /* ===== Render Components ===== */
  return (
    <Grid
      container
      spacing={4}
      direction={{ xs: "row-reverse", sm: "row-reverse", lg: "row", xl: "row", md: "row" }}
      p={{ xl: 2.5, xs: 1.5 }}
      sx={{ width: '100%', gridTemplateRows: "auto 1fr" }}
    >
      <Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
        {/* Mobile phone preview */}
        <Box
          height="100%"
          width="100%"
          p={1.5}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={2}
          position="relative"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <DesktopMacOutlined
              onClick={() => setPreviewMode("desktop")}
              sx={{ color: previewMode === "desktop" ? "#925FFF" : "#1E1E47" }}
            />
            <PhoneIphoneOutlined
              onClick={() => setPreviewMode("mobile")}
              sx={{ color: previewMode === "mobile" ? "#925FFF" : "#1E1E47" }}
            />
          </Stack>
  
          <Box
            sx={{
              border: previewMode === "mobile" ? "12px solid #000" : "0",
              borderRadius: previewMode === "mobile" ? "36px" : "6px",
/*               height: { xl: previewMode === "mobile" ? "650px" : "500px", xs: "650px" },
              width: { xl: previewMode === "mobile" ? "350px" : "500px", xs: "100%" }, */
              height: { lg: "650px", md: "650px", sm: "650px", xs: "650px" },
              width: { lg: "350px", md: "350px", sm: "350px", xs: "100%" },
              overflow: "hidden",
              position: "relative",
              backgroundColor: previewMode === "mobile" ? (selectedPlatform === "facebook" ? "#f6f6f6" : "#fff") : "#fff",
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23)',
              outline: '2px solid #d3d3d3',
              display: "flex",
              flexDirection: "column",
            }}
            className="preview-sider"
          >
            {previewMode === "mobile" && (
              <>
                {/* Mobile notch (optional) */}
                <Box
                  sx={{
                    backgroundColor: "#f6f6f6",
                    position: "sticky",
                    height: '30px',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 99,
                  }}
                  px={1}
                >
                  <Stack direction="row" justifyContent="space-between" p={1}>
                    <Typography className="darkblue size_12 t_oktelo_medium">
                      {dayjs().format("HH:mm")}
                    </Typography>
                    <NetworkWifi sx={{ fontSize: "14px" }} />
                  </Stack>
                  <Box
                    sx={{
                      width: "90px",
                      height: "22px",
                      backgroundColor: "#000",
                      borderRadius: "24px",
                      position: "absolute",
                      top: "4px",
                      left: "calc(50% - 45px)",
                      display: 'flex',
                      justifyContent: 'flex-end',
                      pr: 0.5,
                      alignItems: 'center',
                    }}
                  >
                    <Avatar sx={{ backgroundColor: '#989898', width: '16px', height: '16px' }}>
                      <CircleOutlined sx={{ color: '#000', fontSize: '16px' }} />
                    </Avatar>
                  </Box>
                </Box>
              </>
            )}
  
            <Box
              sx={{
                flex: 1, // Take up remaining space, pushing the bottom menu to the bottom
                overflowY: "auto",
              }}
              className="preview-sider"
            >
{
  selectedPlatform === "facebook" ? (
    selectedKey === "5" ? (
      FacebookStoryPreview(
        socialPages,
        selectedPageId,
        postMedia,
        postMessage,
        postDate,
        userHashtags,
        selectedHashtag
      )
    ) : (
      FacebookLivePreview(
        socialPages,
        selectedPageId,
        postDate,
        postMessage,
        postMedia,
        galleryPhotos,
        previewMode,
        selectedKey,
        postLink,
        selectedHashtag,
        userHashtags,
        isImage,
        isSeeMore,
        setSeeMore,
      )
    )
  ) : selectedPlatform === "instagram" ? (
    selectedKey === "5" ? (
      FacebookStoryPreview(
        socialPages,
        selectedPageId,
        postMedia,
        postMessage,
        postDate,
        userHashtags,
        selectedHashtag
      )
    ) : (
      InstagramLivePreview(
        socialPages,
        selectedPageId,
        postDate,
        postMessage,
        postMedia,
        galleryPhotos,
        previewMode,
        selectedKey,
        postLink,
        selectedHashtag,
        userHashtags,
        igCollaborator,
        igUsersTagged,
        isImage,
        isSeeMore,
        setSeeMore,
      )
    )
  ) : (
    <Box>Hibás platform</Box>
  )
}

            </Box>
  
            {(previewMode === "mobile" && selectedPlatform === "facebook") && (
              <Box
                sx={{
                  width: "100%",
                  height: "60px",
                  backgroundColor: "#fff",
                  position: "sticky", // Ensure it sticks at the bottom during scroll
                  bottom: 0,
                  left: 0,
                }}
              >
                <Box sx={{ backgroundColor: "#fff" }} px={1} width="100%">
                  <Stack direction="row" justifyContent="space-between" p={1}>
                    <Home sx={{ color: "#1e90ff", fontSize: "26px" }} />
                    <PeopleOutline sx={{ color: "#989898", fontSize: "26px" }} />
                    <OndemandVideoOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                    <NotificationsOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                    <MenuOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                  </Stack>
                </Box>
              </Box>
            )}
  
            {(previewMode === "mobile" && selectedPlatform === "instagram") && (
              <Box
                sx={{
                  width: "100%",
                  height: "60px",
                  backgroundColor: "#fff",
                  position: "sticky", // Ensure it sticks at the bottom during scroll
                  bottom: 0,
                  left: 0,
                }}
              >
                <Box sx={{ backgroundColor: "#fff" }} px={1} width="100%">
                  <Stack direction="row" justifyContent="space-between" p={1}>
                    <Home sx={{ color: "#000", fontSize: "26px" }} />
                    <SearchOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                    <AddBoxOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                    <SmartDisplayOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                    <AccountCircleOutlined sx={{ color: "#989898", fontSize: "26px" }} />
                  </Stack>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>

      <Grid item xl={6} lg={6} md={7} xs={12} sm={12} sx={{ overflowY: "auto" }}>
        <Layout>
          <Content style={{ padding: "0 0px" }}>
            <Layout
              style={{
                padding: "24px 0 0 0",
                background: "#fff",
                borderRadius: "0px",
                boxShadow:
                  "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
              }}
              className="settings_layout"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
                px={2}
              >
                <Typography className="t_oktelo_semibold size_16 darkblue">
                  Poszt típusa
                </Typography>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  className="social-select-row"
                >
                  <Tooltip title="Facebook">
                    <button
                      className="social-select-button"
                      style={{
                        color:
                          selectedPlatform === "facebook"
                            ? "#925FFF"
                            : "#989898",
                      }}
                      onClick={() => setSelectedPlatform("facebook")}
                    >
                      <Facebook />
                    </button>
                  </Tooltip>
                  <Tooltip title="Instagram">
                    <button
                      className="social-select-button"
                      style={{
                        color:
                          selectedPlatform === "instagram"
                            ? "#925FFF"
                            : "#989898",
                      }}
                      onClick={() => setSelectedPlatform("instagram")}
                    >
                      <Instagram />
                    </button>
                  </Tooltip>
                  <Tooltip title="LinkedIn">
                    <button
                      className="social-select-button"
                      style={{
                        color:
                          selectedPlatform === "linkedin"
                            ? "#925FFF"
                            : "#989898",
                      }}
                    >
                      <LinkedIn />
                    </button>
                  </Tooltip>
                </Stack>
              </Stack>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={["0"]}
                defaultOpenKeys={["0"]}
                style={{
                  width: "100%",
                  paddingBottom: "12px",
                  marginBottom: "20px",
                }}
                items={items2}
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
              />
              {/* </Sider> */}
              <Box
                style={{
                  padding: "0 24px 0px 24px",
                  minHeight: 500,
                  backgroundColor: "#fff !important",
                  height: "500px",
                  overflowY: "scroll",
                  width: '100%',
                }}
                className="cretor-content-layout"
              >
                {decideFormRender()}
              </Box>
              <Footer
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "0px 0px 0px 0px",
                  display: "flex",
                  padding: "12px",
                }}
              >{!isLoading ?
                <Stack
                  direction="column"
                  gap={2}
                  alignItems="center"
                  justifyContent="center"
                  className="submit-post-creator-row"
                  width="100%"
                > 
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    width="100%"
                  >
                    <Tooltip title="Kilépés, mentés nélkül">
                      <button className="cancel"
                        onClick={() => navigate('/posts')}
                      >Mégsem</button>
                    </Tooltip>
                    <Tooltip title="A bejegyzés elmentése vázlatként, közzététel nélkül">
                      <button
                        className="draft"
                        onClick={() => handleSubmitPost("draft")}
                      >
                        Vázlat
                      </button>
                    </Tooltip>
                  </Stack>
                  {selectedPageId !== "" && (
                    <Tooltip
                      title="A bejegyzésed közzététele vagy ütemezése a kiválasztott időben"
                      placement="bottom"
                      autoAdjustOverflow
                      zIndex={999}
                    >
                      <button className="submit"
                      onClick={() => handleSubmitPost("public")}
                      >Közzététel</button>
                    </Tooltip>
                  )}
                </Stack> :
              <Box>
                <CircularProgress sx={{ color: '#925FFF', fontSize: "32px" }}/>
              </Box>
                }
              </Footer>
            </Layout>
          </Content>
        </Layout>
      </Grid>
    </Grid>
  );
};

const FacebookLivePreview = (
  socialPages: UserPagesData,
  selectedPageId: string,
  postDate: string | null,
  postMessage: string,
  postMedia: any,
  galleryPhotos: Gallery[],
  previewMode: string,
  selectedKey: string,
  postLink: string,
  selectedHashtag: string,
  userHashtags: UserHashtags[],
  isImage: boolean,
  isSeeMore: boolean,
  setSeeMore: React.Dispatch<React.SetStateAction<boolean>>,
) => {

  return (
    <Box
      sx={{
        padding: 1,
        backgroundColor: "#fff",
        height: '100%',
        overflowY: 'auto',
        width: '100%'
      }}
    >
      {/* Header */}
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        px={2}
        py={1}
        sx={{ backgroundColor: "#fff", mb: 1 }}
      >
        <Typography className="size_24 t_oktelo_bold" sx={{ color: "#1e90ff" }}>
          facebook
        </Typography>
        <Avatar sx={{ width: "30px", height: "30px", backgroundColor: "#ebecef" }}>
          <SearchRounded sx={{ fontSize: "18px" }} />
        </Avatar>
      </Stack>

      {/* User Information */}
      <Stack direction="row" alignItems="center" gap={1.5} mb={1}>
        <Avatar
          alt="Profile Pic"
          src={socialPages.allPages.find(page => page.id === selectedPageId)?.photo || "https://via.placeholder.com/150"}
        />
        <Stack>
          <Typography className="t_oktelo_medium size_14 darkblue">
            {socialPages.allPages.find(page => page.id === selectedPageId)?.name || "Példa oldal"}
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Public sx={{ fontSize: "12px", color: "#989898" }} />
            <Typography className="t_oktelo_medium size_12" sx={{ color: "#989898" }}>
              {postDate ? dayjs(postDate).format("YYYY.MM.DD") : dayjs().format("YYYY.MM.DD")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Post Content */}
      <Box mb={1} py={1}>
        <Typography
          className="t_oktelo_normal preview-text-create"
          sx={{
            fontSize: postMessage.length < 85 ? "24px" : "14px",
            lineHeight: postMessage.length < 85 ? "28px" : "18px",
            wordWrap: "break-word",
            overflowWrap: "anywhere"
          }}
        >
          {postMessage.length >= 140 ? (
            <>
              {!isSeeMore ? postMessage.slice(0, 140) + '...' : postMessage + '...'}
              <Tooltip title={!isSeeMore ? 'Összes mutatása' : 'Eredeti előnézet'}>
                <Typography className="t_oktelo_medium size_14" sx={{ cursor: 'pointer' }} component="span" onClick={() => setSeeMore(!isSeeMore)}>
                  {!isSeeMore ? 'Továbbiak' : 'Elrejtés'}
                </Typography>
              </Tooltip>
            </>
          ) : (
            postMessage
          )}
        </Typography>
        <Tooltip title={"Hashtag sablon: " + userHashtags.find(tag => tag.id === selectedHashtag)?.hashtags.join(' ')}
          placement="right">
          <Typography
            className="t_oktelo_normal preview-text-create"
            sx={{
              color: '#1e90ff',
              fontSize: postMessage.length < 85 ? "18px" : "14px",
              lineHeight: postMessage.length < 85 ? "22px" : "18px",
            }}
          >
            {selectedHashtag && userHashtags.find(tag => tag.id === selectedHashtag)?.hashtags.join(' ').slice(0, 50)}
          </Typography>
        </Tooltip>
      </Box>

      {/* Media Content */}
      <Stack
        sx={{
          width: '100%',
          overflow: 'hidden',
          height: selectedKey !== "0" ? '300px' : '0px',
          backgroundColor: '#fff',
          alignItems: 'center'
        }}
      >
        {postMedia[0].url && (
          <>
            {(postMedia[0].type === "video" || postMedia[0].type === "reels") && (
              <video
                key={postMedia[0].name}
                style={{ objectFit: "contain", background: '#414141', width: '100%', height: '100%' }}
                controls
              >
                <source src={postMedia[0].url} type="video/mp4" />
              </video>
            )}
            {postMedia[0].type === "image" && (
              <img
                key={postMedia[0].name}
                src={postMedia[0].url}
                alt="facebook post"
                style={{ objectFit: "contain", width: '100%', height: '100%' }}
                className="property_details-img"
              />
            )}
          </>
        )}
              {/* Post Link Preview */}
      {postLink && (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            marginTop: 2,
            overflow: 'hidden',
            borderRadius: '6px',
            backgroundColor: '#ede4ff'
          }}
          component="a"
          href={postLink.startsWith('http') ? postLink : `https://${postLink}`}
          target="_blank"
        >
          {isImage ? (

            <Box
            sx={{
              padding: 2,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#f9f9f9'
            }}
          >
            <img
              src={postLink}
              alt="facebook post link preview"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <Typography className="size_12 t_oktelo_normal darkblue" p={2}>
              A bejegyzés az URL kattintható előnézetét tartalmazza.
            </Typography>
          </Box>
          ) : (
            <Tooltip title="A link típusú bejegyzés az általad megadott hivatkozásra fog mutatni és az adott hivatkozás előnézetét fogja megjeleníteni (pl. média link, weboldal képe, youtube videó).">
            <Box
              sx={{
                padding: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#f9f9f9'
              }}
            >
              <Typography className="size_14 t_oktelo_medium darkblue">URL Előnézet</Typography>
              <Typography className="size_12 t_oktelo_normal darkblue" p={2}>
                A bejegyzés az URL kattintható előnézetét tartalmazza.
              </Typography>
            </Box>
            </Tooltip>
          )}
        </Box>
      )}
      </Stack>



      {/* Gallery Photos */}
      {galleryPhotos.length >= 1 && galleryPhotos[0].url && (
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <img
              key={galleryPhotos[0].name}
              src={galleryPhotos[0].url}
              alt="facebook post preview 1"
              style={{ objectFit: 'cover', width: '100%', height: '150px' }}
            />
          </Grid>
          {galleryPhotos.slice(1, 4).map((photo, index) => (
            <Grid item xs={galleryPhotos.length === 2 ? 12 : 6} key={index}>
              <img
                src={photo.url}
                alt={`facebook post preview ${index + 2}`}
                style={{ objectFit: 'cover', width: '100%', height: '150px' }}
              />
              {index === 2 && galleryPhotos.length > 3 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography className="t_oktelo_normal size_18 t_white">
                    +{galleryPhotos.length - 3}
                  </Typography>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      )}

      {/* Likes, Comments, Shares */}
      <Stack direction="row" justifyContent="space-between" gap={0.5} alignItems="center" py={1}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Avatar src={okt_likeicon} sx={{ width: "30px", height: "30px" }} />
          <Avatar src={okt_hearticon} sx={{ marginLeft: "-12px", width: "30px", height: "30px" }} />
          <Typography className="size_12 t_oktelo_light">4.8K</Typography>
        </Stack>
        {/* <Typography className="size_12 t_oktelo_light">8 hozzászólás 3 megosztás</Typography> */}
      </Stack>
    </Box>
  );
};

const FacebookStoryPreview = (
  socialPages: UserPagesData,
  selectedPageId: string,
  storyMedia: any,
  storyText: string,
  postDate: string | null,
  userHashtags: UserHashtags[],
  selectedHashtag: string,
) => {

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        maxWidth: "400px",
        overflow: "hidden",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#000",
      }}
    >
      {/* Story Media */}
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
        }}
      >
        {storyMedia[0].url && (
          <>
            {storyMedia[0].type === "image_story" && (
              <img
                src={storyMedia[0].url}
                alt="Facebook Story"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            )}
            {storyMedia[0].type === "video_story" && (
              <video
                src={storyMedia[0].url}
                controls
                autoPlay
                muted
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            )}
          </>
        )}

        {/* Story Overlay Text */}
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            right: "20px",
            color: "#fff",
            textShadow: "0px 2px 8px rgba(0, 0, 0, 0.7)",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {storyText && (
            <Typography
              className="t_oktelo_medium"
              sx={{ fontSize: "18px", lineHeight: "22px", wordWrap: "break-word" }}
            >
              {storyText}
            </Typography>
          )}
          {selectedHashtag && (
            <Typography
              className="t_oktelo_medium"
              sx={{ fontSize: "16px", color: "#1e90ff" }}
            >
              {userHashtags.find(tag => tag.id === selectedHashtag)?.hashtags.join(' ')}
            </Typography>
          )}
        </Box>
      </Box>

      {/* User Info Overlay */}
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: "absolute",
          top: "16px",
          left: "16px",
          right: "16px",
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar
            alt="Profile Pic"
            src={socialPages.allPages.find(page => page.id === selectedPageId)?.photo || "https://via.placeholder.com/150"}
            sx={{ width: "32px", height: "32px" }}
          />
            <Typography className="t_oktelo_medium size_12" sx={{ color: "#fff" }}>
              {socialPages.allPages.find(page => page.id === selectedPageId)?.name || "Példa oldal"}
            </Typography>
            <Typography className="t_oktelo_light size_12" sx={{ color: "#f0f0f0" }}>
              9 ó.
            </Typography>
            <Public sx={{ fontSize: '16px', color: '#fff'}}/>
        </Stack>
        <MoreHoriz sx={{ fontSize: "16px", color: "#fff" }} />
      </Stack>
    </Box>
  );
};


const InstagramLivePreview = (
  socialPages: UserPagesData,
  selectedPageId: string,
  postDate: string | null,
  postMessage: string,
  postMedia: any[] = [],  // Default to an empty array
  galleryPhotos: any[] = [],  // Default to an empty array
  previewMode: string,
  selectedKey: string,
  postLink: string = '',  // Default to an empty string
  selectedHashtag: string,
  userHashtags: UserHashtags[] = [],  // Default to an empty array
  igCollaborator: string = '',  // Default to an empty string
  igUsersTagged: IGUserTag[] = [],  // Default to an empty array
  isImage: boolean,
  isSeeMore: boolean,
  setSeeMore: React.Dispatch<React.SetStateAction<boolean>>,
) => {


  const locationStyles: { [key: string]: { left?: string; top?: string; right?: string; bottom?: string; transform?: string } } = {
    topLeft: { left: '8px', top: '8px' },
    bottomLeft: { left: '8px', bottom: '8px' },
    topRight: { right: '8px', top: '8px' },
    bottomRight: { right: '8px', bottom: '8px' },
    center: { left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }
  };


  return (
    <Box
      sx={{
        width: '100%',
        height: previewMode === 'mobile' ? '100%' : 'auto',
        backgroundColor: '#fff',
      }}
    >
      {/* Header */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1}
        sx={{ backgroundColor: '#fff' }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar
            alt="Profile Pic"
            src={socialPages.allPages.find(page => page.id === selectedPageId)?.photo || 'https://via.placeholder.com/150'}
          />
          <Typography className="t_oktelo_medium size_12 darkblue">
            {socialPages.allPages.find(page => page.id === selectedPageId)?.name || 'Példa oldal'}
            {igCollaborator && igCollaborator !== '' && (
              <span>
                <span className="t_oktelo_normal"> és </span>
                {igCollaborator}
              </span>
            )}
          </Typography>
        </Stack>
        <MoreVert />
      </Stack>

      {/* Post Content */}
      <Box
        sx={{
          padding: 0,
          marginTop: 0,
          height: previewMode === 'mobile' ? '350px' : '500px',
          width: '100%',
          backgroundColor: '#fff',
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {postMedia[0]?.url && (
            <>
              {postMedia[0].type === 'video' && (
                <video
                  key={postMedia[0].name}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '0px',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                  controls
                >
                  <source src={postMedia[0].url} type="video/mp4" />
                </video>
              )}
              {postMedia[0].type === 'image' && (
                <img
                  key={postMedia[0].name}
                  src={postMedia[0].url}
                  alt="Instagram post"
                  style={{
                    objectFit: 'cover',
                    borderRadius: '0px',
                    width: '100%',
                    height: '100%',
                  }}
                  className="property_details-img"
                />
              )}
            </>
          )}

          {postLink && (
            <Box
              sx={{
                position: 'relative',
                height: 'auto',
                width: '100%',
                overflow: 'hidden',
                borderRadius: '6px',
                backgroundColor: '#f6f6f6',
              }}
              component="a"
              href={postLink.startsWith('http') ? postLink : `https://${postLink}`}
              target="_blank"
            >
              {isImage ? (
                <img
                  src={postLink}
                  alt="Instagram post link preview"
                  style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                  className="property_details-img"
                />
              ) : (
                <Box p={2} textAlign="center">
                  <Typography className="size_14 t_oktelo_medium darkblue">Hibás fotó URL</Typography>
                </Box>
              )}
            </Box>
          )}

          {galleryPhotos.length > 0 && (
            <ConfigProvider
              theme={{
                components: {
                  Carousel: {
                    dotActiveWidth: 5,
                    dotHeight: 5,
                    dotWidth: 5,
                  },
                },
              }}
            >
              <div style={{ height: '100%', width: '100%' }} className="preview-carousel-container">
                <Carousel dots infinite={false} draggable>
                  {galleryPhotos.map(photo => (
                    <div key={photo.url} style={{ width: '100%', height: '100%' }}>
                      <img
                        src={photo.url}
                        alt="Gallery"
                        style={{
                          objectFit: 'cover',
                          borderRadius: '0px',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </ConfigProvider>
          )}

          {igUsersTagged.map(tag => {
            const style = locationStyles[tag.location];
            if (!style) return null;

            return (
              <Box
                sx={{
                  position: 'absolute',
                  padding: '4px 8px',
                  backgroundColor: 'rgba(49,45,41,0.8)',
                  borderRadius: '6px',
                  color: 'white',
                  ...style,
                  '::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderWidth: '6px',
                    borderStyle: 'solid',
                    borderColor: 'transparent transparent rgba(49,45,41,0.8) transparent',
                    zIndex: 333,
                  },
                }}
                key={tag.username}
              >
                <Typography className="size_12 t_white t_oktelo_medium">
                  {tag.username.length > 30 ? `${tag.username.slice(0, 30)}..` : tag.username}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>

      {/* Post Interaction */}
      <Box mt={0.5}>
        <Stack direction="row" gap={1} alignItems="center" my={0.5} px={1} justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <FavoriteBorder />
            <ModeCommentOutlined />
            <NearMeOutlined />
          </Box>
          <BookmarkBorderOutlined />
        </Stack>
        <Stack mb={0.5} px={1}>
          <Typography className="t_oktelo_medium darkblue size_14">
            okteloapp <span className="t_oktelo_light">és </span> további emberek <span className="t_oktelo_light">kedvelik</span>
          </Typography>
        </Stack>
        <Stack p={1}>
          <Typography
            className="t_oktelo_normal darkblue size_14"
            sx={{ wordWrap: 'break-word' }}
          >
            <span style={{ fontWeight: '500' }}>
              {socialPages.allPages.find(page => page.id === selectedPageId)?.name || 'Példa oldal'}
            </span>{' '}
            {postMessage.length > 77 ? 
            <>
            {!isSeeMore ? postMessage.slice(0, 77) + '...' : postMessage + '...'}
            <Tooltip title={!isSeeMore ? 'Összes mutatása' : 'Eredeti előnézet'}>
                <Typography className="t_oktelo_medium size_14" sx={{ cursor: 'pointer' }} component="span" onClick={() => setSeeMore(!isSeeMore)}>
                  {!isSeeMore ? 'Továbbiak' : 'Elrejtés'}
                </Typography>
              </Tooltip>
            </> :
            postMessage}
            <Tooltip title={"Hashtag sablon: " + userHashtags.find(tag => tag.id === selectedHashtag)?.hashtags.join(' ')}
              placement="right">
            <span className="t_oktelo_normal size_14" style={{ color: '#E4405F' }}>
              {' '}{selectedHashtag && userHashtags.find(tag => tag.id === selectedHashtag)?.hashtags.join(' ').slice(0, 50)}
            </span></Tooltip>
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};


const RenderCreateTextPost = (
  selectedKey: string,
  postMessage: string,
  setPostMessage: React.Dispatch<React.SetStateAction<string>>,
  isFirstComment: boolean,
  setFirstComment: React.Dispatch<React.SetStateAction<boolean>>,
  handleEmojiClick: any,
  setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  emojiPickerOpen: boolean,
  setFirstCommentMessage: React.Dispatch<React.SetStateAction<string>>,
  firstCommentMessage: string,
  handlePublishNow: () => void,
  isPublishNow: boolean,
  postDate: string | null,
  postTime: string | null,
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>,
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>,
  socialPages: UserPagesData,
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>,
  selectedPageId: string,
  selectedPlatform: string,
  postNoteMessage: string,
  setPostNoteMessage: React.Dispatch<React.SetStateAction<string>>,
  userTags: UserTags[],
  selectedTag: string,
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>,
  userHashtags: UserHashtags[],
  selectedHashtag: string,
  setSelectedHashtag: React.Dispatch<React.SetStateAction<string>>,
  setHastagModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  hashtagModalVisible: boolean,
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>,
  isAiTextModalShow: boolean,
  onStartAiTextGeneration: () => void,
  aiInstructionsText: string,
  setAiInstructionsText: React.Dispatch<React.SetStateAction<string>>,

) => {

  return (
    <Box>
      <SelectPage
        socialPages={socialPages}
        selectedPlatform={selectedPlatform}
        selectedPageId={selectedPageId}
        setSelectedPageId={setSelectedPageId}
      />
      <PostMessageSection
        postMessage={postMessage}
        setPostMessage={setPostMessage}
        emojiPickerOpen={emojiPickerOpen}
        setEmojiPickerOpen={setEmojiPickerOpen}
        handleEmojiClick={handleEmojiClick}
        hashtagModalVisible={hashtagModalVisible}
        setHashtagModalVisible={setHastagModalVisible}
        selectedHashtag={selectedHashtag}
        setSelectedHashtag={setSelectedHashtag}
        userHashtags={userHashtags}
        setAiTextModalShow={setAiTextModalShow}
        isAiTextModalShow={isAiTextModalShow}
      />
      <AiModalSection
        isAiTextModalShow={isAiTextModalShow}
        setAiTextModalShow={setAiTextModalShow}
        onStartAiTextGeneration={onStartAiTextGeneration}
        AiInstructionsText={aiInstructionsText}
        setAiInstructionsText={setAiInstructionsText}/>
      <FirstCommentSection
        isFirstComment={isFirstComment}
        setFirstComment={setFirstComment}
        firstCommentMessage={firstCommentMessage}
        setFirstCommentMessage={setFirstCommentMessage}
      />
      <DateTimePicker
        postDate={postDate}
        postTime={postTime}
        setPostDate={setPostDate}
        setPostTime={setPostTime}
        handlePublishNow={handlePublishNow}
        isPublishNow={isPublishNow}
      />
      <TagSelector
        userTags={userTags}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
      <NoteSection
        note={postNoteMessage}
        setNote={setPostNoteMessage}
      />
    </Box>
  );
};

const RenderCreatePhotoPost = (
  selectedKey: string,
  postMessage: string,
  setPostMessage: React.Dispatch<React.SetStateAction<string>>,
  isFirstComment: boolean,
  setFirstComment: React.Dispatch<React.SetStateAction<boolean>>,
  handleEmojiClick: any,
  setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  emojiPickerOpen: boolean,
  setFirstCommentMessage: React.Dispatch<React.SetStateAction<string>>,
  firstCommentMessage: string,
  handlePublishNow: () => void,
  isPublishNow: boolean,
  postDate: string | null,
  postTime: string | null,
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>,
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>,
  socialPages: UserPagesData,
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>,
  selectedPageId: string,
  selectedPlatform: string,
  postNoteMessage: string,
  setPostNoteMessage: React.Dispatch<React.SetStateAction<string>>,
  userTags: UserTags[],
  selectedTag: string,
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>,
  postMedia: any,
  setPostMedia: any,
  isImageUrlPasterOpen: boolean,
  setImageUrlPasterOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getImageUrl: string,
  setImageUrl: React.Dispatch<React.SetStateAction<string>>,
  userHashtags: UserHashtags[],
  selectedHashtag: string,
  setSelectedHashtag: React.Dispatch<React.SetStateAction<string>>,
  setHastagModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  hashtagModalVisible: boolean,
  igCollaborator: string,
  setIgCollaborator: React.Dispatch<React.SetStateAction<string>>,
  igUsersTagged: IGUserTag[],
  setIgUsersTagged: React.Dispatch<React.SetStateAction<IGUserTag[]>>,
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>,
  isAiTextModalShow: boolean,
  onStartAiTextGeneration: () => void,
  aiInstructionsText: string,
  setAiInstructionsText: React.Dispatch<React.SetStateAction<string>>,
  isMediaWindowOpen: boolean,
  setMediaWindowOpen: React.Dispatch<React.SetStateAction<boolean>>,
  localMedia: MediaFile[],
  refetchMedia: () => void,
) => {

  const handleImageChange = (file: File | string) => {
    if (file instanceof File) {
      const reader = (readFile: File) =>
        new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(readFile);
        });

      reader(file).then((result: string) =>
        setPostMedia([{ name: file.name, url: result, type: "image" }])
      );
    } else if (typeof file === "string") {
      setPostMedia([
        {
          name: `OkteloAI_${generateRandomSuffix(6)}`,
          url: file,
          type: "image",
        },
      ]);
    }
  };

  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const file = info.fileList[0]?.originFileObj as RcFile | undefined;
    if (file) {
      handleImageChange(file);
    }
  };

  function generateRandomSuffix(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  const handleUsernameChange = (index: number, text: string) => {
      const newIgUsersTagged = [...igUsersTagged];
      if (newIgUsersTagged[index]) {
        newIgUsersTagged[index].username = text;
      } else {
        newIgUsersTagged.push({ username: text, location: 'topLeft' });
      }
      setIgUsersTagged(newIgUsersTagged);
  };

  const handleTagLocationChange = (index: number, option: string) => {
    const newIgUsersTagged = [...igUsersTagged];
    if (newIgUsersTagged[index]) {
      newIgUsersTagged[index].location = option;
    } else {
      newIgUsersTagged.push({ username: '', location: option });
    }
    setIgUsersTagged(newIgUsersTagged);
  };


  const submitImageUrl = () => {
    try {
      const isUrl = new URL(getImageUrl);

      const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.svg', '.webp'];

      const lowerCaseUrl = getImageUrl.toLowerCase();
      const isImageExtension = imageExtensions.some(ext => lowerCaseUrl.includes(ext));

      if(isImageExtension && isUrl) {
        setPostMedia([{name: generateRandomSuffix(8), url: getImageUrl, type: "image"}])
      } else {
        setPostMedia([{ name: "", url: "", type: "wrong_format"}])
      }

      return true;
    } catch (e) {
      setPostMedia([{ name: "", url: "", type: "wrong_format"}])
      return false;
    }
  }

  return (
    <Box>
      <SelectPage
          socialPages={socialPages}
          selectedPlatform={selectedPlatform}
          selectedPageId={selectedPageId}
          setSelectedPageId={setSelectedPageId}
        />
      <PostMessageSection
        postMessage={postMessage}
        setPostMessage={setPostMessage}
        emojiPickerOpen={emojiPickerOpen}
        setEmojiPickerOpen={setEmojiPickerOpen}
        handleEmojiClick={handleEmojiClick}
        hashtagModalVisible={hashtagModalVisible}
        setHashtagModalVisible={setHastagModalVisible}
        selectedHashtag={selectedHashtag}
        setSelectedHashtag={setSelectedHashtag}
        userHashtags={userHashtags}
        setAiTextModalShow={setAiTextModalShow}
        isAiTextModalShow={isAiTextModalShow}
      />
      <AiModalSection
        isAiTextModalShow={isAiTextModalShow}
        setAiTextModalShow={setAiTextModalShow}
        onStartAiTextGeneration={onStartAiTextGeneration}
        AiInstructionsText={aiInstructionsText}
        setAiInstructionsText={setAiInstructionsText}/>
      <FirstCommentSection
        isFirstComment={isFirstComment}
        setFirstComment={setFirstComment}
        firstCommentMessage={firstCommentMessage}
        setFirstCommentMessage={setFirstCommentMessage}
      />
      {selectedPlatform === "instagram" &&
      <InstagramExtras
        igCollaborator={igCollaborator}
        setIgCollaborator={setIgCollaborator}
        igUsersTagged={igUsersTagged}
        handleTagLocationChange={handleTagLocationChange}
        handleUsernameChange={handleUsernameChange}
      />}
      <ImageUploadSection
        handleUploadChange={handleUploadChange}
        setPostMedia={setPostMedia}
        postMedia={postMedia}
        setImageUrl={setImageUrl}
        getImageUrl={getImageUrl}
        submitImageUrl={submitImageUrl}
        setImageUrlPasterOpen={setImageUrlPasterOpen}
        isImageUrlPasterOpen={isImageUrlPasterOpen}
        isMediaWindowOpen={isMediaWindowOpen}
        setMediaWindowOpen={setMediaWindowOpen}
      />
      <MediaLibrary
        isMediaWindowOpen={isMediaWindowOpen}
        setMediaWindowOpen={setMediaWindowOpen}
        userMediaFiles={localMedia}
        currentKey={selectedKey}
        setPostMedia={setPostMedia}
        refetchMedia={refetchMedia}
      />
      <DateTimePicker
        postDate={postDate}
        postTime={postTime}
        setPostDate={setPostDate}
        setPostTime={setPostTime}
        handlePublishNow={handlePublishNow}
        isPublishNow={isPublishNow}
      />
      <TagSelector
        userTags={userTags}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
    <NoteSection
        note={postNoteMessage}
        setNote={setPostNoteMessage}
      />
    </Box>
  );
};

const RenderCreateVideoPost = (
  selectedKey: string,
  postMessage: string,
  setPostMessage: React.Dispatch<React.SetStateAction<string>>,
  isFirstComment: boolean,
  setFirstComment: React.Dispatch<React.SetStateAction<boolean>>,
  handleEmojiClick: any,
  setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  emojiPickerOpen: boolean,
  setFirstCommentMessage: React.Dispatch<React.SetStateAction<string>>,
  firstCommentMessage: string,
  handlePublishNow: () => void,
  isPublishNow: boolean,
  postDate: string | null,
  postTime: string | null,
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>,
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>,
  socialPages: UserPagesData,
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>,
  selectedPageId: string,
  selectedPlatform: string,
  postNoteMessage: string,
  setPostNoteMessage: React.Dispatch<React.SetStateAction<string>>,
  userTags: UserTags[],
  selectedTag: string,
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>,
  postMedia: any,
  setPostMedia: any,
  userHashtags: UserHashtags[],
  selectedHashtag: string,
  setSelectedHashtag: React.Dispatch<React.SetStateAction<string>>,
  setHastagModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  hashtagModalVisible: boolean,
  igCollaborator: string,
  setIgCollaborator: React.Dispatch<React.SetStateAction<string>>,
  igUsersTagged: IGUserTag[],
  setIgUsersTagged: React.Dispatch<React.SetStateAction<IGUserTag[]>>,
  isReelsVideo: boolean,
  setReelsVideo: React.Dispatch<React.SetStateAction<boolean>>,
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>,
  isAiTextModalShow: boolean,
  onStartAiTextGeneration: () => void,
  aiInstructionsText: string,
  setAiInstructionsText: React.Dispatch<React.SetStateAction<string>>,
  isMediaWindowOpen: boolean,
  setMediaWindowOpen: React.Dispatch<React.SetStateAction<boolean>>,
  localMedia: MediaFile[],
  refetchMedia: () => void,
  isImageUrlPasterOpen: boolean,
  setImageUrlPasterOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {

  const handleImageChange = (file: File | string) => {
    if (file instanceof File) {
      const reader = (readFile: File) =>
        new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(readFile);
        });

      reader(file).then((result: string) =>
        setPostMedia([{ name: file.name, url: result, type: "video" }])
      );
    } else if (typeof file === "string") {
      setPostMedia([
        {
          name: `OkteloAI_${generateRandomSuffix(6)}`,
          url: file,
          type: "video",
        },
      ]);
    }
  };

  const handleReelChange = () => {
    if(isReelsVideo){
      setReelsVideo(false);
      setPostMedia([{ name: postMedia[0].name, url: postMedia[0].url, type: 'video'}])
    } else {
      setReelsVideo(true);
      setPostMedia([{ name: postMedia[0].name, url: postMedia[0].url, type: 'reels'}])
    }
  }

  const handleUsernameChange = (index: number, text: string) => {
    const newIgUsersTagged = [...igUsersTagged];
    if (newIgUsersTagged[index]) {
      newIgUsersTagged[index].username = text;
    } else {
      newIgUsersTagged.push({ username: text, location: 'topLeft' });
    }
    setIgUsersTagged(newIgUsersTagged);
};

const handleTagLocationChange = (index: number, option: string) => {
  const newIgUsersTagged = [...igUsersTagged];
  if (newIgUsersTagged[index]) {
    newIgUsersTagged[index].location = option;
  } else {
    newIgUsersTagged.push({ username: '', location: option });
  }
  setIgUsersTagged(newIgUsersTagged);
};

  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const file = info.fileList[0]?.originFileObj as RcFile | undefined;
    if (file) {
      handleImageChange(file);
    }
  };

  function generateRandomSuffix(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  return (
    <Box>
           <SelectPage
        socialPages={socialPages}
        selectedPlatform={selectedPlatform}
        selectedPageId={selectedPageId}
        setSelectedPageId={setSelectedPageId}
      />
      <PostMessageSection
        postMessage={postMessage}
        setPostMessage={setPostMessage}
        emojiPickerOpen={emojiPickerOpen}
        setEmojiPickerOpen={setEmojiPickerOpen}
        handleEmojiClick={handleEmojiClick}
        hashtagModalVisible={hashtagModalVisible}
        setHashtagModalVisible={setHastagModalVisible}
        selectedHashtag={selectedHashtag}
        setSelectedHashtag={setSelectedHashtag}
        userHashtags={userHashtags}
        setAiTextModalShow={setAiTextModalShow}
        isAiTextModalShow={isAiTextModalShow}
      />
      <AiModalSection
        isAiTextModalShow={isAiTextModalShow}
        setAiTextModalShow={setAiTextModalShow}
        onStartAiTextGeneration={onStartAiTextGeneration}
        AiInstructionsText={aiInstructionsText}
        setAiInstructionsText={setAiInstructionsText}/>
      <FirstCommentSection
        isFirstComment={isFirstComment}
        setFirstComment={setFirstComment}
        firstCommentMessage={firstCommentMessage}
        setFirstCommentMessage={setFirstCommentMessage}
      />
      {selectedPlatform === "instagram" &&
      <InstagramExtras
        igCollaborator={igCollaborator}
        setIgCollaborator={setIgCollaborator}
        igUsersTagged={igUsersTagged}
        handleTagLocationChange={handleTagLocationChange}
        handleUsernameChange={handleUsernameChange}
      />}
      <Box className="oktelo-create-post-form" mt={2}>
        <Stack direction="row" alignItems="center" gap={1} mb={1.5}>
          <Avatar
            sx={{ width: "30px", height: "30px", backgroundColor: "#f2e8e5" }}
          >
            <VideoCameraBack
              sx={{ fontSize: "16px", color: "#802200" }}
            />
          </Avatar>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Videó
          </Typography>
        </Stack>
        <Typography className="t_oktelo_medium size_12 darkblue">
          Videó feltöltése
        </Typography>
        <Upload
          onChange={handleUploadChange}
          accept="video/*"
          type="drag"
          listType="picture-card"
          beforeUpload={() => false}
          className="upload-list-inline"
          multiple={false}
          maxCount={1}
          onRemove={() => setPostMedia([{ name: "", url: "", type: "" }])}
          fileList={postMedia[0].url !== "" ? postMedia : []}
        >
          <Stack
            direction="row"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
            <Box textAlign="left">
              <Typography className="t_oktelo t_purple t_highlight">
                Videó kiválasztása
              </Typography>
              <Typography className="t_oktelo t_misc">
                Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
              </Typography>
            </Box>
          </Stack>
        </Upload>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" gap={1} alignItems="center">
        <VideoSettings sx={{ fontSize: '16px', color: '#925FFF'}}/>
        <Typography className="t_oktelo_medium size_14 darkblue">Videó típusa</Typography>
        <Tag className="tag_oktelo size_12" color="purple" bordered={false}>
          {isReelsVideo ? 'Reels (9:16, max. 90mp)' : 'Videó poszt (1:1, max 5p)'}
        </Tag>
      </Stack>
        <Switch checked={isReelsVideo} color="secondary" onChange={() => handleReelChange()} />
        </Stack>
        <Stack direction="row" gap={1} alignItems="center" width="100%">
          <button className="image-url-button" onClick={() => setImageUrlPasterOpen(!isImageUrlPasterOpen)}>
            <LinkOutlined style={{ marginRight: '6px' }}/> Kép URL {isImageUrlPasterOpen? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
          </button>
          <button className="image-url-button" onClick={() => setMediaWindowOpen(!isMediaWindowOpen)}>
            <ImageSearchOutlined style={{ marginRight: '6px' }}/> Média könyvtár
          </button>
        </Stack>
      </Box>
      <MediaLibrary
        isMediaWindowOpen={isMediaWindowOpen}
        setMediaWindowOpen={setMediaWindowOpen}
        userMediaFiles={localMedia}
        currentKey={selectedKey}
        setPostMedia={setPostMedia}
        refetchMedia={refetchMedia}
      />
      <DateTimePicker
        postDate={postDate}
        postTime={postTime}
        setPostDate={setPostDate}
        setPostTime={setPostTime}
        handlePublishNow={handlePublishNow}
        isPublishNow={isPublishNow}
      />
      <TagSelector
        userTags={userTags}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
          <NoteSection
        note={postNoteMessage}
        setNote={setPostNoteMessage}
      />
    </Box>
  );
};


const RenderCreateGalleryPost = (
  postMessage: string,
  setPostMessage: React.Dispatch<React.SetStateAction<string>>,
  isFirstComment: boolean,
  setFirstComment: React.Dispatch<React.SetStateAction<boolean>>,
  handleEmojiClick: any,
  setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  emojiPickerOpen: boolean,
  setFirstCommentMessage: React.Dispatch<React.SetStateAction<string>>,
  firstCommentMessage: string,
  handlePublishNow: () => void,
  isPublishNow: boolean,
  postDate: string | null,
  postTime: string | null,
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>,
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>,
  socialPages: UserPagesData,
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>,
  selectedPageId: string,
  selectedPlatform: string,
  postNoteMessage: string,
  setPostNoteMessage: React.Dispatch<React.SetStateAction<string>>,
  userTags: UserTags[],
  selectedTag: string,
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>,
  galleryPhotos: any,
  setGalleryPhotos: any,
  userHashtags: UserHashtags[],
  selectedHashtag: string,
  setSelectedHashtag: React.Dispatch<React.SetStateAction<string>>,
  setHashtagModalVisible:React.Dispatch<React.SetStateAction<boolean>>,
  hashtagModalVisible: boolean,
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>,
  isAiTextModalShow: boolean,
  onStartAiTextGeneration: () => void,
  aiInstructionsText: string,
  setAiInstructionsText: React.Dispatch<React.SetStateAction<string>>,
) => {

  const handleMultipleUploadChange = (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const files = info.fileList
      .map((file) => file.originFileObj)
      .filter(Boolean) as File[];
    handleCarouselChange(files);
  };

  const handleCarouselChange = (files: File[]) => {
    const reader = (readFile: File) =>
      new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result as string);
        fileReader.readAsDataURL(readFile);
      });

    const promises = files.map((file) =>
      reader(file).then((result: string) => ({
        name: file.name,
        url: result,
      }))
    );

    Promise.all(promises).then((results) => {
      setGalleryPhotos(results);
    });
  };

  const handleRemove = (file: UploadFile) => {
    setGalleryPhotos((prevPhotos: UploadFile[]) =>
      prevPhotos.filter((photo) => photo.name !== file.name)
    );
  };



  return (
    <Box>
      <SelectPage
          socialPages={socialPages}
          selectedPlatform={selectedPlatform}
          selectedPageId={selectedPageId}
          setSelectedPageId={setSelectedPageId}
        />
      <PostMessageSection
        postMessage={postMessage}
        setPostMessage={setPostMessage}
        emojiPickerOpen={emojiPickerOpen}
        setEmojiPickerOpen={setEmojiPickerOpen}
        handleEmojiClick={handleEmojiClick}
        hashtagModalVisible={hashtagModalVisible}
        setHashtagModalVisible={setHashtagModalVisible}
        selectedHashtag={selectedHashtag}
        setSelectedHashtag={setSelectedHashtag}
        userHashtags={userHashtags}
        setAiTextModalShow={setAiTextModalShow}
        isAiTextModalShow={isAiTextModalShow}
      />
      <AiModalSection
        isAiTextModalShow={isAiTextModalShow}
        setAiTextModalShow={setAiTextModalShow}
        onStartAiTextGeneration={onStartAiTextGeneration}
        AiInstructionsText={aiInstructionsText}
        setAiInstructionsText={setAiInstructionsText}/>
      <FirstCommentSection
        isFirstComment={isFirstComment}
        setFirstComment={setFirstComment}
        firstCommentMessage={firstCommentMessage}
        setFirstCommentMessage={setFirstCommentMessage}
      />
      <Box className="oktelo-create-post-form" mt={2}>
        <Stack direction="row" alignItems="center" gap={1} mb={1.5}>
          <Avatar
            sx={{ width: "30px", height: "30px", backgroundColor: "#f2e8e5" }}
          >
            <PhotoSizeSelectActualOutlined
              sx={{ fontSize: "16px", color: "#802200" }}
            />
          </Avatar>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Képek
          </Typography>
        </Stack>
        <Typography className="t_oktelo_medium size_12 darkblue">
          Képek kiválasztása (max. 5)
        </Typography>
        <Upload
          onChange={handleMultipleUploadChange}
          accept="image/*"
          type="drag"
          listType="picture"
          onRemove={handleRemove}
          className="upload-list-inline"
          multiple={true}
          beforeUpload={() => false}
          maxCount={5}
          defaultFileList={[]}
          fileList={galleryPhotos}
        >
          <Stack
            direction="row"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
            <Box textAlign="left">
              <Typography className="t_oktelo t_purple t_highlight">
                Képek
              </Typography>
              <Typography className="t_oktelo t_misc">
                Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
              </Typography>
            </Box>
          </Stack>
        </Upload>
      </Box>
      <DateTimePicker
        postDate={postDate}
        postTime={postTime}
        setPostDate={setPostDate}
        setPostTime={setPostTime}
        handlePublishNow={handlePublishNow}
        isPublishNow={isPublishNow}
      />
            <TagSelector
        userTags={userTags}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
    <NoteSection
        note={postNoteMessage}
        setNote={setPostNoteMessage}
      />
    </Box>
  );
};

const RenderCreateLinkPost = (
  postMessage: string,
  setPostMessage: React.Dispatch<React.SetStateAction<string>>,
  isFirstComment: boolean,
  setFirstComment: React.Dispatch<React.SetStateAction<boolean>>,
  handleEmojiClick: any,
  setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  emojiPickerOpen: boolean,
  setFirstCommentMessage: React.Dispatch<React.SetStateAction<string>>,
  firstCommentMessage: string,
  handlePublishNow: () => void,
  isPublishNow: boolean,
  postDate: string | null,
  postTime: string | null,
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>,
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>,
  socialPages: UserPagesData,
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>,
  selectedPageId: string,
  selectedPlatform: string,
  postNoteMessage: string,
  setPostNoteMessage: React.Dispatch<React.SetStateAction<string>>,
  userTags: UserTags[],
  selectedTag: string,
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>,
  postLink: string,
  setPostLink: React.Dispatch<React.SetStateAction<string>>,
  userHashtags: UserHashtags[],
  selectedHashtag: string,
  setSelectedHashtag: React.Dispatch<React.SetStateAction<string>>,
  setHastagModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  hashtagModalVisible: boolean,
  setAiTextModalShow: React.Dispatch<React.SetStateAction<boolean>>,
  isAiTextModalShow: boolean,
  onStartAiTextGeneration: () => void,
  aiInstructionsText: string,
  setAiInstructionsText: React.Dispatch<React.SetStateAction<string>>,
) => {

  const isValidUrl = (urlString: string) => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const returnStatus = (link: string) => {
    // Regular expression for validating URLs
    const urlPattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/[\w\d-]*)*(\?[;&=\w\d-]*)?(#[\w\d-]*)?$/i;
  
    // Check if the input link matches the URL pattern
    if ( urlPattern.test(link) &&  isValidUrl(link)) {
      return ""; // Valid URL
    } else {
      return "error"; // Invalid URL
    }
  };

  return (
    <Box>
            <SelectPage
          socialPages={socialPages}
          selectedPlatform={selectedPlatform}
          selectedPageId={selectedPageId}
          setSelectedPageId={setSelectedPageId}
        />
            <PostMessageSection
        postMessage={postMessage}
        setPostMessage={setPostMessage}
        emojiPickerOpen={emojiPickerOpen}
        setEmojiPickerOpen={setEmojiPickerOpen}
        handleEmojiClick={handleEmojiClick}
        hashtagModalVisible={hashtagModalVisible}
        setHashtagModalVisible={setHastagModalVisible}
        selectedHashtag={selectedHashtag}
        setSelectedHashtag={setSelectedHashtag}
        userHashtags={userHashtags}
        setAiTextModalShow={setAiTextModalShow}
        isAiTextModalShow={isAiTextModalShow}
      />
      <AiModalSection
        isAiTextModalShow={isAiTextModalShow}
        setAiTextModalShow={setAiTextModalShow}
        onStartAiTextGeneration={onStartAiTextGeneration}
        AiInstructionsText={aiInstructionsText}
        setAiInstructionsText={setAiInstructionsText}/>
      <FirstCommentSection
        isFirstComment={isFirstComment}
        setFirstComment={setFirstComment}
        firstCommentMessage={firstCommentMessage}
        setFirstCommentMessage={setFirstCommentMessage}
      />
      <Box className="oktelo-create-post-form" mt={2}>
        <Stack direction="row" alignItems="center" gap={1} mb={1.5}>
          <Avatar
            sx={{ width: "30px", height: "30px", backgroundColor: "#f2e8e5" }}
          >
            <Link sx={{ fontSize: "16px", color: "#802200" }} />
          </Avatar>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Hivatkozás
          </Typography>
        </Stack>
        <Typography className="t_oktelo_medium size_12 darkblue">
          URL címe
        </Typography>
        <Input
          onChange={(e) => setPostLink(e.target.value)}
          name="link"
          placeholder="https://..."
          prefix={<LinkOutlined/>}
          status={returnStatus(postLink)}
        />
        {returnStatus(postLink) === "error" &&
        <Typography className="t_oktelo_normal size_12 darkblue">
          Elfogadható formátum: https://valami.xy...
        </Typography>
        }
      </Box>
      <DateTimePicker
        postDate={postDate}
        postTime={postTime}
        setPostDate={setPostDate}
        setPostTime={setPostTime}
        handlePublishNow={handlePublishNow}
        isPublishNow={isPublishNow}
      />
      <TagSelector
        userTags={userTags}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
    <NoteSection
        note={postNoteMessage}
        setNote={setPostNoteMessage}
      />
    </Box>
  );
};

const RenderCreateStory = (

  handlePublishNow: () => void,
  isPublishNow: boolean,
  postDate: string | null,
  postTime: string | null,
  setPostDate: React.Dispatch<React.SetStateAction<string | null>>,
  setPostTime: React.Dispatch<React.SetStateAction<string | null>>,
  socialPages: UserPagesData,
  setSelectedPageId: React.Dispatch<React.SetStateAction<string>>,
  selectedPageId: string,
  selectedPlatform: string,
  postNoteMessage: string,
  setPostNoteMessage: React.Dispatch<React.SetStateAction<string>>,
  userTags: UserTags[],
  selectedTag: string,
  setSelectedTag: React.Dispatch<React.SetStateAction<string>>,
  postMedia: any,
  setPostMedia: any,
  isStoryTypeImage: boolean,
  setStoryTypeImage: React.Dispatch<React.SetStateAction<boolean>>,

) => {

  const handleImageChange = (file: File | string) => {
    if (file instanceof File) {
      const reader = (readFile: File) =>
        new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string);
          fileReader.readAsDataURL(readFile);
        });

      reader(file).then((result: string) =>
        setPostMedia([{ name: file.name, url: result, type: isStoryTypeImage ? "image_story" : "video_story" }])
      );
    } else if (typeof file === "string") {
      setPostMedia([
        {
          name: `OkteloAI_${generateRandomSuffix(6)}`,
          url: file,
          type: "story",
        },
      ]);
    }
  };

  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const file = info.fileList[0]?.originFileObj as RcFile | undefined;
    if (file) {
      handleImageChange(file);
    }
  };

  function generateRandomSuffix(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  return (
    <Box>
           <SelectPage
          socialPages={socialPages}
          selectedPlatform={selectedPlatform}
          selectedPageId={selectedPageId}
          setSelectedPageId={setSelectedPageId}
        />

      <Box className="oktelo-create-post-form" mt={2}>
        <Stack direction="row" alignItems="center" gap={1} mb={1.5}>
          <Avatar
            sx={{ width: "30px", height: "30px", backgroundColor: "#f2e8e5" }}
          >
            <PhotoSizeSelectActualOutlined
              sx={{ fontSize: "16px", color: "#802200" }}
            />
          </Avatar>
          <Typography className="t_oktelo_medium size_16 darkblue">
            Történet média
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography className="t_oktelo_medium size_14 darkblue">Történet típusa</Typography>
        <Tag className="tag_oktelo size_12" color="purple" bordered={false}>
          {isStoryTypeImage ? 'Kép' : 'Videó'}
        </Tag>
      </Stack>
        <Switch checked={isStoryTypeImage} color="secondary" onChange={() => {
          setStoryTypeImage(!isStoryTypeImage);
          setPostMedia([{ name: "", url: "", type: "" }]); }}/>
        </Stack>
        {isStoryTypeImage ? (
          <>
        <Typography className="t_oktelo_medium size_12 darkblue">
          Kép feltöltése (ajánlott 9:16 arány, maximum méret: 8MB, .JPG)
        </Typography>
        <Upload
          onChange={handleUploadChange}
          accept="image/*"
          type="drag"
          listType="picture"
          beforeUpload={() => false}
          className="upload-list-inline"
          multiple={false}
          maxCount={1}
          onRemove={() => setPostMedia([{ name: "", url: "", type: "" }])}
          defaultFileList={[]}
          fileList={postMedia[0].url !== "" ? postMedia : []}
        >
          <Stack
            direction="row"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
            <Box textAlign="left">
              <Typography className="t_oktelo t_purple t_highlight">
                Kép kiválasztása
              </Typography>
              <Typography className="t_oktelo t_misc">
                Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
              </Typography>
            </Box>
          </Stack>
        </Upload>
        </>)
        : <>
        <Typography className="t_oktelo_medium size_12 darkblue">
          Videó feltöltése (ajánlott 9:16 arány, maximum méret: 100MB)
        </Typography>
        <Upload
          onChange={handleUploadChange}
          accept="video/*"
          type="drag"
          listType="picture"
          beforeUpload={() => false}
          className="upload-list-inline"
          multiple={false}
          maxCount={1}
          onRemove={() => setPostMedia([{ name: "", url: "", type: "" }])}
          defaultFileList={[]}
          fileList={postMedia[0].url !== "" ? postMedia : []}
        >
          <Stack
            direction="row"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
            <Box textAlign="left">
              <Typography className="t_oktelo t_purple t_highlight">
                Videó kiválasztása
              </Typography>
              <Typography className="t_oktelo t_misc">
                Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
              </Typography>
            </Box>
          </Stack>
        </Upload>
        </>}
      </Box>
      <DateTimePicker
        postDate={postDate}
        postTime={postTime}
        setPostDate={setPostDate}
        setPostTime={setPostTime}
        handlePublishNow={handlePublishNow}
        isPublishNow={isPublishNow}
      />
        <TagSelector
        userTags={userTags}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
      />
    <NoteSection
        note={postNoteMessage}
        setNote={setPostNoteMessage}
      />
    </Box>
  );
};

const SelectPage: React.FC<SelectPageProps> = ({ socialPages, selectedPlatform, selectedPageId, setSelectedPageId }) => {
  const filteredPages = socialPages.allPages.filter(page => page.platform === selectedPlatform);

  return (
    <Box className="oktelo-create-post-form">
      <AvatarWithLabel icon={<IosShare sx={{ fontSize: "16px", color: "#7076fc" }} />} label="Közösségi kapcsolat" bgColor="#e2e3fe" />
      <Typography className="t_oktelo_medium size_12 darkblue">Csatlakoztatott oldal</Typography>
      <Select
        value={selectedPageId}
        onChange={(e) => setSelectedPageId(e.target.value)}
        displayEmpty
        fullWidth
        MenuProps={{ PaperProps: { style: { maxHeight: '350px', overflowY: 'scroll' } } }}
        className="selector-page"
        renderValue={(selected) => {
          if (selected) {
            if (selected === "0") {
              return (
                <Typography className="t_oktelo_normal darkblue size_14">
                  Ne legyen kapcsolva
                </Typography>
              );
            }
            const selectedPage = socialPages.allPages.find(
              (page) => page.id === selected
            );
            return (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                justifyContent="space-between"
                sx={{ backgroundColor: "#fff", p: 1, borderRadius: "0px" }}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  {selectedPage?.platform === "instagram" ? (
                    <Instagram sx={{ color: "#E4405F", fontSize: "18px" }} />
                  ) : (
                    <Facebook sx={{ color: "#1e90ff", fontSize: "18px" }} />
                  )}
                  <Typography className="t_oktelo_normal darkblue size_14">
                    {selectedPage?.name}
                  </Typography>
                </Stack>
              </Stack>
            );
          } else {
            return (
              <Typography className="t_oktelo_normal darkblue size_14">
                Ne legyen kapcsolva
              </Typography>
            );
          }
        }}
      >
        <MenuItem value="0" className="create-menuitem" sx={{ backgroundColor: "#fff" }}>
          <Typography className="t_oktelo_normal darkblue size_14">Ne legyen kapcsolva</Typography>
        </MenuItem>
        {filteredPages.map((page) => (
          <MenuItem key={page.id} value={page.id} sx={{ backgroundColor: "#fff" }} className="create-menuitem">
            <Stack direction="row" alignItems="center" gap={1}>
              {page.platform === "instagram" ? (
                <Instagram sx={{ color: "#E4405F", fontSize: "18px" }} />
              ) : (
                <Facebook sx={{ color: "#1e90ff", fontSize: "18px" }} />
              )}
              <img src={page.photo} style={{ width: "26px", height: "26px", borderRadius: "50%" }} alt={page.name} />
              <Typography className="t_oktelo_normal darkblue size_14">{page.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const AvatarWithLabel: React.FC<AvatarWithLabelProps> = ({ icon, label, bgColor, iconColor }) => (
  <Stack direction="row" alignItems="center" gap={1} mb={1.5} mt={3}>
    <Avatar sx={{ width: "30px", height: "30px", backgroundColor: bgColor }}>
      {icon}
    </Avatar>
    <Typography className="t_oktelo_medium size_16 darkblue">{label}</Typography>
  </Stack>
);

const NoteSection: React.FC<NoteSectionProps> = ({ note, setNote }) => (
  <Box className="oktelo-create-post-form">
    <AvatarWithLabel icon={<StickyNote2Outlined sx={{ fontSize: "16px", color: "#000000" }} />} label="Megjegyzés" bgColor="#f5f5f5" />
    <Typography className="t_oktelo_medium size_12 darkblue">Megjegyzés a bejegyzéshez (opcionális)</Typography>
    <TextAreaWithCount
      value={note}
      onChange={(e) => setNote(e.target.value)}
      placeholder="Megjegyzés"
      maxLength={500}
    />
  </Box>
);


const TextAreaWithCount: React.FC<TextAreaWithCountProps> = ({ value, onChange, placeholder, maxLength }) => (
  <TextArea
    rows={4}
    name="message"
    value={value}
    onChange={onChange}
    autoSize={{ minRows: 4, maxRows: 6 }}
    placeholder={placeholder}
    maxLength={maxLength}
    showCount
  />
);

const FirstCommentSection: React.FC<FirstCommentSectionProps> = ({ isFirstComment, setFirstComment, firstCommentMessage, setFirstCommentMessage }) => (
  <Box className="oktelo-create-post-form">
        <AvatarWithLabel icon={<AutoAwesome sx={{ fontSize: "16px", color: "#88c032" }} />} label="Extrák" bgColor="#f6fdeb" />
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography className="t_oktelo_medium size_14 darkblue">Első hozzászólás írása</Typography>
        <Tag className="tag_oktelo size_12" color="purple" bordered={false}>Újdonság</Tag>
      </Stack>
      <Switch checked={isFirstComment} color="secondary" onChange={() => setFirstComment(!isFirstComment)} />
    </Stack>
    {isFirstComment && (
      <TextAreaWithCount
        value={firstCommentMessage}
        onChange={(e) => setFirstCommentMessage(e.target.value)}
        placeholder="Első komment szövege, ami posztolás után lesz közzétéve"
        maxLength={500}
      />
    )}
    </Box>

);

const InstagramExtras: React.FC<InstagramExtrasProps> = ({igCollaborator, setIgCollaborator, igUsersTagged, handleUsernameChange, handleTagLocationChange}) => (
  <Box className="oktelo-create-post-form">
  <Stack direction="column" gap={1}>
  <Typography className="t_oktelo_medium size_12 darkblue">
    Kollaboráció vele:
  </Typography>
  <Input prefix={<AlternateEmail sx={{ fontSize: '16px' }}/>}
  placeholder="okteloapp"
  value={igCollaborator}
  onChange={(e) => setIgCollaborator(e.target.value)}/>
  </Stack>
  <Stack direction="column" gap={1} mt={1.5}>
  <Typography className="t_oktelo_medium size_12 darkblue">
    Megjelölések (csak publikus instagram fiókok!)
  </Typography>

    <Stack direction="column" gap={1} width="100%">
      <Stack direction="row" gap={1} alignItems="center">
        <Input prefix={<AlternateEmail sx={{ fontSize: '16px' }}/>}
          placeholder="publikus_profil"
          value={igUsersTagged[0]?.username}
          onChange={(e) => handleUsernameChange(0, e.target.value)}
          />
        <Select
        className="selector-page"
        fullWidth
        placeholder="Pozíció"
        value={igUsersTagged[0]?.location ?? 'topLeft'}
        onChange={(e: any) => handleTagLocationChange(0, e.target.value)}
        renderValue={(selected) => {
          if (!selected || selected === '' || selected === undefined) {
            return (
              <Stack direction="row">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Nincs pozíció
                </Typography>
              </Stack>
            );
          }
          return (
            <Stack direction="row">
              <Typography className="t_oktelo_normal size_14 darkblue">
                {selected === 'topLeft'
                  ? 'Fent, balra'
                  : selected === 'bottomLeft'
                  ? 'Lent, balra'
                  : selected === 'topRight'
                  ? 'Fent, jobbra'
                  : selected === 'bottomRight'
                  ? 'Lent, jobbra'
                  : selected === 'center'
                  ? 'Középen'
                  : 'Hiba'}
              </Typography>
            </Stack>
          );
        }}
      >
        <MenuItem value="topLeft">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Fent, balra
          </Typography>
        </MenuItem>
        <MenuItem value="topRight">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Fent, jobbra
          </Typography>
        </MenuItem>
        <MenuItem value="bottomLeft">
        <Typography className="t_oktelo_normal size_14 darkblue">
            Lent, balra
          </Typography>
        </MenuItem>
        <MenuItem value="bottomRight">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Lent, jobbra
          </Typography>
        </MenuItem>
        <MenuItem value="center">
         <Typography className="t_oktelo_normal size_14 darkblue">
            Középen
          </Typography>
        </MenuItem>
        </Select>
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
      <Input prefix={<AlternateEmail sx={{ fontSize: '16px' }}/>}
        placeholder="publikus_profil"
        value={igUsersTagged[1]?.username}
        onChange={(e) => handleUsernameChange(1, e.target.value)}
        />
      <Select
        className="selector-page"
        fullWidth
        placeholder="Pozíció"
        value={igUsersTagged[1]?.location ?? 'topLeft'}
        onChange={(e: any) => handleTagLocationChange(1, e.target.value)}
        renderValue={(selected) => {
          if (!selected || selected === '' || selected === undefined) {
            return (
              <Stack direction="row">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Nincs pozíció
                </Typography>
              </Stack>
            );
          }
          return (
            <Stack direction="row">
              <Typography className="t_oktelo_normal size_14 darkblue">
                {selected === 'topLeft'
                  ? 'Fent, balra'
                  : selected === 'bottomLeft'
                  ? 'Lent, balra'
                  : selected === 'topRight'
                  ? 'Fent, jobbra'
                  : selected === 'bottomRight'
                  ? 'Lent, jobbra'
                  : selected === 'center'
                  ? 'Középen'
                  : 'Hiba'}
              </Typography>
            </Stack>
          );
        }}
      >
        <MenuItem value="topLeft">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Fent, balra
          </Typography>
        </MenuItem>
        <MenuItem value="topRight">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Fent, jobbra
          </Typography>
        </MenuItem>
        <MenuItem value="bottomLeft">
        <Typography className="t_oktelo_normal size_14 darkblue">
            Lent, balra
          </Typography>
        </MenuItem>
        <MenuItem value="bottomRight">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Lent, jobbra
          </Typography>
        </MenuItem>
        <MenuItem value="center">
         <Typography className="t_oktelo_normal size_14 darkblue">
            Középen
          </Typography>
        </MenuItem>
        </Select>
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
      <Input prefix={<AlternateEmail sx={{ fontSize: '16px' }}/>}
          placeholder="publikus_profil"
          value={igUsersTagged[2]?.username}
          onChange={(e) => handleUsernameChange(2, e.target.value)}
          />
      <Select
        className="selector-page"
        fullWidth
        placeholder="Pozíció"
        value={igUsersTagged[2]?.location ?? 'topLeft'}
        onChange={(e: any) => handleTagLocationChange(2, e.target.value)}
        renderValue={(selected) => {
          if (!selected || selected === '' || selected === undefined) {
            return (
              <Stack direction="row">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Nincs pozíció
                </Typography>
              </Stack>
            );
          }
          return (
            <Stack direction="row">
              <Typography className="t_oktelo_normal size_14 darkblue">
                {selected === 'topLeft'
                  ? 'Fent, balra'
                  : selected === 'bottomLeft'
                  ? 'Lent, balra'
                  : selected === 'topRight'
                  ? 'Fent, jobbra'
                  : selected === 'bottomRight'
                  ? 'Lent, jobbra'
                  : selected === 'center'
                  ? 'Középen'
                  : 'Hiba'}
              </Typography>
            </Stack>
          );
        }}
      >
        <MenuItem value="topLeft">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Fent, balra
          </Typography>
        </MenuItem>
        <MenuItem value="topRight">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Fent, jobbra
          </Typography>
        </MenuItem>
        <MenuItem value="bottomLeft">
        <Typography className="t_oktelo_normal size_14 darkblue">
            Lent, balra
          </Typography>
        </MenuItem>
        <MenuItem value="bottomRight">
          <Typography className="t_oktelo_normal size_14 darkblue">
            Lent, jobbra
          </Typography>
        </MenuItem>
        <MenuItem value="center">
         <Typography className="t_oktelo_normal size_14 darkblue">
            Középen
          </Typography>
        </MenuItem>
        </Select>
      </Stack>
    </Stack>
  </Stack>
  </Box>
)

const TagSelector: React.FC<TagSelectorProps> = ({ userTags, selectedTag, setSelectedTag }) => (
  <Box className="oktelo-create-post-form">
    <AvatarWithLabel icon={<LabelOutlined sx={{ fontSize: "16px", color: "#e7c502" }} />} label="Címkék" bgColor="#fefbe6" />
    <Stack direction="row" gap={1} alignItems="center">
    <Select
      displayEmpty
      fullWidth
      value={selectedTag}
      onChange={(e) => setSelectedTag(e.target.value)}
      MenuProps={{ PaperProps: { style: { maxHeight: '350px', overflowY: 'scroll' } } }}
      className="selector-page"
      renderValue={(selected) => {
        if (selected) {
          if (selected === "0") {
            return (
              <Typography className="t_oktelo_normal darkblue size_14">
                Címke nélkül
              </Typography>
            );
          }
          const selected_tag = userTags.find(
            (tag) => tag.id === selected
          );
          return (
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              justifyContent="space-between"
              sx={{ backgroundColor: "#fff", p: 1, borderRadius: "0px" }}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <Avatar
                  sx={{
                    backgroundColor: "#f9f9f9",
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <LocalOfferOutlined
                    sx={{ fontSize: "16px", color: selected_tag?.color }}
                  />
                </Avatar>
                <Typography className="t_oktelo_normal darkblue size_14">
                  {selected_tag?.title}
                </Typography>
              </Stack>
            </Stack>
          );
        } else {
          return (
            <Typography className="t_oktelo_normal darkblue size_14">
              Címke nélkül
            </Typography>
          );
        }
      }}
    >
      <MenuItem value="" className="create-menuitem" sx={{ backgroundColor: "#fff" }}>
        <Typography className="t_oktelo_normal darkblue size_14">Ne legyen címkézve</Typography>
      </MenuItem>
      {userTags.map((tag) => (
        <MenuItem key={tag.id} value={tag.id} sx={{ backgroundColor: "#fff" }} className="create-menuitem">
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar sx={{ backgroundColor: '#f9f9f9', height: "24px", width: "24px" }}>
              <LocalOfferOutlined sx={{ fontSize: "16px", color: tag.color }} />
            </Avatar>
            <Typography className="t_oktelo_normal darkblue size_14">{tag.title}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
    <button
            className="social-select-button"
            style={{ color: "#1E1E47" }}
            onClick={() => window.open("/props", "_blank")}
          >
            <Settings />
          </button>
    </Stack>
  </Box>
);

const DateTimePicker: React.FC<DateTimePickerProps> = ({ handlePublishNow, isPublishNow, postDate, postTime, setPostDate, setPostTime }) => (
  <Box className="oktelo-create-post-form">
    <AvatarWithLabel icon={<CalendarMonthRounded sx={{ fontSize: "16px", color: "#2ba2ce" }} />} label="Tervezés" bgColor="#e9f5fa" />
    <Stack direction="row" alignItems="center" width="100%" gap={2} justifyContent="space-between">
      <Stack direction="column" style={{ width: "100%" }} gap="8px">
        <Typography className="t_oktelo_medium size_12 darkblue">Dátum</Typography>
        <DatePicker
          value={postDate ? dayjs(postDate) : null}
          minDate={dayjs()}
          allowClear={false}
          onChange={(date) => setPostDate(date ? dayjs(date).format("YYYY.MM.DD") : null)}
          format={{ format: "YYYY.MM.DD", type: "mask" }}
          needConfirm={false}
          style={{ width: "100%" }}
          placeholder="Dátum választása"
        />
        <button className="remove-time" onClick={() => setPostDate(null)}>Törlés</button>
      </Stack>
      {postDate && (
        <Stack direction="column" style={{ width: "100%" }} gap="8px">
          <Typography className="t_oktelo_medium size_12 darkblue">Idő</Typography>
          <TimePicker
            value={postTime ? dayjs(postTime, "HH:mm") : dayjs()}
            onChange={(time) => setPostTime(time ? dayjs(time).format("HH:mm") : null)}
            allowClear={false}
            format={{ format: "HH:mm", type: "mask" }}
            needConfirm={false}
            style={{ width: "100%" }}
            placeholder="Idő választása"
          />
          <button className="remove-time" onClick={() => setPostTime(null)}>Törlés</button>
        </Stack>
      )}
    </Stack>
    <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography className="t_oktelo_medium size_14 darkblue">
              Közzététel azonnal
            </Typography>
          </Stack>
          <Switch
            checked={isPublishNow}
            color="secondary"
            onChange={() => handlePublishNow()}
          />
        </Stack>
        {isPublishNow && (
          <Typography className="t_oktelo_normal size_14 darkblue">
            A bejegyzésed közzé lesz téve a Közzététel gombra való kattintás
            után.
          </Typography>
        )}
  </Box>
);

const MediaLibrary: React.FC<MediaLibraryProps> = ({isMediaWindowOpen, setMediaWindowOpen, userMediaFiles, currentKey, setPostMedia, refetchMedia}) => {
 
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  // Sorting logic based on sortOrder
  const sortedMedia = [...userMediaFiles].sort((a, b) => {
    return sortOrder === "desc" 
      ? new Date(b.uploaded_at).getTime() - new Date(a.uploaded_at).getTime() 
      : new Date(a.uploaded_at).getTime() - new Date(b.uploaded_at).getTime();
  });

 return (
  <Modal title="Oktelo média"
  open={isMediaWindowOpen}
  onCancel={() => {
    setMediaWindowOpen(false)
    setPostMedia([{ name: "", url: "", type: "", uid: ""}])
  }}
  onOk={() => setMediaWindowOpen(false)}
  cancelText="Mégsem"
  okText="Mentés"
  width="90%"  // Use a percentage for the width
  style={{ maxWidth: '640px' }}
  centered
  destroyOnClose>
   <Box width="100%">
  {userMediaFiles && userMediaFiles.length > 0 ? (
    <Grid container spacing={2}>
      <Grid item xl={12} xs={12}>
        <Stack direction="row" gap={1} alignItems="center">
          <button className="home-action-button b925" onClick={() => window.open('/mymedia', '_blank')}>
            Médiatár <OpenInNew/>
          </button>
          <button className="home-action-button bc6a" onClick={() => refetchMedia()}>
            Frissítés <Refresh/>
          </button>
            <button 
                  className="home-action-button b9d9" 
                  onClick={() => setSortOrder(prev => prev === "desc" ? "asc" : "desc")}
                >
                  Dátum {sortOrder === "desc" ? <ArrowDownward /> : <ArrowUpward />}
                </button>
        </Stack>
      </Grid>
      {/* Filter and map images if currentKey is "1" */}
      {(currentKey === "1" || currentKey === "5") && 
        sortedMedia
          .filter(media => media.resource_type === "image")
          .map(media => (
            <Grid item xl={3} key={media.asset_id}>
              <Box width="100%" height="150px" padding={0}
              onClick={() => setPostMedia([{ name: media.asset_id, url: media.secure_url, type: "image", uid: media.asset_id }])}
              sx={{ cursor: 'pointer', ':hover': { opacity: 0.9} }}>
                <img src={media.secure_url} alt={`Image from canva`}
                style={{ width: '100%', height: '100%', objectFit: 'cover'}} />
              </Box>
            </Grid>
          ))
      }
      
      {/* Filter and map videos if currentKey is "2" or "5" */}
      {(currentKey === "2" || currentKey === "5") &&
        sortedMedia
          .filter(media => media.resource_type === "video")
          .map(media => (
            <Grid item xl={3} key={media.asset_id}>
              <Box width="100%" height="150px" padding={0}
              onClick={() => setPostMedia([{ name: media.asset_id, url: media.secure_url, type: "video", uid: media.asset_id }])}>
              <img 
                src={`${media.secure_url.replace('.mp4', '.jpg')}?time=1s`} 
                alt="Video Thumbnail" 
                style={{ width: '100%', height: '100%', objectFit: 'cover'}} />
              </Box>
            </Grid>
          ))
      }
    </Grid>
  ) : (
    <Box width="100%">
      <Typography className="t_oktelo_normal size_14 darkblue">
           Nincsenek média elemeid.  
      </Typography>
    </Box>
  )}
</Box>

  </Modal>
 )
}

const ImageUploadSection: React.FC<ImageUploadSectionProps> = ({handleUploadChange, setPostMedia, postMedia, setImageUrlPasterOpen, isImageUrlPasterOpen, getImageUrl, setImageUrl, submitImageUrl, isMediaWindowOpen, setMediaWindowOpen}) => (
  <Box className="oktelo-create-post-form">
  <AvatarWithLabel icon={<PhotoSizeSelectActualOutlined sx={{ fontSize: "16px", color: "#802200" }} />} label="Fotó" bgColor="#f2e8e5" />
  <Typography className="t_oktelo_medium size_12 darkblue">
    Kép feltöltése
  </Typography>
  <Upload
    onChange={handleUploadChange}
    accept="image/*"
    type="drag"
    listType="picture"
    beforeUpload={() => false}
    className="upload-list-inline"
    multiple={false}
    maxCount={1}
    onRemove={() => setPostMedia([{ name: "", url: "", type: "", uid: "" }])}
    defaultFileList={[]}
    fileList={postMedia[0].url !== "" ? postMedia : []}
  >

    <Stack
      direction="row"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      <InboxOutlined style={{ color: "#d3d3d3", fontSize: "42px" }} />
      <Box textAlign="left">
        <Typography className="t_oktelo t_purple t_highlight">
          Kép kiválasztása
        </Typography>
        <Typography className="t_oktelo t_misc">
          Kattints a fájlfeltöltéshez, vagy használd a drag and drop-ot
        </Typography>
      </Box>
    </Stack>
  </Upload>
  <Stack direction="row" gap={1} alignItems="center" width="100%">
    <button className="image-url-button" onClick={() => setImageUrlPasterOpen(!isImageUrlPasterOpen)}>
      <LinkOutlined style={{ marginRight: '6px' }}/> Kép URL {isImageUrlPasterOpen? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
    </button>
    <button className="image-url-button" onClick={() => setMediaWindowOpen(!isMediaWindowOpen)}>
      <ImageSearchOutlined style={{ marginRight: '6px' }}/> Média könyvtár
    </button>
  </Stack>
  {isImageUrlPasterOpen &&
  <Box sx={{ border: '1px solid #d3d3d3', borderRadius: '0px', p: 1}}>
  <Stack direction="row" alignItems="center" gap={0.5} mb={1}>
    <Input value={getImageUrl} onChange={(e) => setImageUrl(e.target.value)} placeholder="https://kep-url-cime.com/kep.jpg"/>
    <button className="image-url-button" style={{ width: 'auto' }} onClick={() => submitImageUrl()}><CheckBoxOutlined/></button>
  </Stack>
  <>
    {postMedia[0].type === "wrong_format" &&
    <Typography className="size_12 t_oktelo_medium darkblue">Az URL-t nem sikerült felhasználni. Próbálj megy egy másik linket.</Typography>
      }
    </>
    </Box>
    }
</Box>
)

const PostMessageSection: React.FC<PostMessageSectionProps> = ({postMessage, setPostMessage, emojiPickerOpen, setEmojiPickerOpen, setHashtagModalVisible, handleEmojiClick, hashtagModalVisible, setSelectedHashtag, selectedHashtag, userHashtags, setAiTextModalShow, isAiTextModalShow}) =>
(
<Box className="oktelo-create-post-form">
      <AvatarWithLabel icon={<Brush sx={{ fontSize: "16px", color: "#f1979f" }} />} label="Tartalom" bgColor="#fdf0f2" />
        <Typography className="t_oktelo_medium size_12 darkblue">
          Poszt szövege
        </Typography>
        <TextAreaWithCount
          value={postMessage}
          onChange={(e) => setPostMessage(e.target.value)}
          placeholder="Megjegyzés"
          maxLength={500}
        />
        <Stack direction="row" alignItems="center" gap={1}>
          <button className="ai-content-button" onClick={() => setAiTextModalShow(!isAiTextModalShow)}>
            <AutoFixHigh sx={{ fontSize: "14px" }} /> AI Szöveg
          </button>
          <button
            className="emoji-select-button"
            onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
          >
            <AddReactionOutlined sx={{ fontSize: "20px", color: emojiPickerOpen ? '#925FFF' : '#1e1e47' }} />
          </button>
          <button className="emoji-select-button"
          onClick={() => setHashtagModalVisible(!hashtagModalVisible)}
          >
            <TagRounded sx={{ fontSize: "20px", color: hashtagModalVisible ? '#925FFF' : '#1e1e47' }} />
          </button>
        </Stack>
        {emojiPickerOpen && (
          <EmojiPicker
            open={emojiPickerOpen}
            lazyLoadEmojis={true}
            width="100%"
            onEmojiClick={handleEmojiClick}
            style={{ zIndex: "999" }}
          />
        )}
        {hashtagModalVisible && (
          <Stack direction="row" alignItems="center" gap={2} my={1}>
            <Select
            displayEmpty
            fullWidth
            value={selectedHashtag}
            onChange={(e: any) => {
              setSelectedHashtag(e.target.value);
            }}
            MenuProps={{ PaperProps: { style: { maxHeight: '350px', overflowY: 'scroll' }}}}
            renderValue={(selected) => {
              if (selected) {
                if (selected === "") {
                  return (
                    <Stack p={1}>
                      <Typography className="t_oktelo_medium size_14 darkblue">
                        Nincs hashtag sablon kiválasztva.
                      </Typography>
                    </Stack>
                  );
                } else {
                  return (
                    <Stack p={1}>
                      <Typography className="t_oktelo_medium size_14 darkblue">
                        {userHashtags.find(tag => tag.id === selected)?.title || "Unknown title"}
                      </Typography>
                    </Stack>
                  );
                }
              } else {
                return (
                  <Stack p={1}>
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      Nincs hashtag sablon kiválasztva.
                    </Typography>
                  </Stack>
                );
              }
            }}
            className="selector-page"
          >
            <MenuItem key="" value="">
              <Stack p={1}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Nincs
                </Typography>
              </Stack>
            </MenuItem>
            {userHashtags && userHashtags.length > 0 &&
              userHashtags.map((tag: UserHashtags) => (
                <MenuItem key={tag.id + tag.title} value={tag.id} sx={{ maxWidth: '400px', overflow: 'hidden' }}>
                  <Stack p={1} direction="row" gap={0.5} alignItems="center">
                    <TagOutlined sx={{ color: '#925FFF', fontSize: '16px' }} />
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      {tag.title}{" "}
                      {tag.hashtags.map((t: string) => (
                        <span key={t} className="t_oktelo_light">{t} </span>
                      ))}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))
            }
          </Select>

            <button
            className="social-select-button"
            style={{ color: "#dc2743" }}
            onClick={() => setSelectedHashtag("")}
          >
            <ClearOutlined />
          </button>
          <button
            className="social-select-button"
            style={{ color: "#1E1E47" }}
            onClick={() => window.open("/props", "_blank")}
          >
            <Settings />
          </button>
          </Stack>
        )}
      </Box>
);

const AiModalSection: React.FC<AiModalProps> = ({isAiTextModalShow, setAiTextModalShow, AiInstructionsText, setAiInstructionsText, onStartAiTextGeneration}) =>
  (
    <Modal
    title="Segítünk a szövegírásban!"
    centered
    open={isAiTextModalShow}
    onOk={() => onStartAiTextGeneration()}
    okText="Generálás"
    cancelText="Mégsem"
    onCancel={() => setAiTextModalShow(false)}
  >
    <Stack direction="column" gap={1}>
      <Typography className="t_oktelo_medium size_14 darkblue">
        Egy-két mondatban írd le a posztod témáját!
      </Typography>
      <TextArea
      rows={4}
      cols={1}
      value={AiInstructionsText}
      placeholder="Elindult az új nyári akciónk, aki most vásárol 1000Ft-os kupont kap ajándékba. Vásárlás itt: www.boltom.hu"
      onChange={(e) => setAiInstructionsText(e.target.value)}
      />
      <Typography className="size_12 darkblue t_oktelo_normal">
        Írj le pár részletet a posztodról, akár instrukciókat is. A szöveg alapján az Oktelo AI generál neked egy kész poszt tartalmat.
      </Typography>
    </Stack>
  </Modal>
  );

export default CreatePost;

import React, { createContext, useContext, ReactNode } from 'react';
import { useNotification } from '@refinedev/core';

// Define the type for the notification handler
type NotificationHandler = (message: string) => void;

// Create the context with the correct type and an undefined default value
const NotificationContext = createContext<NotificationHandler | undefined>(undefined);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const { open } = useNotification();

  console.log("Notification handler");

  // Define the handler function for notifications
  const handleNotification: NotificationHandler = (message) => {
    switch (message) {
      case 'out_of_oktelo_tokens':
        open?.({
          type: 'error',
          message: 'Elfogytak az Oktelo tokenjeid! Az újratöltés éjfélkor történik.',
        });
        break;

      case 'some_other_message':
        open?.({
          type: 'error',
          message: 'Another specific message to handle.',
        });
        break;

      // Add more cases as needed
      default:
        open?.({
          type: 'error',
          message: 'Something went wrong. Please try again.',
        });
    }
  };

  return (
    <NotificationContext.Provider value={handleNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the NotificationContext
export const useNotificationHandler = (): NotificationHandler => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotificationHandler must be used within a NotificationProvider');
  }

  return context;
};


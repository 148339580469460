import React, { useState, useEffect } from "react";
import api from "utils/interceptor";
import apiconfig from "utils/apiconfig";
import { Box, Stack, Grid, Table, Typography, Select, MenuItem, TextField, InputAdornment, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import {
    LinkPlaceholder,
    TextPlaceholder,
    VideoPlaceholder,
    okt_placeholer,
  } from "../../assets/index";
import { AccessTimeOutlined, ArrowDownwardOutlined, ArrowUpwardOutlined, ArticleOutlined, CalendarMonth, CalendarMonthOutlined, CampaignOutlined, CancelOutlined, Category, CheckCircleOutline, CheckCircleOutlineOutlined, CloudUpload, CollectionsOutlined, DeleteForeverRounded, Facebook, FormatAlignCenterOutlined, InfoOutlined, Instagram, LinkedIn, LinkOutlined, List, LocalCafeOutlined, LocalOfferOutlined, OndemandVideoOutlined, PendingOutlined, PhotoCameraBackOutlined, Search, SearchOutlined, Window } from "@mui/icons-material";
import { CheckCircleOutlined, ClockCircleFilled, ClockCircleOutlined, DeleteFilled, DownOutlined, EyeFilled, FundViewOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Space, Tag } from "antd";
import { useNotification } from "@refinedev/core";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hu';
import { useNavigate } from "react-router-dom";
dayjs.locale('hu')
dayjs.extend(utc);
dayjs.extend(timezone);

type PageInfo = {
    id: string;
    name: string;
    photo: string;
    platform: string;
  };
  
  type SocialData = {
    pages: PageInfo[];
  };
  
  interface FilterProps {
    searchTerm: string;
    sortStatus: SortStatus;
    parent: string;
    sortField: SortField;
    sortType: SortType;
  }
  
  type SortType = "all" | "post" | "campaign";
  type SortField = "scheduleDateDesc" | "scheduleDateAsc";
  type SortStatus = "all" | "pending" | "published";

interface Schedule {
    _id: string;
    postId: string;
    message: string;
    parent: {
        name: string;
        id: string;
    };
    platform: string;
    attachment: {
        type: string;
        url: {
            photoUrl: string | undefined;
            videoUrl: string | undefined;
            thumbnailUrl: string | undefined;
            webUrl: string | undefined;
            carouselUrls: string[] | undefined;
        };
    };
    scheduleDate: Date;
    isPublished: boolean;
    type: string;
}

const ScheduledPosts = () => {
    const navigate = useNavigate();
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [socials, setSocials] = useState<SocialData>({ pages: [] });
    const [isLoading, setLoading] = useState(false);
    const { open } = useNotification();
    const [view, setView] = useState<string>("grid");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filters, setFilters] = useState<FilterProps>({
        searchTerm: "",
        sortStatus: "all",
        parent: localStorage.getItem('sp') || socials.pages[0]?.id || '',
        sortField: "scheduleDateDesc",
        sortType: "all",
      });

    const handleFilterChange = (key: keyof FilterProps, value: any) => {
        setFilters((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setFilters(prevFilters => ({
            ...prevFilters, // Spread the previous filters to retain other properties
            parent: localStorage.getItem('sp') || socials.pages[0]?.id || '' // Update the 'parent' field
        }));
    }, [socials]);

    useEffect(() => {
    localStorage.setItem('sp', filters.parent);
    }, [filters.parent]);


    const getSocials = async () => {
        try {
            setLoading(true);

            const socialResponse = await api.get(
                `${apiconfig.BACKEND_URI}/data/socials`
              );

            if (socialResponse.status === 200) {
            const allFacebook = socialResponse.data.facebookPages;
            const allInstagram = socialResponse.data.instagramPages;
            const allLinkedin = socialResponse.data.linkedinPages;
    
            const merge: SocialData = {
                pages: [...allFacebook, ...allInstagram, ...allLinkedin],
            };
    
            setSocials(merge);
            }
        } catch (error) {
        console.log(error);
        } finally {
        setLoading(false);
        }
    };

    const getSchedules = async () => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/schedules/`);

            if (response.status === 200) {
                setSchedules(response.data.schedules);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSchedules();
    }, []);

    useEffect(() => {
        getSocials();
      }, []);

    const handleOpenPost = (postId: string) => {
        window.open(`/posts/show/${postId}`, '_blank');
    };
    
    const handleNewSchedule = () => {
        // Your code for scheduling, e.g., opening a modal
    };
    
    const handleDeleteSchedule = async (postId: string, scheduleId: string) => {
        try {
            
            if (!postId || !scheduleId) {
                return open?.({
                    type: 'error',
                    message: 'Nincs bejegyzés vagy ütemezés kiválasztva.'
                })
            }

            const response = await api.post(`${apiconfig.BACKEND_URI}/schedules/remove`, { postId: postId, scheduleId: scheduleId});

            if (response.status === 200){
                open?.({
                    type: 'success',
                    message: 'Sikeres törlés.',
                })
                return getSchedules();
            }

        } catch (error) {
            open?.({
                type: 'error',
                message: 'Sikertelen művelet.',
            })
        }
    };
    
    const items = (schedule: Schedule) => [
        {
            key: '1',
            icon: <EyeFilled/>,
            label: (
                <div onClick={() => navigate(`/posts/show/${schedule.postId}`)} className="t_oktelo_normal size_14 darkblue">
                    Poszt megnyitása
                </div>
            ),
        },
        {
            key: '2',
            icon: <ClockCircleFilled/>,
            label: (
                <div onClick={handleNewSchedule} className="t_oktelo_normal size_14 darkblue">
                    Új időpont
                </div>
            ),
        },
        {
            key: '3',
            danger: true,
            icon: <DeleteFilled/>,
            label: (
                <div onClick={() => handleDeleteSchedule(schedule.postId, schedule._id)} className="t_oktelo_normal size_14">
                    Ütemezés törlése
                </div>
            ),
        },
    ];

    return (
        <Box width="100%">
            <Grid container spacing={2}>
          <Grid item xl={1}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="space-evenly"
            className="filter-icons-row"
            width="100%"
            height="100%"
          >
            <Stack direction="row" alignItems="center" gap={0.5}>
              <button
                className="filter-post-view-button"
                onClick={() => setView("list")}
              >
                <List sx={{ color: view === "list" ? "#925FFF" : "#1E1E47" }} />
              </button>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              onClick={() => setView("grid")}
            >
              <button className="filter-post-view-button">
                <Window
                  sx={{ color: view === "grid" ? "#925FFF" : "#1E1E47" }}
                />
              </button>
            </Stack>
          </Stack>
          </Grid>
            <Grid item xl={2.5} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
            <Select
            className="posts-select"
            value={filters.parent || 'all'}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "all") {
                setFilters((prevFilters) => ({ ...prevFilters, parent: "" }));
              } else {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  parent: value,
                }));
              }
            }}
            renderValue={(selected) => {
              if (selected) {
                if (selected === "all") {
                  return (
                    <Typography className="t_oktelo_normal darkblue size_14" ml={1}>
                      Összes oldal
                    </Typography>
                  );
                } else {
                  const selectedPage = socials.pages.find(
                    (page) => page.id === selected
                  );
                  return (
                    <Stack direction="row" gap={1} alignItems="center">
                      {selectedPage?.platform === "facebook" ? (
                        <Facebook sx={{ fontSize: "16px" }} />
                      ) : selectedPage?.platform === "instagram" ? (
                        <Instagram sx={{ fontSize: "16px" }} />
                      ) : (
                        <LinkedIn sx={{ fontSize: "16px" }} />
                      )}
                      <img
                        src={selectedPage?.photo}
                        style={{
                          objectFit: "contain",
                          borderRadius: "50%",
                          border: "1px solid #d9d9d9",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <Typography className="posts-select-item size_14 t_oktelo_normal darkblue">
                        {selectedPage?.name}
                      </Typography>
                    </Stack>
                  );
                }
              }
            }}
          >
            <MenuItem value="all" className="posts-select-item">
              Összes
            </MenuItem>
            {socials &&
              socials.pages &&
              socials.pages.length > 0 &&
              socials.pages.map((p) => (
                <MenuItem key={p.id} value={p.id} className="posts-select-item">
                  <Stack direction="row" gap={1} alignItems="center">
                    {p.platform === "facebook" ? (
                      <Facebook sx={{ fontSize: "16px" }} />
                    ) : p.platform === "instagram" ? (
                      <Instagram sx={{ fontSize: "16px" }} />
                    ) : (
                      <LinkedIn sx={{ fontSize: "16px" }} />
                    )}
                    <img
                      src={p.photo}
                      style={{
                        objectFit: "contain",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography className="posts-select-item">
                      {p.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
          </Select>
          </Stack>
            </Grid>
            <Grid item xl={2.5} xs={12}>
            {/* Filters */}
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
              <TextField
                placeholder="Szöveg keresése..."
                value={filters.searchTerm}
                onChange={(e) =>
                  handleFilterChange("searchTerm", e.target.value)
                }
                fullWidth
                disabled={view === 'calendar'}
                className="posts-select-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ fontSize: '16px' }}/>
                    </InputAdornment>
                  ),
                  sx: {
                    height: "40px",
                    "& .MuiInputBase-input": {
                      height: "40px",
                      padding: "0 14px",
                      color: "#1E1E47",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "0",
                      outline: "0",
                    },
                  },


                }}
                InputLabelProps={{
                  sx: {
                    lineHeight: "40px",
                    color: "#1E1E47",
                    fontSize: "14px",
                    border: "0",
                    outline: "0",
                    '&.Mui-disabled': {
                      WebkitTextFillColor: '#000', // Change to the color you prefer
                      color: '#000', // Change to the color you prefer
                      opacity: 1, // Ensure the text is opaque
                    },
                  },
                }}
                sx={{
                  ".MuiInputBase-root": {
                    height: "40px",
                    color: "#1E1E47",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "0",
                    outline: "0",
                  },
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </Stack>
          </Grid>
          <Grid item xl={2} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
              <Select
                value={filters.sortStatus}
                onChange={(e) => handleFilterChange("sortStatus", e.target.value)}
                className="posts-select"
                placeholder="Státusz"
                displayEmpty
                disabled={view === 'calendar'}
                startAdornment={
                  <InputAdornment position="start">
                    <CloudUpload sx={{ fontSize: '16px' }}/>
                  </InputAdornment>
                }
                renderValue={(selected) => {
                  if (selected === "all") {
                    return (
                      <Typography className="t_oktelo_normal darkblue size_14">
                        Összes státusz
                      </Typography>
                    );
                  }
                  return (
                    <Typography className="t_oktelo_medium darkblue size_14">
                      {selected === "published"
                        ? "Közzétéve"
                        : "Várakozik"}
                    </Typography>
                  );
                }}
              >
                <MenuItem value="all" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Összes státusz
                  </Typography>
                </MenuItem>
                <MenuItem value="pending" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Várakozik
                  </Typography>
                </MenuItem>
                <MenuItem value="published" className="posts-select-item">
                  <Typography className="t_oktelo_medium size_14 darkblue">
                    Közzétéve
                  </Typography>
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xl={2} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
              <Select
                value={filters.sortType}
                onChange={(e) =>
                  handleFilterChange("sortType", e.target.value)
                }
                disabled={view === 'calendar'}
                displayEmpty
                startAdornment={
                  <InputAdornment position="start">
                    <Category sx={{ fontSize: '16px' }}/>
                  </InputAdornment>
                }
                className="posts-select"
                renderValue={(selected) => {
                  if (selected === "all") {
                    return (
                      <Typography className="t_oktelo_normal darkblue size_14">
                        Összes típus
                      </Typography>
                    );
                  }
                  return (
                    <Typography className="t_oktelo_medium darkblue size_14">
                      {selected === "post"
                        ? "Bejegyzés"
                        : "Kampány"}
                    </Typography>
                  );
                }}
              >
                <MenuItem value="all" className="posts-select-item">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Összes típus
                </Typography>
                </MenuItem>
                <MenuItem value="post" className="posts-select-item">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Bejegyzés
                </Typography>
                </MenuItem>
                <MenuItem value="campaign" className="posts-select-item">
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Kampány
                </Typography>
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
          <Grid item xl={2} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className="post-filter-row-main"
            >
            <button className="b_oktelo_purple_action" 
            onClick={() => handleFilterChange("sortField", filters.sortField === "scheduleDateDesc" ? "scheduleDateAsc" : "scheduleDateDesc")}
            style={{ height: '40px', width: '100%', backgroundColor: '#1E1E47', display: 'flex', alignItems: 'center', gap: '6px', justifyContent: 'center' }}>
               {filters.sortField === "scheduleDateAsc" ? <ArrowUpwardOutlined sx={{ fontSize: '16px' }}/> : <ArrowDownwardOutlined sx={{ fontSize: '16px' }}/>}
                Dátum
            </button>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
        {view === "grid" &&
          schedules &&
          schedules.length > 0 &&
          renderGridPosts(schedules, currentPage, setCurrentPage, filters, navigate, items)}
        {view === "list" &&
          schedules &&
          schedules.length > 0 &&
          renderListPosts(schedules, currentPage, setCurrentPage, filters, navigate)}
        </Grid>
        </Box>
    );
};

const renderGridPosts = (
  posts: Schedule[],
  currentPage: number,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  filters: FilterProps,
  navigate: any,
  items: any,
) => {
  const pageSize = 12;
  // Apply filters
  const filteredPosts = posts.filter((post) => {
    return (
      (!filters.searchTerm ||
        post.message?.toLowerCase().includes(filters.searchTerm.toLowerCase())) &&
      (filters.sortType === "all" || post.type === filters.sortType) &&
      (!filters.parent || post.parent?.id === filters.parent) &&
      (filters.sortStatus === "all" ||
        (filters.sortStatus === "pending" && !post.isPublished) ||
        (filters.sortStatus === "published" && post.isPublished))
    );
  });

  // Apply sorting
  const sortedPosts = filteredPosts.sort((a, b) => {
    if (filters.sortField === "scheduleDateAsc") {
      return new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime();
    } else if (filters.sortField === "scheduleDateDesc") {
      return new Date(b.scheduleDate).getTime() - new Date(a.scheduleDate).getTime();
    }
    return 0; // fallback case if sortField isn't specified correctly
  });

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedPosts = sortedPosts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredPosts.length / pageSize);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if(!url) return false
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video" || type === "reels") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const iconMap: Record<string, JSX.Element> = {
    textOnly: <FormatAlignCenterOutlined sx={{ color: '#925FFF !important' }}/>,
    video: <OndemandVideoOutlined sx={{ color: '#925FFF !important' }}/>,
    photo: <PhotoCameraBackOutlined sx={{ color: '#925FFF !important' }}/>,
    webUrl: <LinkOutlined sx={{ color: '#925FFF !important' }}/>,
    story: <LocalCafeOutlined sx={{ color: '#925FFF !important' }}/>,
    carousel: <CollectionsOutlined sx={{ color: '#925FFF !important' }}/>,
    default: <ArticleOutlined sx={{ color: '#925FFF !important' }}/>,
  };

  const returnIcon = (type: string) => iconMap[type] || iconMap.default;


  if (paginatedPosts.length < 1) {
    return <div className="size_14 darkblue t_oktelo_normal" style={{ padding: '18px'}}>Nincs találat.</div>
  }

  return (
    <>
      {paginatedPosts.map((schedule: Schedule) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={schedule._id}>
        <Box className="oktelo_card" >
            <Stack direction="column" gap={1.5} width="100%">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" gap={1.5} alignItems="center">
                        {returnIcon(schedule.attachment.type)}
                        {schedule.platform === "facebook" ? (
                            <Facebook sx={{ color: "#1e90ff !important", fontSize: '16px' }} />
                            ) : schedule.platform === "instagram" ? (
                            <Instagram sx={{ color: "#E4405F !important", fontSize: '16px' }} />
                            ) : (
                            <LinkedIn sx={{ fontSize: '16px' }} />
                            )}
                        <Typography className="size_12 t_oktelo_medium darkblue">
                            {schedule.parent.name}
                        </Typography>

                    </Stack>
                    <Dropdown menu={{ items: items(schedule) }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer' }}>
                        <Space className="t_oktelo_medium t_purple size_12 " align="end">
                            Opciók
                            <DownOutlined />
                        </Space>
                        </a>
                    </Dropdown>
                </Stack>
            <img
            src={hImageSrc(schedule.attachment.type, schedule.attachment.url)}
            alt={schedule.message}
            style={{ objectFit: 'cover', width: '100%', height: '100%', maxHeight: '250px'}}
            />
            <Stack direction="column" gap={1.5} width="100%" sx={{ 'svg': { fontSize: '14px', padding: 0, margin: 0 }}}>
                <Typography className="t_oktelo_normal size_14 darkblue">
                    {schedule.message.slice(0,120)}
                </Typography>
                <Typography className="t_oktelo_medium size_12 darkblue">
                    Ütemezett időpont: <span className="t_link"> <AccessTimeOutlined/> {dayjs(schedule.scheduleDate).format('YYYY.MM.DD HH:mm')}</span>
                </Typography>
                <Typography className="t_oktelo_medium size_12 darkblue">
                    Állapot: <span className="t_link"> {schedule.isPublished ? <CheckCircleOutlineOutlined/> : <InfoOutlined/>} {schedule.isPublished ? 'Közzétéve' : 'Várakozik'}</span>
                </Typography>
                <Typography className="t_oktelo_medium size_12 darkblue">
                    Típus: <span className="t_link"> {schedule.type === "post" ? <ArticleOutlined/> : <CampaignOutlined/>} {schedule.type === "post" ? 'Önálló bejegyzés' : 'Kampány'}</span>
                </Typography>
            </Stack>
            </Stack>
        </Box>
    </Grid>
      ))}
      <Grid container mt={2}>
        <Grid item xl={12} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <button
              className="post-grid-pagination"
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              Előző
            </button>
            <Typography mx={2}>
              Oldal: {currentPage} / {totalPages}
            </Typography>
            <button
              className="post-grid-pagination"
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              Következő
            </button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const renderListPosts = (
  posts: Schedule[],
  currentPage: number,
  setCurrentPage: any,
  filters: FilterProps,
  navigate: any,
) => {

  const filteredPosts = posts.filter((post) => {
    return (
      (!filters.searchTerm ||
        post.message?.toLowerCase().includes(filters.searchTerm.toLowerCase())) &&
      (filters.sortType === "all" || post.type === filters.sortType) &&
      (!filters.parent || post.parent?.id === filters.parent) &&
      (filters.sortStatus === "all" ||
        (filters.sortStatus === "pending" && !post.isPublished) ||
        (filters.sortStatus === "published" && post.isPublished))
    );
  });

  // Apply sorting
  const sortedPosts = filteredPosts.sort((a, b) => {
    if (filters.sortField === "scheduleDateAsc") {
      return new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime();
    } else if (filters.sortField === "scheduleDateDesc") {
      return new Date(b.scheduleDate).getTime() - new Date(a.scheduleDate).getTime();
    }
    return 0; // fallback case if sortField isn't specified correctly
  });
  

  const pageSize = 8;

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedPosts = sortedPosts.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredPosts.length / pageSize);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if(!url) return false
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const iconMap: Record<string, JSX.Element> = {
    textOnly: <FormatAlignCenterOutlined sx={{ color: '#925FFF !important' }}/>,
    video: <OndemandVideoOutlined sx={{ color: '#925FFF !important' }}/>,
    photo: <PhotoCameraBackOutlined sx={{ color: '#925FFF !important' }}/>,
    webUrl: <LinkOutlined sx={{ color: '#925FFF !important' }}/>,
    story: <LocalCafeOutlined sx={{ color: '#925FFF !important' }}/>,
    carousel: <CollectionsOutlined sx={{ color: '#925FFF !important' }}/>,
    default: <ArticleOutlined sx={{ color: '#925FFF !important' }}/>,
  };

  const returnIcon = (type: string) => iconMap[type] || iconMap.default;

  if (paginatedPosts.length < 1) {
    return <div className="size_14 darkblue t_oktelo_normal" style={{ padding: '18px'}}>Nincs találat.</div>
  }

  return (
    <>
      <Grid item xl={12} xs={12}>
        <TableContainer>
        <Table className="post-list-table" sx={{ borderCollapse: 'separate', borderSpacing: '0' }}>
        <TableHead
          sx={{
            backgroundColor: "#fff",
            marginBottom: '12px !important',
            borderRadius: '0px',
            borderBottom: '0px',
            overflow: 'hidden', // Ensure children do not overflow the rounded corners
          }}
        >
          <TableRow>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Típus</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Tartalom</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Platform</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Dátum</Typography>
            </TableCell>
            <TableCell>
              <Typography className="t_oktelo_medium size_14 darkblue">Típus</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
            <TableBody>
              {paginatedPosts.map((post) => (
                <TableRow key={post._id} onClick={() => navigate(`/posts/show/${post.postId}`)} sx={{ cursor: 'pointer', border: '0px' }}>
                  <TableCell>
                  {returnIcon(post.attachment.type)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                    
                      <img
                        src={hImageSrc(
                          post.attachment.type,
                          post.attachment.url
                        )}
                        alt={post.message}
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "8px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                      <Typography className="darkblue size_14 t_oktelo_normal">
                        {post.message
                          ? post.message.slice(0, 32) + "..."
                          : null}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="A posztod platformja és a hozzákapcsolt közösségi média oldalad">
                        <Stack direction="row" alignItems="center" gap={1}>
                            {post.platform === "facebook" ? (
                            <Facebook sx={{ fontSize: "16px", color: '#925FFF' }} />
                            ) : post.platform === "instagram" ? (
                            <Instagram sx={{ fontSize: "16px", color: '#925FFF' }} />
                            ) : (
                            <LinkedIn sx={{ fontSize: "16px", color: '#925FFF' }} />
                            )}
                        <Typography className="darkblue size_14 t_oktelo_normal">
                            {post.parent &&
                            typeof post.parent === "object" &&
                            post.parent.name
                            ? post.parent.name
                            : "Nem kapcsolt"}
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Ide helyezted a naptárban a posztot">
                    <Stack direction="row" alignItems="center" gap={1}>
                    <CalendarMonthOutlined sx={{ fontSize: "16px", color: '#925FFF' }} />
                        <Typography className="darkblue size_14 t_oktelo_normal">
                            {post.scheduleDate && !post.isPublished
                            ? dayjs.utc(post.scheduleDate).format("YYYY.MM.DD HH:mm")
                            : post.isPublished ?
                              dayjs.utc(post.scheduleDate).format('YYYY.MM.DD HH:mm')
                              :
                              "Nincs dátum"
                          }
                        </Typography>
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    { post.type &&
                      <Tooltip title="Ütemezés típusa">
                        <Tag
                          className="tag_oktelo size_14 t_oktelo_medium"
                          bordered={false}
                          color={
                            post.type === "post"
                              ? "purple"
                              : post.type === "campaign"
                              ? "gold"
                              : "volcano"
                          }
                        >
                      <Typography className="t_oktelo_normal darkblue size_14">{post.type === "post" ? "Bejegyzés" : "Kampány"}</Typography>
                            
                        </Tag>
                      </Tooltip>
                      }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container mt={2}>
        <Grid item xl={12} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <button
              className="post-grid-pagination"
              disabled={currentPage === 1}
              onClick={handlePrev}
            >
              Előző
            </button>
            <Typography mx={2}>
              Oldal: {currentPage} / {totalPages}
            </Typography>
            <button
              className="post-grid-pagination"
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              Következő
            </button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};



export default ScheduledPosts;

import React from "react";
import { Box } from '@mui/material';
import CreatePost from "components/common/oktelo_forms/CreatePost";


const CreateHUB = () => {

    return(
        <Box width="100%" display="flex" alignContent="center" alignItems="center" justifyContent="center" flexDirection="column" gap={4} pt={0}>

                <CreatePost/>

        </Box>
    )
    }


export default CreateHUB;
import { VideocamOutlined } from "@mui/icons-material";
import { Box, Grid, Select, InputAdornment, MenuItem, Stack, Typography, Card, CardContent, CardMedia, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

interface TiktokHomeProps {
    pageid: string;
    username: string;
}

const TiktokHome: React.FC<TiktokHomeProps> = ({ pageid, username }) => {

    const [videos, setVideos] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState("createdAt");

    // Fetch profile data
    const getProfileData = async () => {
        try {
            // Simulate fetching profile data
            console.log(`Fetching profile data for ${username}`);
        } catch (error) {
            console.error("Error fetching profile data:", error);
        }
    };

    // Fetch TikTok videos for the profile
    const getTiktokVideos = async () => {
        try {
            // Simulating video data fetch (replace with actual API call)
            const fetchedVideos = [
                { id: 1, title: "Video 1", thumbnail: "https://via.placeholder.com/150", date: "2024-11-01" },
                { id: 2, title: "Video 2", thumbnail: "https://via.placeholder.com/150", date: "2024-11-02" },
                // Add more videos
            ];
            setVideos(fetchedVideos);
        } catch (error) {
            console.error("Error fetching TikTok videos:", error);
        }
    };

    useEffect(() => {
        getProfileData();
        getTiktokVideos();
    }, [username]);

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            {/* Header Section */}
            <Grid container spacing={2}>
                <Grid item xl={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'darkblue' }}>
                        TikTok Management
                    </Typography>
                </Grid>
                <Grid item xl={8}>
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                        {`Managing TikTok profile for ${username}`}
                    </Typography>
                </Grid>
            </Grid>

            {/* Filter Section */}
            <Grid item xl={4} xs={12} sx={{ marginTop: 2 }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Select
                        value={selectedFilter}
                        onChange={(e) => setSelectedFilter(e.target.value)}
                        displayEmpty
                        startAdornment={
                            <InputAdornment position="start">
                                <VideocamOutlined sx={{ fontSize: '20px' }} />
                            </InputAdornment>
                        }
                        sx={{ width: 200 }}
                    >
                        <MenuItem value="createdAt">Created At</MenuItem>
                        <MenuItem value="updatedAt">Updated At</MenuItem>
                        <MenuItem value="calendarDate">Calendar Date</MenuItem>
                    </Select>
                </Stack>
            </Grid>

            {/* Video Grid */}
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {videos.map((video) => (
                    <Grid item xl={4} md={6} xs={12} key={video.id}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f9f9f9' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={video.thumbnail}
                                alt={video.title}
                            />
                            <CardContent>
                                <Typography variant="h6" sx={{ color: 'darkblue' }}>
                                    {video.title}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'gray' }}>
                                    {new Date(video.date).toLocaleDateString()}
                                </Typography>
                            </CardContent>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ margin: 1 }}
                                onClick={() => console.log(`Viewing video ${video.id}`)}
                            >
                                View
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TiktokHome;

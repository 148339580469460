import React from "react";
import ReactDOM from "react-dom"; // Use this instead of 'react-dom/client'
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookSDKProvider } from "utils/FBSDK";
import { ConfigProvider } from "antd";
import { NotificationProvider } from './utils/NotificationProvider';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "index.css";
import "./components/styles/responsive.css";
import dayjs from 'dayjs';
import hu_HU from 'antd/lib/locale/hu_HU';
dayjs.locale('hu')

const queryClient = new QueryClient();
const container = document.getElementById("root") as HTMLElement;

if (!container) {
  console.error('Root container is missing');
}

// Use ReactDOM.render instead of createRoot
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId="157858432316-3bq7kcm236ka5dmnve4hrbtt833590ff.apps.googleusercontent.com">
      <FacebookSDKProvider>
        <NotificationProvider>
          <ConfigProvider locale={hu_HU}>
            <App />
          </ConfigProvider>
        </NotificationProvider>
      </FacebookSDKProvider>
    </GoogleOAuthProvider> 
  </QueryClientProvider>,
  container // Pass the container as the second argument
);

import { Stack, Typography } from "@mui/material";
import { usePermissions } from "@refinedev/core";
import dayjs from "dayjs";
import { OkteloPostProps, UserProps } from "interfaces/oktelo";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiconfig from "utils/apiconfig";
import api from "utils/interceptor";

type PermissionsData = {
    isPermit: boolean;
};

interface SelectedPost {
    post: OkteloPostProps;
}

interface Message {
    from: string;
    content: string;
    date: Date;
}

interface Ticket {
    _id: string;
    createdBy: string;
    subject: string;
    assignedTo: string | null;
    status: string;
    priority: string;
    resolvedAt: Date | null;
    messages: Message[];
    createdAt: Date;
    updatedAt: Date;
}

interface AdminUserProps {
    id: string;
    name: string;
    email: string;
}

type AdminView = 'users' | 'tickets' | 'posts';

const AdminPanel: React.FC = () => {
    const { data: permissionsData, isLoading } = usePermissions<PermissionsData>();
    const [isPermit, setPermit] = useState<boolean>(false);
    const [view, setView] = useState<AdminView>('tickets');

    // Admin data
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [limit] = useState<number>(10);
    const [users, setUsers] = useState<UserProps[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserProps | null>(null);
    const [selectedPost, setSelectedPost] = useState<OkteloPostProps | null>(null);
    const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
    const [posts, setPosts] = useState<OkteloPostProps[]>([]);
    const [admins, setAdmins] = useState<AdminUserProps[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading) {
            if (permissionsData?.isPermit) {
                setPermit(true);
            } else {
                console.log("Redirecting to home");
                navigate("/");
            }
        }
    }, [permissionsData, isLoading, navigate]);

    const getAllTickets = async (page: number = 1, limit: number = 10) => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/adminsecretroute00/tickets`, {
                params: { page, limit },
            });
            const { tickets, totalPages } = response.data;
            setTickets(tickets);
            setTotalPages(totalPages);
            getAdminUsers();
        } catch (error) {
            console.error("Error fetching tickets:", error);
        }
    };

    const getAdminUsers = async () => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/adminsecretroute00/admins`);

            const { admins } = response.data;
            setAdmins(admins);

        } catch (error) {
            console.error("error getting admins");
        }
    }

    const getAllUsers = async (page: number = 1, limit: number = 10) => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/adminsecretroute00/users`, {
                params: { page, limit },
            });
            const { users, totalPages } = response.data;
            setUsers(users);
            setTotalPages(totalPages);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const getAllPosts = async (page: number = 1, limit: number = 10) => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/adminsecretroute00/posts`, {
                params: { page, limit },
            });
            const { posts, totalPages } = response.data;
            setPosts(posts);
            setTotalPages(totalPages);
        } catch (error) {
            console.error("Error fetching posts:", error);
        }
    };

    const handlePageChange = (direction: 'next' | 'prev') => {
        const newPage = direction === 'next' ? currentPage + 1 : currentPage - 1;
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        if (view === 'tickets') getAllTickets(currentPage, limit);
        else if (view === 'users') getAllUsers(currentPage, limit);
        else if (view === 'posts') getAllPosts(currentPage, limit);
    }, [view, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [view]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isPermit) {
        return <div>Access Denied</div>;
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
                <h3>Admin Panel</h3>
                <select value={view} onChange={(e) => setView(e.target.value as AdminView)}>
                    <option value="tickets">Tickets</option>
                    <option value="users">Users</option>
                    <option value="posts">Posts</option>
                </select>
            </div>

            {/* Render selected view */}
            {view === 'tickets' && (
                <TicketsView
                    tickets={tickets}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setSelectedTicket={setSelectedTicket}
                    handlePageChange={handlePageChange}
                    selectedTicket={selectedTicket}
                    admins={admins}
                    getAllTickets={getAllTickets}
                />
            )}
            {view === 'users' && (
                <UsersView
                    users={users}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setSelectedUser={setSelectedUser}
                    handlePageChange={handlePageChange}
                    selectedUser={selectedUser}
                />
            )}
            {view === 'posts' && (
                <PostsView
                    posts={posts}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setSelectedPost={setSelectedPost}
                    handlePageChange={handlePageChange}
                    selectedPost={selectedPost}
                />
            )}
        </div>
    );
};

// Tickets View Component
const TicketsView: React.FC<{
    tickets: Ticket[];
    admins: AdminUserProps[];
    currentPage: number;
    totalPages: number;
    setSelectedTicket: (ticket: Ticket) => void;
    handlePageChange: (direction: 'next' | 'prev') => void;
    selectedTicket: Ticket | null;
    getAllTickets: () => void;
}> = ({ tickets, admins, currentPage, totalPages, setSelectedTicket, handlePageChange, selectedTicket, getAllTickets }) => (
    <div style={{ width: '100%' }}>
        <h4>Tickets (Page {currentPage} of {totalPages})</h4>
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>Subject</th>
                    <th>Created By</th>
                    <th>Status</th>
                    <th>Priority</th>
                    <th>Assigned To</th>
                    <th>Updated At</th>
                </tr>
            </thead>
            <tbody>
                {tickets && tickets.length > 0 && tickets.map(ticket => (
                    <tr onClick={() => setSelectedTicket(ticket)} key={ticket.createdAt.toString()} style={{ cursor: 'pointer' }}>
                        <td>{ticket.subject}</td>
                        <td>{ticket.createdBy}</td>
                        <td>{ticket.status}</td>
                        <td>{ticket.priority}</td>
                        <td>{ticket.assignedTo || 'Not Assigned'}</td>
                        <td>{new Date(ticket.updatedAt).toLocaleString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        {selectedTicket && <TicketDetails ticket={selectedTicket} admins={admins} getAllTickets={getAllTickets}/>}
        {/* Pagination Controls */}
        <div>
            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
        </div>
    </div>
);

// Ticket Details Component
const TicketDetails: React.FC<{ ticket: Ticket, admins: AdminUserProps[], getAllTickets: () => void }> = ({ ticket, admins, getAllTickets }) => {
    
    const [selectedAdmin, setSelectedAdmin] = useState<string>(admins[0]?.id || "");

    const assignAdmin = async () => {
        try {
            if (!selectedAdmin) return;

            const response = await api.post(`${apiconfig.BACKEND_URI}/ticket/assign`, {
                userId: selectedAdmin,
                ticketId: ticket._id,
            })

            if (response.data){
                alert('Success');
                getAllTickets();
            }
        } catch (error) {
            console.log(error);
        }

    }

 return(   
    <Stack style={{ width: '100%', backgroundColor: '#fff' }} direction="column" gap={1.5}>
        <h4 className="size_16 darkblue t_oktelo_semibold">Ticket Details</h4>
        <ul className="size_14 darkblue t_oktelo_normal">
            <li><strong>Subject:</strong> {ticket.subject}</li>
            <li><strong>User:</strong> {ticket.createdBy}</li>
            <li><strong>Status:</strong> {ticket.status}</li>
            <li><strong>Priority:</strong> {ticket.priority}</li>
            <li><strong>Assigned To:</strong> {ticket.assignedTo || 'Not Assigned'}</li>
            <li><strong>Created At:</strong> {new Date(ticket.createdAt).toLocaleString()}</li>
            <li><strong>Updated At:</strong> {new Date(ticket.updatedAt).toLocaleString()}</li>
        </ul>

        <h4 className="size_16 darkblue t_oktelo_semibold">Assign To</h4>
        <Stack direction="row" gap={1.5} alignItems="center">
        <select onChange={(e) => setSelectedAdmin(e.target.value)} value={selectedAdmin}>
                {admins && admins.length > 0 &&
                    admins.map(admin => (
                        <option key={admin.id} value={admin.id}>{admin.name}</option>
                    ))
                }
            </select>
            <button disabled={ticket.assignedTo !== null} onClick={assignAdmin}>Assign to ticket</button>
        </Stack>


        <h4 className="size_16 darkblue t_oktelo_semibold">Messages</h4>
        <Stack direction="column" gap={2} width={'100%'}>
        {ticket.messages && ticket.messages.length > 0 &&
            ticket.messages.map(message => (
                <Stack key={message.date.toLocaleString()} style={{ width: '100%', border: '1px solid #d9d9d9', padding: '14px' }} direction="column" gap={0.5}>
                    <Typography className="size_14 darkblue t_oktelo_medium">
                        {message.from} {dayjs(message.date).format('YYYY.MM.DD HH:mm')}
                    </Typography>
                    <Typography className="size_14 darkblue t_oktelo_normal">
                        {message.content}
                    </Typography>
                </Stack>
            ))
        }
        </Stack>
    </Stack>
);
};

// Users View Component
const UsersView: React.FC<{
    users: UserProps[]; // Use UserProps here
    currentPage: number;
    totalPages: number;
    setSelectedUser: (user: UserProps) => void; // Update to UserProps
    handlePageChange: (direction: 'next' | 'prev') => void;
    selectedUser: UserProps | null; // Update to UserProps
}> = ({ users, currentPage, totalPages, setSelectedUser, handlePageChange, selectedUser }) => (
    <div style={{ width: '100%' }}>
        <h4>Users (Page {currentPage} of {totalPages})</h4>
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created At</th>
                    <th>Company</th>
                    <th>Partner Status</th>
                </tr>
            </thead>
            <tbody>
                {users && users.length > 0 && users.map(user => (
                    <tr onClick={() => setSelectedUser(user)} key={user._id} style={{ cursor: 'pointer' }}>
                        <td>{`${user.account.firstName} ${user.account.lastName}`}</td>
                        <td>{user.account.email}</td>
                        <td>{user.role.type}</td>
                        <td>{user.createdAt ? new Date(user.createdAt).toLocaleString() : 'N/A'}</td>
                        <td>{user.account.company || 'N/A'}</td>
                        <td>{user.account.partner?.isPartner ? 'Yes' : 'No'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        {selectedUser && <UserDetails user={selectedUser} />}
        {/* Pagination Controls */}
        <div>
            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
        </div>
    </div>
);


// User Details Component
const UserDetails: React.FC<{ user: UserProps }> = ({ user }) => (
    <div style={{ width: '100%', marginTop: '20px' }}>
        <h4>User Details</h4>
        <p><strong>Name:</strong> {`${user.account.firstName} ${user.account.lastName}`}</p>
        <p><strong>Email:</strong> {user.account.email}</p>
        <p><strong>Company:</strong> {user.account.company || 'N/A'}</p>
        <p><strong>Role Type:</strong> {user.role.type}</p>
        <p><strong>Account Created At:</strong> {new Date(user.createdAt).toLocaleString() || 'N/A'}</p>
        <p><strong>Account Updated At:</strong> {new Date(user.updatedAt).toLocaleString() || 'N/A'}</p>

        <h5>Partner Information</h5>
        {user.account.partner ? (
            <div>
                <p><strong>Is Partner:</strong> {user.account.partner.isPartner ? 'Yes' : 'No'}</p>
                <p><strong>Promo Code:</strong> {user.account.partner.promoCode || 'N/A'}</p>
                <p><strong>Used Count:</strong> {user.account.partner.usedCount || 0}</p>
                <p><strong>Payout Value:</strong> {user.account.partner.payoutValue || 0}</p>
                <p><strong>Payout Allowed:</strong> {user.account.partner.payoutAllowed ? 'Yes' : 'No'}</p>
            </div>
        ) : (
            <p>No partner information available.</p>
        )}

        <h5>Role Details</h5>
        <p><strong>Oktelo Tokens:</strong> {user.role.okteloTokens}</p>
        <p><strong>Limits:</strong></p>
        <ul>
            <li>Max Posts: {user.role.limits.maxPosts}</li>
            <li>Max Connections: {user.role.limits.maxConnections}</li>
            <li>Max Approvals: {user.role.limits.maxApprovals}</li>
            <li>Max Campaigns: {user.role.limits.maxCampaigns}</li>
            <li>Max Ads: {user.role.limits.maxAds}</li>
            <li>Max Reports: {user.role.limits.maxReports}</li>
        </ul>
    </div>
);

// Posts View Component
const PostsView: React.FC<{
    posts: OkteloPostProps[];
    currentPage: number;
    totalPages: number;
    setSelectedPost: (post: OkteloPostProps) => void;
    handlePageChange: (direction: 'next' | 'prev') => void;
    selectedPost: OkteloPostProps | null;
}> = ({ posts, currentPage, totalPages, setSelectedPost, handlePageChange, selectedPost }) => (
    <div style={{ width: '100%' }}>
        <h4>Posts (Page {currentPage} of {totalPages})</h4>
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th>id</th>
                    <th>platform</th>
                    <th>status</th>
                    <th>parent</th>
                    <th>createdAt</th>
                </tr>
            </thead>
            <tbody>
                {posts && posts.length > 0 && posts.map(post => (
                    <tr onClick={() => setSelectedPost(post)} key={post._id} style={{ cursor: 'pointer' }}>
                        <td>{post._id}</td>
                        <td>{post.platform}</td>
                        <td>{post.status}</td>
                        <td>{post.parent ? post.parent.name : 'nincs'}</td>
                        <td>{new Date(post.createdAt).toLocaleString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        {selectedPost && <PostDetails post={selectedPost} />}
        {/* Pagination Controls */}
        <div>
            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
        </div>
    </div>
);

// Post Details Component
const PostDetails: React.FC<{ post: OkteloPostProps }> = ({ post }) => {
    const {
        message,
        platform,
        creator,
        calendarDate,
        status,
        attachment,
        approval,
        publish,
        tag,
        firstComment,
        logs,
        createdAt,
        updatedAt,
        campaign,
    } = post;

    return (
        <div style={{ width: '100%', border: '1px solid #ccc', padding: '16px', borderRadius: '8px', marginTop: '16px' }}>
            <h4>Post Details</h4>
            {message && <p><strong>Message:</strong> {message}</p>}
            <p><strong>Platform:</strong> {platform}</p>
            <p><strong>Created By:</strong> {creator}</p>
            <p><strong>Created At:</strong> {new Date(createdAt).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(updatedAt).toLocaleString()}</p>
            {calendarDate && <p><strong>Scheduled Date:</strong> {new Date(calendarDate).toLocaleString()}</p>}
            <p><strong>Status:</strong> {status}</p>

            {attachment && (
                <div>
                    <h5>Attachment</h5>
                    <p><strong>Type:</strong> {attachment.type}</p>
                    {attachment.url && (
                        <div>
                            {attachment.type === 'photo' && attachment.url.photoUrl && (
                                <img src={attachment.url.photoUrl} alt="Post Attachment" style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '8px' }} />
                            )}
                            {attachment.type === 'video' && attachment.url.videoUrl && (
                                <video controls style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '8px' }}>
                                    <source src={attachment.url.videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                            {attachment.type === 'webUrl' && attachment.url.webUrl && (
                                <p><strong>Web URL:</strong> <a href={attachment.url.webUrl} target="_blank" rel="noopener noreferrer">{attachment.url.webUrl}</a></p>
                            )}
                            {attachment.type === 'carousel' && attachment.url.carouselUrls && (
                                <div>
                                    <strong>Carousel Images:</strong>
                                    {attachment.url.carouselUrls.map((url, index) => (
                                        <img key={index} src={url} alt={`Carousel Image ${index + 1}`} style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '8px', marginRight: '8px' }} />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {approval && (
                <div>
                    <h5>Approval</h5>
                    <p><strong>Is Needed:</strong> {approval.isNeeded ? 'Yes' : 'No'}</p>
                    <p><strong>Status:</strong> {approval.status}</p>
                    {approval.approvedBy && <p><strong>Approved By:</strong> {approval.approvedBy}</p>}
                    {approval.approvedDate && <p><strong>Approved Date:</strong> {approval.approvedDate}</p>}
                </div>
            )}

            {publish && (
                <div>
                    <h5>Publishing Status</h5>
                    <p><strong>Is Published:</strong> {publish.isPublished ? 'Yes' : 'No'}</p>
                    {publish.trackId && <p><strong>Track ID:</strong> {publish.trackId}</p>}
                    {publish.date && <p><strong>Published Date:</strong> {new Date(publish.date).toLocaleString()}</p>}
                </div>
            )}

            {tag && (
                <div>
                    <h5>Tag</h5>
                    <p><strong>Title:</strong> {tag.title}</p>
                    <p style={{ backgroundColor: tag.color, padding: '4px', borderRadius: '4px' }}><strong>Color:</strong> {tag.color}</p>
                </div>
            )}

            {firstComment && (
                <div>
                    <h5>First Comment</h5>
                    <p><strong>Enabled:</strong> {firstComment.enabled ? 'Yes' : 'No'}</p>
                    {firstComment.message && <p><strong>Message:</strong> {firstComment.message}</p>}
                </div>
            )}

            {logs && logs.length > 0 && (
                <div>
                    <h5>Logs</h5>
                    <ul>
                        {logs.map((log, index) => (
                            <li key={index}>
                                <strong>{log.email}</strong>: {log.message} <em>({new Date(log.date).toLocaleString()})</em>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {campaign && (
                <div>
                    <h5>Campaign</h5>
                    <p><strong>ID:</strong> {campaign._id}</p>
                    <p><strong>Title:</strong> {campaign.title}</p>
                    <p><strong>Status:</strong> {campaign.status}</p>
                </div>
            )}
        </div>
    );
};


export default AdminPanel;

import { ApexOptions } from 'apexcharts';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

interface ChartSeriesData {
  name: string;
  data: { x: Date | string; y: number }[];
}

interface ChartProps {
  title: string;
  chartData: ChartSeriesData[];
}

const metricNameMapping: { [key: string]: string } = {
    totalUsers: "Összes felhasználó",
    newUsers: "Új felhasználók",
    sessions: "Munkamenetek",
    engagedSessions: "Elkötelezett munkamenetek",
    screenPageViews: "Oldal megtekintések",
    eventCount: "Események",
}

const DateAreaChart: React.FC<ChartProps> = ({ title, chartData }) => {
  const [series, setSeries] = useState<ChartSeriesData[]>([]);
  const [options, setOptions] = useState<ApexOptions>({});

  useEffect(() => {
    if (chartData && chartData.length > 0) {

        const seriesData = chartData.map(metric => ({
            name: metricNameMapping[metric.name] || metric.name, // Map the name
            data: metric.data.map(item => ({
              x: dayjs(item.x).format('MM.DD.'), // Format the date
              y: item.y, // Keep the y value as is
            })),
          }));

      // Extract x-axis categories from the first series
      const firstSeries = chartData[0]?.data || [];
      const categories = firstSeries.map(point => dayjs(new Date(point.x)).format('MM.DD'));

      const optionsData: ApexOptions = {
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: "",
          },
          
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.3,
            opacityTo: 0.4,
          }
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
          type: 'area',
        },
        colors: ["#8576FF", "#7BC9FF", "#7BC9FF", "#A3FFD6"],
        plotOptions: {
          area: {
            
          }
        },
        markers: {
          size: 3
        },
        grid: {
          show: false,
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 2,
          dashArray: 0,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        tooltip: {
          y: {
            formatter(val: number) {
              return `${val}`;
            },
          },
        },
        noData: {
          text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: '#000',
            fontSize: '14px',
          }
        }
      };
      setSeries(seriesData);
      setOptions(optionsData);
    }
  }, [chartData]);

  return (
    <div style={{ width: '100%', padding: '0px', flex: 1}}>
    <ReactApexChart
      series={series}
      type="area"
      height={300}
      width="100%"
      options={options}
    />
    </div>
  );
};

export default DateAreaChart;

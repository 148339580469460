import React, { useState, useEffect, useMemo, useCallback } from "react";
import api from "utils/interceptor"
import { useParams } from "react-router-dom";
import locale from "antd/locale/hu_HU";
import { ConfigProvider, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import {
  Grid,
  Box,
  Stack,
  Typography,
  CircularProgress,
  LinearProgress,
  Avatar,
  Link,
} from "@mui/material";
import { Input, Tag, Tooltip } from "antd";
import "./sitedata.css";
import { AdsClick, Coffee, Comment, EmojiEmotions, Favorite, LinkSharp, Person, PersonSearch, Public, Save, Search, Share, Visibility, Web } from "@mui/icons-material";
import AnalyticsheatMapChart from "components/charts/SiteData/_HeatmapChart";
import AnalyticsAreaChart from "components/charts/SiteData/AnalyticsAreaChart";
import apiconfig from "utils/apiconfig";

const { RangePicker } = DatePicker;

interface PageInfo {
  name: string;
  picture: string;
}

type MetricData = { name: string; values: { date: string; value: number | Record<string, number> }[] };
type MetricDataValues = { date: string; value: number | Record<string, number> };
type MetricGroup = {
  name: string;
  icon: JSX.Element;
  metrics: Record<string, MetricDataValues[]>;
};

interface TotalMetricValue {
  value: number;
}

interface DataPoint {
  name: string;
  value: number;
}


interface TotalMetricGroup {
  name: string;
  icon: JSX.Element;
  tooltip: string;
  value: number | null;
}  

interface DemoMetricGroup {
  name: string;
  icon: JSX.Element;
  tooltip: string;
  values: DataPoint[];
}

interface TypeMetricGroup {
  name: string;
  icon: JSX.Element;
  tooltip: string;
  ratio: number;
  values: DataPoint[];
}


const IGSiteData = () => {
  /* Main */
  const { pageid } = useParams<{ pageid: string }>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [isLoading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState<any[]>([]);
  /* Filters */
  const [since, setSince] = useState<string | null>(null);
  const [until, setUntil] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [preset, setPreset] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  /* Metrics */
  const [heatmapChartMetricsGroups, setHeatmapChartMetricsGroups] = useState<MetricGroup[]>([]);
  const [areaChartMetricsGroups, setAreaChartMetricsGroups] = useState<MetricGroup[]>([]);
  const [demoMetrics, setDemoMetrics] = useState<DemoMetricGroup[]>([]);
  const [totalMetrics, setTotalMetrics] = useState<TotalMetricGroup[]>([]);
  const [typeMetrics, setTypeMetrics] = useState<TypeMetricGroup[]>([]);
  
  /* Fetch */        
  const fetchInsights = async () => {     
    try {             
      setLoading(true);     
      const response = await api.post(
        `${apiconfig.BACKEND_URI}/insights/analyze/${pageid}`,
        {
          since: since || dayjs().subtract(7, 'days'),
          until: until || dayjs(),
          preset: preset || null
        }          
      );                 
      if (response.status === 200 && response.data){
        setPageInfo(response.data.info);
        setMetrics(response.data.data); 
      }  
    } catch (error: any) {   
      console.error("Error fetching insights:", error.message);
    } finally { 
      setLoading(false); 
    }
  };    


  const addHeatmapChartMetricsToGroup = useCallback(() => {
    const metricGroups = [
      {
        name: "Online követők idő szerinti eloszlása",
        icon: <Public />,
        metrics: {
          online: metrics.find(
            (metric: MetricData) => metric.name === "flat_online_followers"
          )?.values || [],
        },
      },
    ];
    setHeatmapChartMetricsGroups(metricGroups);
  }, [metrics]);
 
  const addAreaChartMetricsToGroup = useCallback(() => {
    const metricGroups = [
      {
        name: "Impressziók és elérés",  
        icon: <Visibility />,
        metrics: {
          impressions: metrics.find((metric) => metric.name === "flat_impressions")?.values || [],
          reach: metrics.find((metric) => metric.name === "flat_reach")?.values || [],
        } as Record<string, MetricDataValues[]>, 
      },
      {
        name: "Új követések",  
        icon: <Favorite/>,
        metrics: {
          dailyFollowsUnique: metrics.find((metric) => metric.name === "flat_follower_count")?.values || [],
        } as Record<string, MetricDataValues[]>, 
      },
      {
        name: "Profil interakciók",  
        icon: <AdsClick/>,
        metrics: {
          emailClicks: metrics.find((metric) => metric.name === "flat_email_contacts")?.values || [],
          messageClicks: metrics.find((metric) => metric.name === "flat_text_message_clicks")?.values || [],
          websiteClicks: metrics.find((metric) => metric.name === "flat_website_clicks")?.values || [],
        } as Record<string, MetricDataValues[]>, 
      },
    ];
    setAreaChartMetricsGroups(metricGroups); 
  }, [metrics]);


  const addTotalMetrics = useCallback(() => {
    const metricsGroups = [
    {
      name: "Impressziók",
      icon: <Visibility/>,
      tooltip: "Az a szám, ahányszor bejegyzéseid, történeteid, reels-eid, videóid és élő videóid megjelentek a képernyőn, beleértve a hirdetéseket is.",
      value: metrics.find((metric) => metric.assigName === "total_impressions")?.data[0].value || 0,
    },
    {
      name: "Elérés",
      icon: <Person/>,
      tooltip: "Az instagram felhasználók száma, amelyek legalább egyszer látták a  tartalmaid, beleértve a hirdetéseket is.",
      value: metrics.find((metric) => metric.assigName === "total_reach")?.data[0].value || 0,
    },
    {
      name: "Interakciók",
      icon: <Person/>,
      tooltip: "Összes, a tartalmaiddal kapcsolatos interakciók száma (poszt, reels, story, videó).",
      value: metrics.find((metric) => metric.assigName === "total_total_interactions")?.data[0].value || 0,
    },
    {
      name: "Elköteleződött felhasználók",
      icon: <EmojiEmotions/>,
      tooltip: "Azon fiókok száma, amelyek interakcióba léptek a tartalmaiddal, beleértve a hirdetéseket is.",
      value: metrics.find((metric) => metric.assigName === "total_accounts_engaged")?.data[0].value || 0,
    },
    {
      name: "Kedveléses",
      icon: <Favorite/>,
      tooltip: "A bejegyzéseidre, reelsekre és videóidra adott kedvelések száma.",
      value: metrics.find((metric) => metric.assigName === "total_likes")?.data[0].value || 0,
    },
    {
      name: "Kommentek",
      icon: <Comment/>,
      tooltip: "A bejegyzéseidre, reelsekre és videóidra érkezett kommentek száma.",
      value: metrics.find((metric) => metric.assigName === "total_comments")?.data[0].value || 0,
    },
    {
      name: "Mentések",
      icon: <Save/>,
      tooltip: "Bejegyzéseid, reelseid és videóid mentéseinek száma.",
      value: metrics.find((metric) => metric.assigName === "total_saves")?.data[0].value || 0,
    },
    {
      name: "Megosztások",
      icon: <Share/>,
      tooltip: "Bejegyzéseid, reelseid és videóid megosztásának száma.",
      value: metrics.find((metric) => metric.assigName === "total_shares")?.data[0].value || 0,
    },
    {
      name: "Story interakciók",
      icon: <Coffee/>,
      tooltip: "A történetből kapott válaszok száma, beleértve a szöveges válaszokat és a gyorsreagálású válaszokat.",
      value: metrics.find((metric) => metric.assigName === "total_replies")?.data[0].value || 0,
    },
    {
      name: "Profil link kattintások",
      icon: <LinkSharp/>,
      tooltip: "Az üzleti címre, a hívásgombra, az e-mail gombra és a szöveges gombra való koppintások száma.",
      value: metrics.find((metric) => metric.assigName === "total_profile_links_taps")?.data[0].value || 0,
    },
    {
      name: "Profil weboldal kattintások",
      icon: <Web/>,
      tooltip: "Az a szám, ahányszor koppintottak a webhelyedre mutató hivatkozásra.",
      value: metrics.find((metric) => metric.assigName === "total_website_clicks")?.data[0].value || 0,
    },
    {
      name: "Profil megtekintések",
      icon: <PersonSearch/>,
      tooltip: "A profilod látogatásainak száma.",
      value: metrics.find((metric) => metric.assigName === "total_profile_views")?.data[0].value || 0,
    },
  ];

  setTotalMetrics(metricsGroups);

  }, [metrics])

  const addDemoMetrics = useCallback(() => {

    const metricsGroups: DemoMetricGroup[] = [
      {
        name: "Elköteleződött felhasználók, ország szerint",
        icon: <EmojiEmotions />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "engaged_audience_demographics_country")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elköteleződött felhasználók, város szerint",
        icon: <EmojiEmotions />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "engaged_audience_demographics_city")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elköteleződött felhasználók, kor szerint",
        icon: <EmojiEmotions />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "engaged_audience_demographics_age")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elköteleződött felhasználók, nem szerint",
        icon: <EmojiEmotions />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "engaged_audience_demographics_gender")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elért felhasználók, ország szerint",
        icon: <Person />,
        tooltip: "Tooltip",
        values: metrics
        .find((metric) => metric.name === "reached_audience_demographics_country")
        ?.data.map((m: DataPoint) => ({
          name: m.name,
          value: m.value || 0,
        }))
        .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
    },
      {
        name: "Elért felhasználók, város szerint",
        icon: <Person />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "reached_audience_demographics_city")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elért felhasználók, kor szerint",
        icon: <Person />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "reached_audience_demographics_age")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elért felhasználók, nem szerint",
        icon: <Person />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "reached_audience_demographics_gender")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Követők, ország szerint",
        icon: <Favorite />,
        tooltip: "Tooltip",
        values: metrics
        .find((metric) => metric.name === "follower_demographics_country")
        ?.data.map((m: DataPoint) => ({
          name: m.name,
          value: m.value || 0,
        }))
        .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
    },
      {
        name: "Követők, város szerint",
        icon: <Favorite />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "follower_demographics_city")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Követők, kor szerint",
        icon: <Favorite />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "follower_demographics_age")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Követők, nem szerint",
        icon: <Favorite />,
        tooltip: "Tooltip",
        values: metrics
          .find((metric) => metric.name === "follower_demographics_gender")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
    ];

    setDemoMetrics(metricsGroups);

  }, [metrics])


  const addTypeMetrics = useCallback(() => {

    const metricsGroups: TypeMetricGroup[] = [
      {
        name: "Követések és kikövetések",
        icon: <EmojiEmotions />,
        tooltip: "Azon fiókok száma, amelyek bekövették a profilod, és azon fiókok száma, amelyek leállították a követést vagy elhagyták az Instagramot a kiválasztott időszakban.",
        ratio: 0,
        values: metrics
          .find((metric) => metric.name === "follows_and_unfollows_follow_type")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Interakciók tartalom szerint",
        icon: <EmojiEmotions />,
        tooltip: "A tartalmaidra érkezett interakciók, tartalom típusa szerint lebontva.",
        ratio: 0,
        values: metrics
          .find((metric) => metric.name === "total_interactions_media_product_type")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Elérés tartalom szerint",
        icon: <EmojiEmotions />,
        tooltip: "Azon egyedi fiókok száma, amelyek legalább egyszer látták a tartalmaid, lebontva a tartalom típusaira.",
        ratio: 0,
        values: metrics
          .find((metric) => metric.name === "reach_media_product_type")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Kedvelések tartalom szerint",
        icon: <EmojiEmotions />,
        tooltip: "A tartalmaidra érkezett kedvelések, tartalom típusa szerint lebontva.",
        ratio: 0,
        values: metrics
          .find((metric) => metric.name === "likes_media_product_type")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Mentések tartalom szerint",
        icon: <EmojiEmotions />,
        tooltip: "A tartalmaid elmentéseinek száma, tartalom típusa szerint lebontva.",
        ratio: 0,
        values: metrics
          .find((metric) => metric.name === "saves_media_product_type")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
      {
        name: "Megosztások tartalom szerint",
        icon: <EmojiEmotions />,
        tooltip: "A tartalmaid megosztásai, tartalom típusa szerint lebontva.",
        ratio: 0,
        values: metrics
          .find((metric) => metric.name === "shares_media_product_type")
          ?.data.map((m: DataPoint) => ({
            name: m.name,
            value: m.value || 0,
          }))
          .sort((a: DataPoint, b: DataPoint) => b.value - a.value) || []
      },
    ] 


    setTypeMetrics(metricsGroups);

  }, [metrics])


  useEffect(() => {
    if (pageid) {
      fetchInsights();
    }
  }, [pageid, dateRange, preset]);

  useEffect(() => {
    if (metrics.length) {
      addHeatmapChartMetricsToGroup();
      addAreaChartMetricsToGroup();
      addTotalMetrics();
      addDemoMetrics();
      addTypeMetrics();
    }
  }, [metrics, addHeatmapChartMetricsToGroup, addAreaChartMetricsToGroup, addTotalMetrics, addDemoMetrics, addTypeMetrics]);

  /* Filter handlers */
  const onRangeChange = useCallback((dates: (Dayjs | null)[] | null, dateStrings: [string, string]) => {
    if (dates) {
      // Handle the case where dates is an array of [Dayjs | null, Dayjs | null]
      setSince(dates[0] ? dateStrings[0] : null); // Use dateStrings only if dates is not null
      setUntil(dates[1] ? dateStrings[1] : null); // Use dateStrings only if dates is not null
      setDateRange(dates as [Dayjs | null, Dayjs | null]);
    } else {
      // Handle the case where dates is null
      setSince(null);
      setUntil(null);
      setDateRange([null, null]);
    }
  }, []);

  const onPresetChange = useCallback((value: string | undefined) => {
    if (value) {
      setPreset(value);
    } else {
      setPreset(null);
    }
  }, []);


  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value.toLowerCase());
    },
    []
  );

  const filterMetrics = useCallback(
    <T extends { name: string }>(metrics: T[], searchQuery: string): T[] => {
      return metrics.filter((group) =>
        group.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
    []
  );

  const getFlagClass = useCallback((key: string, index: number) => {
    if (index === 0 || index === 4 || index === 8) {
      return `fi fi-${key.toLowerCase()}`;
    } else if (index === 1) {
      const code = key.split("_")[1].toLowerCase();
      return `fi fi-${code}`;
    }
    return "";
  }, []);

  const TypeMetricMap: { [key: string]: string } = {
    POST: "Bejegyzés",
    REEL: "Reels videó",
    AD: "Hirdetés",
    CAROUSEL_CONTAINER: "Carousel",
    STORY: "Történet",
    FOLLOWER: "Bekövetés",
    NON_FOLLOWER: "Kikövetés",
  };

  if (isLoading) {
    return (
      <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "9999",
        }}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
          <Typography className="t_oktelo_medium size_18 t_purple">
            Egy pillanat! Adatok betöltése...
          </Typography>
        </Stack>
      </Box>    
    ); 
  }
 
  if (!metrics) {
    return <Box>Nicsenek adatok</Box>;
  }     
      
  return (
    <Box width="100%">
      <Typography className="t_oktelo_semibold size_24 darkblue">
        Oktelo Analytics  
      </Typography>
      <Typography className="t_oktelo_normal size_14 darkblue" mb={3}>
        Részletes elemzés a kiválasztott közösségi média oldalad adatai alapján.
      </Typography>          
      <Stack direction="column" gap={1} sx={{ backgroundColor: '#ffe4e4', padding: '16px', borderRadius: '0px'}}>
        <Typography className="t_oktelo_semibold size_12 darkblue">
          Kedves felhasználó,
        </Typography>
        <Typography className="t_oktelo_normal size_14 darkblue">
          Az Instagram oldalak adatait csak 30 napos távlatban tudjuk feldolgozni, azon belül pedig szokásosan kiválaszthatóak különböző intervallumok. A demográfiai adatok csak az adott hónapra vonatkoznak, így érdemes a hónap utolsó napján riportot készíteni róluk, továbbá egyes adatok nem elérhetőek, ha az adott mérőszámokból nincs minimum 100-as érték. A megértést köszönjük!
        </Typography>
      </Stack>
      <Box className="analytics-filter-box" my={2}>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}> 
            <Stack     
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
              p={1}
              gap={0.5}
            >
              <Typography className="t_oktelo_medium size_12 darkblue">
                Kártya keresése
              </Typography>
              <Input
                placeholder="Keresés"
                prefix={<Search sx={{ fontSize: "16px", color: "#d9d9d9" }} />}
                style={{ width: "100%", backgroundColor: "#fff !important" }}
                value={search}
                onChange={handleSearchChange}
              />
            </Stack>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
              p={1}
              gap={0.5}
            >
              <Typography className="t_oktelo_medium size_12 darkblue">
                Elemzés intervalluma
              </Typography>
              <ConfigProvider locale={locale}>
                <RangePicker
                  style={{ width: "100%" }}
                  onChange={onRangeChange}
                  value={dateRange}
                  maxDate={dayjs().add(1, "day")}
                  minDate={dayjs().subtract(30, "day")}
                  showTime={false}
                />
              </ConfigProvider>
            </Stack>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
              p={1}
              gap={0.5}
            >
              <Typography className="t_oktelo_medium size_12 darkblue">
                Elemzés sablonok
              </Typography>
              <Select
                placeholder="Sablonok"
                style={{ width: "100%" }}
                value={preset}
                allowClear
                onChange={onPresetChange}
                options={[
                  { value: "this_month", label: "Hónap" },
                  { value: "this_week", label: "Hét"},
                  { value: "last_3d", label: "Előző 3 nap" },
                  { value: "last_7d", label: "Előző 7 nap" },
                  { value: "last_14d", label: "Előző 14 nap" },
                  { value: "last_28d", label: "Előző 28 nap" },
                  { value: "last_30d", label: "Előző 30 nap" },
                  { value: "last_week_mon_sun", label: "Előző hét" },
                  { value: "this_week_mon_today", label: "Hétfőtől máig" },
                ]}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="flex-start"
        mt={3}
        mb={1}
      >
        <Avatar
          sx={{
            width: "40px",
            height: "40px",
            padding: "8px",
            backgroundColor: "#fff",
            border: "1px solid #ff5800",
          }}
          src={pageInfo?.picture}
        />
        <Typography className="t_oktelo_medium size_14 darkblue">
          {pageInfo?.name}
          <br></br>
          <span className="t_oktelo_normal size_12">
            {dayjs().format("YYYY.MMMM.DD (ddd) HH:mm:ss")}
          </span>
        </Typography>
      </Stack>
      <Grid container spacing={2}>
      {filterMetrics(totalMetrics, search).map((group, index) => (
          <Grid item xl={3} lg={3} md={6} sm={12} xs={12} key={index + group.name}>
            <Box className="oktelo_card" height="100%">
              <Stack direction="column" gap={2} justifyContent="space-between" height="100%">
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {group.tooltip}
                </Typography>
                <Typography className="t_oktelo_bold size_20 darkblue">
                  {group.value}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      {filterMetrics(areaChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={index + group.name}>
            <Box className="oktelo_card">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
{/*                 <SingleSumMetricsComponent
                  index={index}
                  singleSumMetrics={singleSumMetrics}
                /> */}
                <AnalyticsAreaChart title={group.name} chartData={group.metrics} />
              </Stack>
            </Box>
          </Grid>
        ))}
      {filterMetrics(heatmapChartMetricsGroups, search).map((group, index) => (
          <Grid item xl={12} xs={12} key={index + group.name}>
            <Box className="oktelo_card">
              <Stack direction="column" gap={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                <AnalyticsheatMapChart title={group.name} chartData={group.metrics} />
              </Stack>
            </Box>
          </Grid>
        ))}
        {filterMetrics(typeMetrics, search).map((group, index) => (
          <Grid item xl={3} xs={12} key={index + group.name}>
            <Box className="oktelo_card" height="100%">
              <Stack direction="column" gap={2} height="100%">
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                </Stack>
                {group.tooltip}
                <Box sx={{ maxHeight: '300px', overflowY: 'scroll'}}>
                <ul style={{ listStyle: 'none', padding: '8px'}}>
                {group.values.map((value, idx) => (
                  <li key={idx} style={{ borderBottom: '1px solid #d9d9d9'}}>
                    <Typography className="t_oktelo_medium size_14 darkblue">
                    {TypeMetricMap[value.name] || value.name}:
                    </Typography>
                    <Typography className={`t_oktelo_bold size_18 ${idx === 0 ? 't_purple' : 'darkblue'}`}>
                    {value.value}
                    </Typography>
                  </li>
                ))}
              </ul>
              </Box>
              </Stack>
            </Box>
          </Grid>
        ))}
        <Grid item xl={12} xs={12} mt={3}> 
          <Stack direction="column" gap={2}>
            <Typography className="t_oktelo_semibold size_18 darkblue">
              Demográfiai adatok (csak havi összesítés elérhető)
            </Typography>
             <Stack direction="row" gap={1} alignItems="center">
                <Tag color="purple" className="tag_oktelo size_14 t_oktelo_medium">
                  {dayjs().startOf('month').format('YYYY.MM.DD')}-tól
                </Tag>
                <Tag color="purple" className="tag_oktelo size_14 t_oktelo_medium">
                  {dayjs().endOf('month').format('YYYY.MM.DD')}-ig
                </Tag>
              </Stack>
          </Stack>
        </Grid>
        {filterMetrics(demoMetrics, search).map((group, index) => (
          <Grid item xl={3} xs={12} key={index + group.name}>
            <Box className="oktelo_card" height="100%">
              <Stack direction="column" gap={2} height="100%">
                <Stack direction="row" gap={1} alignItems="center">
                  <Avatar className="small-icon-metric">
                    {group.icon}
                  </Avatar>
                  <Typography className="t_oktelo_semibold size_16 darkblue">
                    {group.name}
                  </Typography>
                  
                </Stack>
                <Box sx={{ maxHeight: '300px', overflowY: 'scroll'}}>
                <ul style={{ listStyle: 'none', padding: '8px'}}>
                {group.values.map((value, idx) => (
                  <li key={idx} style={{ borderBottom: '1px solid #d9d9d9'}}>
                    <Typography className="t_oktelo_medium size_14 darkblue">
                    {index === 0 || index === 4 || index === 8 ? (
                        <span className={getFlagClass(value.name, index)}> </span>
                      ) : null}
                      {" "}
                      {value.name === "F" ? "Nő" : value.name === "M" ? "Férfi" : value.name === "U" ? "Ismeretlen" : value.name}:
                    </Typography>
                    <Typography className={`t_oktelo_bold size_18 ${idx === 0 ? 't_purple' : 'darkblue'}`}>
                    {value.value}
                    </Typography>
                  </li>
                ))}
              </ul>
              </Box>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default IGSiteData;

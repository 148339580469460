import React, { useState, useEffect } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import api from "utils/interceptor";
import apiconfig from "utils/apiconfig";
import dayjs from "dayjs";
import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';

interface AiChatProps {
  _id: string;
  requestTitle: string;
  responseAnswer: string;
  date: Date;
}

const ITEMS_PER_PAGE = 8;

const OkteloAI = () => {
  document.title = "Oktelo AI | Oktelo";
  const [aiInteractions, setAiInteractions] = useState<AiChatProps[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentPage, setCurrentPage] = useState(1);

  const getAiChats = async ( ) => {
      try {
          const response = await api.get(`${apiconfig.BACKEND_URI}/ai/chats`);

          if(response.data){
              setAiInteractions(response.data.interactions);
          } else {
              setAiInteractions([]);
          }

      } catch (error) {
          console.log("Cant load Ai chats");
      }
  };

  const deleteChat = async ( id: string ) => {
      if (!id) return;
      try {
          const response = await api.post(`${apiconfig.BACKEND_URI}/ai/chats`, { id: id });
          if (response.data.success){
              return getAiChats();
          }
      } catch (error) {
          console.log(error);
      }
  }

  useEffect(() => {
      getAiChats();
  }, []);


  // Toggle sort order and reset page to 1
  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    setCurrentPage(1);
  };

  // Sort and paginate data
  const sortedData = [...aiInteractions].sort((a, b) => {
    return sortOrder === 'asc' 
      ? new Date(a.date).getTime() - new Date(b.date).getTime()
      : new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  const paginatedData = sortedData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Handle pagination
  const handleNextPage = () => setCurrentPage(prev => prev + 1);
  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));


  return (

    <Box width="100%">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={8} xl={10}>
          <Stack flexDirection="column" alignItems="flex-start">
            <Typography className="size_20 darkblue t_oktelo_semibold">Oktelo AI üzenetek</Typography>
            <Typography className="t_oktelo_normal size_14 darkblue">Az általad generált interakciókat mentjük el.</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={2} >
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <button className="home-action-button b925" onClick={toggleSortOrder}>
              {sortOrder === 'desc' ? 'Legújabb' : 'Legrégebbi'}
              {sortOrder === 'desc' ? <ArrowDownward/> : <ArrowUpward/>}
            </button>
          </Stack>
        </Grid>

        {(paginatedData && paginatedData.length > 0) ? (
          paginatedData.map(interaction => (
        <Grid item xs={12} md={12} lg={12} p={2} key={interaction._id}>
            <Box className="aiInteractionBox" >
                <Stack direction="column" gap={1.5} style={{ width: '100%' }}>
                    <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between" py={1.5} sx={{ borderBottom: '1px solid #d9d9d9'}} flexWrap="wrap">
                        <Typography className="t_oktelo_semibold size_16 darkblue">
                            {interaction.requestTitle}
                        </Typography>
                        <Typography className="t_oktelo_medium size_14 darkblue">
                            {dayjs(interaction.date).format('YYYY.MM.DD. HH:mm')}
                        </Typography>
                    </Stack>
                    <Typography
                    className="t_oktelo_normal size_14 darkblue"
                    dangerouslySetInnerHTML={{ __html: interaction.responseAnswer }}
                />
                </Stack>
                <Stack direction="row" gap={1} alignItems="center" mt={1}>
                    <button className="home-action-button b925" onClick={() => deleteChat(interaction._id)}>
                        <Delete/>
                        Törlés
                    </button>
                </Stack>
            </Box>
        </Grid>
        ))
        ) : (
          <Typography className="t_oktelo_normal darkblue size_14">
            Nem mentettél el Oktelo AI elemzéseket.
          </Typography>
        )}

        {/* Pagination Controls */}
        <Stack direction="row" spacing={2} justifyContent="center" width="100%" mt={2}>
        <button  className="home-action-button bc6a" onClick={handlePreviousPage} disabled={currentPage === 1}>
          Előző oldal
        </button>
        <button className="home-action-button bc6a" onClick={handleNextPage} disabled={(currentPage * ITEMS_PER_PAGE) >= sortedData.length}>
          Következő oldal
        </button>
      </Stack>
    </Grid>
  </Box>
  );
};


export default OkteloAI;

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import api from "utils/interceptor"
import { useState, useEffect, useCallback } from "react";
import { Typography } from "@mui/material";
import CampaignList from "components/common/CampaignList";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import apiconfig from "utils/apiconfig";


const Campaign = () => {
  document.title = "Kampányok | Oktelo";
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`${apiconfig.BACKEND_URI}/data/campaigns`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, []);

useEffect(() => {
  fetchUserData();
}, []);


if (loading) return (
  <Box style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
  <CircularProgress sx={{color: "#925FFF", fontSize: "32px"}} />
  </Box>
);

if (data && data.length > 0)
  return (
    <Box width="100%">
        <CampaignList campaigns={data} refetch={fetchUserData}/>
    </Box>
  )
else {
  return (
    <Stack width="100%" direction="column" gap={1.5} alignItems="center" mt={2.5}>
      <Typography className="t_oktelo_semibold size_20 darkblue">
        Úgy tűnik, hogy nincsenek kampányaid
      </Typography>
      <Typography className="t_oktelo_normal size_14 darkblue">
        A kampányok segítenek rendszerezni a bejegyzéseid közzétételét és biztosítják a folyamatos teljesítmény-figyelést.
      </Typography>
      <button className="home-action-button b925"
      onClick={() => navigate('/campaign/create')}>
        <AddCircleOutlineOutlined/>
        Kampány létrehozása
      </button>
    </Stack>
  )
}

}

export default Campaign
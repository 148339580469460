import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

// Define the types
interface MetricDataValues {
    date: string;
    value: number | Record<string, number>;
}

interface DataPoint {
    x: string;
    y: number;
}

interface AnalyticsTreemapChartProps {
    title: string;
    chartData: { [key: string]: MetricDataValues[] };
}

const calculateColorRanges = (data: DataPoint[]) => {
    const allValues = data.map(d => d.y);
    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);

    const lowThreshold = minValue + (maxValue - minValue) * 0.15;
    const mediumThreshold = minValue + (maxValue - minValue) * 0.50;
    const highThreshold = minValue + (maxValue - minValue) * 0.80;


    return [
        {
            from: minValue,
            to: lowThreshold,
            name: 'Low',
            color: '#925fff'
        },
        {
            from: lowThreshold + 0.001,
            to: mediumThreshold,
            name: 'Medium',
            color: '#b39fe5'
        },
        {
            from: mediumThreshold + 0.001,
            to: highThreshold,
            name: 'High',
            color: '#925fff'
        },
        {
            from: highThreshold + 0.001,
            to: maxValue,
            name: 'Peak',
            color: '#573999'
        }
    ];
};

const transformToDataPoints = (data: MetricDataValues[]): DataPoint[] => {
    return data.reduce((acc: DataPoint[], entry) => {
        if (typeof entry.value === 'number') {
            acc.push({ x: entry.date, y: entry.value });
        } else if (typeof entry.value === 'object') {
            Object.entries(entry.value).forEach(([name, value]) => {
                acc.push({ x: name, y: value });
            });
        }
        return acc;
    }, []);
};

const AnalyticsTreemapChart: React.FC<AnalyticsTreemapChartProps> = ({ title, chartData }) => {
    const series = chartData && Object.keys(chartData).map((key) => ({
        name: key,
        data: chartData[key].reduce((acc: DataPoint[], entry) => {
            if (typeof entry.value === 'number') {
                acc.push({ x: entry.date, y: entry.value });
            } else if (typeof entry.value === 'object') {
                Object.entries(entry.value).forEach(([name, value]) => {
                    acc.push({ x: name, y: value });
                });
            }
            return acc;
        }, [])
    }));

    const flattenedData = Object.values(chartData).flatMap(transformToDataPoints);
    const colorRanges = calculateColorRanges(flattenedData);

    const options: ApexOptions = {
        chart: {
            type: 'treemap',
            height: 450
        },
        title: {
            text: '',
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif'
            },
            formatter: (text, { seriesIndex, dataPointIndex, w }) => {
                const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
                return dataPoint ? `${dataPoint.x}\n${dataPoint.y}` : 'No data';
            }
        },
        plotOptions: {
            treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                colorScale: {
                    ranges: colorRanges
                }
            }
        },
        colors: ["#925fff"],
        tooltip: {
            y: {
                formatter: (val: number) => `${val}`
            }
        },
        noData: {
            text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: '#000',
                fontSize: '14px',
            }
        }
    };

    return (
        <ReactApexChart
            series={series}
            type="treemap"
            height={450}
            options={options}
        />
    );
};

export default AnalyticsTreemapChart;

import React, { useEffect, useState } from 'react'
import {Box, Grid, Stack, Typography} from "@mui/material";
import { Add, Remove, SendOutlined } from '@mui/icons-material';
import { Tag } from 'antd';
import api from 'utils/interceptor';
import apiconfig from 'utils/apiconfig';
import dayjs from 'dayjs';

interface Message {
  from: string;
  content: string;
  date: Date;
}

interface Ticket {
  _id: string;
  createdBy: string;
  subject: string;
  assignedTo: string | null;
  status: string;
  priority: string;
  resolvedAt: Date | null;
  messages: Message[];
  createdAt: Date;
  updatedAt: Date;
}

const Help = () => {
  document.title = "Segítség | Oktelo";

  const [isAddNewTicket, setAddNewTicket] = useState<boolean>(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [message, setMessage] = useState<string>('');
  const [subject, setSubject] = useState<string>('');

  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

  const getTickets = async ( ) => {
    try {
      const response = await api.get(`${apiconfig.BACKEND_URI}/ticket/self`);

      if (response.data){
        setTickets(response.data.tickets);
      }

    } catch (error) {
      console.log("error");
    }
  };

  const createTicket = async ( ) => {
    try {

      if(!subject || subject.length < 5 || !message || message.length < 30) {
        return;
      }

      const response = await api.post(`${apiconfig.BACKEND_URI}/ticket/create`, {
        subject: subject,
        content: message,
      });

      if (response.data){
        getTickets();
      }

    } catch (error) {
      console.log("error");
    }
  }

  useEffect(() => {
    getTickets();
  }, [])


  return (
    <Box width="100%">
      <Typography className="t_oktelo_bold size_24 darkblue" mb={2}>
        Segítség központ
      </Typography>
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Box className="oktelo_card">
            <Stack direction="column" gap={2} alignItems="flex-start">
            <Typography className="t_oktelo_medium size_18 darkblue">
              Segítség
            </Typography>
            <Typography className="t_oktelo_normal size_14 darkblue">
              Ha elakadtál bármelyik funkció használata közben, vagy csak többet szeretnél
              megtudni róluk, bármikor böngészheted az Oktelo dokumentációt, ahol videókat és
              szöveges leírásokat teszünk közzé.
            </Typography>
              <Stack direction="row" gap={1} alignItems="center">
                <button className="home-action-button bc6a" onClick={() => window.open('https://oktelo.com/docs/kezdo-lepesek/', '_blank')}>
                  Segítségközpont
                </button>
                <button className="home-action-button b925" onClick={() => window.open('https://oktelo.com/adatkezelesi-tajekoztato/', '_blank')}>
                  Adatkezelési tájékoztató
                </button>
                <button className="home-action-button b925" onClick={() => window.open('https://oktelo.com/adatmegorzesi-szabalyzat/', '_blank')}>
                  Adatfeldolgozási irányelvek
                </button>
                <button className="home-action-button b925" onClick={() => window.open('https://oktelo.com/altalanos-szerzodesi-feltetelek/', '_blank')}>
                  Általános Szerződési Feltételek
                </button>
                <button className="home-action-button b925" onClick={() => window.open('https://oktelo.com/oktelo-partnerprogram/', '_blank')}>
                  Partner program
                </button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xl={ selectedTicket ? 7 : 12} lg={selectedTicket ? 7 : 12} md={selectedTicket ? 6 : 12} sm={12} xs={12}>
          <Box className="oktelo_card">
            <Stack direction="column" gap={2} alignItems="flex-start" width="100%">
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Typography className="t_oktelo_semibold size_18 darkblue">
                  Ticketek
                </Typography>
                <button className="home-action-button b925" onClick={() => setAddNewTicket(!isAddNewTicket)}>
                  {!isAddNewTicket ? <Add/> : <Remove/>}
                  {isAddNewTicket ? 'Mégsem' : 'Új ticket'}
                </button>
              </Stack>
            {isAddNewTicket &&
              <Stack direction="column" gap={1.5} sx={{ width: '100%' }}>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Ticket tárgya, címe röviden ({subject.length}/60)
                </Typography>
                <input className={"oktelo_input_filter"} style={{ width: '100%', border: '1px solid #d9d9d9' }} value={subject} onChange={(e) => setSubject(e.target.value)}
                max={60} maxLength={60} placeholder='Általános segítségkérés'/>
                <Typography className="t_oktelo_medium size_14 darkblue">
                  Probléma, vagy kérdés részletes leírása ({message.length}/1024)
                </Typography>
                <textarea className={"oktelo_input_filter"} style={{ width: '100%', border: '1px solid #d9d9d9' }} value={message} onChange={(e) => setMessage(e.target.value)}
                  maxLength={1024} rows={8} placeholder='Részletes kérdés, probléma'/>
                <Typography className="t_oktelo_normal size_12 darkblue">
                  Kérjük, hogy a leírást részletesen fogalmazd meg, hogy egyből tudjunk rá válaszolni.
                  Ne adj meg privát információt, pl. bejelentkezési adatokat, hozzáféréseket.
                </Typography>
                <button className="home-action-button b925" onClick={createTicket}>
                  <SendOutlined/> Küldés
                </button>
              </Stack>
            }
            <Typography className="t_oktelo_normal size_14 darkblue">
              Az ügyfélszolgálatnak küldött segítségkéréseid.
            </Typography>
            <Stack direction="column" gap={2} sx={{ width: '100%'}}>
            {tickets && tickets.length > 0 ? tickets.map(ticket => (
            <Box className="ticket-container" width="100%" key={ticket._id} onClick={() => setSelectedTicket(ticket)}>
            <Stack direction="column" alignItems="flex-start" gap={1} sx={{ border: '1px solid #d9d9d9', width: '100%', padding: '12px', borderRadius: '0px', cursor: 'pointer'}}>
              <Tag color="success">{ticket.status}</Tag>
              <Typography className="t_oktelo_medium size_16 darkblue">
                {ticket.subject}
              </Typography>
              <Typography className="t_oktelo_normal size_14 darkblue">
                Létrehozva: {dayjs(ticket.createdAt).format('YYYY.MM.DD HH:mm')}
              </Typography>
              <Typography className="t_oktelo_normal size_14 darkblue">
                Munkatárs: {ticket.assignedTo || 'Még nincs kapcsolva'}
              </Typography>
            </Stack>
          </Box>

            )) :
            <Typography className="t_oktelo_normal size_14 darkblue">
              Nem találtunk Ticketet.
            </Typography>
            
            }
            </Stack>
            </Stack>
          </Box>
        </Grid>
        {selectedTicket && 
        <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
          <Box className="oktelo_card">
            <Stack direction="column" gap={2} alignItems="flex-start">
            <Typography className="t_oktelo_medium size_18 darkblue">
              Ticket
            </Typography>
            <Typography className="t_oktelo_normal size_14 darkblue">
              {selectedTicket.subject}
            </Typography>
                  {selectedTicket.messages && selectedTicket.messages.length > 0 ?
                    selectedTicket.messages.map(message => (
                      <Stack direction="column" gap={0.5} sx={{ width: '100%', border: '1px soldi #d9d9d9', padding: '18px', borderRadius: '0px'}} key={message.date.toLocaleString()}>
                        <Typography className='t_oktelo_medium size_14 darkblue'>
                          {message.from} <span className="t_oktelo_normal">{dayjs(message.date).format('YYYY.MM.DD HH:mm')}</span>
                        </Typography>
                        <Typography className='t_oktelo_medium size_14 darkblue'>
                          {message.content}
                        </Typography>
                      </Stack>
                    ))
                    :
                    <Typography className="t_oktelo_normal size_14 darkblue">Nincsenek üzenetek.</Typography>
                  }
            </Stack>
          </Box>
        </Grid>}
      </Grid>
    </Box>
  )
}

export default Help
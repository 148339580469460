import { SocialPostProps } from "../../../interfaces/oktelo"
import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Tag } from "antd";
import { EyeOutlined, HeartOutlined, UserOutlined, CommentOutlined, TrophyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { logo } from "../../../assets";
import { useNavigate } from "react-router-dom";

interface HomeSocialPostDataProps {
    posts: SocialPostProps[];
  }

  const HomeSocialPostData: React.FC<HomeSocialPostDataProps> = ({ posts }) => {
    const navigate = useNavigate();
    const groupPostsByDay = (posts: SocialPostProps[]) => {
        const daysOfWeek = ['hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat', 'vasárnap'];
        const daysColors = ['#925FFF', '#FE6F47', '#598dfa', '#3da564', '#c6ff00', '#154a54', '#d954a0']
        const groupedPosts: { [key: string]: { posts: SocialPostProps[], color: string, totals: { impressions: number, clicks: number, comments: number, reactions: number }, performance: number } } = {};
      
        // Initialize groupedPosts with arrays for each day
        daysOfWeek.forEach((day, index) => {
            groupedPosts[day] = { posts: [], color: daysColors[index], totals: { impressions: 0, clicks: 0, comments: 0, reactions: 0 }, performance: 0 };
          });
      
          posts && posts.length > 0 && posts.forEach(post => {
            const createdDate = new Date(post.created_time);
            const dayOfWeek = createdDate.toLocaleDateString('hu-HU', { weekday: 'long' });
      
            if (groupedPosts[dayOfWeek]) {
              groupedPosts[dayOfWeek].posts.push(post);
              groupedPosts[dayOfWeek].totals.impressions += post.impressions || 0;
              groupedPosts[dayOfWeek].totals.clicks += post.clicks || 0;
              groupedPosts[dayOfWeek].totals.comments += post.comments || 0;
              groupedPosts[dayOfWeek].totals.reactions += post.reactions || 0;
              groupedPosts[dayOfWeek].performance += (post.impressions || 0) + (post.clicks || 0) + (post.comments || 0) + (post.reactions || 0);
            } else {
              console.warn(`Day '${dayOfWeek}' not found in daysOfWeek or groupedPosts.`);
            }
          });
      
          return groupedPosts;
      };
      
      // Group posts by day
      const groupedPosts = groupPostsByDay(posts);

      const dayWithMostPerformance = Object.entries(groupedPosts).reduce((maxDay, [day, data]) => {
        return data.performance > (groupedPosts[maxDay]?.performance || 0) ? day : maxDay;
      }, '');
  
      return (
        <Grid container spacing={2}>
        {Object.entries(groupedPosts).map(([day, { posts, color, totals }]) => (
            <Grid item key={day} xs={12} sm={6} md={4} lg={4} xl={1.714285714}>
              <Box sx={{ backgroundColor: 'transparent', width: '100%'}}>
                <Stack sx={{  marginBottom: '3px', padding: '8px', width: '100%', borderBottom: '2px solid #d9d9d9' }} direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    <Stack direction="row" width="100%">
                    {dayWithMostPerformance === day && <TrophyOutlined style={{ color: '#925FFF', marginRight: '8px' }} />}
                    <Typography className="t_oktelo_semibold size_16 darkblue">{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
                    </Stack>
                <Stack direction="row" alignItems="center" gap={1.2} flexWrap="wrap">
                    <Typography className="t_oktelo_normal size_12 darkblue"><EyeOutlined/> {totals.impressions}</Typography>
                    <Typography className="t_oktelo_normal size_12 darkblue"><UserOutlined/> {totals.clicks}</Typography>
                    <Typography className="t_oktelo_normal size_12 darkblue"><CommentOutlined/> {totals.comments}</Typography>
                    <Typography className="t_oktelo_normal size_12 darkblue"><HeartOutlined/> {totals.reactions}</Typography>
                </Stack>
                </Stack>
                <Stack direction="column" width="100%" gap={2} p={1} height={{lg: '500px', xs: '400px'}} sx={{ overflowY: posts.length > 0 ? 'scroll' : 'hidden', backgroundColor: '#fff'}} className="post_home_container">
                  {posts.map(post => (
                    <>
                    <Stack key={post.id} className="post_home" sx={{ position: 'relative', cursor: 'pointer', ':hover': { opacity: '0.9'} }}
                      onClick={() => navigate(`/postanalytics/${post.id}`, { state: { post, sp: post.page_id, platform: post.platform } })}>
                      <img src={post.attachment_image} style={{ objectFit: 'cover', height: '240px', width: '100%', borderRadius: '0px' }} alt={post.message.slice(0,40)} />
                      <Tag
                      color="purple"
                      bordered={false}
                      className="tag_oktelo size_12 t_oktelo_medium"
                      style={{ position: 'absolute', right: '12px', top: '12px' }}
                      icon={<EyeOutlined/>}>
                        {post.impressions !== 0? post.impressions : '0'}
                    </Tag>
                        {post.is_oktelo && <img src={logo} style={{ position: 'absolute', top: '12px', left: '12px', width: '16px', height: '16px' }}/>}
                        <Box sx={{position: 'absolute', width: '100%', borderRadius: '0px 0px 0px 0px', backgroundColor: 'rgba(146,95,255, 0.8)', bottom: 0, left: 0, padding: '6px', backdropFilter: 'blur(2px)'}}>
                          <Stack direction="row" gap={1} alignItems="center">
                            <Typography className="t_oktelo_medium size_12 t_white">
                              {dayjs(post.created_time).format('HH:mm')}
                            </Typography>
{/*                             <Typography className="t_oktelo_normal size_12 t_white">
                              {post.message?.slice(0,32)}..
                            </Typography> */}
                          </Stack>
                        </Box>
                    </Stack>
                    </>
                  ))}
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    };

export default HomeSocialPostData;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import api from "utils/interceptor"
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/hu';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { Badge, Button, Drawer, List } from "antd";
import NotificationsNoneOutlined from '@mui/icons-material/NotificationsNoneOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Circle, Close, Delete, Notifications, Visibility } from '@mui/icons-material';
import { useNotification } from '@refinedev/core';
import apiconfig from "utils/apiconfig";

dayjs.extend(relativeTime);
dayjs.locale('hu');

interface NotificationProps {
  _id: string;
  title: string;
  date: Date;
  importance: number;
  label: string;
  type: string;
  message?: string;
  attachment?: string;
  object: string;
}

const NotificationSystem: React.FC = () => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [visible, setVisible] = useState(false);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const { open } = useNotification();

  const handleNotificationIconClick = () => {
    setVisible(!visible);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get(`${apiconfig.BACKEND_URI}/data/notifications`);
      const { notifications } = response.data;
      setNotifications(notifications);
      setUpcomingCount(notifications.length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, []);

  const deleteNotification = async (id: string) => {
    try {
      
      if (!id) { return }

      const response = await api.delete(`${apiconfig.BACKEND_URI}/notification/${id}`);

      if (response.status === 200){
        open?.({
          type: 'success',
          message: 'Értesítés törölve!'
        })
        setUpcomingCount(prevCount => prevCount - 1);
        return fetchData();
      }

    } catch (error) {
      open?.({
        type: 'error',
        message: 'Nem sikerült törölni az értesítést!'
      })
    }
  }

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 300000); 
    return () => clearInterval(intervalId);
  }, [fetchData]);

  return (
    <Box position="relative" sx={{ width: '100%' }}>
      <NotificationIconBox
        upcomingCount={upcomingCount}
        visible={visible}
        handleNotificationIconClick={handleNotificationIconClick}
      />
      <Drawer
        title={`Értesítések (${notifications && notifications.length})`}
        placement="right" 
        onClose={handleNotificationIconClick}
        open={visible} 
        width={400}  
        zIndex={9999}
      >    
        {notifications && notifications.map(notify => (
          <Stack direction="column" width="100%" gap={1} key={notify._id} p={1}
          sx={{ mb: 1.5 }}
          >
            <Typography className="t_oktelo_normal size_12" sx={{ color: '#989898'}}>
              {dayjs(notify.date).format('MM.DD, dddd, HH:mm')}
            </Typography>
            <Stack direction="row" alignItems="center" gap={0.5}>
              {notify.attachment &&
                <Avatar sx={{ width: '34px', height: '34px'}}
                src={notify.attachment}/>
              }
            <Typography className="t_oktelo_normal size_14 miscgray">
                <span className="t_oktelo_medium" style={{ color: notify.importance === 3 ? '#ce4257' : notify.importance === 2 ? '#538d22' : notify.importance === 1 ? '#219ebc' : '#925fff'}}>{notify.label}</span> történt a(z) <span className="t_oktelo_medium darkblue">{notify.title ? notify.title.slice(0,32) : 'Nincs szöveg'}...</span> tartalmadnál.
              </Typography>
            </Stack>
            {notify.message &&
            <Stack direction="row" p={1.5} sx={{ backgroundColor: '#f6f6f6' }}>
              <Typography className="t_oktelo_normal size_14 darkblue">
                {notify.message}
              </Typography> 
            </Stack>
            }
            <Stack direction="row" gap={1} alignItems="center" mt={1}>
              <button className="home-action-button bc6a"><Visibility/></button>
              <button className="home-action-button b9d9" onClick={() => deleteNotification(notify._id)}><Close/></button>
            </Stack>
          </Stack>
        ))}
      </Drawer> 
    </Box> 
  );
};

const NotificationIconBox: React.FC<{
  upcomingCount: number;
  visible: boolean;
  handleNotificationIconClick: () => void;
}> = ({ upcomingCount, handleNotificationIconClick, visible }) => (

    visible ? (
      <ArrowDropUpIcon onClick={handleNotificationIconClick} sx={{ fontSize: '24px', lineHeight: '24px', color: '#989898' }} />
    ) : (
      <Stack direction="row" gap={0} alignItems="center" onClick={handleNotificationIconClick}>
        <Notifications  style={{ fontSize: '24px', color: '#fff' }} />
        <Typography className="t_oktelo_normal text-monster t_white size_14">
          {upcomingCount > 10 ? '10+' : upcomingCount}
        </Typography>
      </Stack>

    )

);

export default NotificationSystem;

import { ApexOptions } from 'apexcharts';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

// Define interfaces for chart data
interface MetricData {
    date: string;
    value: number | Record<string, number>;
}

interface MetricDataValues {
    [key: string]: MetricData[];
}

interface MetricGroup {
    name: string;
    metrics: MetricDataValues;
}

interface ChartData {
    [metricName: string]: MetricData[];
}

interface ChartProps {
    title: string;
    chartData: ChartData; // Adjusted to fit expected data structure
}

const metricNameMapping: { [key: string]: string } = {
    haha: "Vicces",
    love: "Imádom",
    anger: "Dühítő",
    wow: "Hűha",
    like_care: "Tetszik/Ölelés",
    sorry: "Szomorú",

}

const AnalyticsBarChart: React.FC<ChartProps> = ({ title, chartData }) => {
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});
    useEffect(() => {
        if (chartData) {
            // Extract series data
            const seriesData = Object.keys(chartData).map(metricName => ({
                name: metricNameMapping[metricName] || metricName,
                data: chartData[metricName].map(item => typeof item.value === 'number' ? item.value : 0),
            }));

            // Extract x-axis categories from the first metric
            const firstMetricValues = chartData[Object.keys(chartData)[0]];
            const optionsData: ApexOptions = {
                xaxis: {
                    categories: firstMetricValues.map(value => dayjs(value.date).format('MM.DD.')),
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                },
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                      horizontal: false,
                      dataLabels: {
                        total: {
                          enabled: true,
                          offsetX: 0,
                          style: {
                            fontSize: '14px',
                            fontWeight: 500
                          }
                        }
                      }
                    },
                  },
                colors: ["#925fff", "#BE3144", "#F8D082", "#F27BBD", "#0766AD", "#A4CE95"],

                markers: {
                    size: 3
                },
                grid: {
                    show: true,
                },
                stroke: {
                    show: true,
                   curve: 'smooth',
                    lineCap: 'butt',
                    width: 1,
                    dashArray: 0,
                    colors: ['#fff']
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                tooltip: {
                    y: {
                        formatter(val: number) {
                            return `${val}`;
                        },
                    },
                },
                noData: {
                    text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        color: '#000',
                        fontSize: '14px',
                    }
                }
            };

            setSeries(seriesData);
            setOptions(optionsData);
        }
    }, [chartData]);

    return (
        <ReactApexChart
            series={series}
            type="bar"
            height={450}
            options={options}
        />
    );
};

export default AnalyticsBarChart;

import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';
import { ApexOptions } from 'apexcharts';

// Define the MetricValue type
interface MetricValue {
    total?: number;
    paid?: number;
    unpaid?: number;
    mention?: number;
    page_post?: number;
    checkin?: number;
    coupon?: number;
    event?: number;
    question?: number;
    user_post?: number; 
    other?: number;
    fan?: number;
    [key: string]: number | undefined;
}

// Define the MetricData type
interface MetricData {
    date: string;
    value: MetricValue;
}

// Define the ChartData type
interface ChartData {
    [metricName: string]: MetricData[];
}

// Define the ChartProps type
interface ChartProps {
    title: string;
    chartData: ChartData; // Data structure with multiple metrics
}

const metricNameMapping: { [key: string]: string } = {
    total: "Teljes",
    paid: "Fizetett",
    unpaid: "Organikus",
    checkin: "Oldal checkin",
    fan: "Oldal like",
    coupon: "Ajánlat követelések",
    event: "RSVP az eseményre",
    mention: "Említések",
    'page_post': "Oldal posztok",
    question: "Kérdés válaszok",
    'user_post': "Posztok másoktól",
    other: "Egyéb"
};

const AnalyticsLineChart: React.FC<ChartProps> = ({ title, chartData }) => {
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});

    useEffect(() => {
        if (chartData) {
            // Extract dates for x-axis
            const dates = Object.keys(chartData).length > 0 
                ? chartData[Object.keys(chartData)[0]].map(item => dayjs(item.date).format('MM.DD.')) 
                : [];

            // Create series data dynamically
            const seriesData: { name: string; data: number[] }[] = [];

            // Collect all possible metric names
            const metricNames = Object.keys(chartData).reduce((acc, metricKey) => {
                const metricValues = chartData[metricKey][0]?.value ?? {};
                return [...acc, ...Object.keys(metricValues)];
            }, [] as string[]);

            // Generate series data for each metric
            metricNames.forEach(metricName => {
                seriesData.push({
                    name: metricNameMapping[metricName] || metricName,
                    data: Object.values(chartData).flat().map(item => item.value[metricName] ?? 0),
                });
            });

            // Chart options
            const optionsData: ApexOptions = {
                xaxis: {
                    categories: dates,
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                },

                dataLabels: {
                    enabled: false,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                },
                 colors: [
                    "#925FFF", // Purple
                    "#FAB74A", // Orange
                    "#7BC9FF", // Light Blue
                    "#FF6F61", // Coral
                    "#4CAF50", // Green
                    "#fa2583", // Amber
                    "#9C27B0", // Purple
                    "#03A9F4", // Light Blue
                    "#58ff33"  // Blue
                ],
                
                markers: {
                    size: 5,
                    hover: {
                        sizeOffset: 6
                    }
                },
                grid: {
                    show: true,
                },
                stroke: {
                    width: [3, 3, 3],
                    curve: 'smooth',
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                },
                tooltip: {
                    y: {
                        formatter(val: number) {
                            return `${val}`;
                        },
                    },
                },
                noData: {
                    text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        color: '#000',
                        fontSize: '14px',
                    }
                }
            };

            setSeries(seriesData);
            setOptions(optionsData);
        }
    }, [chartData]);

    return (
        <ReactApexChart
            series={series}
            type="line"
            height={450}
            options={options}
        />
    );
};

export default AnalyticsLineChart;

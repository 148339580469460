import React, { useEffect, useState } from "react";
import { OkteloPostProps } from "interfaces/oktelo";
import api from "utils/interceptor"
import dayjs, { Dayjs } from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Stack,
  Grid,
  CircularProgress,
  Avatar,
  Link,
  IconButton,
} from "@mui/material";
import { useNotification } from "@refinedev/core";
import {
  LinkPlaceholder,
  TextPlaceholder,
  VideoPlaceholder,
  okt_placeholer,
} from "assets";
import {
  AccountCircleOutlined,
  AdsClickOutlined,
  Assistant,
  AutoAwesome,
  CalendarMonthOutlined,
  Campaign,
  CancelOutlined,
  Check,
  CheckCircleOutline,
  Close,
  ContentCopyOutlined,
  Delete,
  DeleteOutline,
  Edit,
  EditOutlined,
  Facebook,
  FileCopy,
  GroupAddOutlined,
  InfoOutlined,
  Insights,
  Instagram,
  LinkOutlined,
  NewReleasesOutlined,
  OfflineBoltOutlined,
  PendingOutlined,
  People,
  PersonPin,
  PlayArrow,
  PlayArrowOutlined,
  Public,
  PublicOutlined,
  RssFeed,
  SettingsOutlined,
  TipsAndUpdatesOutlined,
} from "@mui/icons-material";
import { Tag, Tooltip } from "antd";
import { 
  analyzeEmotionalWords, 
  analyzeHashtagUsage, 
  analyzeTextLength, 
/*   analyzeEmojiUsage,  */
  analyzeCallToAction, 
  analyzeRepetitiveWords, 
  analyzeSchedulingTime 
} from '../../utils/postAnalyticsManual';

import "./posts.css";
import apiconfig from "utils/apiconfig";
import { DatePicker } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

interface PostApproval{
  _id: string;
  title: string;
  comments: [{
    _id: string;
    from: string;
    message: string;
    date: Date;
    type: string;
  }]
}

const PostViewDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { open } = useNotification();
  const [post, setPost] = useState<OkteloPostProps>();
  const [postApproval, setPostApproval] = useState<PostApproval>();
  const [isLoading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState("mobile");
  const [parentPhoto, setParentPhoto] = useState<string | undefined>(undefined);
  const [okteloAiText, setOkteloAiText] = useState<string>('');
  const [isOkteloAiLoading, setOkteloAiLoading] = useState(false);
  const [isLightboxOpen, setLightboxOpen] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const handleOpenLightbox = () => {
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
  };

  /* RATINGS */
  const [emotionalWordsRating, setEmotionalWordsRating] = useState('');
  const [hashtagRating, setHashtagRating] = useState('');
  const [textLengthRating, setTextLengthRating] = useState('');
/*   const [emojiUsageRating, setEmojiUsageRating] = useState(''); */
  const [ctaRating, setCtaRating] = useState('');
  const [repetitiveWordsRating, setRepetitiveWordsRating] = useState('');
  const [schedulingTimeRating, setSchedulingTimeRating] = useState('');

  useEffect(() => {
    if (post) {
        const { message, calendarDate, platform, hashtagTemplate } = post;

        let textToAnalyize = '';

        if (hashtagTemplate){
          textToAnalyize = (message || '') + hashtagTemplate.hashtags.join(' ');
        } else {
          textToAnalyize = message || '';
        }

        setEmotionalWordsRating(analyzeEmotionalWords(textToAnalyize));
        setHashtagRating(analyzeHashtagUsage(textToAnalyize));
        setTextLengthRating(analyzeTextLength(textToAnalyize, platform));
/*         setEmojiUsageRating(analyzeEmojiUsage(textToAnalyize)); */
        setCtaRating(analyzeCallToAction(textToAnalyize));
        setRepetitiveWordsRating(analyzeRepetitiveWords(message || ''));
        setSchedulingTimeRating(analyzeSchedulingTime(new Date(calendarDate)));
    }
}, [post]);

useEffect(() => {
  if (isLightboxOpen) {
    // Disable background scroll
    document.body.style.overflow = 'hidden';
  } else {
    // Re-enable background scroll
    document.body.style.overflow = '';
  }
  
  // Cleanup to reset overflow on component unmount
  return () => {
    document.body.style.overflow = '';
  };
}, [isLightboxOpen]);

  const getPostDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${apiconfig.BACKEND_URI}/data/show/${id}`);

      if (response.status === 200) {
        setPost(response.data.post);
        setPostApproval(response.data.approval);
        if (response.data.parent) {
          setParentPhoto(response.data.parent);
        }
      } else {
        open?.({
          type: "error",
          message: "Hiba történt.",
        });
        return;
      }
    } catch (error) {
      console.log("hiba: ", error);
    } finally {
      setLoading(false);
    }
  };

  const analyzePost = async () => {
    try {
      setOkteloAiLoading(true);
      if (!post) { return; }

      const sendAttachment = post.attachment.type === 'photo' ?
      post.attachment.url?.photoUrl :
      (post.attachment.type === 'video' || post.attachment.type === 'reels') ?
      post.attachment.url?.thumbnailUrl :
      (post.attachment.type === 'carousel' && post.attachment.url?.carouselUrls && post.attachment.url?.carouselUrls.length > 0) ?
      post.attachment.url?.carouselUrls[0] :
      null;
      
      const response = await api.post(`${apiconfig.BACKEND_URI}/ai/okteloaipost`, {
        message: post.message || '- nincs szöveg',
        attachment: returnAttachmentText(post.attachment.type),
        attachmentImage: sendAttachment,
        parent: post.parent?.name || '- nincs parent oldal',
        platform: post.platform,
        hashtags: post.hashtagTemplate?.hashtags.join(' ') || '- nincs template (csak szövegben) -',
        calendarDate: post.calendarDate,
        firstComment: post.firstComment?.message || '- nincs első komment -'
      })

      if (response.status === 200){
        setOkteloAiText(response.data.result);
      } else if (response.data.message === 'out_of_oktelo_tokens' || response.status === 403){
        open?.({
          type: 'error',
          message: 'Elfogytak az Oktelo tokenjeid. Frissítés minden éjfélkor.'
        })
      }

    } catch (error: any) {
      console.log(error.message);
      if (error.response.status === 403){
        return open?.({
          type: 'error',
          message: 'Elfogytak az Oktelo tokenjeid. Frissítés minden éjfélkor.'
        })
      }
    }
    finally {
      setOkteloAiLoading(false);
      dispatchEvent(new Event("storage"));
      const element = document.getElementById('oktelo_ai_elemzes');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }


  const deletePost = async () => {
    try {
      if (!post) return;
      const response = await api.delete(`${apiconfig.BACKEND_URI}/posts/${post._id}`)

      if(response.status === 200){
        open?.({
          type: "success",
          message: "Sikeresen törölted a bejegyzést"
        })
        navigate('/posts')
      } else {
        open?.({
          type: "error",
          message: "Hiba történt a poszt törlésénél"
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const duplicatePost = async () => {
    try {
      if (!post) return;
      const response = await api.post(`${apiconfig.BACKEND_URI}/posts/copy/${post._id}`)

      if(response.status === 200){
        open?.({
          type: "success",
          message: "Sikeresen duplikáltad a bejegyzést."
        })
        navigate('/posts')
      } else {
        open?.({
          type: "error",
          message: "Hiba történt a poszt duplikálásánál."
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPostDetails();
  }, []);

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if(!url) return false
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video" || type === "reels") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  const convertToHtml = (text: any) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert **bold** to <strong>
      /* .replace(/\n/g, '<br />'); */ // Convert new lines to <br>
  };

  const ApprovalIconMap: Record<string, JSX.Element> = {
    approved: <CheckCircleOutline sx={{ fontSize: "14px" }} />,
    pending: <PendingOutlined sx={{ fontSize: "14px" }} />,
    rejected: <CancelOutlined sx={{ fontSize: "14px" }} />,
    noNeed: <OfflineBoltOutlined sx={{ fontSize: "14px" }} />,
    default: <OfflineBoltOutlined sx={{ fontSize: "14px" }} />,
  };

  const ApprovalTextMap: Record<string, string> = {
    approved: "Jóváhagyott",
    pending: "Függőben",
    rejected: "Elutasított",
    noNeed: "Nem szükséges",
    default: "Nincs adat",
  };

  const StatusTextMap: Record<string, string> = {
    draft: "Vázlat",
    calendarDraft: "Naptárba helyezve",
    scheduledWithPublish: "Ütemezett közzététel",
    published: "Közzétéve",
    errorWithPublishing: "Hiba a közzétételnél",
    unPublished: "Visszavont közzététel",
    default: "Nem elérhető",
  };

  const AttachmentTextMap: Record<string, string> = {
    textOnly: "Szöveg",
    photo: "Kép",
    video: "Videó",
    reels: "Reels",
    carousel: "Galéria",
    webUrl: "Hivatkozás",
    story: "Történet",
    default: "Nem elérhető",
  };

  const TagColorMap: Record<string, string> = {
    approved: "green",
    pending: "gold",
    rejected: "red",
    noNeed: "magenta",
    draft: "magenta",
    calendarDraft: "volcano",
    scheduledWithPublish: "gold",
    published: "green",
    unPublished: "red",
    errorWithPublishing: "red",
    default: "purple",
  };

  const returnApprovalIcon = (type: string) =>
    ApprovalIconMap[type] || ApprovalIconMap.default;
  const returnStatusText = (type: string) =>
    StatusTextMap[type] || StatusTextMap.default;
  const returnAttachmentText = (type: string) =>
    AttachmentTextMap[type] || AttachmentTextMap.default;
  const returnApprovalText = (type: string) =>
    ApprovalTextMap[type] || ApprovalTextMap.default;
  const returnTagColor = (type: string) =>
    TagColorMap[type] || TagColorMap.default;

  const returnApprovalCommentType = (type: string) => {
    if (type === "approve") {
      return "Jóváhagyás"
    } else if (type === "comment") {
      return "Megjegyzés"
    } else if (type === "reject") {
      return "Elutasítás"
    }
  }

  if (isLoading) {
    return (
      <Box
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
      </Box>
    );
  }

  if (post) {
    document.title = post.message
      ? post.message.slice(0, 12) + ".. | Oktelo"
      : post.platform + " | Oktelo";

    return (
      <Box width="100%">
              {/* Lightbox Implementation */}
      {isLightboxOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(4px)',
            zIndex: 1000,
            overflow: 'hidden',
            pointerEvents: 'auto'
          }}
          onClick={handleCloseLightbox}
        >
          <Box
            sx={{
              position: 'relative',
              width: {xs: '90%', sm: '80%', md: '400px'},
              maxWidth: '800px',
              backgroundColor: '#000',
              borderRadius: '0px',
              overflow: 'hidden',
              zIndex: 1001,
            }}
            onClick={(e) => e.preventDefault()}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#fff',
                zIndex: 1003,
              }}
              onClick={handleCloseLightbox}
            >
              <Close />
            </IconButton>
            <video
              controls
              style={{ width: '100%', height: 'auto', zIndex: 1002 }}
              src={post.attachment.url?.videoUrl}
              autoPlay={isLightboxOpen}
            />
          </Box>
        </Box>
      )}
      {isPopupOpen && (
        <div className="custom_popup-overlay">
        <ViewPublishSettings
          calendarDate={post.calendarDate || null}
          publishDate={post.publish.date || null}
          currentStatus={returnStatusText(post.status)}
          currentStatus_={post.status}
          postId={post._id}
          onClose={closePopup}
        />
      </div>
      )}
        <Grid container spacing={2} mb={5}>
          <Grid item xl={9}>
            <Grid container spacing={3}>
              <Grid item xl={6}>
                <Box className="post-holder-card" height="100%">
                  <Stack direction="column" gap={3} width="100%">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Stack direction="row" alignItems="center" gap={1}>
                      {post.platform === "instagram" ?
                        <Instagram sx={{ color: '#E4405F'}}/>
                          :
                        <Facebook sx={{ color: '#1e90ff' }}/>
                        }
                        <img
                          src={parentPhoto || okt_placeholer}
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />  
                        <Typography className="t_oktelo_medium size_16 darkblue">
                          {post.parent?.name || 'Nem kapcsolt'}
                        </Typography>

                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <button className="post-settings-smallbtn" onClick={() => duplicatePost()}>
                          <FileCopy/>
                        </button>
                        <button className="post-settings-smallbtn">
                          <Edit/>
                        </button>
                        <button className="post-settings-smallbtn post-delete-btn" onClick={() => deletePost()}>
                          <Delete/>
                        </button>
                      </Stack>
                    </Stack>
                    <Stack direction="row" sx={{
  width: '100%',
  maxHeight: '400px',
  height: '400px',
  backgroundColor: '#f9f9f9',
  position: 'relative',
}}>
  <img
    src={hImageSrc(
      post.attachment.type,
      post.attachment.url
    )}
    style={{
      width: "100%",
      height: "100%",
      position: 'relative',
      objectFit:
        post.platform === "instagram" ? "contain" : "contain",
    }}
  />
  {(post.attachment.type === "video" || post.attachment.type === "reels" || post.attachment.type === "webUrl") &&
    <Box sx={{ 
      position: 'absolute', 
      borderRadius: '50%', 
      backgroundColor: 'rgba(255,255,255,0.4)', 
      width: '60px', 
      height: '60px', 
      left: '50%', 
      top: '50%', 
      transform: 'translate(-50%, -50%)', // Center the box
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
    }}
      onClick={() => {

        {(post.attachment.type === "video" || post.attachment.type === "reels") ?
          handleOpenLightbox()
          :
          window.open(`${post.attachment.url?.webUrl}`, '_blank', "width=640,height=480")
         }


      }}
    
    >
      {post.attachment.type === "webUrl" ?
        <Link sx={{ fontSize: '40px', color: '#925FFF' }}/>
      :
        <PlayArrow sx={{ fontSize: '40px', color: '#925FFF' }}/>
      }
    </Box>
  }
</Stack>


                    <Stack direction="column" width="100%" gap={1.5}>
                      <Typography className="t_oktelo_light size_14 miscgray" sx={{ overflowWrap: 'break-word', overflowY: 'hidden', whiteSpace: 'pre-wrap' }}>
                        {post.message || ''}
                      </Typography>
                      <Typography
                    className="t_oktelo_normal size_14 t_link"
                    sx={{ textAlign: "left", overflowWrap: 'break-word', overflowY: 'hidden', whiteSpace: 'pre-wrap' }}
                  >
                    {post.hashtagTemplate && 
                      post.hashtagTemplate.hashtags.join(' ')
                    }
                  </Typography>
                    </Stack>
                    <Stack direction="column" gap={1} width="100%">
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        Első komment ({post.firstComment?.enabled ? 'Bekapcsolva' : 'Kikapcsolva'})
                      </Typography>
                      {post.firstComment?.enabled &&
                        <Box width="100%" sx={{ backgroundColor: '#f9f9f9', borderRadius: '0px', padding: '10px' }}>
                          <Typography className="t_oktelo_light size_14 miscgray">
                            {post.firstComment.message || ''}
                          </Typography>
                        </Box>
                      }
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xl={6}>
                <Box className="post-holder-card">
                  <Stack direction="column" gap={2} width="100%">
                    <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                      <Stack direction="row" gap={1} alignItems="center">
                        <Avatar sx={{ width: '40px', height: '40px', backgroundColor: '#f9f9f9' }}>
                          <Check sx={{ color: '#1e1e47' }}/>
                        </Avatar>
                        <Typography className="t_oktelo_medium size_16 darkblue">
                          Jóváhagyás
                        </Typography>
                      </Stack>
                      <Box className={`approval-tag ${post.approval.status === "approved" ? 'success-tag' : post.approval.status === "pending" ? 'comment-tag' : post.approval.status === 'rejected' ? 'reject-tag' : 'noneed-tag'}`}>
                        {post.approval.status === "approved" ? 'Jóváhagyva'
                        : post.approval.status === "rejected" ? 'Elutasítva'
                        : post.approval.status === "pending" ? 'Függőben' :
                        'Nem szükséges'}
                      </Box>
                    </Stack>
                    {postApproval && postApproval._id &&
                      <>
                        <Stack direction="row" gap={1} alignItems="center">
                          <Typography className="t_oktelo_normal size_14 darkblue">
                              Jóváhagyás: 
                          </Typography>
                          <Typography className="t_oktelo_light size_14 miscgray">
                              {postApproval.title}
                          </Typography>
                        </Stack>
                        {postApproval.comments && postApproval.comments.length > 0 &&
                        <Stack direction="column" gap={1}>
                          <Typography className="t_oktelo_normal size_14 darkblue">
                            Legfrissebb változás:
                          </Typography>
                          <Box sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '0px' }}>
                          <Typography className="t_oktelo_normal size_14 darkblue">
                            {postApproval.comments[postApproval.comments.length - 1].from + 
                            ", " + 
                            returnApprovalCommentType(postApproval.comments[postApproval.comments.length - 1].type) +
                            ", " +
                            dayjs(postApproval.comments[postApproval.comments.length - 1].date).format('YYYY.MM.DD HH:mm')
                            }
                          </Typography>
                          <Typography className="t_oktelo_light size_14 miscgray">
                            {postApproval.comments[postApproval.comments.length - 1].message}
                          </Typography>
                          </Box> 
                        </Stack>
                        }
                      </>
                    }
                    {!postApproval &&
                      <Typography className="t_oktelo_light size_14 miscgray">
                        A bejegyzés nem szerepel jóváhagyásban. Ha szeretnéd, hogy valaki jóváhagyja ezt a posztot, készíts egyet.
                      </Typography>
                    }
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                      <button className="home-action-button b925"
                        onClick={() => {
                          postApproval ? navigate(`/previews/show/${postApproval._id}`) : navigate(`/approvals/create`);
                        }}
                      >
                        {postApproval ? 'Megnyitás' : 'Új jóváhagyás'}
                      </button>
                      {postApproval &&
                      <button className="home-action-button bc6a">
                        Jóváhagyom
                      </button>}
                    </Stack>
                  </Stack>
                </Box>
                <Box className="post-holder-card" mt={3}>
                  <Stack direction="column" gap={2} width="100%">
                    <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                      <Stack direction="row" gap={1} alignItems="center">
                        <Avatar sx={{ width: '40px', height: '40px', backgroundColor: '#f9f9f9' }}>
                          <Public sx={{ color: '#1e1e47' }}/>
                        </Avatar>
                        <Typography className="t_oktelo_medium size_16 darkblue">
                          Közzététel & ütemezés
                        </Typography>
                      </Stack>
                      <Box className={`approval-tag ${post.status === "published" ? 'success-tag' : post.status === "scheduledWithPublish" ? 'comment-tag' : post.status === 'calendarDraft' ? 'noneed-tag' : 'reject-tag'}`}>
                        {post.status === "published" ? 'Közzétéve'
                        : post.status === "scheduledWithPublish" ? 'Ütemezve'
                        : post.status === "calendarDraft" ? 'Csak naptárban' :
                        'Vázlat'}
                      </Box>
                    </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {post.status === "scheduledWithPublish" ? 'Ütemezett közzététel időpontja: '
                      : post.status === "published" ? 'Közzététel időpontja: '
                      : ' Nincs közzététel és ütemezés ennél a posztnál.' }
                      {(post.status === "scheduledWithPublish" || post.status === "published") &&
                        <span className="t_purple">
                          {post.publish.date ? dayjs(post.publish.date).format('YYYY.MM.DD HH:mm (dddd)') : 'Nincs'}
                        </span>}
                    </Typography>
                    {post.calendarDate &&
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        A posztot naptárba helyezted: {" "} <span className="t_purple">
                          {dayjs(post.calendarDate).format('YYYY.MM.DD HH:mm (dddd)')}
                        </span>
                      </Typography>
                    }
                    
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                      <button className="home-action-button b925" onClick={openPopup}>
                        Közzététel beállítások
                      </button>
                      <button className="home-action-button bc6a">
                        Közösségi posztok
                      </button>
                      {post.publish.isPublished &&
                      <button className={`home-action-button ${post.platform === "instagram" ? "be44" : "b1e9"}`}
                        onClick={() => 
                        {post.platform === "instagram" ?
                          window.open(`https://instagram.com/${post.parent?.name}`, '_blank')
                          :
                          window.open(`https://facebook.com/${post.publish.trackId}`, '_blank')
                        }
                        }>
                        {post.platform === "instagram" ? <Instagram/> : <Facebook/>}
                        Megnyitás
                      </button>
                      }
                    </Stack>
                  </Stack>
                </Box>
                <Box className="post-holder-card" mt={3}>
                  <Stack direction="column" gap={2} width="100%">
                    <Stack direction="row" gap={1} alignItems="center">
                        <Avatar sx={{ width: '40px', height: '40px', backgroundColor: '#f9f9f9' }}>
                          <AutoAwesome sx={{ color: '#1e1e47' }}/>
                        </Avatar>
                        <Typography className="t_oktelo_medium size_16 darkblue">
                          Extrák
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Campaign sx={{ fontSize: '16px' }}/>
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        Oktelo kampány:
                      </Typography>
                      <Typography className="t_oktelo_light size_14 miscgray">
                        {post.campaign ? post.campaign.title + " | " + post.campaign.status : 'Nincs'}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <People sx={{ fontSize: '16px' }}/>
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        Kollaboráció:
                      </Typography>
                      <Typography className="t_oktelo_light size_14 miscgray">
                        {post.extras?.collaborator ? post.extras.collaborator : 'Nincs'}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                      <PersonPin sx={{ fontSize: '16px' }}/>
                      <Typography className="t_oktelo_normal size_14 darkblue">
                        Megjelölések ({post.extras?.users?.length || '0'}): 
                      </Typography>
                      <Typography className="t_oktelo_light size_14 miscgray">
                      {post.extras?.users && post.extras.users.length > 0 &&
                          post.extras.users.map(u => (
                            u.username + " "
                          ))
                        }
                      </Typography>
                    </Stack>
                     <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
                      <button className="home-action-button b925" onClick={analyzePost}>
                        Oktelo AI elemzés
                      </button>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={3}>
            <Box className="post-holder-card" height="100%">
              <Stack direction="column" gap={2} width="100%" height="100%">
                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" gap={1} alignItems="center">
                    <Avatar sx={{ width: '40px', height: '40px', backgroundColor: '#f9f9f9' }}>
                      <Insights sx={{ color: '#1e1e47' }}/>
                    </Avatar>
                    <Typography className="t_oktelo_medium size_16 darkblue">
                      Poszt infók
                    </Typography>
                  </Stack>
                </Stack>
                <Box width="100%" sx={{ borderRadius: '0px', padding: '12px', backgroundColor: '#f9f9f9'}}>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    Oktelo címke: <span style={{ color: post.tag?.color || '#6C6C89'}} className="t_oktelo_light">{post.tag?.title || 'Nincs'}</span>
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    Csatolmány: <span className="t_oktelo_light miscgray">{returnAttachmentText(post.attachment.type)}</span>
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    Platform: <span className="t_oktelo_light miscgray">{post.platform}</span>
                  </Typography>
                  <Typography className="t_oktelo_normal size_14 darkblue">
                    Hashtag sablon: <span className="t_oktelo_light miscgray">{post.hashtagTemplate?.title || 'Nincs'}</span>
                  </Typography>
                </Box>
            <Box width="100%" sx={{ borderRadius: '0px', padding: '12px',
              height: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'space-evenly'
            }}>
            {/* Emotional Words */}
            <Stack direction="column" width="100%">
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Emocionális szavak: {" "}
                    <span className={`rate-${emotionalWordsRating === "Megfelelő" ? "good" : emotionalWordsRating === "Javítani való" ? "improvement" : "bad"}`}>
                        {emotionalWordsRating}
                    </span>
                </Typography>
                <Typography className="t_oktelo_light size_14 miscgray">
                    A bejegyzéseidben előforduló emocionális kifejezések. Érdemes legalább egyet megjeleníteni.
                </Typography>
            </Stack>

            {/* Hashtag Usage */}
            <Stack direction="column" width="100%">
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Hashtag használat: {" "}
                    <span className={`rate-${hashtagRating === "Megfelelő" ? "good" : hashtagRating === "Javítani való" ? "improvement" : "bad"}`}>
                        {hashtagRating}
                    </span>
                </Typography>
                <Typography className="t_oktelo_light size_14 miscgray">
                    Az ideális hashtag-használat biztosítja a bejegyzés jobb elérését.
                </Typography>
            </Stack>

            {/* Text Length */}
            <Stack direction="column" width="100%">
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Szöveg hossza: {" "}
                    <span className={`rate-${textLengthRating === "Megfelelő" ? "good" : textLengthRating === "Javítani való" ? "improvement" : "bad"}`}>
                        {textLengthRating}
                    </span>
                </Typography>
                <Typography className="t_oktelo_light size_14 miscgray">
                    A szöveg hossza optimális a platform számára.
                </Typography>
            </Stack>

            {/* Call to Action */}
            <Stack direction="column" width="100%">
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Call To Action: {" "}
                    <span className={`rate-${ctaRating === "Megfelelő" ? "good" : ctaRating === "Javítani való" ? "improvement" : "bad"}`}>
                        {ctaRating}
                    </span>
                </Typography>
                <Typography className="t_oktelo_light size_14 miscgray">
                    Egy jó CTA növeli az elköteleződést.
                </Typography>
            </Stack>

            {/* Repetitive Words */}
            <Stack direction="column" width="100%">
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Ismétlődő szavak: {" "}
                    <span className={`rate-${repetitiveWordsRating === "Megfelelő" ? "good" : repetitiveWordsRating === "Javítani való" ? "improvement" : "bad"}`}>
                        {repetitiveWordsRating}
                    </span>
                </Typography>
                <Typography className="t_oktelo_light size_14 miscgray">
                    Kerüld az ismétlődő szavakat a tisztább kommunikáció érdekében.
                </Typography>
            </Stack>

            {/* Scheduling Time */}
            <Stack direction="column" width="100%">
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Ütemezési idő: {" "}
                    <span className={`rate-${schedulingTimeRating === "Megfelelő" ? "good" : "improvement"}`}>
                        {schedulingTimeRating}
                    </span>
                </Typography>
                <Typography className="t_oktelo_light size_14 miscgray">
                    A bejegyzés időzítése optimalizált a legnagyobb elérés érdekében.
                </Typography>
            </Stack>
            </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid item xl={12}>
          <Box className="post-holder-card" height="100%">
              <Stack direction="column" gap={2} width="100%" height="100%">
                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" gap={1} alignItems="center">
                    <Avatar sx={{ width: '40px', height: '40px', backgroundColor: '#f9f9f9' }}>
                      <RssFeed sx={{ color: '#1e1e47' }}/>
                    </Avatar>
                    <Typography className="t_oktelo_medium size_16 darkblue">
                      Napló ({post.logs?.length})
                    </Typography>
                  </Stack>
                </Stack>
                {post.logs && post.logs.length > 0 ? (
              post.logs
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Sort logs by date in descending order
                .map((log) => (
                  <Stack
                    direction="column"
                    gap={0.5}
                    p={1}
                    key={dayjs(log.date).format("LLL")}
                    mb={2}
                    sx={{ borderRadius: "0px", backgroundColor: "#f6f6f6" }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography className="t_oktelo_semibold size_14 darkblue">
                        {log.email || "Ismeretlen"}
                      </Typography>
                      <Typography
                        className="t_oktelo_light size_12"
                        sx={{ color: "#606060" }}
                      >
                        {dayjs(log.date).format("YYYY.MM.DD HH:mm")}
                      </Typography>
                    </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                      {log.message}
                    </Typography>
                  </Stack>
                ))
            ) : (
              <Typography className="t_oktelo_normal size_14 darkblue">
                Nincsenek történések.
              </Typography>
            )}
              </Stack>
            </Box>
          </Grid>
          <Grid item xl={12}>
            <Box className="post-holder-card" id="oktelo_ai_elemzes">
            <Stack direction="column" gap={2} width="100%" height="100%">
                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                  <Stack direction="row" gap={1} alignItems="center">
                    <Avatar sx={{ width: '40px', height: '40px', backgroundColor: '#f9f9f9' }}>
                      <Assistant sx={{ color: '#1e1e47' }}/>
                    </Avatar>
                    <Typography className="t_oktelo_medium size_16 darkblue">
                      Oktelo AI elemzés
                    </Typography>
                  </Stack>
                </Stack>
            {isOkteloAiLoading ?
              <Stack direction="row" gap={1} alignItems="center">
                <CircularProgress sx={{ color: '#925FFF', fontSize: '20px'}}/>
                <Typography className="t_oktelo_medium size_14 t_purple">
                  Elemezzük a bejegyzésedet..
                </Typography>
              </Stack>
                :
                <div dangerouslySetInnerHTML={{ __html: convertToHtml(okteloAiText || 'Az elemzés eredménye itt fog megjelenni.') }}
                className="t_oktelo_light size_14 miscgray" />
              }
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return <Box> Hiba </Box>;
  }
};

interface PublishSettingsProps {
  calendarDate: Date | null;
  publishDate: Date | null;
  currentStatus: string;
  currentStatus_: string;
  postId: string;
  onClose: () => void; // Prop to close the popup
}

const ViewPublishSettings: React.FC<PublishSettingsProps> = ({ calendarDate, publishDate, currentStatus, currentStatus_, postId, onClose }) => {
  const navigate = useNavigate();
  const { open } = useNotification();
  const [selectedDate, setSelectedDate] = useState<Dayjs | string | null>();

  useEffect(() => {
    setSelectedDate(publishDate
      ? dayjs(publishDate)
      : calendarDate
      ? dayjs(calendarDate)
      : dayjs())
  }, [postId])

  const publishPost = async (publishNow: boolean) => {
    try {

      if (publishNow === undefined || !selectedDate || !postId) {
        console.log("Something is not found: ", publishNow, selectedDate, postId);
        return;
      }

      console.log("publishing: ", postId, publishNow, dayjs(selectedDate).format('YYYY.MM.DD HH:mm'));

      const publish = await api.post(`${apiconfig.BACKEND_URI}/publish/handler`, {
        postId: postId,
        isPublishNow: publishNow,
        scheduleDate: selectedDate
      });

      if (publish.data) {
        if (publish.data.success) {
          open?.({ type: 'success', message: 'Sikeres közzététel.' });
        } else {
          open?.({ type: 'error', message: 'Sikertelen közzététel.' });
        }
      } else {
        open?.({ type: 'error', message: 'Váratlan válasz a közzételhez.' });
      }      
    } catch (error) {
      open?.({ type: "error", message: "Váratlan hiba történt a poszt közzétételekor." });
    } finally {
      navigate(`/posts/show/${postId}`);
    }
  }

  return (
    <Box className="custom_popup">
      <Stack direction="column" gap={3} width="100%">
        <Typography className="t_oktelo_semibold size_18 darkblue">
          Közzététel beállítások
        </Typography>
        <Stack direction="column" gap={1}>
          <Typography className="t_oktelo_medium size_14 darkblue">
            Dátum ({currentStatus})
          </Typography>
          <DatePicker
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            disabled={currentStatus_ === "published" || currentStatus_ === "scheduledWithPublish"}
            format={"YYYY.MM.DD HH:mm"}
          />
          <Stack direction="row" gap={1.2} alignItems="center">
            <InfoOutlined sx={{ fontSize: '14px' }}/>
            <Typography className="t_oktelo_normal size_14 darkblue">
            {publishDate ? "Ez a bejegyzés már közzétéve vagy ütemezve van." : calendarDate ? "Ez a bejegyzés vázlat, amihez megadtál egy naptári dátumot. A bejegyzést ütemezheted vagy azonnal közzéteheted." : "Ehhez a bejegyzéshez nem adtál meg naptári és közzétételi dátumot. A bejegyzést ütemezheted vagy azonnal közzéteheted."}
          </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" gap={1} width="100%">
          <Typography className="t_oktelo_medium size_14 darkblue">
            Opciók
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
          {currentStatus_ === "draft" || currentStatus_ === "calendarDraft" || currentStatus_ === "errorWithPublishing" || currentStatus_ === "unPublished"  ? 
            <Stack direction="row" gap={1} alignItems="center">
              <button className="home-action-button b925" onClick={() => publishPost(true)}>
                Közzététel
              </button>
              <button className="home-action-button bc6a" onClick={() => publishPost(false)}>
                Ütemezés
              </button>
            </Stack>
          : currentStatus_ === "scheduledWithPublish" ?
            <Stack direction="row" gap={1} alignItems="center">
              <button className="home-action-button b925">
                Ütemezés törlése
              </button>
            </Stack>
          : currentStatus_ === "published" ?
            <Stack direction="row" gap={1} alignItems="center">
              <button className="home-action-button b925">
                Törlés a feed-ből
              </button>
            </Stack>
          :
          <></>
        }
            <Stack direction="row" gap={1} alignItems="center">
              <button className="home-action-button b9d9" onClick={onClose}>
                Mégsem
              </button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PostViewDetails;

import React, { ReactElement } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Popover, ConfigProvider } from "antd";


interface TooltipProps {
    title: string;
    question?: boolean;
    children?: ReactElement<any, any>;
    content?: string | any | Date;
    color?: string;
}


const ShowTooltip: React.FC<TooltipProps> = ({ title, question, children, content, color }) => {

    return (
        <ConfigProvider
      theme={{
        token: {
            colorBgElevated: 'rgba(255,255,255,0.95)',
            colorText: '#1E1E47',
            borderRadiusLG: 12,
            borderRadiusXS: 12,
            boxShadowSecondary: 'rgba(0, 0, 0, 0.05) -4px 9px 25px -6px',
        },
      }}
    >
        <Popover
            content={<span style={{ fontSize: '14px', fontWeight: '300', maxWidth: '600px', whiteSpace: 'pre-line' }}>{content}</span>}
            title={title}
            placement="bottom"
            arrow
            autoAdjustOverflow
            overlayStyle={{ zIndex: '1500', maxWidth: '600px' }}
        >
            <div style={{ display: 'inline-block' }} > 
                {question ? 
                    <HelpOutlineIcon sx={{ fontSize: '20px', color: color || "#925fff", borderRadius: '500px' }} /> :
                    children
                }
            </div>
        </Popover>
        </ConfigProvider>
    );
};


export default ShowTooltip;

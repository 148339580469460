import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { PieChart } from "components";
import { useEffect, useState } from "react";
import { AutoAwesomeOutlined, LinkedIn, BarChartOutlined, CalendarMonthOutlined, Facebook, FavoriteRounded, Instagram, NotificationsOutlined, PersonSearchRounded, PsychologyOutlined, RefreshSharp, RocketRounded, SmartToyOutlined, TipsAndUpdatesOutlined, WhatshotRounded, HourglassTop, RocketOutlined, FavoriteOutlined, WhatshotOutlined, PersonSearchOutlined, FavoriteBorder, QueryStatsOutlined, RssFeedOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Avatar, CircularProgress, LinearProgress } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Modal, Tour } from 'antd';
import { DownOutlined, TikTokFilled } from '@ant-design/icons';
import { Select, Dropdown, Alert, MenuProps } from "antd";
import api from "utils/interceptor";
import { useGetIdentity, useNotification } from "@refinedev/core";
import {
  AudienceCardProps,
  OkteloPostProps,
  SocialPostProps,
} from "interfaces/oktelo";
import AudienceCard from "components/charts/home/AudienceCard";
import WeeklyPerformance from "components/charts/home/WeeklyPerformance";
import UpcomingCard from "components/charts/home/UpcomingCard";
import ChatWindow from "components/common/chat/ChatWindow";
import HomePostCard from "components/common/HomePostCard";
import HomeSocialPostData from "components/common/post/HomeSocialPostDate";
import dayjs from "dayjs";
import "dayjs/locale/hu";
import { OkteloUserIdentity } from "interfaces/user";
import apiconfig from "utils/apiconfig";
import { WelcomeTour } from "utils/welcomeTour";
import TiktokHome from "components/TiktokHome";

interface PageStory {
  media_url: string;
  story_url: string;
  id: string;
  story_status: string;
  media_id: string;
  like_count?: number;
  comment_count?: number;
  create_date?: number;
  media_type: string;
  thumbnail?: string;
}

interface PostComment {
  comments: {
    summary: {
      total_count: number;
    };
  };
  id: string;
}

interface BeforeValues {
  impression: number;
  engagement: number;
  view: number;
  fan: number;
  reach: number;
}

interface Attachment {
  description: string;
  media?: {
    image?: {
      src: string;
      height: number;
      width: number;
    };
  };
  url?: string;
}

interface Insight {
  post_impressions?: number;
  post_engaged_users?: number;
  post_reactions_by_type_total?: {
    like?: number;
    haha?: number;
    love?: number;
    wow?: number;
    anger?: number;
    sorry?: number;
  };
  reach?: number;
}

interface Post {
  id: string;
  message: string;
  attachments?: Attachment[];
  created_time: Date;
  insights: Insight;
  timestamp: Date;
  story?: string;
  platform: string;
  page_name: string;
  page_image_url: string;
  caption: string;
  comment_count: number;
  comments_count: number;
  like_count?: number;
  permalink?: string;
  is_oktelo: boolean;
}

type SelectedPerformance = {
  key: string;
  label: string;
}

type AIResponse = {
  name: string;
  message: string;
  page: string;
  platform: string;
  time: Date | null;
}

type PageInfo = {
  id: string;
  name: string;
  photo: string;
  platform: string;
};

type SocialData = {
  pages: PageInfo[];
};

const { Option, OptGroup } = Select;

dayjs.locale("hu");

const Home = () => {
  document.title = "Vezérlőpult | Oktelo";
  const { data: identity } = useGetIdentity<OkteloUserIdentity>();
  const { open } = useNotification();
  const navigate = useNavigate();
  const [latestProperties, setLatestProperties] = useState<OkteloPostProps[]>(
    []
  );
  const [selectedPage, setSelectedPage] = useState<string | null>(null);
  const [displayPageImpressions, setPageImpressions] = useState(0);
  const [displayPageFansTotal, setLifetimeTotalLikes] = useState(0);
  const [displayPageEngagement, setPageEngagedUsers] = useState(0);
  const [displayPageTotalViews, setPageTotalViews] = useState(0);
  const [displayPageReach, setPageReach] = useState(0);
  const [posts, setPosts] = useState<SocialPostProps[]>([]);
  const [publishedPosts, setPublishedPosts] = useState<any[]>([]);
  const [selectedPostLimit, setSelectedPostLimit] = useState<number>(6);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [upcomings, setUpcomings] = useState<any>(null);
  const [facebookExpired, setFacebookExpired] = useState<boolean>(false);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedPerformanceDate, setSelectedPerformanceDate] =
    useState<SelectedPerformance>({key: 'current_week', label: 'Heti'});
  const [getPageInsights, setPageInsights] = useState<BeforeValues | null>(
    null
  );
  const [beforeValues, setBeforeValues] = useState<BeforeValues | null>(null);
  const [audienceData, setAudienceData] = useState<AudienceCardProps | null>(
    null
  );
  const [chartData, setChartData] = useState<any | null>(null);
  const [isAiModalOpen, setAiModalOpen] = useState(false);
  const [aiModalData, setAiModalData] = useState<AIResponse | null>({ name: '', page: '', message: '', platform: '', time: null });
  const [isShowWelcome, setShowWelcome] = useState(false);
  const [isAiLoading, setAiLoading] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.get(
          `${apiconfig.BACKEND_URI}/data/socials`
        );

        const published = await api.get(
          `${apiconfig.BACKEND_URI}/data/published`
        );

        if (response.status === 200) {
          const allFacebook = response.data.facebookPages;
          const allInstagram = response.data.instagramPages;
          const allLinkedin = response.data.linkedinPages;
          const allTiktok = response.data.tiktokPages;
  
          const merge: SocialData = {
            pages: [...allFacebook, ...allInstagram, ...allLinkedin, ...allTiktok],
          };

        setData(merge);

        }

        if (published.status === 200) {
          const pposts = published.data.published;
          setPublishedPosts(pposts);
        }



      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } 

    async function fetchUpcomings() {
      try {
        const response = await api.get(`${apiconfig.BACKEND_URI}/data/homenotify`);

        setUpcomings(response.data);
      } catch (error) {
        console.error("Error fetching upcomings:", error);
      }
    }

    fetchUserData();
    /* fetchUpcomings(); */
  }, []);

  useEffect(() => {
    if (data && data.pages && data.pages.length > 0) {
      if (!selectedPage) {
        if (localStorage.getItem("sp")) {
          setSelectedPage(localStorage.getItem("sp"));
        } else {
          setSelectedPage(data.pages[0].id);
        }
      }
      if (!selectedPostLimit) {
        setSelectedPostLimit(6);
      }
    }
    console.log(selectedPage);
  }, [data]);

  const fetchData = async () => {
    try {
      // Fetch user properties
      const userPropertiesResponse = await api.get(
        `${apiconfig.BACKEND_URI}/data/homeposts`
        
      );
      setLatestProperties(userPropertiesResponse.data);
    } catch (error) {
      console.log("Error fetching user properties: ", error);
    }
  };

  const fetchSocialData = async () => {
    if (!selectedPage) return;
    setLoading(true);
  
    const selectedPageData = data?.pages.find(
      (page: PageInfo) => page.id === selectedPage
    );
  
    if (!selectedPageData) {
      setLoading(false);
      return;
    }
  
    const isFacebookPage = selectedPageData.platform === "facebook";
    const isInstagramPage = selectedPageData.platform === "instagram";
    const isTiktokPage = selectedPageData.platform === "tiktok";
  
    try {
      if (isFacebookPage) {
        await fetchFacebookData();
      } else if (isInstagramPage) {
        await fetchInstagramData();
      } else if (isTiktokPage) {
        setSelectedPlatform("tiktok"); // show tiktok home
      }
    } catch (error) {
      console.error('Error fetching social data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFacebookData = async () => {
    setSelectedPlatform("facebook");
    try {
      const facebookResponse = await api.get(
        `${apiconfig.BACKEND_URI}/socialdata/facebook/`,
        {
          headers: {
            "Content-Type": "application/json",
            pageId: selectedPage,
            postLimit: selectedPostLimit,
            chartDate: selectedPerformanceDate.key,
          },
        }
      );

      const responseData = facebookResponse.data;

      // Extract values for summaryData
      const pageEngagedUsersValue = responseData.summary.page_post_engagements;
      const pageImpressionsValue = responseData.summary.page_impressions;
      const pageViewsTotalValue = responseData.summary.page_views_total;
      const pageReachValue = responseData.summary.page_impressions_unique;

      // Audience
      const audienceDataValue = responseData.audience;

      // Chart
      const chartDataValue = responseData.chart;

      // Filter the data to get only the items with name "page_fans"
      const pageFansValue = responseData.fan_summary.page_fans;

      // Extract compare values for summaryData compare
      const pageEngagedUsersValue_Compare =
        responseData.summary_compare.page_post_engagements;
      const pageImpressionsValue_Compare =
        responseData.summary_compare.page_impressions;
      const pageViewsTotalValue_Compare =
        responseData.summary_compare.page_views_total;
      const pageFans_Compare = responseData.summary_compare.page_fans;
      const pageReachValue_Compare = responseData.summary_compare.page_impressions_unique;

      setBeforeValues({
        impression: pageImpressionsValue_Compare,
        engagement: pageEngagedUsersValue_Compare,
        view: pageViewsTotalValue_Compare,
        fan: pageFans_Compare,
        reach: pageReachValue_Compare,
      });
      setPageInsights({
        impression: pageImpressionsValue,
        engagement: pageEngagedUsersValue,
        fan: pageFansValue,
        view: pageViewsTotalValue,
        reach: pageReachValue,
      });

      const getCommentCount = (postId: string) => {
        const postComment: PostComment | undefined =
          responseData.post_comments.find(
            (comment: PostComment) => comment.id === postId
          );
        return postComment ? postComment.comments.summary.total_count : 0;
      };

      const checkIfOkteloPost = (id: string) => {
        const allOkteloPosts = publishedPosts;
        return allOkteloPosts.includes(id);
      };


      const mergedPosts = responseData.post_resp.map(
        (post: any, index: number) => ({
          ...post,
          platform: "facebook",
          page_image_url: responseData.pageInfo.photo,
          page_name: responseData.pageInfo.name,
          page_id: selectedPage,
          is_oktelo: checkIfOkteloPost(post.id),
        })
      );

      localStorage.setItem("spp", responseData.pageInfo.photo);
      dispatchEvent(new Event("storage"));

      setChartData(chartDataValue);
      setAudienceData(audienceDataValue);
      setPosts(mergedPosts);
      setPageEngagedUsers(pageEngagedUsersValue);
      setPageImpressions(pageImpressionsValue);
      setLifetimeTotalLikes(pageFansValue);
      setPageTotalViews(pageViewsTotalValue);
      setPageReach(pageReachValue)
    } catch (error: any) {;
      handleFetchError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchInstagramData = async () => {
    setSelectedPlatform("instagram");
    try {
      const instagramResponse = await api.get(
        `${apiconfig.BACKEND_URI}/socialdata/instagram/`,
        {
          headers: {
            "Content-Type": "application/json",
            pageId: selectedPage,
            timeFrame: "day",
            postLimit: selectedPostLimit,
            chartDate: selectedPerformanceDate.key,
          },
        }
      );

      const responseData = instagramResponse.data;
      const postsInsightsData = instagramResponse.data.ig_media_insights;
      const chartDataValue = responseData.chart;
      const pageViewsTotalValue = responseData.ig_insights.profile_views; //page_views
      const pageImpressionsValue = responseData.ig_insights.impressions; //impressions
      const pageEngagedUsersValue = responseData.ig_insights.accounts_engaged; //accounts_engaged
      const maxPageFansValue = responseData.ig_insights_day.follower_count; //follower_count
      const pageReachValue = responseData.ig_insights.reach;
      const audienceDataValue = responseData.audience;
      
      // Extract compare values for summaryData compare
      const pageEngagedUsersValue_Compare =
        responseData.ig_insights_comp.accounts_engaged;
      const pageImpressionsValue_Compare =
        responseData.ig_insights_comp.impressions;
      const pageViewsTotalValue_Compare =
        responseData.ig_insights_comp.profile_views;
      const pageReachValue_Compare =
        responseData.ig_insights_comp.reach;

      setBeforeValues({
        impression: pageImpressionsValue_Compare,
        engagement: pageEngagedUsersValue_Compare,
        view: pageViewsTotalValue_Compare,
        fan: maxPageFansValue,
        reach: pageReachValue_Compare,
      });
      setPageInsights({
        impression: pageImpressionsValue,
        engagement: pageEngagedUsersValue,
        fan: maxPageFansValue,
        view: pageViewsTotalValue,
        reach: pageReachValue,
      });

      const checkIfOkteloPost = (id: string) => {
        const allOkteloPosts = publishedPosts;
        return allOkteloPosts.includes(id);
      };

      const mergedPosts = instagramResponse.data.ig_media && instagramResponse.data.ig_media.length > 0 && instagramResponse.data.ig_media.map(
        (post: any, index: number) => ({
          id: post.id,
          attachments: post.media_url || post.thumbnail_url,
          impressions: postsInsightsData[index]?.impressions || 0,
          engaged_users: postsInsightsData[index]?.total_interactions || 0,
          reactions: post.like_count,
          comments: post.comments_count,
          created_time: post.timestamp,
          link: post.permalink,
          attachment_image: post.thumbnail_url || post.media_url,
          platform: "instagram",
          page_image_url: responseData.ig_insights_day.page_image_url,
          page_name: post.username,
          page_id: selectedPage,
          like_count: post.like_count,
          message: post.caption,
          is_oktelo: checkIfOkteloPost(post.id),
        })
      );

      setAudienceData(audienceDataValue);
      setChartData(chartDataValue);
      setPosts(mergedPosts);
      setPageEngagedUsers(pageEngagedUsersValue);
      setPageImpressions(pageImpressionsValue);
      setLifetimeTotalLikes(maxPageFansValue);
      setPageReach(pageReachValue);
      setPageTotalViews(pageViewsTotalValue);
      dispatchEvent(new Event("storage"));
    } catch (error: any) {
      handleFetchError(error);
    } finally {
      setLoading(false);
    }
  };

  //Handling fetch errors
  const handleFetchError = (error: any) => {
    console.error("Unknown error:", error.message);
    if (error.response && error.response.status === 401) {
      const errorMessage = error.response.data?.message;
      if (errorMessage === "oktelo_facebook_token_reconnect") {
        setFacebookExpired(true);
      }
    } else {
      console.error(
        "Backend error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  //Handle Form Click
  const handleMenuItemClick = (pageId: string) => {
    setSelectedPage(pageId);
    localStorage.setItem("sp", pageId);
    const pagename = data?.pages.find((page: PageInfo) => page.id === pageId).name;
    localStorage.setItem("spn", pagename);
    localStorage.setItem("isFetched", "false");
  };



/*   const menu: MenuProps = (
    <Menu onClick={() => handlePerformanceDate} className="t_oktelo_text">
        <Menu.Item key="current_week" className="t_oktelo_text">
            Heti
        </Menu.Item>
        <Menu.Item key="current_month" className="t_oktelo_text">
            Havi
        </Menu.Item>
        <Menu.Item key="7_days" className="t_oktelo_text">
            7 napos
        </Menu.Item>
        <Menu.Item key="20_days" className="t_oktelo_text">
            20 napos
        </Menu.Item>
    </Menu>
); */

  const items: MenuProps['items'] = [
      {
        key: 'current_week',
        label: 'Heti',
        onClick: () => setSelectedPerformanceDate({key: 'current_week', label: 'Heti'}),
      },
      {
        key: 'current_month',
        label: 'Havi',
        onClick: () => setSelectedPerformanceDate({key: 'current_month', label: 'Havi'}),
      },
      {
        key: '7_days',
        label: '7 napos',
        onClick: () => setSelectedPerformanceDate({key: '7_days', label: '7 napos'}),
      },
      {
        key: '14_days',
        label: '14 napos',
        onClick: () => setSelectedPerformanceDate({key: '14_days', label: '14 napos'}),
      },
    ];


  useEffect(() => {
    if (selectedPage) {
      fetchSocialData();
      fetchData();
    }
  }, [selectedPage, selectedPostLimit, selectedPerformanceDate]);



  const okteloAIAnalytics = async (type: string) => {
    try {
      setAiLoading(true);

      const pagename = localStorage.getItem('spn');

      if (type === "audience"){
      const response = await api.post(`${apiconfig.BACKEND_URI}/ai/okteloai`, {
        platform: selectedPlatform,
        pagename: pagename,
        type: type,
        data: audienceData,
      })

      if (response.status === 200){
        setAiModalOpen(true);
        const formattedMessage = response.data.message; // Keep the raw message
        setAiModalData({ name: response.data.name, page: response.data.page, message: formattedMessage, platform: response.data.platform, time: response.data.time });
      }

    }

    else if (type === "performance"){

      const date = dayjs();
      const today_day_name = date.format('dddd');

      const sendData = {
          heti_impressziók: getPageInsights?.impression,
          heti_elköteleződött_felhasználók: getPageInsights?.engagement,
          heti_oldal_megtekintések: getPageInsights?.view,
          oldal_követői: getPageInsights?.fan,
          heti_posztok_száma: posts.length,
          hét_napja: today_day_name,
      }

      const response = await api.post(`${apiconfig.BACKEND_URI}/ai/okteloai`, {
        platform: selectedPlatform,
        pagename: pagename,
        type: type,
        data: sendData,
      })

      if (response.status === 200){
        setAiModalOpen(true);
        const formattedMessage = response.data.message; // Keep the raw message
        setAiModalData({ name: response.data.name, page: response.data.page, message: formattedMessage, platform: response.data.platform, time: response.data.time });
      }

    }

    else if (type === 'week_posts') {

      const postinfos = posts.map(post => ({
        posztolt_ido: post.created_time,
        impressziok: post.impressions,
        elkotelezodott_felhasznalok: post.clicks,
        kommentek: post.comments,
        reakciok: post.reactions,
        szoveg_preview: post.message ? post.message.slice(0,70) + '...' : 'nincs szövege',
      }));


      const senddata = {...postinfos };


      const response = await api.post(`${apiconfig.BACKEND_URI}/ai/okteloai`, {
        platform: selectedPlatform,
        pagename: pagename,
        type: type,
        data: senddata,
      })

      if (response.status === 200){
        setAiModalOpen(true);
        const formattedMessage = response.data.message; // Keep the raw message
        setAiModalData({ name: response.data.name, page: response.data.page, message: formattedMessage, platform: response.data.platform, time: response.data.time });
      }

    }
      
    } catch (error) {
      console.log(error);
    } finally {
      setAiLoading(false);
    }
  }

/*   const renderMessage = (message: string) => {
    // Split the message into lines
    const lines = message.split('\n');

    return lines.map((line, index) => (
      <Typography key={index} className="t_oktelo_light size_14 darkblue">
        {line}
      </Typography>
    ));
  };
 */
  const renderMessage = (text: any) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert **bold** to <strong>
      .replace(/\n/g, '<br />'); // Convert new lines to <br>
  };

  useEffect(() => {
    const getToShow = localStorage.getItem('isWelcome');
    if (getToShow === "true" && identity?.roleId === 0){
      setShowWelcome(true);
    } else {
      setShowWelcome(false);
    }
  }, [identity, isShowWelcome]);

  const handleWelcomeClose = () => {
    localStorage.setItem('isWelcome', "false");
    setShowWelcome(false);
  }

  return (
    <Box height="100%" gap={3} display="flex" flexDirection="column">
      {loading && (
        <Box
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "9999",
          }}
        >
          <CircularProgress sx={{ color: "#925FFF", fontSize: "32px" }} />
        </Box>
      )}
      {isShowWelcome && (
        <WelcomeTour isOpen={isShowWelcome} handleClose={handleWelcomeClose}/>
      )}
      {facebookExpired && (
        <Box my={2} mx={2}>
          <Alert
            message="Oktelo Értesítés"
            description="A közösségi média kapcsolatod lejárt. A folytatáshoz lépj a Beállítások oldalra, és csatlakozz újra a Facebookhoz. Elnézést a kellemetlenségért, Oktelo."
            type="info"
            className="t_desc t_oktelo"
            showIcon
            closable
            afterClose={() => navigate("/settings")}
            action={
              <Button
                className="oktelo_card_button"
                onClick={() => navigate("/settings")}
              >
                Beállítások
              </Button>
            }
          />
        </Box>
      )}
       <ChatWindow />
      {isAiModalOpen && aiModalData &&
        <Modal title={aiModalData.name} open={isAiModalOpen} onCancel={() => setAiModalOpen(!isAiModalOpen)} /* onOk={handleOk} */>
        <Typography className="t_oktelo_semibold size_14 darkblue">
          {aiModalData.page} - {aiModalData.platform} - {dayjs(aiModalData.time).format('YYYY.MM.DD HH:mm')} 
        </Typography>
        <p dangerouslySetInnerHTML={{ __html: renderMessage(aiModalData.message || 'Az elemzés eredménye itt fog megjelenni.') }}
                style={{ fontWeight: 400, fontSize: '14px'}} />
      </Modal>
      }
      <Grid container spacing={3}>
        <Grid item xl={8} lg={6}>
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar
              sx={{ width: "50px", height: "50px", backgroundColor: "transparent" }}
              src={'https://res.cloudinary.com/duhpymkbk/image/upload/v1718629626/default_user_atlfh4.png'}
            >
            </Avatar>
            <Stack direction="column" gap={0}>
              <Typography className="t_oktelo_bold size_20 darkblue">Oktelo vezérlőpult</Typography>
              <Typography className="t_oktelo_normal size_14 darkblue">
                Üdvözöllek, {identity && identity.username ? identity.username : "felhasználó"}!
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xl={4} lg={6} alignItems="center" alignContent="center">
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="flex-end"
            className="okt_home_form"
          >
            <Select
              style={{ width: '100%', borderRadius: "0px" }}
              value={selectedPage === null ? "" : selectedPage}
              placeholder="Oldal választása..."
              onChange={handleMenuItemClick}
              dropdownStyle={{ minWidth: "250px" }}
              disabled={loading}
            >
              <Option disabled value="">
                <Typography className="t_oktelo_text">
                  Oldal választása...
                </Typography>
              </Option>
              <OptGroup label="Facebook">
                {data && data.pages && data.pages.length > 0 &&
                data.pages.map((p: PageInfo) => (
                  <Option key={p.id} value={p.id}>
                    <Stack direction="row" gap={1} alignItems="center">
                    {p.platform === "facebook" ? (
                      <Facebook sx={{ fontSize: "16px", color: '#1e90ff' }} />
                    ) : p.platform === "instagram" ? (
                      <Instagram sx={{ fontSize: "16px", color: '#E4405F' }} />
                    ) : p.platform === "tiktok" ? (
                      <TikTokFilled style={{ fontSize: "16px ", color: '#2b2b2b'}} />
                    ) :
                    (
                      <LinkedIn sx={{ fontSize: "16px" }} />
                    )}
                    <img
                      src={p.photo}
                      style={{
                        objectFit: "contain",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Typography className="posts-select-item">
                      {p.name}
                    </Typography>
                  </Stack>
                  </Option>
                ))}
              </OptGroup>
            </Select>
            <button
            className="home-action-button b925"
            onClick={() => {
              fetchData();
              fetchSocialData();
            }}>
            <RefreshSharp
            />
            Frissítés
            </button>

          </Stack>
        </Grid>
      </Grid>
      {displayPageFansTotal < 100 && (
        <Box>
          <Typography className="t_oktelo_medium size_14 t_purple">
            Úgy tűnik, hogy 100-nál kevesebb követőd van. Ilyen esetben csak korlátolt
            számú adat áll rendelkezésre.
          </Typography>
        </Box>
      )}
      {selectedPage && selectedPlatform === "tiktok" ?
        <TiktokHome pageid={selectedPage} username={localStorage.getItem('spn') || 'username'} />
      :
      <>
      <Grid container spacing={2} className="animate__animated animate__slideInDown">
        <Grid item xs={6} md={6} lg={6} xl={2.4}>
          <PieChart
            title="Követők"
            desc={"Azon emberek teljes száma, akik kedvelték az oldaladat."}
            value={displayPageFansTotal}
            series={[displayPageFansTotal]}
            colors={["#1fd9ad", "#1e90ff", "#7fba7a", "#FFA2C0"]}
            labels={["Likeok"]}
            before={beforeValues?.fan ?? 0}
             icon={<FavoriteBorder sx={{ fontSize: '20px', color: '#1e1e47'}}/>}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6} xl={2.4}>
          <PieChart
            title="Impressziók"
            desc={"Azon alkalmak száma, amikor az oldalad vagy az oldaladról származó tartalom megjelent egy személy képernyőjén."}
            value={displayPageImpressions}
            series={[displayPageImpressions]}
            colors={["#1e90ff", "#1e90ff"]}
            labels={["Impressziók"]}
            before={beforeValues?.impression ?? 0}
              icon={<RocketOutlined sx={{ fontSize: '20px', color: '#1e1e47'}}/>} 
          />
        </Grid>
        <Grid item xs={6} md={6} lg={4} xl={2.4}>
          <PieChart
            title="Elérés"
            desc={"Azon emberek száma, akiknek az oldalad vagy az oldaladról származó tartalom megjelent a képernyőjén."}
            value={displayPageReach}
            series={[displayPageReach]}
            colors={["#1e90ff", "#1e90ff"]}
            labels={["Elérés"]}
            before={beforeValues?.reach ?? 0}
            icon={<RssFeedOutlined sx={{ fontSize: '20px', color: '#1e1e47'}}/>} 
          />
        </Grid>
        <Grid item xs={6} md={6} lg={4} xl={2.4}>
          <PieChart
            title="Elköteleződés"
            desc={"Azon alkalmak száma, amikor az emberek reakciók, megjegyzések és megosztások révén kapcsolatba léptek bejegyzéseiddel."}
            value={displayPageEngagement}
            series={[displayPageEngagement]}
            colors={["#ffc106", "#1e90ff"]}
            labels={["Elköteleződés"]}
            before={beforeValues?.engagement ?? 0}
             icon={<WhatshotOutlined sx={{ fontSize: '20px', color: '#1e1e47'}}/>} 
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4} xl={2.4}>
          <PieChart
            title="Látogatók"
            desc={"Az oldalad profiljának megtekintései"}
            value={displayPageTotalViews}
            series={[displayPageTotalViews]}
            colors={["#B784B7", "#1e90ff"]}
            labels={["Látogatók"]}
            before={beforeValues?.view ?? 0}
            icon={<PersonSearchOutlined sx={{ fontSize: '20px', color: '#1e1e47'}}/>} 
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xl={8} lg={7} md={12} sm={12} xs={12}>
          <Box
            className="oktelo_card"
            sx={{ boxShadow: "none" }}
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              alignContent="center"
              className="okt_home_form"
            >
              <Box gap={1}>
              <Typography className="size_20 darkblue t_oktelo_semibold">
                {selectedPerformanceDate.label} teljesítmény
              </Typography>
              <Typography className="size_14 darkblue t_oktelo_light">
                  A napi adatok az előző nap 07:00-tól, aznap 07:00-ig van kalkulálva.
                </Typography>
              </Box>
              <Dropdown
                menu={{
                  items,
                  selectable: true,
                  defaultSelectedKeys: ['current_week'],
                }}>
                <Button style={{ width: "100px" }} className="darkblue size_14 t_oktelo_light notransform" onClick={e => e.preventDefault()} disabled={loading}>
                    Idő <DownOutlined style={{ fontSize: '12px', marginLeft: '4px'}}/>
                </Button>
              </Dropdown>
            </Stack> 
            <WeeklyPerformance chartData={chartData} />
            <Stack direction="row" gap={1} alignItems="center">
                <button
                className="home-action-button b925"
              onClick={() => {
                {selectedPlatform === "facebook" ?
                  navigate(`/site/${selectedPage}`)
                  :
                  navigate(`/igsite/${selectedPage}`)
                }
              }}
            >
              <BarChartOutlined/>
              Részletes adatok
            </button>
            <button
                  className="home-action-button bccf"
                  disabled={isAiLoading}
                  onClick={() => {
                    okteloAIAnalytics('performance')
                  }}
                >
                  {isAiLoading ? <HourglassTop/> : <SmartToyOutlined/>}
                  {isAiLoading ? 'Pillanat..' : 'Oktelo AI'}
                </button>
              </Stack>
          </Box>
        </Grid>
        <Grid item xl={4} lg={5} md={12} sm={12} xs={12}>
          <Box className="oktelo_card" width="100%" sx={{ boxShadow: "none" }}>
            <Stack
              direction="column"
              gap={1}
              width="100%"
              height="100%"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box gap={1}>
              <Typography className="size_20 darkblue t_oktelo_semibold">
                  Közönség
                </Typography>
                <Typography className="size_14 darkblue t_oktelo_light">
                  A követőid aggregált adatai.
                </Typography>
              </Box>
              {audienceData && <AudienceCard data={audienceData} />}
              <Stack direction="row" gap={1} alignItems="center">
                <button
                  className="home-action-button b925"
                  onClick={() => {
                    {selectedPlatform === "facebook" ?
                      navigate(`/site/${selectedPage}`)
                      :
                      navigate(`/igsite/${selectedPage}`)
                    }
                  }}
                >
                  <TipsAndUpdatesOutlined/>
                  Részletek
                </button>
                <button
                  className="home-action-button bccf"
                  disabled={isAiLoading}
                  onClick={() => {
                    okteloAIAnalytics('audience')
                  }}
                >
                  {isAiLoading ? <HourglassTop/> : <SmartToyOutlined/>}
                  {isAiLoading ? 'Pillanat..' : 'Oktelo AI'}
                </button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xl={12}>
        <Box gap={1} mb={2}>
            <Stack direction="column" gap={1.5} justifyContent="flex-start" alignItems="flex-start">
            <Typography className="size_20 darkblue t_oktelo_semibold">
            Heti közösségi posztok
          </Typography>
          <button
                  className="home-action-button bccf"
                  disabled={isAiLoading}
                  onClick={() => {
                    okteloAIAnalytics('week_posts')
                  }}
                >
                  {isAiLoading ? <HourglassTop/> : <SmartToyOutlined/>}
                  {isAiLoading ? 'Pillanat..' : 'Oktelo AI'}
                </button>
            </Stack>


        </Box>
        <HomeSocialPostData posts={posts} />
      </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box width="100%">
            <Stack
              direction="column"
              gap={1}
              width="100%"
              height="100%"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box gap={1}>
              <Typography className="size_20 darkblue t_oktelo_semibold">
                  Legújabb Oktelo posztok
                </Typography>
                <Typography className="size_14 darkblue t_oktelo_light">
                  Legújabb oktelo bejegyzéseid és állapotuk.
                </Typography>
              </Box>
              <Grid container spacing={2} mt={0}>
                {latestProperties && latestProperties.length > 0 && latestProperties.map((property) => (
                  <Grid
                    item
                    key={property._id}
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    component={Link}
                    to={`/posts/show/${property._id}`}
                  >
                    <HomePostCard
                      key={property._id}
                      _id={property._id}
                      attachment={property.attachment}
                      tag={property.tag}
                      platform={property.platform}
                    />
                  </Grid>
                ))}
              </Grid>
              <Stack direction="row" gap={1} alignItems="center">
                <button
                  className="home-action-button b925"
                  onClick={() => navigate("/create")}
                >
                  <AutoAwesomeOutlined/>
                  Létrehozás
                </button>
                <button
                  className="home-action-button bccf"
                  onClick={() => navigate("/posts")}
                >
                  <ArticleOutlinedIcon/>
                  Posztok
                </button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
{/*         <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Box className="oktelo_card" width="100%">
            <Stack
              direction="column"
              gap={1}
              width="100%"
              height="100%"
              justifyContent="space-between"
            >
              <Box gap={1} mb={2}>
              <Typography className="size_20 darkblue t_oktelo_medium">
                  Történések
                </Typography>
                <Typography className="size_14 darkblue t_oktelo_light">
                  Legújabb Oktelo tartalmaid, eseményeid.
                </Typography>
              </Box>
              {upcomings && <UpcomingCard data={upcomings} />}
              <Stack direction="row" gap={1} alignItems="center">
                <button
                  className="home-action-button b925"
                  onClick={() => navigate("/create")}
                >
                  <NotificationsOutlined/>
                  Összes értesítés
                </button>
                <button
                  className="home-action-button bccf"
                  onClick={() => navigate("/calendar")}
                >
                  <CalendarMonthOutlined/>
                  Naptár
                </button>
              </Stack>
            </Stack>
          </Box>
        </Grid> */}
      </Grid></>}

  </Box>
  );
};

export default Home;

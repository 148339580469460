import React, { useState, useEffect } from "react";
import { Input, Select, Form, Space } from 'antd';
import { LinearProgress, Stack, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { FacebookOutlined, InstagramOutlined, TwitterOutlined, LinkedinOutlined, PinterestOutlined, StarTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { industries, styles, ai_models, languages } from 'constants/ai-arrays';
import api from "utils/interceptor"
import { AutoAwesomeOutlined } from "@mui/icons-material";


const { Option } = Select;
const { TextArea } = Input;

interface AIResp {
    title: string;
    content: string;
    photo_idea: string;
    hashtags: string;
}

const AI_PostWriteForm = ({ onAiResponse, onFormSubmit }: { onAiResponse: Function, onFormSubmit: Function }) => {
    const [userPages, setUserPages] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [selectedModel, setSelectedModel] = useState<string>("");
    const [form] = Form.useForm();

    const platformOptions = [
        { value: 'facebook', label: 'Facebook' },
        { value: 'instagram', label: 'Instagram' },
        { value: 'twitter', label: 'Twitter/X' },
        { value: 'pinterest', label: 'Pinterest' },
        { value: 'linkedin', label: 'LinkedIn' }
      ];

    useEffect(() => {
        async function fetchUserData() {
            try {
                const response = await api.get(`http://localhost:8080/api/v1/users`, {
                    headers: {
                        "user_request_type": "get_all_pages",
                    },
                });
                const allFacebookNames = response.data.allFacebook.map((page: any) => page.name);
                const allInstagramUsernames = response.data.allInstagram.map((page: any) => page.name);

                const userPagesData = {
                    allNames: [...allFacebookNames, ...allInstagramUsernames],
                };

                setUserPages(userPagesData);
                console.log(userPagesData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        fetchUserData();

    }, []);

    const onFinish = async (values: any) => {
        onFormSubmit(values);
        setLoading(true);
        try {
            const response = await api.post('http://localhost:8080/api/v1/ai/generate/', values);

            const res_data = {
                title: response.data.response.outputTitle,
                content: response.data.response.outputContent,
                photo_idea: response.data.response.imageContent,
                hashtags: response.data.response.outputHashtags,
            };

            if (res_data) {
                onAiResponse(res_data);
            }


        } catch (error) {
            console.error('Error:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleModelChange = (value: string) => {
        setSelectedModel(value);
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" variant="outlined" className="ai_form" style={{ width: '100%' }}>
            <Form.Item required label="AI Model" name="AiModel">
                <Select showSearch={false} maxCount={1} maxTagCount={1} allowClear placeholder="Oktelo_v2_Pro" onChange={handleModelChange}>
                    {ai_models.map((style, index) => (
                        <Option key={index} value={style}>{style}</Option>
                    ))}
                </Select>
            </Form.Item>    
            <Form.Item required label="Cím" name="title" extra="Ez a bejegyzésed általános kontextusa/címe, amely segít az AI-nak megérteni a témát.">
                <Input placeholder="Akciós női adidas cipők nyárra az üzletünkben"
                count={{
                    show: true,
                    max: 64,
                    }}
                    />
            </Form.Item>
            <Form.Item required label="Kulcsszavak" name="keywords" extra="Pár kiegészítő kulcsszó, hogy jobb eredmény készüljön.">
            <Input placeholder="adidas, női, cipők, akció, üzlet, Budapest, leárazás"
            count={{
                show: true,
                max: 50,
                }}
                />
            </Form.Item>
            {selectedModel === "Oktelo_v2_Pro" && 
            <Form.Item required label="Bejegyzés nyelve" name="language">
                <Select showSearch={false} maxCount={1} maxTagCount={1} allowClear defaultValue={languages[0]}>
                    {languages.map((style, index) => (
                        <Option key={index} value={style}>{style}</Option>
                    ))}
                </Select>
            </Form.Item>  }
            <Form.Item required label="Platform" name="platform"  style={{ display: 'inline-block', width: selectedModel === 'Oktelo_v2_Pro'? 'calc(50% - 8px)' : '100%' }}>
            <Select showSearch={false} maxCount={1} maxTagCount={1} allowClear placeholder="Facebook">
                    <Option value="facebook"><FacebookOutlined /> Facebook</Option>
                    <Option value="twitter"><TwitterOutlined /> Twitter/X</Option>
                    <Option value="instagram"><InstagramOutlined /> Instagram</Option>
                    <Option value="linkedin"><LinkedinOutlined /> LinkedIn</Option>
                    <Option value="pinterest"><PinterestOutlined /> Pinterest</Option>
                </Select>
            </Form.Item>
            {selectedModel === "Oktelo_v2_Pro" && 
            <>
            <Form.Item required label="Szöveg hossza" name="textLength" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '8px' }} >
            <Select showSearch={false} maxCount={1}  maxTagCount={1} allowClear placeholder="Közepes">
                    <Option value="short">Rövid</Option>
                    <Option value="medium">Közepes</Option>
                    <Option value="long">Hosszú</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Szektor" name="industry" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
            <Select showSearch={false} maxCount={1}  maxTagCount={1} allowClear placeholder="Vendéglátás">
                    {industries.map((industry, index) => (
                        <Option key={index} value={industry}>{industry}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Stílus (max. 2)" name="styleTone" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '8px' }}>
                <Select showSearch={false} maxCount={2} mode="multiple" maxTagCount={2} allowClear placeholder="Informatív">
                    {styles.map((style, index) => (
                        <Option key={index} value={style}>{style}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Oldal" name="businessName">
                <Select showSearch={false} maxCount={1}  maxTagCount={1} allowClear placeholder={userPages?.allNames[0]}>
                    {userPages?.allNames.map((name: string, index: number) => (
                        <Option key={index} value={name}>
                            <Space>
                                <StarTwoTone twoToneColor="#925fff"/>
                                {name}
                            </Space>
                        </Option>
                    ))}
                    </Select>
            </Form.Item></>}
{/*             <Form.Item label="Picture instructions (optional)" name="photo_input" extra="You can write your exact photo idea for this post. This has higher priority than keywords in image generation. Leaving it blank will result in a random generation based on your inputs.">
                <Input placeholder="A cozy, modern restaurant interior with white chairs and candles"
                            count={{
                                show: true,
                                max: 64,
                                }}/>
            </Form.Item>     */}   
            <Form.Item>
            <Button type="submit" sx={{ width: 'auto', padding: '8px 20px' }} className="t_oktelo t_desc b_oktelo_purple">Kész! Generálj egy poszot!<AutoAwesomeOutlined sx={{ marginLeft: '6px'}}/></Button>
            </Form.Item>
            {loading &&
            <Stack direction="column" gap={1}>
            <Typography className="t_oktelo t_misc">🚀 A bejegyzés létrehozása folyamatban van. Ez eltarthat néhány másodpercig!</Typography>
            <LinearProgress color="primary" />
            </Stack>}
        </Form>
    );
}

export default AI_PostWriteForm;

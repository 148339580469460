import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Stack,
  Typography,
  Avatar,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import api from "utils/interceptor"
import {
  AddCircleOutlineOutlined,
  AdsClick,
  CalendarMonthOutlined,
  Comment,
  Facebook,
  Favorite,
  FavoriteBorderOutlined,
  FormatListBulleted,
  Instagram,
  LinkedIn,
  PersonAdd,
  PersonAddOutlined,
  Public,
  PublicOutlined,
  SearchRounded,
  TrendingUpOutlined,
  Visibility,
  VisibilityOutlined,
  Window,
} from "@mui/icons-material";
import { SocialPostProps, SocialPostRenderProps } from "interfaces/oktelo";
import {
  EyeOutlined,
  HeartOutlined,
  UserOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import "./posts.css";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { okt_placeholer } from "assets";
import apiconfig from "utils/apiconfig";
import { Input, Select } from "antd";

const { Option } = Select;

type PageInfo = {
  id: string;
  name: string;
  photo: string;
  platform: string;
};

type SocialData = {
  pages: PageInfo[];
};

interface SelectedPageInfoProps {
  name: string;
  photo: string;
  platform: string;
}

const SocialPosts = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<SocialPostProps[]>([]);
  const [postLimit, setPostLimit] = useState(8);
  const [loading, setLoading] = useState(false);
  const [socialPages, setSocialPages] = useState<SocialData>({ pages: [] });
  const [selectedPage, setSelectedPage] = useState<string>(
    localStorage.getItem("sp") || socialPages?.pages[0]?.id || "none"
  );
  const [sortedPosts, setSortedPosts] = useState<SocialPostProps[]>([]);
  const [currentSortBy, setCurrentSortBy] =
    useState<string>("created_time.desc");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isPostLoading, setPostLoading] = useState(false);
  const [selectedPageInfo, setSelectedPageInfo] =
    useState<PageInfo>({ name: "", photo: "", platform: "", id: "" });
  const [filteredPosts, setFilteredPosts] = useState<SocialPostProps[]>([]);

  const updateSelectedPageInfo = (pageId: string) => {
    const page = socialPages.pages.find((p) => p.id === pageId);
    if (page) {
      setSelectedPageInfo({
        id: page.id,
        name: page.name,
        photo: page.photo,
        platform: page.platform,
      });
    }
  };

  const handlePostLimit = (value: number) => {
    setPostLimit(value);
  };

  const handlePageChange = (value: any) => {
    const pageId = value as string;
    setSelectedPage(pageId);
    updateSelectedPageInfo(pageId);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value.toLowerCase();
    setSearchKeyword(keyword);

    const filtered = keyword
      ? posts.filter((post) => post.message.toLowerCase().includes(keyword))
      : posts;

    setFilteredPosts(filtered);
    handleSortBy(currentSortBy, filtered); // Pass filtered posts to avoid redundant sorting
  };

  const handleSortBy = (value: any, postsToSort = filteredPosts) => {
    const selectedSortBy = value as string;
    setCurrentSortBy(selectedSortBy);

    if (!selectedSortBy) return;

    if (postsToSort.length > 0) {
      const [sortTerm, sortType] = selectedSortBy.split(".") as [
        keyof SocialPostProps,
        "asc" | "desc"
      ];

      const sorted = [...postsToSort].sort((a, b) => {
        const aValue = a[sortTerm];
        const bValue = b[sortTerm];

        if (aValue === undefined || bValue === undefined) return 0;

        let comparison = 0;
        if (aValue < bValue) comparison = -1;
        else if (aValue > bValue) comparison = 1;

        return sortType === "asc" ? comparison : -comparison;
      });

      setSortedPosts(sorted);
    } else {
      setSortedPosts([]);
    }
  };

  useEffect(() => {
    if (posts.length > 0) {
      handleSearchChange({
        target: { value: searchKeyword },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [posts]);

  useEffect(() => {
    if (selectedPage === "none" && socialPages.pages.length > 0) {
      const sp = localStorage.getItem("sp") || socialPages.pages[0]?.id;
      setSelectedPage(sp);
      updateSelectedPageInfo(sp);
    }
  }, [socialPages]);

  useEffect(() => {
    if (selectedPage && selectedPage !== "none") {
      updateSelectedPageInfo(selectedPage);
      localStorage.setItem('sp', selectedPage)
    }
  }, [selectedPage]);

  const getSocialPosts = async () => {
    try {
      setPostLoading(true);
      if (selectedPage === "none" || !selectedPage) return;
      const response = await api.post(
        `${apiconfig.BACKEND_URI}/posts/social`,
        {
          limit: postLimit,
          parent: selectedPage,
        }
      );

      if (response.status === 200) {
        setPosts(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPostLoading(false);
    }
  };

  const getSocialPages = async () => {
    try {
      setLoading(true);

      const socialResponse = await api.get(
        `${apiconfig.BACKEND_URI}/data/socials`
      );

      if (socialResponse.status === 200) {
        const allFacebook = socialResponse.data.facebookPages;
        const allInstagram = socialResponse.data.instagramPages;
        const allLinkedin = socialResponse.data.linkedinPages;

        const merge: SocialData = {
          pages: [...allFacebook, ...allInstagram, ...allLinkedin],
        };

        setSocialPages(merge);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSocialPages();
  }, []);

  useEffect(() => {
    getSocialPosts();
  }, [selectedPage, postLimit]);

  if (loading) {
    return (
      <Box width="100%">
        <Typography className="t_oktelo_normal size_18 darkblue">
          Betöltés..
        </Typography>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xl={12} xs={12}>
          <Box className="approval-filter-holder">
            <Grid container spacing={1.5} alignItems="flex-start">
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12} pl={1} pt={1}>
                <Stack direction="column" width="100%" gap={0.5} sx={{ minHeight: '70px' }}>
                  <Typography
                    className="t_oktelo_medium size_12 darkblue"
                    pl={1}
                  >
                    Közösségi oldal
                  </Typography>
                  <Select
                    variant="filled"
                    size="middle"
                    className="approval-filter-input"
                    onChange={handlePageChange}
                    defaultValue={selectedPage}
                  >
                    <Option value="all">Összes</Option>
                    {socialPages &&
                      socialPages.pages &&
                      socialPages.pages.length > 0 &&
                      socialPages.pages.map((p) => (
                        <Option key={p.id} value={p.id}>
                          <Stack direction="row" gap={1} alignItems="center">
                            {p.platform === "facebook" ? (
                              <Facebook
                                sx={{
                                  fontSize: "16px",
                                  color: "#1e90ff !important",
                                }}
                              />
                            ) : p.platform === "instagram" ? (
                              <Instagram
                                sx={{
                                  fontSize: "16px",
                                  color: "#E4405F !important",
                                }}
                              />
                            ) : (
                              <LinkedIn
                                sx={{
                                  fontSize: "16px",
                                  color: "#0077B5 !important",
                                }}
                              />
                            )}
                            <img
                              src={p.photo}
                              style={{
                                objectFit: "contain",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <Typography className="posts-select-item">
                              {p.name}
                            </Typography>
                          </Stack>
                        </Option>
                      ))}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12} pl={1} pt={1}>
                <Stack direction="column" width="100%" gap={0.5} sx={{ minHeight: '70px' }}>
                  <Typography
                    className="t_oktelo_medium size_12 darkblue"
                    pl={1}
                  >
                    Szöveg kereső
                  </Typography>

                  <Input
                    className="approval-filter-input"
                    placeholder="Keresés a szövegben..."
                    prefix={<SearchRounded sx={{ color: "#d3d3d3" }} />}
                    value={searchKeyword}
                    onChange={handleSearchChange}
                    allowClear
                  />
                </Stack>
              </Grid>
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12} pl={1} pt={1}>
                <Stack direction="column" width="100%" gap={0.5} sx={{ minHeight: '70px' }}>
                  <Typography
                    className="t_oktelo_medium size_12 darkblue"
                    pl={1}
                  >
                    Posztok száma
                  </Typography>
                  <Select
                    variant="filled"
                    size="middle"
                    className="approval-filter-input"
                    onChange={(value) => handlePostLimit(value)}
                    defaultValue={8}
                  >
                    <Option value={8}>8 poszt</Option>
                    <Option value={16}>16 poszt</Option>
                    <Option value={32}>32 poszt</Option>
                    <Option value={48}>48 poszt</Option>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xl={3} lg={3} md={12} sm={6} xs={12} pl={1} pt={1}>
                <Stack direction="column" width="100%" gap={0.5} sx={{ minHeight: '70px' }}>
                  <Typography
                    className="t_oktelo_medium size_12 darkblue"
                    pl={1}
                  >
                    Rendezés
                  </Typography>
                  <Select
                    variant="filled"
                    size="middle"
                    className="approval-filter-input"
                    onChange={(value) => handleSortBy(value, filteredPosts)}
                    defaultValue="created_time.desc"
                  >
                    <Option value="created_time.desc">
                      Létrehozva (csökkenő)
                    </Option>
                    <Option value="created_time.asc">
                      Létrehozva (növekvő)
                    </Option>
                    <Option value="impressions.desc">
                      Impressziók (csökkenő)
                    </Option>
                    <Option value="impressions.asc">
                      Impressziók (növekvő)
                    </Option>
                    <Option value="reactions.desc">Reakciók (csökkenő)</Option>
                    <Option value="reactions.asc">Reakciók (növekvő)</Option>
                    <Option value="reach.desc">Elérés (csökkenő)</Option>
                    <Option value="reach.asc">Elérés (növekvő)</Option>
                  </Select>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {isPostLoading && (
        <Box width="100%" p={4}>
          <Stack direction="row" gap={1} alignItems="center">
            <CircularProgress sx={{ color: "#925FFF" }} />
            <Typography className="t_oktelo_medium size_18 t_purple">
              Posztok betöltése..
            </Typography>
          </Stack>
        </Box>
      )}
      {(selectedPage !== "none" &&
      sortedPosts &&
      sortedPosts.length > 0) &&
      !isPostLoading ? (
        <>
          <Grid container spacing={3} mt={2}>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Box className="chart-box info-hover-chart">
                <Grid container spacing={1}>
                  <Grid item xl={8} xs={8}>
                    <Stack direction="column" gap={1}>
                      <Typography className="size_12 t_oktelo_medium darkblue">
                        Impressziók
                      </Typography>
                      <Typography className="size_20 t_oktelo_semibold darkblue">
                        {posts.reduce((sum, post) => sum + post.impressions, 0)}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={4} xs={4}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Avatar
                        sx={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#ede4ff",
                        }}
                      >
                        <VisibilityOutlined
                          sx={{ fontSize: "18px", color: "#925FFF" }}
                        />
                      </Avatar>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Box className="chart-box info-hover-chart">
                <Grid container spacing={1}>
                  <Grid item xl={8} xs={8}>
                    <Stack direction="column" gap={1}>
                      <Typography className="size_12 t_oktelo_medium darkblue">
                        Kattintások
                      </Typography>
                      <Typography className="size_20 t_oktelo_semibold darkblue">
                        {posts.reduce(
                          (sum, post) => sum + post.clicks,
                          0
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={4} xs={4}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Avatar
                        sx={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#ffeff1",
                        }}
                      >
                        <PersonAddOutlined
                          sx={{ fontSize: "18px", color: "#e5556e" }}
                        />
                      </Avatar>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Box className="chart-box info-hover-chart">
                <Grid container spacing={1}>
                  <Grid item xl={8} xs={8}>
                    <Stack direction="column" gap={1}>
                      <Typography className="size_12 t_oktelo_medium darkblue">
                        Reakciók
                      </Typography>
                      <Typography className="size_20 t_oktelo_semibold darkblue">
                        {posts.reduce((sum, post) => sum + post.reactions, 0)}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={4} xs={4}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Avatar
                        sx={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#eff9ff",
                        }}
                      >
                        <FavoriteBorderOutlined
                          sx={{ fontSize: "18px", color: "#55b7e5" }}
                        />
                      </Avatar>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
              <Box className="chart-box info-hover-chart">
                <Grid container spacing={1}>
                  <Grid item xl={8} xs={8}>
                    <Stack direction="column" gap={1}>
                      <Typography className="size_12 t_oktelo_medium darkblue">
                        Elérés
                      </Typography>
                      <Typography className="size_20 t_oktelo_semibold darkblue">
                        {posts.reduce(
                          (sum, post) => sum + (post.reach || 0),
                          0
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={4} xs={4}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Avatar
                        sx={{
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#e5f5ef",
                        }}
                      >
                        <TrendingUpOutlined
                          sx={{ fontSize: "18px", color: "#008e56" }}
                        />
                      </Avatar>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={2}>
            {sortedPosts.map((post) => (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={post.id}>
                {selectedPageInfo && selectedPageInfo.platform === "instagram" ? (
                  <InstagramCardRender
                    post={post}
                    parentName={selectedPageInfo.name}
                    parentPhoto={selectedPageInfo.photo ?? okt_placeholer}
                    selectedPageId={selectedPage || localStorage.getItem('sp') || selectedPageInfo.id}
                    platform="instagram"
                  />
                ) : (
                  <FacebookCardRender
                    post={post}
                    parentName={selectedPageInfo.name}
                    parentPhoto={selectedPageInfo.photo ?? okt_placeholer}
                    selectedPageId={selectedPage || localStorage.getItem('sp') || selectedPageInfo.id}
                    platform="facebook" 
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography className="t_oktelo_normal size_14 darkblue" mt={2}>
          Nincsenek megjeleníthető bejegyzések, vagy nem választottál ki
          közösségi oldalt.
        </Typography>
      )}
    </Box>
  );
};

const FacebookCardRender: React.FC<SocialPostRenderProps> = ({
  post,
  parentName,
  parentPhoto,
  selectedPageId,
  platform
}) => {
  const [isMessageSliced, setMessageSliced] = useState(true);
  const navigate = useNavigate();

  return (
    <Box 
      className="card-post-social" 
      onClick={() => navigate(`/postanalytics/${post.id}`, { 
        state: { 
          post: post, 
          sp: selectedPageId, 
          platform: platform 
        } 
      })}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <img src={parentPhoto} className="social-parent-photo" />
        <Stack direction="column">
          <Typography className="t_oktelo_medium size_12 darkblue">
            {parentName}
          </Typography>
          <Stack direction="row" gap={0.5} alignItems="center">
            <Public sx={{ fontSize: "12px" }} />
            <Typography className="t_oktelo_normal size_12 miscgray">
              {dayjs(post.created_time).format("YYYY.MM.DD HH:mm")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Typography className="t_oktelo_normal size_12 miscgray card-message">
        {isMessageSliced ? post.message.slice(0, 100) : post.message}{" "}
        {isMessageSliced ? (
          <span className="span-btn" onClick={() => setMessageSliced(false)}>
            Továbbiak
          </span>
        ) : (
          <span className="span-btn" onClick={() => setMessageSliced(true)}>
            Elrejtés
          </span>
        )}
      </Typography>
      <Box className="img-holder">
        <img src={post.attachment_image || okt_placeholer} alt="social media post"/>
      </Box>
      <Grid container spacing={1}>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Visibility sx={{ fontSize: "12px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.impressions}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <AdsClick sx={{ fontSize: "14px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.clicks}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Favorite sx={{ fontSize: "14px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.reactions}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Comment sx={{ fontSize: "14px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.comments}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

const InstagramCardRender: React.FC<SocialPostRenderProps> = ({
  post,
  parentName,
  parentPhoto,
  selectedPageId,
  platform
}) => {
  const [isMessageSliced, setMessageSliced] = useState(true);
  const navigate = useNavigate();

  return (
    <Box 
      className="card-post-social" 
      onClick={() => navigate(`/postanalytics/${post.id}`, { 
        state: { 
          post: post, 
          sp: selectedPageId, 
          platform: platform
        } 
      })}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <img src={parentPhoto} className="social-parent-photo" />
        <Stack direction="column">
          <Typography className="t_oktelo_medium size_12 darkblue">
            {parentName}
          </Typography>
          <Typography className="t_oktelo_normal size_12 miscgray">
            {dayjs(post.created_time).format("YYYY.MM.DD HH:mm")}
          </Typography>
        </Stack>
      </Stack>
      <Box className="img-holder">
        <img src={post.attachment_image || okt_placeholer} alt="social media post"/>
      </Box>
      <Typography className="t_oktelo_normal size_12 miscgray card-message">
        <span className="darkblue t_oktelo_medium">{parentName}</span>{" "}
        {isMessageSliced ? post.message.slice(0, 100) : post.message}{" "}
        {isMessageSliced ? (
          <span className="span-btn" onClick={() => setMessageSliced(false)}>
            Továbbiak
          </span>
        ) : (
          <span className="span-btn" onClick={() => setMessageSliced(true)}>
            Elrejtés
          </span>
        )}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Visibility sx={{ fontSize: "12px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.impressions}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <AdsClick sx={{ fontSize: "14px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.clicks}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Favorite sx={{ fontSize: "14px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.reactions}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xl={3} xs={3}>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="center"
          >
            <Comment sx={{ fontSize: "14px" }} />
            <Typography className="t_oktelo_normal size_12 darkblue">
              {post.comments}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SocialPosts;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  Grid,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Collapse,
  Checkbox,
  Avatar,
} from "@mui/material";
import api from "utils/interceptor"
import { OkteloPostProps, OkteloPreview } from "interfaces/oktelo";
import { logo, okt_placeholer, oktelo } from "assets";
import dayjs from "dayjs";
import { useNotification } from "@refinedev/core";
import { Form, Input, Progress, Tag, Tooltip } from "antd";
import InputAdornment from "@mui/material/InputAdornment";



import {
  Check,
  CheckCircle,
  CheckOutlined,
  Close,
  CloseOutlined,
  CommentOutlined,
  Event,
  Facebook,
  Instagram,
  Key,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Person,
  PlayArrowOutlined,
  SearchRounded,
  Visibility,
} from "@mui/icons-material";
import apiconfig from "utils/apiconfig";


interface DrawPost {
  data: OkteloPreview;
  post: OkteloPostProps;
  handleActionClick: (postId: string, action: string) => void;
  onFinish: (postId: string, values: any) => void;
  isLightBoxOpen: boolean;
  setLightBoxOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const { TextArea } = Input;


const PreviewDisplay = () => {
  const { open } = useNotification();
  const navigate = useNavigate();
  const { previd } = useParams();
  const [data, setData] = useState<OkteloPreview | null>(null);
  const [password, setPassword] = useState<string>("");
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [formActions, setFormActions] = useState<{ [key: string]: string }>({});
  const [refetch, setRefetch] = useState<boolean>(false);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [isLightboxOpen, setLightboxOpen] = useState(false);

  const handleOpenLightbox = () => {
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
  };

  useEffect(() => {
    if (isLightboxOpen) {
      // Disable background scroll
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable background scroll
      document.body.style.overflow = '';
    }
    
    // Cleanup to reset overflow on component unmount
    return () => {
      document.body.style.overflow = '';
    };
  }, [isLightboxOpen]);

  const toggleRowExpansion = (rowId: any) => {
    setExpandedRowId((prevRowId) => (prevRowId === rowId ? null : rowId));
  
    // Check if data and posts are available
    if (!data || !data.posts) {
      console.error('Data or posts are not available');
      return;
    }
  
    const getpost = data.posts.find(post => post._id === rowId);
    
    // Log if post is not found
    if (!getpost) {
      console.error('Post not found for rowId:', rowId);
    }
    
    setSelectedPost(getpost);
  };
  document.title = "Jóváhagyás | Oktelo App";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedAuthCodesJson = localStorage.getItem("pac");
        const storedAuthCodes = storedAuthCodesJson
          ? JSON.parse(storedAuthCodesJson)
          : {};

        if (storedAuthCodes && previd && storedAuthCodes[previd]) {
          setAuthenticated(true);
          await authenticate(previd, storedAuthCodes[previd]);
        } else {
          await authenticate(previd ? previd : "", "");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [previd, refetch]);

  const authenticate = async (previd: string, code: string) => {
    try {
      const response = await api.post(
        `${apiconfig.BACKEND_URI}/previews/show/${previd}`,
        { code }
      );

      if (response.status === 200) {
        setAuthenticated(true);
        setData(response.data);
        if (code) {
          const storedAuthCodesJson = localStorage.getItem("pac");
          const storedAuthCodes = storedAuthCodesJson
            ? JSON.parse(storedAuthCodesJson)
            : {};
          storedAuthCodes[previd] = code;
          localStorage.setItem("pac", JSON.stringify(storedAuthCodes));
        }
      } else if (response.status === 206) {
        setData(response.data);
        if (response.data.access.everyone) {
          setAuthenticated(true);
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        open?.({
          type: "error",
          message: "Hibás bejelentkezési adatok.",
        });
      } else {
        open?.({
          type: "error",
          message: "Hiba történt. A belépés sikertelen.",
        });
      }
    }
  };

  const handleAuthentication = async () => {
    await authenticate(previd ? previd : "", password);
  };



  const getApprovalStatus = (type: string) => {
    if (type === "approved") {
      return (
        <Tag
          color="#D3FFB0"
          style={{ color: "#5A8F30" }}
          className="tag_oktelo size_12 t_oktelo_medium"
          bordered={false}
        >
          Jóváhagyott
        </Tag>
      );
    } else if (type === "pending") {
      return (
        <Tag
          color="#FFEEB0"
          style={{ color: "#934F00" }}
          className="tag_oktelo size_12 t_oktelo_medium"
          bordered={false}
        >
          Függőben
        </Tag>
      );
    } else if (type === "rejected") {
      return (
        <Tag
          color="#FFB0B0"
          style={{ color: "#810000" }}
          className="tag_oktelo size_12 t_oktelo_medium"
          bordered={false}
        >
          Elutasítva
        </Tag>
      );
    } else if (type === "noNeed") {
      return (
        <Tag
          color="#B0FFFA"
          style={{ color: "#00524D" }}
          className="tag_oktelo size_12 t_oktelo_medium"
          bordered={false}
        >
          Nem szükséges
        </Tag>
      );
    } else {
      return (
        <Tag
          color="#F6FF93"
          style={{ color: "#6A7300" }}
          className="tag_oktelo size_12 t_oktelo_medium"
          bordered={false}
        >
          Nincs adat
        </Tag>
      );
    }
  };

  const needRefetch = () => {
    if (refetch) {
      setRefetch(false);
    } else {
      setRefetch(true);
    }
  };

  const openNotification = (type: any, message: string) => {
    open?.({
      type: type,
      message: message,
    });
  };

  const handleActionClick = (postId: string, action: string) => {
    setFormActions((prevActions) => ({
      ...prevActions,
      [postId]: action,
    }));
  };

  const onFinish = async (postId: string, values: any) => {
    try {
      const action = formActions[postId];

      // Filter form values to include only those related to the specific post
      const filteredValues = Object.keys(values)
        .filter((key) => key.endsWith(postId))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: values[key],
          };
        }, {} as { [key: string]: any });

      const sendUpdate = {
        data: filteredValues,
        action,
        postid: postId,
        previd: previd,
      };

      const response = await api.post(
        `${apiconfig.BACKEND_URI}/previews/update`,
        sendUpdate
      );

      if (response.status === 200) {
        openNotification("success", "Sikeres állapotfrissítés.");
      } else {
        openNotification("error", "Sikertelen frissítés.");
      }
    } catch (error) {
      openNotification("error", "Hiba történt.");
    } finally {
      needRefetch();
    }
  };

  if (!data) {
    return <div>Betöltés...</div>;
  }

  document.body.style.backgroundColor = "#fff";

  if (!authenticated) {
    return (
      <main style={{ minHeight: '100vh', flex: 1, overflow: 'clip', display: 'flex' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        alignSelf="center"
      >
        <Box
          className="oktelo_card"
          alignItems="center"
          width="90%"
          maxWidth="640px"
          justifyContent="center"
          p={4}
        >
          <Typography
            className="t_oktelo_bold size_20 darkblue"
            mt={2}
            sx={{ textAlign: "center" }}
          >
            Meghívót kaptál bejegyzések jóváhagyására
          </Typography>
          <Typography className="t_oktelo_normal size_14 miscgray" textAlign={"center"} my={1}>
            Ez egy Oktelo meghívó, amihez kód szükséges. Az azonosítás
            után látni fogod a jóváhagyásra váró bejegyzéseket.
          </Typography>
          <Box width="100%" sx={{ color: "red" }}>
            <TextField
              type="password"
              value={password}
              variant="standard"
              onChange={(e) => setPassword(e.target.value)}
              sx={{ my: 2, borderBottom: "1px solid #d3d3d3", width: "100%" }}
              color="info"
              className="t_oktelo_normal size_14 darkblue"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Key sx={{ color: "#d3d3d3" }} />
                  </InputAdornment>
                ),
                style: { color: "#925FFF" },
              }}
            />
          </Box>
          <Button
            className="login_primary_button"
            onClick={handleAuthentication}
          >
            Megerősítés
          </Button>
          <Button
            className="login_secondary_button"
            onClick={handleAuthentication}
          >
            Hogyan tudok belépni?
          </Button>
          <a href="https://oktelo.com">
            <img src={oktelo} width="120px" style={{ marginTop: "32px" }} />
          </a>
          <a
            href="https://oktelo.com"
            className="t_oktelo_normal size_12 darkblue"
            target="_blank"
          >
            Főoldal
          </a>
          <a
            href="https://oktelo.com/adatkezelesi-tajekoztato/"
            className="t_oktelo_normal size_12 darkblue"
            target="_blank"
          >
            Adatkezelési tájékoztató
          </a>
        </Box>
      </Box>
      </main>
    );
  }

  if (data && data.posts) {
    const approvedPosts = data.posts.filter(
      (post) => post.approval.status === "approved"
    ).length;
    const notApprovedPosts = data.posts.length;
    const pendingPosts = data.posts.filter(
      (post) => post.approval.status === "pending"
    ).length;
    const rejectedPosts = data.posts.filter(
      (post) => post.approval.status === "rejected"
    ).length;

    return (
      <main style={{ minHeight: '100vh', flex: 1, overflow: 'clip' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        width="100%"
      >
        {/* Approval heading */}
<Stack
  direction={{ xl: "row", sm: "column" }}
  alignItems={{ xl: "center", sm: "flex-start" }}
  justifyContent="space-between"
  maxHeight="200px"
  height="auto"
  width="100%"
  bgcolor="#fff"
  border="1px solid #e0e0e0"
  borderRadius="8px"
  boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
  px={3}
  py={2.5}
  gap={2}
  mb={3}
>
    <Stack direction="row" gap={2} alignItems="center">
      <a href="https://oktelo.com" target="_blank">
        <img src={logo} width="42px" />
      </a>
      <Stack direction="column" gap={0}>
        <Typography className="t_oktelo_semibold size_18 darkblue">Jóváhagyás</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Event sx={{ fontSize: '18px', color: '#925fff'}} />
          <Typography className="t_oktelo_light size_14 darkblue">
            {dayjs(data.createdAt).format("YYYY.MM.DD HH:mm")}
          </Typography>
        </Stack>
      </Stack>
{/*       <Stack direction="row" gap={1} alignItems="center">
        <CheckCircle fontSize="small" />
        <Typography className="t_oktelo_normal size_14 t_purple">
          Státusz: {approvedPosts}/{notApprovedPosts}
        </Typography>
      </Stack> */}
    </Stack>
    <Stack
      direction={{ xl: "column", sm: "row" }}
      alignItems={{ xl: "flex-start", sm: "center" }}
      justifyContent={{ xl: "center", sm: "flex-start" }}
      gap={0}
      width={{ xl: "auto", sm: "100%" }}
    >
      <Typography className="t_oktelo_normal size_14 darkblue">
        Létrehozta:
      </Typography>
      <Stack direction="row" gap={1} alignItems="center">
{/*         <Person fontSize="small" /> */}
        <Typography className="t_oktelo_light size_14 miscgray">{data.creator}</Typography>
      </Stack>
    </Stack>
  </Stack>
  <Box p={2} width='100%'>
      <Grid container spacing={2}>
        <Grid item xl={3}>
          <Box className="chart-box">
            <Stack direction="row" gap={2} alignItems="center" width="100%" justifyContent="space-between">
              <Stack direction="column" gap={0}>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Jóváhagyott
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography className="text-monster t_oktelo_bold size_32">
                    {approvedPosts}
                  </Typography>
                  <Typography className="t_oktelo_semibold size_14 miscgray">
                    / {data.posts.length}
                  </Typography>
                </Stack>
              </Stack>
              <Avatar sx={{ backgroundColor: '#f9f9f9', width: '36px', height: '36px', borderRadius: '0px' }}>
                <Check/>
              </Avatar> 
            </Stack>
            <Progress
            percent={(approvedPosts / data.posts.length) * 100}
            style={{ width: '100%' }}
            strokeColor="#89e85d"
            showInfo={false}/>
          </Box>
        </Grid>
        <Grid item xl={3}>
        <Box className="chart-box">
            <Stack direction="row" gap={2} alignItems="center" width="100%" justifyContent="space-between">
              <Stack direction="column" gap={0}>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Függőben
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography className="text-monster t_oktelo_bold size_32">
                    {pendingPosts}
                  </Typography>
                  <Typography className="t_oktelo_semibold size_14 miscgray">
                    / {data.posts.length}
                  </Typography>
                </Stack>
              </Stack>
              <Avatar sx={{ backgroundColor: '#f9f9f9', width: '36px', height: '36px', borderRadius: '0px' }}>
                <Check/>
              </Avatar> 
            </Stack>
            <Progress
            percent={(pendingPosts / data.posts.length) * 100}
            style={{ width: '100%' }}
            strokeColor="#F5B041"
            showInfo={false}/>
          </Box>
        </Grid>
        <Grid item xl={3}>
        <Box className="chart-box">
            <Stack direction="row" gap={2} alignItems="center" width="100%" justifyContent="space-between">
              <Stack direction="column" gap={0}>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Elutasítva
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography className="text-monster t_oktelo_bold size_32">
                    {rejectedPosts}
                  </Typography>
                  <Typography className="t_oktelo_semibold size_14 miscgray">
                    / {data.posts.length}
                  </Typography>
                </Stack>
              </Stack>
              <Avatar sx={{ backgroundColor: '#f9f9f9', width: '36px', height: '36px', borderRadius: '0px' }}>
                <Check/>
              </Avatar> 
            </Stack>
            <Progress
            percent={(rejectedPosts / data.posts.length) * 100}
            style={{ width: '100%' }}
            strokeColor="#e85d7e"
            showInfo={false}/>
          </Box>
        </Grid>
        <Grid item xl={3}>
        <Box className="chart-box" height="100%">
            <Stack direction="row" gap={2} alignItems="center" width="100%" justifyContent="space-between">
              <Stack direction="column" gap={0}>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  Megjegyzések
                </Typography>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography className="text-monster t_oktelo_bold size_32">
                    {data.comments.length}
                  </Typography>
                </Stack>
              </Stack>
              <Avatar sx={{ backgroundColor: '#f9f9f9', width: '36px', height: '36px', borderRadius: '0px' }}>
                <Check/>
              </Avatar> 
            </Stack>
            <Progress
            percent={(data.comments.length / data.posts.length) * 100}
            style={{ width: '100%' }}
            strokeColor="#5d84e8"
            showInfo={false}/>
          </Box>
        </Grid>
      </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" width='100%'>
        <Typography className="t_oktelo_medium size_24 darkblue">
            Bejegyzés lista
        </Typography>
        <Typography className="t_oktelo_normal size_14 darkblue">
            Jóváhagyva: {approvedPosts} / {notApprovedPosts} bejegyzés
        </Typography>
        </Stack>

        <Grid container spacing={0}>
            <Grid item xs={12} display="flex" marginTop={1} sx={{ overflowX: 'hide' }}>
            <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <TableContainer component={Paper} className="oktelo_approve_table">
            <Table sx={{ width: "100%", minWidth: '340px', tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Szöveg</TableCell>
                  <TableCell>Státusz</TableCell>
                  <TableCell>Módosítás</TableCell>
                  <TableCell>Megjegyzések</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.posts &&
                  data.posts.map((post: any) => (
                    <React.Fragment key={post._id}>
                      <TableRow
                        sx={{
                            '&:hover': {
                              backgroundColor: '#f9f9f9',
                              cursor: 'Pointer',
                            },
                          }}
                         onClick={() => toggleRowExpansion(post._id)} 
                      >
                     <TableCell padding="checkbox">
                      <Checkbox
                        color="success"
                        sx={{ background: '#f6f6f6', color: '#925FFF' }}
                        inputProps={{
                          'aria-labelledby': post._id,
                        }}
                      />
                    </TableCell>
                        <TableCell>
                          <Stack direction="row" alignItems="center" gap={1}>
                        {post.platform === "facebook" ? (
                            <Facebook className="size_14" sx={{ marginRight: '4px', color: '#1e90ff'}}/>
                          ) : (
                            <Instagram className="size_14" sx={{ marginRight: '4px', color: '#C74EE5' }}/>
                          )}
                          <img src={post.attachment.url.photoUrl || post.attachment.url.thumbnailUrl || okt_placeholer}
                          style={{ width: '40px', height: '40px', borderRadius: '50%' }}/>
                          <Typography className="t_oktelo_normal size_14 darkblue">
                            {post.message && post.message.slice(0, 32)}... 
                          </Typography> 
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {getApprovalStatus(post.approval.status)}
                        </TableCell>
                        <TableCell>
                          {data.comments
                            .filter((comment) => comment.postId === post._id)
                            .slice(-1)
                            .map((comment) => (
                              <>
                              <Stack direction="row" alignItems="center" gap={1} key={comment._id}>
                                <Typography className="t_oktelo_medium size_14 darkblue">
                                  {comment.from}
                                </Typography>
                                <Typography className="t_oktelo_light size_12 darkblue">
                                  {dayjs(comment.date).format("YYYY.MM.DD HH:mm")}
                                </Typography>
                                {dayjs().diff(dayjs(comment.date), 'hour') <= 24 && 
                                <Tag className="tag_oktelo size_10 t_oktelo_medium" bordered={false} color="purple" style={{ marginLeft: '4px', width: '40px' }}>
                                  Új
                                </Tag>
                              } 
                              </Stack>     
                              </>
                            ))}
                        </TableCell>
                        <TableCell>
                        {data.comments
                            .filter((comment) => comment.postId === post._id).length} üzenet
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => toggleRowExpansion(post._id)}
                            className="size_14 darkblue"
                          >
                            {expandedRowId === post._id ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>   
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box></Box>
          </Grid>
        </Grid>
        {selectedPost && (expandedRowId === selectedPost._id) && (
          <Box  mt={3} width="100%">
              <Collapse
                in={expandedRowId === selectedPost._id}
                timeout="auto"
                unmountOnExit
                sx={{ padding: 0, width: '100%' }}
              >
                {selectedPost !== null &&
                <DrawApprovePost 
                data={data} 
                post={{ ...selectedPost, data: selectedPost, parentName: '', parentPic: '' }} 
                onFinish={onFinish} 
                isLightBoxOpen={isLightboxOpen}
                setLightBoxOpen={setLightboxOpen}
                handleActionClick={handleActionClick}/>
                }
                </Collapse>
          </Box>
        )}
        </Box>
      </Box>
      {isLightboxOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(4px)',
            zIndex: 1000,
            overflow: 'hidden',
            pointerEvents: 'auto'
          }}
          onClick={handleCloseLightbox}
        >
          <Box
            sx={{
              position: 'relative',
              width: {xs: '90%', sm: '80%', md: '400px'},
              maxWidth: '800px',
              backgroundColor: '#000',
              borderRadius: '0px',
              overflow: 'hidden',
              zIndex: 1001,
            }}
            onClick={(e) => e.preventDefault()}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#fff',
                zIndex: 1003,
              }}
              onClick={handleCloseLightbox}
            >
              <Close />
            </IconButton>
            {selectedPost.attachment.url.photoUrl ?

            <img src={selectedPost.attachment.url.photoUrl}
            style={{ width: '100%', height: 'auto', zIndex: 1002}}/>
            
            : selectedPost.attachment.url.videoUrl ?

            <video
            controls
            style={{ width: '100%', height: 'auto', zIndex: 1002 }}
            src={selectedPost.attachment.url?.videoUrl}
            autoPlay={isLightboxOpen}
            />

            : selectedPost.attachment.url.carouselUrl.length > 0 ?

            <></>

            : selectedPost.attachment.url.webUrl ?
              <Box>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {selectedPost.attachment.url.webUrl}
                </Typography>
              <button className="home-action-button b925">
                Hivatkozás megnyitása
              </button>
                <Typography className="t_oktelo_light size_12 miscgray">
                  *Csak saját felelősségre
                </Typography>
              </Box>
            :
            <Typography>
              Nincs csatolmány.
            </Typography>
            }

          </Box>
        </Box>
      )}
      </main>
    );
  } else {
    return <div>Hiba</div>;
  }
};

const DrawApprovePost: React.FC<DrawPost> = ({ data, post, onFinish, handleActionClick, isLightBoxOpen, setLightBoxOpen }) => {

  const _post = post;

  const returnImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return null;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video" || type === "reels") {
      return url.thumbnailUrl || null;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return null;
    } else {
      return okt_placeholer;
    }
  };

  const [isImage, setIsImage] = useState(false);
  const isValidUrl = (urlString: string): boolean => {
      try {
        new URL(urlString);
        return true;
      } catch (e) {
        return false;
      }
    };

    const checkIfImageUrl = (url: string): boolean => {
      const imageExtensions = ['.jpeg', '.jpg', '.png', '.gif', '.svg', '.webp', '.bmp'];

      const lowerCaseUrl = url.toLowerCase();
      const isImageExtension = imageExtensions.some(ext => lowerCaseUrl.includes(ext));

      return isValidUrl(url) && (isImageExtension);
    };

    useEffect(() => {
      if(_post.attachment.url?.webUrl !== ''){
        setIsImage(checkIfImageUrl(_post.attachment.url?.webUrl || ''));
      }
    }, [_post.attachment.url?.webUrl]);

  return (
    <Grid container spacing={2} >
      
          <Grid item xl={4} xs={12}>
      <Box className="oktelo_card" height="100%">
        {_post.platform === 'facebook' ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              px={1}
              py={0}
              sx={{ backgroundColor: "#fff", mb: 1 }}
            >
              <Typography
                className="size_24 t_oktelo_bold"
                sx={{ color: "#1e90ff" }}
              >
                facebook
              </Typography>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <img src={post.parent && post.parent.photo ? post.parent.photo : okt_placeholer}
                style={{ width: '40px', height: '40px', borderRadius: '50%'}}
              />
              <Typography className="t_oktelo_medium size_14 darkblue">
                {_post.parent?.name || 'Példa oldal'}
              </Typography>
            </Stack>

            <Typography className="t_oktelo_normal size_14 darkblue" mb={1}>
              <span
                className="t_oktelo_normal preview-text-create"
                style={{
                  fontSize: _post.message && _post.message.length < 85 ? "24px" : "14px",
                  lineHeight: _post.message && _post.message.length < 85 ? "28px" : "18px",
                }}
              >
                {_post.message && _post.message.length >= 140 ? (
                  <>
                    {_post.message.slice(0, 140)}
                    <span className="t_oktelo_medium"> Továbbiak</span>
                  </>
                ) : (
                  _post.message
                )}
              </span>
            </Typography>
            {_post.attachment.type !== "textOnly" && _post.attachment.type !== "webUrl" && (
              <Box sx={{ width: '100%', height: '400px', position: 'relative', backgroundColor: '#f9f9f9' }}>
              <img 
                src={returnImageSrc(_post.attachment.type, _post.attachment.url)} 
                style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
              />
              {_post.attachment.type === "video" &&
                <button
                style={{ position: 'absolute', top: '20px', left: '20px' }}
                className="home-action-button bc6a"
                onClick={() => window.open(post.attachment.url?.videoUrl)}>
                  <PlayArrowOutlined/> Videó megtekintése
                </button>}
              </Box>
            )}
            {_post.attachment.type === "webUrl" && (
              isImage ? (
                <img
                  key={_post.attachment.url?.webUrl+post._id}
                  src={_post.attachment.url?.webUrl}
                  alt="facebook post link preview"
                  height="auto"
                  style={{ objectFit: 'contain', borderRadius: '0px', height: '400px', position: 'relative' }}
                  className="property_details-img"
                />
              ) : (
                <Box
                  sx={{
                    height: '500px',
                    width: '100%',
                    background: '#f6f6f6',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography className="t_oktelo t_highlight t_important">URL Előnézet</Typography>
                  <Typography className="t_oktelo t_misc" p={2}>
                    A Facebook bejegyzésed kattintható előnézetként tartalmazza az URL-t. Ha az URL egy webhely, akkor egy fénykép jelenik meg, például az alapértelmezett közösségi megosztási fotó a SEO beállításokból.
                  </Typography>
                  <Tooltip title="Biztonsági okokból, webhely előnézeteket nem tudunk megmutatni. A posztod egy hivatkozást fog tartalmazni a megadott URL-re mutatva. Az URL nyitóképe lesz itt látható.">
                    <Typography className="t_oktelo t_desc t_link">Miért ezt látom?</Typography>
                  </Tooltip>
                </Box>
              )
            )}
          </>
        ) : (
          post.platform === "instagram" ? (
            <>
            <Stack
              direction="row"
              justifyContent="flex-start"
              px={2}
              py={1}
              sx={{ backgroundColor: "#fff", mb: 1 }}
            >
              <Typography
                className="size_24 t_oktelo_bold"
                sx={{ color: "#C74EE5" }}
              >
                instagram
              </Typography>
            </Stack>
            {_post.attachment.type !== "textOnly" && _post.attachment.type !== "webUrl" && (
              <Box sx={{ width: '100%', height: '400px', position: 'relative' }}>
              <img 
                src={returnImageSrc(_post.attachment.type, _post.attachment.url)} 
                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              />
              {_post.attachment.type === "video" &&
                <button
                style={{ position: 'absolute', top: '20px', left: '20px' }}
                className="home-action-button bc6a"
                onClick={() => window.open(post.attachment.url?.videoUrl)}>
                  <PlayArrowOutlined/> Videó megtekintése
                </button>}
              </Box>
            )}
            {_post.attachment.type === "webUrl" && (
              isImage ? (
                <img
                  key={_post.attachment.url?.webUrl+post._id}
                  src={_post.attachment.url?.webUrl}
                  alt="instagram post link preview"
                  height="auto"
                  style={{ objectFit: 'cover', borderRadius: '0px', height: '400px', position: 'relative' }}
                  className="property_details-img"
                />
              ) : (
                <Box
                  sx={{
                    height: '500px',
                    width: '100%',
                    background: '#f6f6f6',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography className="t_oktelo t_highlight t_important">Hibás kép link</Typography>
                  <Tooltip title="Biztonsági okokból, webhely előnézeteket nem tudunk megmutatni. A posztod egy hivatkozást fog tartalmazni a megadott URL-re mutatva. Az URL nyitóképe lesz itt látható.">
                    <Typography className="t_oktelo t_desc t_link">Miért ezt látom?</Typography>
                  </Tooltip>
                </Box>  
              ) 
            )}
          <Stack p={1}>
          <Typography className="t_oktelo_normal darkblue size_14" sx={{ wordWrap: 'break-word', textWrap: 'wrap'}}>
            <span style={{fontWeight: '500'}}>
              {post.parent?.name || 'Példa oldal'}
            </span>
          {" "}

          {post.message && post.message.slice(0,77)}
          
          { post.message && post.message.length > 77 ? <span className="t_oktelo_light">... tovább</span> : ''}
            
          </Typography>
        </Stack>
          </>
          ) : (
            <></>
          )
        )}
        <button className="home-action-button b925" onClick={() => setLightBoxOpen(!isLightBoxOpen)}>
          Csatolmány megnyitása <Visibility/>
        </button>
      </Box>
    </Grid>
      <Grid item xl={8}  pr={1.5} xs={12}>
        <Box className="oktelo_card">
        <Stack direction="column" gap={2} width="auto" flexWrap="wrap">
            <Typography className="darkblue size_16 t_oktelo_medium">
                Bejegyzés szövege
            </Typography>
            <Typography className="t_oktelo_normal size_14 miscgray">
                {_post.message? _post.message : '- Nincs szöveges tartalom.'}
            </Typography>
            {_post.hashtagTemplate &&
            <Typography className="t_oktelo_normal size_14 t_purple">
                {_post.hashtagTemplate.hashtags.join(' ')}
            </Typography>}
            <Typography className="darkblue size_16 t_oktelo_medium">
                Módosítások és történések
            </Typography>
            {data.comments.filter(
                (comment) => comment.postId === _post._id
            ).length < 1 && (
                <Typography className="t_oktelo_normal size_14 miscgray">
                Nem érkezett javaslat / megjegyzés ehhez a bejegyzéshez.
                </Typography>
            )}
            {data.comments
                .filter((comment) => comment.postId === _post._id)
                .map((comment) => (
                <Stack
                    key={comment._id}
                    direction="column"
                    gap={0.5}
                    p={1.5}
                    sx={{ borderLeft: "2px solid", borderColor: comment.type === 'comment' ? '#1e90ff' : comment.type === 'reject' ? '#dc2743' : '#50C878',borderRadius: "0px", backgroundColor: "#f9f9f9" }}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography className="t_oktelo_medium size_14 darkblue">
                    {comment.from}
                    </Typography>
                    <Tag color={comment.type === 'comment' ? 'blue' : comment.type === 'reject' ? 'red' : 'green'}>
                    {comment.type === 'comment'? "Megjegyzés" : comment.type === 'reject' ? 'Elutasítás' : 'Jóváhagyás'}
                    </Tag>
                    <Typography
                        className="t_oktelo_light size_12 miscgray"
                      >
                        {dayjs(comment.date).format(
                        "YYYY.MM.DD HH:mm:ss"
                        )}
                    </Typography>

                    </Stack>
                    <Typography className="t_oktelo_light size_14 darkblue">
                    {comment.message}
                    </Typography>
                </Stack>
                ))}
                  <Typography className="darkblue size_14 t_oktelo_medium">
                    Új hozzáadása
                  </Typography>
                <Box width="100%" bgcolor="#fff" py={2} px={2.5} borderRadius="0px" sx={{
                  border: '1px solid #d9d9d9'
                }}>
                    <Form
                      onFinish={(values) => onFinish(_post._id, values)}
                      layout="vertical"
                      variant="outlined"
                      className="ai_form"
                      scrollToFirstError
                      style={{ width: "100%" }}
                      initialValues={{ [`postid_${_post._id}`]: _post._id }}
                      id={`previewform_${_post._id}`}
                    >
                      <Form.Item
                        name={`from_${_post._id}`}
                        label="Név/e-mail"
                        required
                        className="t_oktelo_normal"
                        rules={[
                          {
                            required: true,
                            message: "Kötelező nevet/emailt megadni. Ezzel azonosítható, hogy ki frissítette a jóváhagyást.",
                          },
                        ]}
                        extra="Add meg a neved, vagy e-mail címed, hogy a bejegyzés készítője tudja, hogy ki végezte el a módosítást."
                      >
                        <Input 
                        prefix={<Person sx={{ fontSize: '16px', color: '#d9d9d9'}}/>}
                        allowClear
                        placeholder="Azonosító név, vagy email"/>
                      </Form.Item>
                      <Form.Item
                        name={`message_${_post._id}`}
                        label="Megjegyzés"
                        extra="Opcionális: Szöveges üzenet, ami megjelenik mindhárom frissítésnél."
                      >
                        <TextArea 
                          placeholder="Szuper lett a poszt!"/>
                      </Form.Item>
                      <Form.Item name={`postid_${_post._id}`} hidden>
                        <Input value={_post._id} 
                        />
                      </Form.Item>
                      <Stack direction="row" gap={2} alignItems="center" width='100%' justifyContent="flex-end">
                        <button
                          className="approve-button approval-btn-hover"
                          onClick={() => handleActionClick(_post._id, "approve")}
                          type="submit"
                        >
                          <CheckOutlined/>
                          Jóváhagyás
                        </button>
                        <button
                          className="comment-button approval-btn-hover"
                          onClick={() => handleActionClick(_post._id, "comment")}
                          type="submit"
                        >
                          <CommentOutlined/>
                          Új megjegyzés
                        </button>
                        <button
                          className="reject-button approval-btn-hover"
                          onClick={() => handleActionClick(_post._id, "reject")}
                          type="submit"
                        >
                          <CloseOutlined/>
                          Elutasítás
                        </button>
                      </Stack>
                    </Form>
                  </Box>
        </Stack>
        </Box>
      </Grid>
    </Grid>
    
  );
};

export default PreviewDisplay;

import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

const { Option, OptGroup } = Select;


interface CustomModalProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
  }

const NotesModal: React.FC<CustomModalProps> = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const handleCreate = () => {
    form.validateFields().then(values => {
      form.resetFields();
      onCreate(values);
    });
  };

  return (
    <Modal
      open={visible}
      title="Új jegyzet létrehozása"
      okText="Mentés"
      cancelText="Mégsem"
      onCancel={onCancel}
      onOk={handleCreate}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Cím" rules={[{ required: true, message: 'Kötlező elnevezni a jegyzeted.' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Megjegyzés" rules={[{ required: false, message: '' }]}> 
          <Input.TextArea defaultValue=" "/>
        </Form.Item>
        <Form.Item name="date" label="Naptári idő" rules={[{ required: true, message: 'Válassz egy dátumot.' }]}>
          <DatePicker showTime format="YYYY.MM.DD HH:mm" />
        </Form.Item>
        <Form.Item name="color" label="Szín" extra="Megkülönböztetheted a jegyzeteidet színekkel.">
            <Select 
              showSearch={false} 
              placeholder="Select color"
              onChange={(value) => form.setFieldsValue({ color: value })}
            >
              <Option value="#E5E1DA" style={{ backgroundColor: '#E5E1DA' }}>Szürke</Option>
              <Option value="#F8E559" style={{ backgroundColor: '#F8E559' }}>Sárga</Option>
              <Option value="#99DBF5" style={{ backgroundColor: '#99DBF5' }}>Kék</Option>
              <Option value="#74E291" style={{ backgroundColor: '#74E291' }}>Zöld</Option>
              <Option value="#F9B572" style={{ backgroundColor: '#F9B572' }}>Narancs</Option>
              <Option value="#FFDAB9" style={{ backgroundColor: '#FFDAB9' }}>Barack</Option>
              <Option value="#FBA1B7" style={{ backgroundColor: '#FBA1B7' }}>Piros</Option>
              <Option value="#ADA2FF" style={{ backgroundColor: '#ADA2FF' }}>Lila</Option>
            </Select>
          </Form.Item>
          <Form.Item name="icon" label="Ikon" extra="Egyedi ikon ehhez a jegyzethez.">
            <Select 
              showSearch={false} 
              placeholder="Ikon lista.."
              onChange={(value) => form.setFieldsValue({ icon: value })}
            >
              <OptGroup label="Items">
                <Option value="🏆">🏆 Trófea</Option>
                <Option value="🚀">🚀 Rakéta</Option>
                <Option value="💰">💰 Pénz</Option>
                <Option value="📢">📢 Megafon</Option>
                <Option value="⏰">⏰ Ébresztő</Option>
                <Option value="🎤">🎤 Mikrofon</Option>
                <Option value="🥂">🥂 Koccintás</Option>
                <Option value="⭐">⭐ Csillag</Option>
                <Option value="🎈">🎈 Lufi</Option>
                <Option value="💻">💻 Laptop</Option>
                <Option value="📷">📷 Kamera</Option>
                <Option value="🎥">🎥 Videó</Option>
                <Option value="🎁">🎁 Ajándék</Option>
              </OptGroup>
              <OptGroup label="Misc">
                <Option value="❤️">❤️ Szív</Option>
                <Option value="⚠️">⚠️ Veszély</Option>
                <Option value="⛔">⛔ Stop</Option>
                <Option value="❓">❓ Kérdés</Option>
                <Option value="✔️">✔️ Pipa</Option>
                <Option value="❌">❌ Hiba</Option>
              </OptGroup>
            </Select>
          </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotesModal;

import { ApexOptions } from 'apexcharts';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

interface ChartData {
    [metricName: string]: { name: string, data: number }[];
}

// Type for individual metric values
interface SBMetricValue {
  [category: string]: number;
}

// Type for each metric entry
interface SBMetricData {
  value: SBMetricValue;
  date: string;
}

// Type for transformed chart data
interface SBMetricDataValues {
  [date: string]: SBMetricValue;
}

interface ChartProps {
    title: string;
    chartData: SBMetricDataValues;
}

interface SeriesData {
  name: string;
  data: number[];
}

const metricNameMapping: { [key: string]: string } = {
    'M.55-64': "Férfi (55-64)",
    'F.13-17': "Nő (13-17)",
    'U.45-54': "Ism. (45-54)",
    'U.35-44': "Ism. (35-44)",
    'F.45-54': "Nő (45-54)",
    'M.18-24': "Férfi (18-24)",
    'M.35-44': "Férfi (35-44)",
    'F.25-34': "Nő (25-34)",
    'M.25-34': "Férfi (25-34)",
    'F.65+': "Nő (65+)",
    'F.55-64': "Nő (55-64)",
    'M.65+': "Férfi (65+)",
    'F.35-44': "Nő (35-44)",
    'M.45-54': "Férfi (45-54)",
    'F.18-24': "Nő (18-24)",
    'M.13-17': "Férfi (13-17)",
    'U.18-24': "Ism. (18-24)",
    'U.25-34': "Ism. (25-34)",
    'U.55-64': "Ism. (55-64)",
    'U.65+': "Ism. (65+)",
  };

const StackedBarChart: React.FC<ChartProps> = ({ title, chartData }) => {
    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});

    useEffect(() => {
      if (chartData) {
          const dates = Object.keys(chartData);
          const categories = Object.keys(chartData[dates[0]] || {});

          // Prepare series data
          const seriesData: SeriesData[] = categories.map(category => ({
              name: metricNameMapping[category] || category,
              data: dates.map(date => chartData[date][category] || 0),
          }));

            const optionsData: ApexOptions = {
                xaxis: {
                  categories: dates.map(date => dayjs(date).format('MM.DD.')),
                  title: {
                    text: "Felhasználók",
                  },
                },
                yaxis: {
                  title: {
                    text: "Dátum",
                  },
                },
                fill: {
                  opacity: 1
                },
                chart: {
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true
                    },
                    autoSelected: 'zoom',
                  },
                  type: 'bar',
                  stacked: true,
                  height: 450,
                  stackType: 'normal',
                  dropShadow: {
                    enabled: true,
                    blur: 1,
                    opacity: 0.1
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    dataLabels: {
                      position: 'top', // Display data labels on top of the bar segments
                    },
                  },
                },
                colors: ["#925fff", "#BE3144", "#F8D082", "#F27BBD", "#0766AD", "#A4CE95", "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF", "#33FFA1", "#FF8633", "#33A1FF", "#A1FF33", "#FF3386"],
                markers: {
                  size: 3
                },
                grid: {
                  show: true,
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  width: 1,
                  dashArray: 0,
                  colors: ['#fff']
                },
                legend: {
                  position: 'bottom',
                  horizontalAlign: 'center',
                  onItemClick: {
                    toggleDataSeries: true // Allow hiding/showing series on legend item click
                  },
                  onItemHover: {
                    highlightDataSeries: true // Highlight series on legend item hover
                  }
                },
                tooltip: {
                  y: {
                    formatter(val: number) {
                      return `${val}`;
                    },
                  },
                },
                dataLabels: {
                  enabled: false, // Enable data labels
                  formatter: (val: number) => `${val}`,
                  style: {
                    fontSize: '12px',
                    colors: ['#000']
                  },
                  offsetX: -6,
                  offsetY: 0
                },

                responsive: [{
                  breakpoint: 1000,
                  options: {
                    chart: {
                      height: 300
                    },
                    legend: {
                      position: 'bottom',
                      horizontalAlign: 'center'
                    }
                  }
                }],
                noData: {
                  text: 'Nincs elérhető adat, próbálkozz nagyobb időtartammal.',
                  align: 'center',
                  verticalAlign: 'middle',
                  style: {
                      color: '#000',
                      fontSize: '14px',
                  }
              }
              };
              

            setSeries(seriesData); 
            setOptions(optionsData);
        }
    }, [chartData]);

    return (
        <ReactApexChart
            series={series}
            type="bar"
            height={450}
            options={options}
        />
    );
};

export default StackedBarChart;
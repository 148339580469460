import React, { useEffect, useState } from "react";
import api from "utils/interceptor"
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Box, Stack, Typography, Button, Grid, Divider } from "@mui/material";
import { Tag } from "antd";
import { PaymentUserProps } from "interfaces/oktelo";
import dayjs from "dayjs";
import "./pricing.css";
import { Check } from "@mui/icons-material";
import ActionCard from "components/common/ActionCard";
import apiconfig from "utils/apiconfig";

interface Invoice {
    id: string;
    created: number;
    invoice_pdf: string;
    hosted_invoice_url: string;
}

const OkteloPayment = () => {
    const [error, setError] = useState<string | null>(null); // Changed type to string | null
    const [userStatus, setUserStatus] = useState<PaymentUserProps | null>(null);
    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const handleSubscription = async (planId: number) => {
        try {
            const response = await api.post(`${apiconfig.BACKEND_URI}/payment/checkout`, { planId });
            const { sessionId } = response.data;
    
            const stripe = await loadStripe('pk_test_51PIvbwKvySSw7AX4l5hvvMRA2Z9AOXOy6I26kG248SDVdgRsy4jDYSi8iRF5Qg3ivOAUc8jSMzNXlVNamxleYdqX00ITV1wcU6');
            if (stripe) {
                await stripe.redirectToCheckout({ sessionId });
            } else {
                throw new Error('Stripe is not loaded');
            }
        } catch (error: any) {
            console.error('Error handling subscription:', error);
            alert('Failed to handle subscription: ' + error.message);
        }
    };
 
    const getSubscriptionStatus = async () => {
        try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/payment/`);

            setUserStatus(response.data);
 
            setInvoices(response.data.invoices.data);

        } catch (error) {
            return;
        }

    }

    useEffect(() => {
        getSubscriptionStatus();
    }, []);


    const getSubData = (role: number, info: string) => {
        if (role === 0) {
            if (info === 'type'){
                return 'Ingyenes fiók'
            }
            if (info === 'token') {
                return '0'
            }
            if (info === 'page_count') {
                return '0'
            }
            if (info === 'price') {
                return '0 Ft'
            }
            if (info === 'color') {
                return '#2b2b2b'
            }
        }
        else if (role === 1){
            if (info === 'type'){
                return 'Kezdő'
            }
            if (info === 'token') {
                return '20'
            }
            if (info === 'page_count') {
                return '4'
            }
            if (info === 'price') {
                return '4990 Ft'
            } 
            if (info === 'color') {
                return '#1E1E47'
            }           
        }
        else if (role === 2){
            if (info === 'type'){
                return 'Haladó'
            }
            if (info === 'token') {
                return '50'
            }
            if (info === 'page_count') {
                return '20'
            }
            if (info === 'price') {
                return '7990 Ft'
            }
            if (info === 'color') {
                return '#1E1E47'
            }        
        }
        else if (role === 3){
            if (info === 'type'){
                return 'Prémium'
            }
            if (info === 'token') {
                return '120'
            }
            if (info === 'page_count') {
                return '50'
            }
            if (info === 'price') {
                return '12990 Ft'
            } 
            if (info === 'color') {
                return '#1E1E47'
            }          
        }
    }
    const handleDisableAutoRenewal = async () => {
        try {
            const response = await api.post(`${apiconfig.BACKEND_URI}/payment/subscription/disable-auto-renewal`);
            console.log('Auto-renewal disabled:', response.data);
            getSubscriptionStatus();
        } catch (error) {
            console.error('Error disabling auto-renewal:', error);
        }
    };

    const handleRenewPlanManually = async () => {
        try {
            const response = await api.post(`${apiconfig.BACKEND_URI}/payment/subscription/renew`);
            console.log('Plan renewed manually:', response.data);
            getSubscriptionStatus();
        } catch (error) {
            console.error('Error renewing plan manually:', error);
        }
    };

    const handleCancelPlan = async () => {
        try {
            const response = await api.post(`${apiconfig.BACKEND_URI}/payment/subscription/cancel`);
            console.log('Plan cancelled:', response.data);
            getSubscriptionStatus();
        } catch (error) {
            console.error('Error cancelling plan:', error);
        }
    };


/*         useEffect(() => {
            const fetchInvoices = async () => {
                try {
                    const response = await api.get(`${apiconfig.BACKEND_URI}/payment/invoices`);
                    setInvoices(response.data.invoices);
                } catch (error) {
                    console.error('Error fetching invoices:', error);
                }
            };
    
            fetchInvoices();
        }, []); */
    
        const handleDownloadInvoice = (invoiceUrl: string) => {
            if (invoiceUrl) {
                window.open(invoiceUrl, '_blank');
            } else {
                console.error('Invoice URL not available');
            }
        };
    

    return (

    <Box width="100%" display="flex" alignContent="center" alignItems="center" justifyContent="flex-start" flexDirection="column" gap={3} pt={0}>
            {error && <Box>{error}</Box>}
    <Stack direction="row" justifyContent="flex-start" width="100%">
        <Typography className="size_24 t_oktelo_bold darkblue">Elérhető csomagok</Typography>
    </Stack>
    <Grid container spacing={4} px={4} mb={3}>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
            <Box className="pricing-box white-box">
                <Stack direction="column" gap={2} width="100%">
                    <Typography className="size_14 t_oktelo_semibold darkblue">
                        Kezdő
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={1}>
{/*                     <Typography className="size_20 t_oktelo_normal darkblue" sx={{ textDecoration: 'line-through'}}>
                        8.990Ft
                    </Typography> */}
                    <Typography className="size_24 t_oktelo_bold darkblue">
                        4.990Ft
                    </Typography>
                    <Typography className="size_16 t_oktelo_normal darkblue">
                        / hónap
                    </Typography>
                    </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                        Kóstolj bele az Oktelo adta lehetőségekbe.
                    </Typography>
                    <Stack direction="column" gap={1}>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            Hozzáférés az összes funkcióhoz
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            5 Közösségi oldal
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            Oktelo AI használat
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            5 Oktelo Token / nap
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            50 poszt
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            3 jóváhagyás
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            3 kampány
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            5 hirdetés
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            5 riport havonta
                        </Stack>
                    </Stack>             
                    <button 
                    onClick={() => handleSubscription(1)}
                    disabled={userStatus?.subdata.type === 1}
                    style={{ backgroundColor: userStatus?.subdata.type === 1 ? '#ede4ff' : '#925FFF' }}>
                        {userStatus?.subdata.type === 1 ? 'Jelenlegi' : 'Vásárlás'}
                    </button>
                </Stack>
            </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <Box className="pricing-box white-box">
                <Stack direction="column" gap={2} width="100%">
                    <Typography className="size_14 t_oktelo_semibold darkblue">
                        Haladó
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={1}>
{/*                     <Typography className="size_20 t_oktelo_normal darkblue" sx={{ textDecoration: 'line-through'}}>
                        8.990Ft
                    </Typography> */}
                    <Typography className="size_24 t_oktelo_bold t_purple">
                        7.990Ft
                    </Typography>
                    <Typography className="size_16 t_oktelo_normal darkblue">
                        / hónap
                    </Typography>
                    </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                        Éld át a teljes Oktelo élményt, bővített lehetőségekkel.
                    </Typography>
                    <Stack direction="column" gap={1}>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            Hozzáférés az összes funkcióhoz
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            20 Közösségi oldal
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            Oktelo AI használat
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            20 Oktelo Token / nap
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            120 poszt
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            10 jóváhagyás
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            5 kampány
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            10 hirdetés
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            10 riport havonta
                        </Stack>             
                    </Stack>         
                    <button 
                        onClick={() => handleSubscription(2)}
                        disabled={userStatus?.subdata.type === 2}
                        style={{ backgroundColor: userStatus?.subdata.type === 2 ? '#ede4ff' : '#925FFF' }}>
                        {userStatus?.subdata.type === 2 ? 'Jelenlegi' : 'Vásárlás'}
                        
                    </button>
                </Stack>
            </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <Box className="pricing-box white-box">
                <Stack direction="column" gap={2} width="100%">
                    <Typography className="size_14 t_oktelo_semibold darkblue">
                        Prémium
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={1}>
{/*                     <Typography className="size_20 t_oktelo_normal darkblue" sx={{ textDecoration: 'line-through'}}>
                        8.990Ft
                    </Typography> */}
                    <Typography className="size_24 t_oktelo_bold darkblue">
                        12.990Ft
                    </Typography>
                    <Typography className="size_16 t_oktelo_normal darkblue">
                        / hónap
                    </Typography>
                    </Stack>
                    <Typography className="t_oktelo_normal size_14 darkblue">
                     Oktelo fanatikusoknak, akik nem szeretik a korlátokat.
                    </Typography>
                    <Stack direction="column" gap={1}>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            Hozzáférés az összes funkcióhoz
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            50 Közösségi oldal
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            Oktelo AI használat
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            50 Oktelo Token / nap
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            300 poszt
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            30 jóváhagyás
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            15 kampány
                        </Stack> 
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            20 hirdetés
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1.5} className="pricing-features-white">
                            <Check/>
                            20 riport havonta
                        </Stack>
                    </Stack>             
                    <button 
                    onClick={() => handleSubscription(1)}
                    disabled={userStatus?.subdata.type === 3}
                    style={{ backgroundColor: userStatus?.subdata.type === 3 ? '#ede4ff' : '#925FFF' }}>
                        
                        {userStatus?.subdata.type === 3 ? 'Jelenlegi' : 'Vásárlás'}
                    </button>
                </Stack>
            </Box>
        </Grid>
    </Grid>
    <Stack direction="row" justifyContent="flex-start" width="100%">
    <Typography className="size_24 t_oktelo_bold darkblue">Jelenlegi előfizetésed</Typography>
    </Stack>
    <Grid container spacing={3}>
  {/* Subscription Details Section */}
  <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
    <Box className="oktelo_card">
      {userStatus && (
        <Stack direction="column" gap={2} width="100%">
            <Stack direction="row" justifyContent="flex-start" gap={1} alignItems="center">
                <Typography className="t_oktelo_semibold size_20 darkblue" sx={{ color: getSubData(userStatus.subdata.type, 'color') }}>
                    {getSubData(userStatus.subdata.type, 'type')}
                </Typography>
                <Tag color="pink-inverse" className="size_12 t_oktelo_medium" bordered={false}>
                Státusz: {userStatus.subdata.status} 
                </Tag> 
            </Stack>
            <Typography className="t_oktelo_medium size_16 darkblue">
                {getSubData(userStatus.subdata.type, 'price')} / hónap
            </Typography>
          <Stack direction="column" justifyContent="flex-start" gap={0.5}>
            <Typography className="t_oktelo_normal size_16 darkblue">
              Előfizetés kezdete: {dayjs(userStatus.subdata.start).format('YYYY.MM.DD HH:mm')}
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
            <Typography className="t_oktelo_normal size_16 darkblue">
              Előfizetés lejárata: {userStatus.subdata.end ? dayjs(userStatus.subdata.end).format('YYYY.MM.DD HH:mm') : 'Nincs'}
            </Typography>
            <Tag color="gold-inverse" className="size_12 t_oktelo_medium" bordered={false}>
                Megújítás: {userStatus.subdata.renewal_date ? dayjs(userStatus.subdata.renewal_date).format('YYYY.MM.DD HH:mm') : 'Nincs'}
            </Tag>
            </Stack>
{/*             <Typography className="t_oktelo_normal size_14 miscgray">
            Automatikus-megújítás: <span className="t_purple">{userStatus.subdata.auto_renewal ? 'Bekapcsolva' : 'Kikapcsolva'}</span>
          </Typography> */}
          </Stack>

          <Typography className="t_oktelo_normal size_16 darkblue">
            Fizetési promóciós kód: {userStatus.subdata.promo ?? 'Nem használtál'}
          </Typography>
          <Typography className="t_oktelo_normal size_16 darkblue">
            Oktelo tokenek: {getSubData(userStatus.subdata.type, 'token')} / nap
          </Typography>
          <Stack direction={{ md: 'row', sm: 'column' }} gap={2} mt={2}>
            <ActionCard
              title={`Automatikus fizetés`}
              description="Az előfizetésed automatikusan megújul. Kattints a gombra az automatikus megújítás leállításához."
              buttonText="Stop Auto-fizetés"
              buttonClass="bf7b"
              onClick={handleDisableAutoRenewal}
              status={`${userStatus.subdata.auto_renewal ? 'Bekapcsolva' : 'Kikapcsolva'}`}
            />
            <ActionCard
              title="Manuális fizetés"
              description="Ha az automatikus megújítás ki van kapcsolva, itt manuálisan megújíthatod az előfizetésed."
              buttonText="Hosszabbítás"
              buttonClass="bc6a"
              onClick={handleRenewPlanManually}
              buttonDisabled={userStatus.subdata.auto_renewal}
              status={`${!userStatus.subdata.auto_renewal ? 'Bekapcsolva' : 'Kikapcsolva'}`}
            />
            <ActionCard
              title="Előfizetés lemondása"
              description="Ha lemondod, az előfizetésed nem újul meg a lejárat után."
              buttonText="Lemondás"
              buttonClass="b25f"
              onClick={handleCancelPlan}
              status={userStatus.subdata.cancel_at_period_end
                ? `Lemondva: ${dayjs(userStatus.subdata.canceled_at).format('YYYY.MM.DD HH:mm')}`
                : 'Nem lemondott'
              }
            />
          </Stack>
        </Stack>
      )}
    </Box>
  </Grid>

  {/* Previous Invoices Section */}
  <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
    <Box className="oktelo_card" overflow="auto" justifyContent="flex-start">
      <Stack direction="column" gap={2} width="100%">
        <Typography className="t_oktelo_semibold size_18 darkblue">
          Korábbi befizetéseid
        </Typography>
        <ul className="invoice-list">
          {invoices.map((invoice, index) => (
            <li key={invoice.id}>
              <span>#{index} {dayjs.unix(invoice.created).format('YYYY.MM.DD')}</span>
              <Stack direction="row" gap={2} alignItems="center">
                <button onClick={() => handleDownloadInvoice(invoice.invoice_pdf)}>Letöltés</button>
                <button onClick={() => window.open(invoice.hosted_invoice_url)}>Megnyitás</button>
              </Stack>
            </li>
          ))}
        </ul>
      </Stack>
    </Box>
  </Grid>
</Grid>

    </Box>
    );
};

export default OkteloPayment;

import React, { useState, useEffect } from "react";
import { CampaignProps } from "interfaces/common";
import { Typography, Box, Stack, Avatar, Grid, Button } from "@mui/material";
import {
  Add,
  AddCircleOutlineOutlined,
  DeleteRounded,
  Facebook,
  Instagram,
  SearchRounded,
} from "@mui/icons-material";
import { CustomButton } from "components";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  TagOutlined,
} from "@ant-design/icons";

import { Input, Select, Tag } from "antd";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

import api from "utils/interceptor"
import { useDelete, useNotification } from "@refinedev/core";
import {
  LinkPlaceholder,
  TextPlaceholder,
  VideoPlaceholder,
  okt_placeholer,
} from "assets";
import apiconfig from "utils/apiconfig";

interface CampaignListProps {
  campaigns: CampaignProps[];
  refetch: () => void;
}

const { Option } = Select;

const CampaignList: React.FC<CampaignListProps> = ({ campaigns, refetch }) => {
  const [selectedStatus, setSelectedStatus] = useState<string>("all");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [filteredCampaigns, setFilteredCampaigns] = useState<CampaignProps[]>(
    []
  );
  const [sortByTitleAscending, setSortByTitleAscending] = useState(true);
  const [sortByDateAscending, setSortByDateAscending] = useState(true);
  const navigate = useNavigate();
  const { open } = useNotification();

  const handleDeleteProperty = async (campaign_id: string) => {
    try {
      const response = await api.delete(
        `${apiconfig.BACKEND_URI}/campaign/${campaign_id}`
      );

      if (response.status === 200) {
        open?.({
          type: "success",
          message: "Sikeres törlés",
        });
      }
    } catch (error) {
    } finally {
      refetch();
    }
  };

  const isValidUrl = (urlString: string): boolean => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkIfImageUrl = (url: string): boolean => {
    if (!url) return false;
    const imageExtensions = [".jpeg", ".jpg", ".png", ".gif", ".svg", ".webp"];
    const lowerCaseUrl = url.toLowerCase();
    const isImageExtension = imageExtensions.some((ext) =>
      lowerCaseUrl.includes(ext)
    );

    return isValidUrl(url) && isImageExtension;
  };

  const hImageSrc = (type: string, url: any) => {
    if (type === "textOnly") {
      return TextPlaceholder;
    } else if (type === "photo" || type === "story") {
      return url.photoUrl;
    } else if (type === "video") {
      return url.thumbnailUrl || VideoPlaceholder;
    } else if (type === "carousel") {
      return url.carouselUrls[0];
    } else if (type === "webUrl") {
      return checkIfImageUrl(url.webUrl) ? url.webUrl : LinkPlaceholder;
    } else {
      return okt_placeholer;
    }
  };

  useEffect(() => {
    filterCampaigns();
  }, [campaigns, selectedStatus, searchKeyword]);

  const handleStatusChange = (value: any) => {
    setSelectedStatus(value as string);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleSortByTitle = () => {
    const sortedCampaigns = [...filteredCampaigns].sort((a, b) => {
      if (sortByTitleAscending) {
        return a.title.localeCompare(b.title);
      } else {
        return b.title.localeCompare(a.title);
      }
    });
    setFilteredCampaigns(sortedCampaigns);
    setSortByTitleAscending((prev) => !prev); // Toggle sorting order
  };

  const handleSortByDate = () => {
    const sortedCampaigns = [...filteredCampaigns].sort((a, b) => {
      if (sortByDateAscending) {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      } else {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    });
    setFilteredCampaigns(sortedCampaigns);
    setSortByDateAscending((prev) => !prev); // Toggle sorting order
  };

  const filterCampaigns = () => {
    if (!Array.isArray(campaigns)) {
      return;
    }

    let filtered = [...campaigns];

    if (selectedStatus !== "all") {
      filtered = filtered.filter(
        (campaign) => campaign.status === selectedStatus
      );
    }

    if (searchKeyword.trim() !== "") {
      const keyword = searchKeyword.toLowerCase();
      filtered = filtered.filter((campaign) =>
        campaign.title.toLowerCase().includes(keyword)
      );
    }

    setFilteredCampaigns(filtered);
  };

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <Tag
            bordered={false}
            icon={<ExclamationCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="default"
          >
            Várakozik
          </Tag>
        );
      case "running":
        return (
          <Tag
            bordered={false}
            icon={<ExclamationCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="warning"
          >
            Folyamatban
          </Tag>
        );
      case "scheduled":
        return (
          <Tag
            bordered={false}
            icon={<ClockCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="processing"
          >
            Ütemezett
          </Tag>
        );
      case "completed":
        return (
          <Tag
            bordered={false}
            icon={<CheckCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="success"
          >
            Befejezett
          </Tag>
        );
      default:
        return (
          <Tag
            bordered={false}
            icon={<ClockCircleOutlined />}
            className="tag_oktelo t_oktelo_medium size_14"
            color="default"
          >
            Vázlat
          </Tag>
        );
    }
  };

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Box className="approval-filter-holder">
            <Grid container spacing={1.5} alignItems="center">
              <Grid item xl={3}>
                <Input
                  className="approval-filter-input"
                  placeholder="Cím..."
                  prefix={<SearchRounded sx={{ color: "#d3d3d3" }} />}
                  value={searchKeyword}
                  onChange={handleSearchChange}
                  allowClear
                />
              </Grid>
              <Grid item xl={2}>
                <Select
                  value={selectedStatus}
                  variant="filled"
                  size="middle"
                  className="approval-filter-input"
                  onChange={handleStatusChange}
                  defaultValue="all"
                >
                  <Option value="all">Minden státusz</Option>
                  <Option value="draft">Vázlat</Option>
                  <Option value="pending">Várakozik</Option>
                  <Option value="scheduled">Ütemezett</Option>
                  <Option value="completed">Befejezett</Option>
                  <Option value="running">Folyamatban</Option>
                </Select>
              </Grid>
              <Grid item xl={2}>
                <button
                  className="approval-sortby-button"
                  onClick={handleSortByTitle}
                >
                  {`Cím ${!sortByTitleAscending ? "↑" : "↓"}`}
                </button>
              </Grid>
              <Grid item xl={2}>
                <button
                  className="approval-sortby-button"
                  onClick={handleSortByDate}
                >
                  {`Létrehozva ${!sortByDateAscending ? "↑" : "↓"}`}
                </button>
              </Grid>
              <Grid item xl={3}>
                <button
                  className="home-action-button b925"
                  style={{ width: '100%', height: '40px' }}
                  onClick={() => {
                    navigate("/campaign/create");
                  }}
                >
                  <AddCircleOutlineOutlined/>
                  Új hozzáadása
                </button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
        <Stack
          direction="row"
          alignItems="stretch"
          gap={2}
          width="100%"
          maxHeight={{ lg: "40px", md: "auto" }}
          height={{ lg: "40px", md: "auto" }}
          justifyContent="stretch"
          flexWrap="wrap"
          mb={2.5}
        >
        </Stack>
      <Grid container spacing={2}>
        {filteredCampaigns.map((campaign) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={campaign._id}>
            <Box className="oktelo_card" height="100%">
              <Stack
                direction="column"
                gap={3}
                height="100%"
                width="100%"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography className="t_oktelo_bold size_18 darkblue">
                    {campaign.title}
                  </Typography>
                  {campaign.tag && (
                    <Tag
                      color="default"
                      style={{
                        border: "1px solid",
                        borderColor: campaign.tag.color,
                        color: campaign.tag.color,
                      }}
                      className="tag_oktelo size_12 t_oktelo_medium"
                      icon={<TagOutlined />}
                    >
                      {campaign.tag.title}
                    </Tag>
                  )}
                </Stack>
                <Typography className="t_oktelo_normal size_14 darkblue">
                  {campaign.description}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="0px"
                  flexWrap="wrap"
                >
                  {campaign.posts.length > 0 &&
                    campaign.posts
                      .slice(0, 6)
                      .map((post) => (
                        <Avatar
                          key={post._id}
                          sx={{
                            width: "40px",
                            height: "40px",
                            marginRight: "-10px",
                          }}
                          src={hImageSrc(
                            post.attachment.type,
                            post.attachment.url
                          )}
                          className="campaign-avatar-post"
                          onClick={() => navigate(`/posts/show/${post._id}`)}
                        />
                      ))}
                  <Avatar
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#d9d9d9",
                    }}
                  >
                    <Typography className="t_oktelo_medium size_14 darkblue">
                      {campaign.posts.length}
                    </Typography>
                  </Avatar>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  flexWrap="wrap"
                >
                  {getStatusTag(campaign.status)}
                  <Tag
                    color="purple"
                    bordered={false}
                    className="tag_oktelo size_14 t_oktelo_medium"
                  >
                    {dayjs(campaign.startDate).format("YYYY.MM.DD HH:mm")}
                  </Tag>
                  <Tag
                    color="magenta"
                    bordered={false}
                    className="tag_oktelo size_14 t_oktelo_medium"
                  >
                    {campaign.scheduleInterval}
                  </Tag>
                </Stack>
                <Stack
                  direction="row"
                  gap={1.5}
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2.5}
                  flexWrap="wrap"
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <button
                      className="home-action-button b925"
                      onClick={() => navigate(`show/${campaign._id}`)}
                    >
                      Részletek
                    </button>
{/*                     <button className="home-action-button bc6a">
                      Módosítás
                    </button> */}
                  </Stack>
                  <button
                    className="home-action-button bc6a"
                    onClick={() => handleDeleteProperty(campaign._id)}
                  >
                    <DeleteRounded />
                  </button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CampaignList;

import { Box, Typography, Button, Stack } from "@mui/material";
import { oktelo } from "assets";
import React from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
const navigate = useNavigate();
    return (
        <Box width="100%" display="flex" alignContent="center" alignItems="center" justifyContent="center" height="80vh">
            <Stack direction="column" gap={3} alignContent="center" alignItems="center">
                <img src={oktelo} width="240px"/>
                <Typography className="t_oktelo_semibold size_18 darkblue">
                    Az oldal nem található!
                </Typography>
                <Typography className="t_oktelo_normal size_14 darkblue">
                    Hoppá! A keresett oldalt nem található. 
                </Typography>
                <button className="home-action-button b925" onClick={()=>navigate('/home')}>
                    Vezérlőpult
                </button>
            </Stack>
        </Box>
    )
}

export default ErrorPage;
import axios from 'axios';
import apiconfig from './apiconfig';
import { authProvider } from './authProvider';

// Create axios instance
const api = axios.create({
    baseURL: apiconfig.BACKEND_URI,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

// Request interceptor for adding the token
api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('auth');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

const handleTokenRefresh = async (originalRequest: any) => {
  const refreshToken = document.cookie.split('; ').find(row => row.startsWith('refreshToken='))?.split('=')[1];

  if (!refreshToken) {
      console.log("[LOGOUT] Refresh token error found!");
      await authProvider.logout({});
      return Promise.reject(new Error('No refresh token'));
  }

  try {
      const response = await api.post(`${apiconfig.BACKEND_URI}/users/refreshtoken`, {}, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${refreshToken}`,
          },
      });

      const newToken = response.data.token;
      localStorage.setItem('auth', newToken);
      api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      return api(originalRequest); // Retry the original request with the new token
  } catch (err) {
      console.error("Error during token refresh:", err);
      // Log out on refresh token error, but ensure this doesn't trigger further requests
      await authProvider.logout({});
      return Promise.reject(err);
  }
};


// Response interceptor for handling errors
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        
        // Handle token expiration and refresh logic
        if (error.response && error.response.status === 401) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                return handleTokenRefresh(originalRequest);
            } else {
                // Log out if the refresh fails
                console.log("[LOGOUT] Refresh token failed.");
                await authProvider.logout({});
                return Promise.reject(error);
            }
        }
        
        return Promise.reject(error);
    }
);

const checkTokenValidity = async () => {
  const token = localStorage.getItem('auth');
  if (!token || isTokenExpired(token)) {
      await handleTokenRefresh(null);
  }
};


const isTokenExpired = (token: string) => {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime >= payload.exp;
};

// Call this function when your app initializes
checkTokenValidity();

export default api;

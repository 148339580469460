import React, { Suspense, lazy } from 'react';
import {
  Authenticated,
  Refine,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  notificationProvider,
  RefineSnackbarProvider,
} from "@refinedev/mui";

import { ThemedLayoutV2 } from "components/layout";
import {
  AccountBalanceWalletOutlined,
  AdsClickOutlined,
  ArticleOutlined,
  AutoFixHighOutlined,
CalendarMonthOutlined,
  CampaignOutlined,
  CheckBoxOutlined,
  DashboardOutlined,
  ExtensionOutlined,
  HelpCenterOutlined,
InterestsOutlined,
LanguageOutlined,
ManageAccountsOutlined,
PermMediaOutlined,
PublicOutlined,
RocketOutlined,
ScheduleSendOutlined,
SettingsOutlined,
}
from '@mui/icons-material'
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { MuiInferencer } from "@refinedev/inferencer/mui";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import AnalyticsHub from "pages/analyticshub";
import CreatePost from "components/common/oktelo_forms/CreatePost";
import AuthLayout from "pages/auth/AuthLayout";
import apiconfig from "utils/apiconfig";
import { authProvider } from "utils/authProvider";
import AdminPanel from "pages/admin/adminpanel";

import { LoginPage, RegisterPage, ForgotPasswordPage } from 'pages';
import LazyLoadComponent from 'utils/lazyload';

const MyMedia = LazyLoadComponent(() => import("pages/media/mymedia"));
const IGSiteData = LazyLoadComponent(() => import("pages/sitedata/igsitedata"));
const SocialPostAnalytics = LazyLoadComponent(() => import("pages/post/socialpostanalytics"));
const SocialPosts = LazyLoadComponent(() => import("pages/post/socialposts"));
const PostEdit = LazyLoadComponent(() => import("pages/post/postedit"));
const PostViewDetails = LazyLoadComponent(() => import("pages/post/postview"));
const PostsPage = LazyLoadComponent(() => import("pages/post/posts"));
const SiteData = LazyLoadComponent(() => import("pages/sitedata/sitedata"));
const Websites = LazyLoadComponent(() => import("pages/websites"));
const PreviewCreate = LazyLoadComponent(() => import("pages/preview/previewcreate"));
const PreviewDisplay = LazyLoadComponent(() => import("pages/preview/previewdisplay"));
const PreviewList = LazyLoadComponent(() => import("pages/preview/previewlist"));
const PaymentSuccess = LazyLoadComponent(() => import("./pages/payments/success"));
const OkteloPayment = LazyLoadComponent(() => import("pages/payments/payment"));
const CreateHUB = LazyLoadComponent(() => import("pages/createhub"));
const ErrorPage = LazyLoadComponent(() => import("./pages/errorpage"));
const Home = LazyLoadComponent(() => import("./pages/home"));
const CalendarPage = LazyLoadComponent(() => import("pages/calendar"));
const AdsHome = LazyLoadComponent(() => import("pages/ads/adshome"));
const OkteloAI = LazyLoadComponent(() => import("pages/okteloai"));
const Settings = LazyLoadComponent(() => import("pages/settings"));
const Help = LazyLoadComponent(() => import("pages/help"));
const Campaign = LazyLoadComponent(() => import("pages/campaign/campaign"));
const CampaignCreator = LazyLoadComponent(() => import("pages/campaign/campaign-creator"));
const CampaignEditor = LazyLoadComponent(() => import("pages/campaign/campaigndisplay"));
/* const PageAnalytics = lazy(() => import("pages/PageAnalytics")); */
/* const CreateAI = lazy(() => import("pages/CreateAI")); */
const TagsHome = LazyLoadComponent(() => import("pages/tags"));
const CampaignDisplay = LazyLoadComponent(() => import("pages/campaign/campaigndisplay"));
/* const ForgotPasswordPage = lazy(() => import("pages/auth/_forgotpassword")); */
const ScheduledPosts = LazyLoadComponent(() => import("pages/post/scheduledposts"));



const App = () => {
    const customTitleHandler = ( ) => {
      let title = "Oktelo App";

      return title;
    };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <DocumentTitleHandler /* handler={customTitleHandler} */ />
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
           <RefineSnackbarProvider> 
              <Refine
                dataProvider={dataProvider(apiconfig.BACKEND_URI)}
                 notificationProvider={notificationProvider} 
                routerProvider={routerBindings}
                authProvider={authProvider} 
                resources={[
                  {
                    name: "home",
                    list: MuiInferencer,
                    options: { label: 'Vezérlőpult'},
                    icon: <DashboardOutlined/>
                  },
/*                     {
                    name: "oktelohub",
                    list: AnalyticsHub,
                    options: { label: 'Oktelo hub'},
                     icon: <ScienceOutlined/>, 

                  },   */
                  {
                    name: "mymedia",
                    list: MyMedia,
                    options: { label: 'Média'},
                     icon: <PermMediaOutlined/>, 
/*                     icon: "🔎" */
                  },  

                  {
                    name: "create",
                    list: CreateHUB,
                    options: { label: 'Létrehozás'},
                    icon: <AutoFixHighOutlined/>,
                    /* icon: "✨", */
                  },
                  {
                    name: "_posts",
                    list: PostsPage,
                    show: PostViewDetails,
                    create: CreatePost,
                    edit: PostEdit,
                    options: { label: 'Bejegyzések'},
                    icon: <ArticleOutlined/>,
                  },
                  {
                    name: "posts",
                    list: PostsPage,
                    show: PostViewDetails,
                    create: CreatePost,
                    edit: PostEdit,
                    options: { label: 'Oktelo'},
                    icon: <InterestsOutlined/>,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "posts/social",
                    list: SocialPosts,
                    options: { label: 'Közösségi'},
                    icon: <PublicOutlined/> ,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "posts/scheduled",
                    list: ScheduledPosts,
                    options: { label: 'Ütemezések'},
                    icon: <ScheduleSendOutlined/> ,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "props",
                    list: TagsHome,
                    options: { label: 'Sablonok'},
                    icon: <ExtensionOutlined/> ,
                    meta: { parent: '_posts' },
                  },
                  {
                    name: "approvals",
                    list: PreviewList,
                    show: PreviewDisplay,
                    create: PreviewCreate,
                    options: { label: 'Jóváhagyások'},
                    icon: <CheckBoxOutlined/>
                  },
                  {
                    name: "ads",
                    list: AdsHome,
                    options: { label: 'Hirdetések'},
                     icon: <AdsClickOutlined/> 
/*                     icon: "💰" */
                  },
                  {
                    name: "websites",
                    list: Websites,
                    options: { label: 'Weboldalak'},
                    icon: <LanguageOutlined/>
                  },
                  {
                    name: "calendar",
                    list: CalendarPage,
                    options: { label: 'Naptár'},
                     icon: <CalendarMonthOutlined/> 
/*                    icon: "📅", */
                  },
                  {
                    name: "postanalytics",
                    show: SocialPostAnalytics,
                    options: { label: 'Poszt analízis'},
                    icon: <CalendarMonthOutlined/>,
                    meta: { hide: true}
                  },
                  {
                    name: "campaign",
                    list: Campaign,
                    show: CampaignDisplay,
                    create: CampaignCreator,
                    edit: CampaignEditor,
                    options: { label: 'Kampányok'},
                     icon: <CampaignOutlined/>, 
                  },
                  {
                    name: "ai",
                    list: OkteloAI,
                    options: { label: 'OkteloAI'},
                     icon: <RocketOutlined/>, 
/*                     icon: "🤖", */
                  },
                  {
                    name: "settings_",
                    list: Settings,
                    options: { label: 'Beállítások'},
                     icon: <SettingsOutlined/>, 
/*                     icon: "⚙️", */
                  },
                  {
                    name: "settings",
                    list: Settings,
                    options: { label: 'Profil'},
                     icon: <ManageAccountsOutlined/>, 
                    meta: {parent: "settings_"}
                  },
                  {
                    name: "subscription",
                    list: OkteloPayment,
                    options: { label: 'Előfizetés'},
                     icon: <AccountBalanceWalletOutlined/>, 
                    meta: {parent: "settings_"}
                  },                    
                  {
                    name: "help",
                    list: Home,
                    options: { label: 'Segítség'},
                     icon: <HelpCenterOutlined/>, 
/*                     icon: "💡" */
                  },
/*                   {
                    name: "oktelo-ai-create",
                    list: CreateAI,
                    options: { label: 'AI Poszt'},
                    meta: { hide: true}
                  }, */

                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "pmMrRk-3tYk6o-dzqmD5",
                  liveMode: "auto"
                }}
                
              >
               
               <Suspense fallback={<div>Betöltés...</div>}>
                <Routes>
                <Route path="/previews/show/:previd" element={<PreviewDisplay />} />
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/login" />}
                      >

                      <ThemedLayoutV2 Header={() => <Header sticky={true} />}>

                          <Outlet />

                      </ThemedLayoutV2>
                      </Authenticated>
                    } 
                  >
                    <Route
                      index
                      element={<NavigateToResource resource="home" />}
                    />
                    <Route path="/home">
                      <Route index element={<Home />} />
                    </Route>
                    <Route path="/settings" element={<Settings />} />
                    {/* <Route path="/create" element={<Navigate to="/createhub" />} /> */}
                    <Route path="/posts">
                      <Route index element={<PostsPage />} />
                      <Route path="edit/:id" element={<PostEdit />} />
                      <Route path="social" element={<SocialPosts />} />
                      <Route path="scheduled" element={<ScheduledPosts />} />
                      <Route path="show/:id" element={<PostViewDetails />} />
                    </Route>
                    <Route path="/approvals">
                      <Route index element={<PreviewList/>} />
                      {/* <Route path="show/:previd" element={<PreviewDisplay/>} /> */}
                      <Route path="create" element={<PreviewCreate/>} />
                    </Route>
{/*                     <Route path="/postwriter">
                      <Route index element={<CreateAI/>} />
                    </Route> */}
                    <Route path="/create">
                      <Route index element={<CreateHUB/>} />
                    </Route>       
                    <Route path="/mymedia">
                      <Route index element={<MyMedia/>} />
                    </Route>                   
                    <Route path="/posts">
                      <Route index element={<PostsPage/>} />
                    </Route> 
                    <Route path="/calendar">
                      <Route index element={<CalendarPage/>} />
                    </Route>
                    <Route path="/site/:pageid">
                      <Route index element={<SiteData/>} />
                    </Route>
                    <Route path="/igsite/:pageid">
                      <Route index element={<IGSiteData/>} />
                    </Route>     
                    <Route path="/props">
                      <Route index element={<TagsHome/>} />
                    </Route>  
                      <Route path="/websites">            
                        <Route index element={<Websites/>} />
                      </Route>
{/*                     <Route path="/analytics/:type/:id">
                      <Route index element={<PageAnalytics/>} />
                    </Route> */}
                    <Route path="/postanalytics/:id">
                      <Route index element={<SocialPostAnalytics/>} />
                    </Route>
                    <Route path="/linkedin">
                      <Route index element={<LinkedInCallback/>} />
                    </Route>
                    <Route path="/help">
                      <Route index element={<Help/>} />
                    </Route>
{/*                     <Route path="/oktelohub">
                      <Route index element={<AnalyticsHub/>} />
                      <Route path=":type/:id" element={<PageAnalytics/>} />
                    </Route> */}
                    <Route path="/ai">
                      <Route index element={<OkteloAI/>} />
                    </Route>
                    <Route path="/subscription">
                      <Route index element={<OkteloPayment/>} />
                    </Route>
                    <Route path="/success">
                      <Route index element={<PaymentSuccess/>} />
                    </Route>
                    <Route path="/campaign">
                      <Route index element={<Campaign />} />
                      <Route path="show/:id" element={<CampaignDisplay />} />
                      <Route path="create" element={<CampaignCreator />} />
                      <Route path="edit/:id" element={<CampaignEditor />} />
                      {/* <Route path="show/:id" element={<PostDetails />} /> */}
                    </Route>
                    <Route path="/adminboard">
                      <Route index element={<AdminPanel/>}/>
                    </Route>
                    <Route path="/ads">
                      <Route index element={<AdsHome/>}/>
                    </Route>
                    <Route path="*" element={<ErrorPage />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<AuthLayout><LoginPage /></AuthLayout>} />
                    <Route path="/forgot-password" element={<AuthLayout><ForgotPasswordPage /></AuthLayout>} />
                    <Route path="/register" element={<AuthLayout><RegisterPage /></AuthLayout>} />
                  </Route>
                </Routes>
                </Suspense>
                <RefineKbar />
                <UnsavedChangesNotifier />
                
                <DocumentTitleHandler handler={customTitleHandler}/>
                
              </Refine>
           </RefineSnackbarProvider> 
      </RefineKbarProvider>
    </BrowserRouter>
  );
}


export default App;

import React, { useState, useEffect } from "react";
import api from "utils/interceptor"
import { FieldValues } from 'react-hook-form';
import { Input, Form, Select, Checkbox, Tag } from "antd";
import { Box, Button, Stack, Typography, Grid, MenuItem } from "@mui/material";
import { OkteloPostProps } from "interfaces/oktelo";
import { useNotification } from "@refinedev/core";
import { useLocation, useNavigate } from "react-router-dom";
import { ArticleOutlined, CancelOutlined, CheckBox, CheckCircleOutline, CollectionsOutlined, Facebook, FormatAlignCenterOutlined, Instagram, LinkOutlined, LocalCafeOutlined, Lock, OfflineBoltOutlined, OndemandVideoOutlined, PendingOutlined, PhotoCameraBackOutlined, Schedule, LinkedIn } from "@mui/icons-material";
import { FacebookFilled, InstagramFilled } from '@ant-design/icons';
import dayjs from "dayjs";
import { LinkPlaceholder, TextPlaceholder, VideoPlaceholder, okt_placeholer } from "assets";
import apiconfig from "utils/apiconfig";

const { Option } = Select; 

const PreviewCreate = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState<any>(undefined);
    const { open } = useNotification();
    const [ isEveryone, setEveryone ] = useState<boolean>(false);
    const navigate = useNavigate();
    const [password, setPassword] = useState<string>('');
    const [postsToApprove, setPostsToApprove] = useState<string[]>([]);

    const { state } = useLocation();

    useEffect(() => {
        if (state && state.selected) {
          setPostsToApprove((prevPosts) => [...prevPosts, state.selected]);
        }
      }, [state]);

    const [isPwError, setPwError] = useState<boolean>(false);

    const handlePostSelect = (id: string) => {
        // Create a copy of the current state to avoid direct mutation
        const updatedPosts = [...postsToApprove];
    
        if (updatedPosts.includes(id)) {
            // Remove the ID from the array
            const index = updatedPosts.indexOf(id);
            if (index > -1) {
                updatedPosts.splice(index, 1);
            }
        } else {
            // Add the ID to the array
            updatedPosts.push(id);
        }
    
        // Update the state with the modified array
        setPostsToApprove(updatedPosts);
    };

    const validateInput = (value: any) => {
      const regex = /^[a-zA-Z0-9]*$/;
      return regex.test(value);
    };
  
    const generateRandomCode = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    };
  
    const handleInputChange = (e: any) => {
      const value = e.target.value;
      if (validateInput(value) && value.length <= 8) {
        setPassword(value);
        setPwError(false);
        form.setFieldsValue({ password: value });
      } else {
        setPwError(true);
      } 
    };
  
    const handleGenerateCode = () => {
      const randomCode = generateRandomCode();
      setPassword(randomCode);
      form.setFieldsValue({ password: randomCode });
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get(`${apiconfig.BACKEND_URI}/data/appovalposts`);
            setData(response.data);
            console.log(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
      }, []);

    type PreviewFormData = {
        name: string,
        description: string,
        posts: string[],
        access: {
            everyone: boolean,
            password?: string,
        }
    }

    const createNewPreview = async (data: FieldValues) => {
        try {

        if (postsToApprove.length < 1) {
            return open?.({
                type: 'error',
                message: 'Legalább egy posztot ki kell választanod.'
            })
        }

        if (data.name.length < 3) {
            return open?.({
                type: 'error',
                message: 'A jóváhagyás elnevezése legyen minimum 3 karakter.'
            })
        }

        if (!isEveryone && (!data.password || data.password < 3)) {
            return open?.({
                type: 'error',
                message: 'A biztonsági kód legyen minimum 3 karakter.'
            })
        }

        const prevData: PreviewFormData = {
            name: data.name,
            description: data.description,
            posts: postsToApprove,
            access: {
                everyone: isEveryone,
                password: data.password?? null,
            }
        };

        const response = await api.post(`${apiconfig.BACKEND_URI}/previews/create`, {prevData: prevData});

        if(response.status === 200){
            open?.({
                type: 'success',
                message: 'Sikeresen létrehoztad a jóváhagyást.'
            })
            return navigate('/approvals');
        } else {
            open?.({
                type: 'error',
                message: 'Hiba történt.'
            })     
        }

        } catch (error: any) {
            console.error(error.message);
        }
    }

    const handleAccess = () => {
        if (isEveryone) {
            setEveryone(false);
        }
        else {
            setEveryone(true); 
        }
    }

    const returnImageSrc = (type: string, url: any) => {
        if (type === "textOnly") {
          return TextPlaceholder;
        } else if (type === "photo" || type === "story") {
          return url.photoUrl;
        } else if (type === "video") {
          return url.thumbnailUrl || VideoPlaceholder;
        } else if (type === "carousel") {
          return url.carouselUrls[0];
        } else if (type === "webUrl") {
          return LinkPlaceholder;
        } else {
          return okt_placeholer;
        }
      };

      const iconMap: Record<string, JSX.Element> = {
        textOnly: <FormatAlignCenterOutlined />,
        video: <OndemandVideoOutlined />,
        photo: <PhotoCameraBackOutlined />,
        webUrl: <LinkOutlined />,
        story: <LocalCafeOutlined />,
        carousel: <CollectionsOutlined />,
        default: <ArticleOutlined />,
      };

      const approvalMap: Record<string, JSX.Element> = {
        approved: <CheckCircleOutline sx={{ color: "#00991f" }} />,
        pending: <PendingOutlined sx={{ color: "#3268bd" }} />,
        rejected: <CancelOutlined sx={{ color: "#a50000" }} />,
        noNeed: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
        default: <OfflineBoltOutlined sx={{ color: "#999999" }} />,
      };

      const returnIcon = (type: string) => iconMap[type] || iconMap.default;
      const returnApproval = (status: string) =>
        approvalMap[status] || approvalMap.default;

    return (
        <Box width="100%">
            <Grid container spacing={2}>
                <Grid item xl={12}>
                    <Typography className="size_24 t_oktelo_bold darkblue">
                        Jóváhagyás létrehozása
                    </Typography>
                    <Typography className="t_oktelo_normal size_14 darkblue" mb={4}>
                            Itt tudsz létrehozni egy jóváhagyást, amihez több posztot is hozzáadhatsz egyszerre. Minden jóváhagyáshoz tartozik
                            egy URL, amivel elérhető lesz Oktelo felhasználó nélkül.
                    </Typography>
                    <Box>
                    <Form 
                    form={form} onFinish={createNewPreview}
                    layout="vertical" variant="outlined" className="ai_form" scrollToFirstError
                    style={{ width: '100%' }}
                    requiredMark={false}
                    >
                        <Box className="form_item_stepbystep">
                        <Form.Item required name="name" label="Jóváhagyás címe" className="t_oktelo_semibold size_16 darkblue"
                        extra="Ezzel tudod megkülönböztetni a jóváhagyásokat. Nem fog látszódni külső személyeknek.">
                            <Input placeholder="Írd be a jóváhagyásod elnevezését"
                                count={{
                                    show: true,
                                    max: 48,
                                }}
                            />
                        </Form.Item>
                        <Form.Item required name="description" label="Leírás" className="t_oktelo_semibold size_16 darkblue"
                        extra="Egyedi megjegyzés a jóváhagyáshoz.">
                            <Input placeholder="Ez egy példa jóváhagyás..."
                                count={{
                                    show: true,
                                    max: 120,
                                }}
                            />
                        </Form.Item>
                        </Box>
                        <Box className="form_item_stepbystep">
                        <Form.Item required name="everyone" label="Hozzáférés">
                            <Checkbox onChange={() => handleAccess()}>A linken kereszül bárki (Nem ajánlott)</Checkbox>
                        </Form.Item>
                        {!isEveryone && 
                            <Form.Item
                                name="password"
                                label="Hozzáférési kód"
                                style={{ marginBottom: '1px' }}
                                extra={isPwError? <Typography className="t_oktelo t_misc t_red">Csak az angol ABC betűi és számok engedélyezettek.</Typography> : 'Ha nem akarod, hogy bárki hozzáférhessen a jóváhagyáshoz, beállíthatsz egy 8 karakteres biztonsági kódot.'}
                            >
                                <Input
                                placeholder="1B9A4c44"
                                value={password}
                                showCount
                                onChange={handleInputChange}
                                maxLength={8}
                                />
                            </Form.Item>
                        }
                        {!isEveryone && 
                        <Button
                            className="oktelo_card_button"
                            onClick={handleGenerateCode}
                            style={{ marginBottom: '20px' }}
                        >
                            <Lock className="size_14" sx={{ marginRight: '4px'}} /> Kódgenerálás
                        </Button>}
                        </Box>
                        <Box className="form_item_stepbystep">
                        <Form.Item style={{ marginBottom: 1 }}>
                            <button type="submit" className="postview-action purple-action" style={{ width: 'auto', padding: '9px 20px' }}>
                                <CheckBox sx={{ marginRight: '6px', fontSize: '16px'}}/>Megerősítés és létrehozás ({postsToApprove.length} poszt)
                            </button>
                        </Form.Item>
                        </Box>
                    </Form>
                    </Box>
                </Grid>
                
                <Grid item xl={12}>

                <Typography className="t_oktelo_semibold size_16 darkblue" mb={2}>Válaszd ki a bejegyzéseket</Typography>

                    <Grid container spacing={2}>
                        {data && data.length > 0 && data.map((post: OkteloPostProps) => (
                            <Grid item xl={3} key={post._id}>
                                <Box className="post-grid-card" onClick={() => handlePostSelect(post._id)}
                                sx={{ cursor: 'pointer', border: postsToApprove.includes(post._id) ? '2px solid #925FFF' : '0' }}>
                                    <Box className="post-grid-type-box">
                                    {returnIcon(post.attachment.type)}
                                    </Box>
                                    {post.approval.isNeeded && (
                                    <Box className="post-grid-approval-box">
                                        {returnApproval(post.approval.status)}
                                    </Box>
                                    )}
                                    <img
                                    src={returnImageSrc(post.attachment.type, post.attachment.url)}
                                    style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                                    />
                                    <Box px={1.5}>
                                    <Typography className="darkblue size_12 t_oktelo_normal">
                                        {post.message ? post.message.slice(0, 32) + "..." : null}
                                    </Typography>
                                    </Box>
                                    <Stack direction="row" alignItems="center" gap={1} px={1.5}>
                                    <Tag
                                        className="tag_oktelo size_12 t_oktelo_medium"
                                        color={
                                        post.platform === "facebook"
                                            ? "blue"
                                            : post.platform === "instagram"
                                            ? "magenta"
                                            : "geekblue"
                                        }
                                        bordered={false}
                                    >
                                        {post.platform === "facebook" ? (
                                        <Facebook sx={{ fontSize: "12px" }} />
                                        ) : post.platform === "instagram" ? (
                                        <Instagram sx={{ fontSize: "12px" }} />
                                        ) : (
                                        <LinkedIn sx={{ fontSize: "12px" }} />
                                        )}
                                        {post.parent &&
                                        typeof post.parent === "object" &&
                                        post.parent.name
                                        ? post.parent.name
                                        : "Nem kapcsolt"}
                                    </Tag>
                                    <Tag
                                        className="tag_oktelo size_12 t_oktelo_medium"
                                        bordered={false}
                                        color="purple"
                                    >
                                        <Schedule sx={{ fontSize: "12px" }} />
                                        {post.calendarDate
                                        ? dayjs(post.calendarDate).format("YYYY.MM.DD HH:mm")
                                        : "Nem ütemezett"}
                                    </Tag>
                                    </Stack>
                                </Box>
                                </Grid>

                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PreviewCreate